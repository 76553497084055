<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="800px">
      <v-card>
        <v-toolbar :color="profile.organization.color" dark>
            <span class="headline">Ficha Tecnica del Producto</span>
          <v-spacer></v-spacer>
            <v-btn icon dark @click="cerrar()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar>
        <v-card-text>
          <v-container>
              <v-row>
              <v-col cols="12" sm="12">
              <span class="headline" style="color: black"> Codigo de Producto: {{ productcode }}</span>
              </v-col>
              <v-col cols="12" sm="12">
              <span class="headline" style="color: black">{{ formProductName }}</span>
              </v-col>
              <!--<v-col cols="12" sm="12" offset="5">
              <span class="headline" style="color: black">Producto</span>
              </v-col>-->
                <v-col cols="12" sm="12" offset="4">
                <img
                  :src="formImagen"
                  height="auto"
                  width="200px"
                >
                </v-col>
                <v-col cols="4" sm="4">
                <span class="headline" style="color: black">Peso: </span>
                  <v-text-field v-model="peso" readonly></v-text-field>
                </v-col>
                <v-col cols="4" sm="4">
                <span class="headline" style="color: black">Marca: </span>
                  <v-text-field v-model="marca" readonly></v-text-field>
                </v-col>
                <v-col cols="4" sm="4">
                <span class="headline" style="color: black">Codigo Proveedor: </span>
                  <v-text-field v-model="cf_560" readonly></v-text-field>
                </v-col>
                <v-col cols="4" sm="4">
                <span class="headline" style="color: black">Familia: </span>
                  <v-text-field v-model="productfamily" readonly></v-text-field>
                </v-col>
                <v-col cols="4" sm="4">
                <span class="headline" style="color: black">Perfil: </span>
                  <v-text-field v-model="perfilserie" readonly></v-text-field>
                </v-col>
                <v-col cols="4" sm="4">
                <span class="headline" style="color: black">Unidad: </span>
                  <v-text-field v-model="unidad" readonly></v-text-field>
                </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-container>
        <v-col cols="12" sm="12" offset="4">
          <span class="headline">Lista de Precios</span>
        </v-col>
        <v-card class="mx-auto" width="98%">
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              loading="true"
              loading-text="Cargando Datos"
              sort-by="productname"
              class="elevation-24"
              :header-props="{
                sortByText: 'Ordenar',
              }"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',
                'items-per-page-text': 'productos por página',
              }"
              multiSort
            >
            </v-data-table>
          </v-card>
        </v-container>
        <v-col cols="12" sm="12" offset="4">
          <span class="headline">Lista de Existencia</span>
        </v-col>
        <v-container>
        <v-card class="mx-auto" width="98%">
            <v-data-table
              :headers="heading"
              :items="item"
              :search="search"
              loading="true"
              loading-text="Cargando Datos"
              sort-by="productname"
              class="elevation-24"
              :header-props="{
                sortByText: 'Ordenar',
              }"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',
                'items-per-page-text': 'productos por página',
              }"
              multiSort
            >
            </v-data-table>
          </v-card>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn color="red" text @click="cerrar()"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapState, mapActions, mapMutations } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';

export default {
  name: 'user-list',
  data: () => ({
    search: '',
    headers: [
      { text: 'Organización', value: 'organizacion', align: 'center' },
      { text: 'Canal', value: 'canal', align: 'center' },
      { text: 'Sector', value: 'sector', align: 'center' },
      { text: 'Lista', value: 'bookname', align: 'center' },
      { text: 'Precio', value: 'listprice', align: 'center' },
      { text: 'Fecha de Actualizacion', value: 'fecha', align: 'center' },
      { text: 'Fecha de Vencimiento', value: 'fecha_vencimiento', align: 'center' },
    ],
    heading: [
      { text: 'Centro', value: 'centro', align: 'center' },
      { text: 'Almacen', value: 'almacen', align: 'center' },
      { text: 'Existencia', value: 'existencia', align: 'center' },
      { text: 'Consignacion', value: 'consignacion', align: 'center' },
      { text: 'Fecha de Actualizacion', value: 'fecha', align: 'center' },
    ],
  }),
  computed: {
    ...mapState('products', ['item', 'items', 'show', 'imagenurl', 'productid', 'productcode', 'peso', 'marca', 'cf_560', 'productfamily', 'perfilserie', 'unidad', 'productname']),
    profile() {
      return Profile.get();
    },
    formProductId: {
      set(value) {
        this.$store.dispatch('products/toAssignProductId', value);
      },
      get() {
        return this.productid;
      },
    },
    formProductName: {
      set(value) {
        this.$store.dispatch('products/toAssignProductName', value);
      },
      get() {
        return this.productname;
      },
    },
    formImagen: {
      set(value) {
        this.$store.dispatch('products/toAssignImagen', value);
      },
      get() {
        return this.imagenurl;
      },
    },
  },
  methods: {
    ...mapMutations('products', ['SHOW']),
    ...mapActions('products', ['cerrar']),
    data() {
      return {
        productid: this.productid,
        company_id: this.profile.organization.id,
      };
    },
  },
};
</script>
