<template>
  <v-app>
    <router-view />
    <message></message>
  </v-app>
  </template>

<script>
// eslint-disable-next-line import/extensions
import Message from './components/Message';
// eslint-disable-next-line import/extensions
// import SetLogout from './components/SetLogout';

export default {
  name: 'App',
  components: {
    Message,
  },
};

</script>
