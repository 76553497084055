<template>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card>
        <v-toolbar :color="profile.organization.color" dark>
          <v-card-title>
            <span class="headline">{{title}} ROL</span>
          </v-card-title>
          <v-spacer></v-spacer>
            <v-btn icon dark @click="reset()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
        </v-toolbar>
        </v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nombre del Rol *"
                  required
                  v-model="formRol"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Observacion"
                  v-model="formnotas"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-card>
            <v-card-text>
              <v-container class="roles-checkbox-container">
                </v-container>
                <v-container class="roles-checkbox-container">
                 <v-row>
                  <v-col
                    v-for="(item, index) in modules" :key="index"
                    cols="12"
                    xs="6"
                    sm="6"
                    md="4"
                  >
                    <input class="pt-4" type="checkbox" :id="item.descripcion" v-model="item.estatus">
                    <label :for="item.descripcion">{{ item.descripcion }}</label>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-card-text>
        <!--<label>{{ this.status }}</label>-->
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn color="red" text @click="reset()"> Cerrar</v-btn>
            <v-btn color="blue darken-1" text @click="add()" v-show="isAdd"> Agregar</v-btn>
            <v-btn color="blue darken-1" text @click="update()" v-show="isUpdate"> Editar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapActions, mapState } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import { store, updated } from '../../config/api/roles';

export default {
  name: 'roles-form',
  data() {
    return {
      status: true,
      modulo: {
        selected: [],
        accionModule: 0,
      },
    };
  },
  computed: {
    ...mapState('roles', ['id', 'accion', 'accionRol', 'nombre', 'notas', 'title', 'item', 'show', 'isAdd', 'isUpdate', 'modules']),
    formId: {
      set(value) {
        this.$store.dispatch('roles/toAssignId', value);
      },
      get() {
        return this.id;
      },
    },
    formRol: {
      set(value) {
        this.$store.dispatch('roles/toAssignRol', value);
      },
      get() {
        return this.nombre;
      },
    },
    formnotas: {
      set(value) {
        this.$store.dispatch('roles/toAssignObservacion', value);
      },
      get() {
        return this.notas;
      },
    },
    formModulo: {
      set(value) {
        this.$store.dispatch('roles/toAssignAccion', value);
      },
      get() {
        return this.accion;
      },
    },
    profile() {
      return Profile.get();
    },
  },
  methods: {
    metodo(valor) {
      const result = valor.substring(0, 1) === this.accionModule;
      this.accionModule = valor.substring(0, 1);
      return !result;
    },
    isChecked(obj) {
      return (typeof obj.status === 'boolean')
        ? obj.status
        : typeof obj.status === 'string' && obj.status === '1';
    },
    ...mapActions('roles', ['add', 'reset', 'update', 'toAssignAccion', 'getModules']),
    data() {
      console.log(this.modules);
      return {
        rolecompany: {
          organization_id: this.profile.organization.id,
          nombre: this.formRol,
          notas: this.formnotas,
          status: 1,
          idrol: this.formId,
        },
        modulos: this.modules,
      };
    },
    async add() {
      this.response(await store(this.data()));
    },
    async update() {
      this.response(await updated(this.data()));
    },
    response(api) {
      this.$store.commit('message/LOADING', true);
      try {
        this.$store.dispatch('roles/reset');
        this.$store.commit('message/MESSAGE', api.data.message);
        this.$store.commit('message/TYPE', 'success');
        this.$store.dispatch('message/run');
        this.$store.dispatch('roles/data', this.profile);
      } catch (error) {
        // eslint-disable-next-line no-alert
        alert(error);
      }
      this.$store.commit('message/LOADING', false);
    },
  },
  created() {
    try {
      /* const requests = {
        company_id: this.profile.organization.id,
        metodo: 'agregar',
      }; */
      const id = 0;
      console.log(id);
      this.$store.dispatch('roles/getModules', id);
      // this.$store.dispatch('roles/data', requests);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(error);
    }
  },
};
</script>

<style>
  .roles-checkbox-container label{
    font-size: 9px;
    font-weight: 600;
    margin-left: 5px;
  }
</style>
