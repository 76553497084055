export default {
  name: 'message',
  namespaced: true,
  state: {
    show: false,
    message: '',
    type: '',
    url: '',
    modulo: '',
    loading: false,
    reload: true,
  },
  mutations: {
    SHOW(state) {
      state.show = !state.show;
    },
    MESSAGE(state, payload) {
      state.message = payload;
    },
    TYPE(state, payload) {
      state.type = payload;
    },
    LOADING(state, payload) {
      state.loading = payload;
    },
    URL(state, payload) {
      state.url = payload;
    },
    MODULO(state, payload) {
      state.modulo = payload;
    },
    RELOAD(state, reload) {
      state.reload = reload;
    },
  },
  actions: {
    run({ commit }) {
      commit('SHOW');
    },
    close({ commit }) {
      commit('SHOW');
    },
    reset({ commit }) {
      commit('URL', '');
    },
  },
};
