import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueTextareaAutosize from 'vue-textarea-autosize';
import App from './App.vue';
// import pdf from 'vue-pdf';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import '@mdi/font/css/materialdesignicons.min.css';

Vue.use(VueTextareaAutosize);

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBCuW3dZEekvWQUYW4Cnt6DYZrRFKCJcpE',
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
