import {
  LimitList,
  SalesEjecutives,
  SimpleList,
} from '../../config/api/products';

export default {

  name: 'ProductLimits',

  namespaced: true,

  state: {
    // PRINCIPAL
    item: [],
    ejecutives: [],
    products: [],
    // FORMULARIO
    show: false,
    title: '',
    //  CAMPOS DE FORMULARIO
    id: '',
    limit1: '',
    limit2: '',
    company: '',
    society: '',
    org: '',
    address: '',
    color: '',
    phone: '',
    photo: '',
    status: true,
    //  DATOS SELECCIONADOS
    formData: [],
  },
  mutations: {
    // PRINCIPAL
    SET_DATA(state, parameter) {
      state.item = parameter.data;
    },
    SET_DATA_EJECUTIVES(state, parameter) {
      state.ejecutives = parameter.data;
    },
    SET_DATA_PRODUCTS(state, parameter) {
      state.products = parameter.data;
    },
    SET_DATA_ID(state, parameter) {
      state.id = parameter.data;
    },
    SET_DATA_LIMIT1(state, parameter) {
      state.limit1 = parameter.data;
    },
    SET_DATA_LIMIT2(state, parameter) {
      state.limit2 = parameter.data;
    },
    SET_ID(state, parameter) {
      state.id = parameter;
    },
    SET_LIMIT1(state, parameter) {
      state.limit1 = parameter;
    },
    SET_LIMIT2(state, parameter) {
      state.limit2 = parameter;
    },
    // FORMULARIO
    SHOW(state) {
      state.show = !state.show;
    },
    SET_TITLE(state, parameter) {
      state.title = parameter;
    },

    // SELECCIONADO
    SET_FORM_DATA(state, parameter) {
      state.formData = parameter;
    },
  },
  actions: {
    // PRINCIPAL
    async data({ commit }, parameter) {
      commit('SET_DATA', await LimitList(parameter));
    },
    async getEjecutives({ commit }, parameter) {
      commit('SET_DATA_EJECUTIVES', await SalesEjecutives(parameter));
    },
    async getProducts({ commit }, parameter) {
      commit('SET_DATA_PRODUCTS', await SimpleList(parameter));
    },
    // FORMULARIO
    toggleForm({ commit }) {
      commit('SHOW');
      commit('SET_TITLE', 'Agregar');
    },
    updateLimits({ commit }, parameter) {
      commit('SHOW');
      commit('SET_TITLE', 'EDITAR');
      // commit('SET_IS_UPDATE', true);
      commit('SET_ID', parameter.id);
      commit('SET_LIMIT1', parameter.limit1);
      commit('SET_LIMIT2', parameter.limit2);
      console.log(parameter.id);
    },
    toAssignId({ commit }, parameter) {
      commit('SET_ID', parameter);
    },
    toAssignLimit1({ commit }, parameter) {
      commit('SET_LIMIT1', parameter);
    },
    toAssignLimit2({ commit }, parameter) {
      commit('SET_LIMIT2', parameter);
    },
  },
};
