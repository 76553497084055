import axios from 'axios';
// eslint-disable-next-line import/extensions
import Profile from '../plugins/profile';

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_URL_BACKEND,
  headers: {
    'Content-Type': 'application/json',
  },
  transformRequest: [(data) => {
    if (Profile.check()) {
      const profile = Profile.get();

      return JSON.stringify({
        ...{
          user_id: profile.user.id,
          session_key: profile.sesionkey,
          society_id: profile.organization.society,
          organization_id: profile.organization.org,
          company_id: profile.organization.id,
          route_current: window.location.pathname.toUpperCase(),
          timewake: profile.update,
        },
        ...data,
      });
    }

    return JSON.stringify(data);
  }],
});

export default httpClient;
