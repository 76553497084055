<template>
  <v-app>
    <Toolbar/>
    <v-main>
      <v-container fluid>
        <v-card>

          <v-card-title>
             <v-icon class="mr-2">
               mdi-email-send-outline
              </v-icon>
              Cola de correos
            <v-spacer></v-spacer>

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

            <v-btn
              v-show="Session.access('43002')"
              title="Agregar Organización"
              class="mx-4 mb-4"
              tile
              color="grey lighten-2"
              @click="test()"
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>

            <v-btn
              v-show="Session.access('43002')"
              title="Agregar Organización"
              class="mx-4 mb-4"
              tile
              color="grey lighten-2"
              @click="refresh()"
            >
              <v-icon dark>
                mdi-refresh
              </v-icon>
            </v-btn>

          <v-card class="mx-auto" width="98%">
            <v-data-table
              v-show="Session.access('40003')"
              :headers="headers"
              :items="item"
              :search="search"
              :loading="item.length === 0"
              sort-by="id"
              class="elevation-24"
              no-data-text="no hay datos"
              :header-props="{
                sortByText: 'Ordenar',
              }"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',
                'items-per-page-text': 'organizaciones por página',
              }"
              multiSort
            >

            <template v-slot:[`item.status`]='{ item }'>
              <v-chip :color='getColor(item.status)' small text-color='white'>
                {{ item.status }}
              </v-chip>
            </template>

            <template v-slot:[`item.acciones`]="{ item }">
              <v-btn icon v-show="Session.access('40003')">
                <v-icon
                  title="Ver"
                  class="mr-2"
                  @click="view(item)">
                  mdi-eye-outline
                </v-icon>
              </v-btn>
              <v-btn icon v-show="Session.access('40003')">
                <v-icon
                  title="Enviar"
                  class="mr-2"
                  @click="send(item)">
                  mdi-send
                </v-icon>
              </v-btn>
            </template>
            </v-data-table>
          </v-card>
          <Form/>
        </v-card>
      </v-container>
    </v-main>
    <SetLogout/>
  </v-app>

</template>

<script>
// eslint-disable-next-line import/extensions
import { mapActions, mapState, mapMutations } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import Form from './EmailForm';
// eslint-disable-next-line import/extensions
import Toolbar from '../../components/Toolbar';
// eslint-disable-next-line import/extensions
import SetLogout from '../../components/SetLogout';
// eslint-disable-next-line import/extensions
import { resend } from '../../config/api/mails';

export default {
  name: 'mails',
  components: {
    Form, Toolbar, SetLogout,
  },
  data: () => ({
    search: '',
    headers: [
      { text: 'Recipiente', value: 'recipient', align: 'center' },
      { text: 'Modulo', value: 'module', align: 'center' },
      { text: 'Titulo', value: 'title', align: 'center' },
      { text: 'Fecha', value: 'date_created', align: 'center' },
      { text: 'Sociedad', value: 'society', align: 'center' },
      { text: 'Estado', value: 'status' },
      { text: 'Acciones', value: 'acciones', sortable: false },
    ],
  }),
  computed: {
    ...mapState('mails', ['item']),
    Session() {
      return Profile;
    },
    profile() {
      return Profile.get();
    },
  },
  methods: {
    ...mapActions('mails', ['all', 'test', 'view']),
    ...mapMutations('message', ['MESSAGE', 'TYPE', 'LOADING']),
    getColor(status) {
      switch (status) {
        case 'QUEUED':
          return 'primary';
        case 'SENDED':
          return 'green';
        case 'SENDING':
          return 'orange';
        case 'ERROR':
          return 'orange';
        default:
          return 'red';
      }
    },
    async send(item) {
      if (item.status !== 'SENDED' && item.status !== 'SENDING') {
        this.response(await resend({ mail_id: item.id }));
      } else if (item.status === 'SENDING') {
        this.alert('Este correo está en proceso de envio');
      } else if (item.status === 'SENDED') {
        this.alert('Este correo ya ha sido enviado');
      }
    },
    refresh() {
      this.$store.commit('message/LOADING', true);
      this.$store.dispatch('mails/all', { });
      this.$store.commit('message/LOADING', false);
    },
    response(api) {
      this.$store.commit('message/LOADING', true);
      try {
        this.alert(api.data.message, 'success');
        this.$store.dispatch('mails/all', { });
      } catch (error) {
        // eslint-disable-next-line no-alert
        this.alert(error);
      }
      this.$store.commit('message/LOADING', false);
    },
    alert(message, type = 'info') {
      this.$store.commit('message/MESSAGE', message);
      this.$store.commit('message/TYPE', type);
      this.$store.commit('message/RELOAD', false);
      this.$store.dispatch('message/run');
    },
    checkPermission(permiso, accion) {
      let valor = false;
      permiso.forEach((permisos) => {
        if (permisos.accion === accion) {
          valor = true;
        }
      });
      return valor;
    },
  },
  created() {
    try {
      const requests = { };
      this.$store.dispatch('mails/all', requests);
    } catch (error) {
      // eslint-disable-next-line
      alert(error);
    }
  },
};
</script>
