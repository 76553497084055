var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('Toolbar'),_c('v-main',[_c('v-container',{staticClass:"mt-5 pt-5",attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-application ")]),_vm._v(" "+_vm._s(_vm.profile.organization.name)+" - Facturas Fletes "),_c('v-spacer'),_c('v-col',{staticClass:"pl-4",attrs:{"sm":"7"}},[_c('v-autocomplete',{staticClass:"mt-0 pt-0 mx-4 pl-3",attrs:{"label":"Clientes","items":_vm.clients,"item-text":"name","item-value":"name","color":"grey darken-4"},on:{"change":_vm.updateClient},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-col',{attrs:{"sm":"5"}},[_c('v-btn',{staticClass:"mx-4",attrs:{"title":"Registrar","tile":"","color":"grey lighten-2"},on:{"click":function($event){return _vm.showFlete(_vm.calcular)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-credit-card-plus ")])],1)],1),_c('v-card',{staticClass:"mx-auto",attrs:{"width":"98%"}},[_c('v-data-table',{staticClass:"elevation-24",attrs:{"headers":_vm.headersMaferca,"items":_vm.items,"search":_vm.search,"single-select":_vm.singleSelect,"loading":_vm.loading,"sort-by":"createdtime","sort-desc":true,"loading-text":"Cargando Datos","show-select":"","item-key":"salesorder_no","no-data-text":"no hay datos","no-results-text":"Cliente sin Facturas","header-props":{
              sortByText: 'Ordenar',
            },"footer-props":{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-text': 'Documentos por página',
            },"multiSort":""},on:{"input":function($event){return _vm.enterSelect()}},scopedSlots:_vm._u([{key:"item.sostatus",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.sostatus),"small":"","text-color":"white"}},[_vm._v(" "+_vm._s(item.sostatus)+" ")])]}},{key:"top",fn:function(){return [_c('v-switch',{staticClass:"pa-3",attrs:{"label":"Selección simple"},model:{value:(_vm.singleSelect),callback:function ($$v) {_vm.singleSelect=$$v},expression:"singleSelect"}})]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('salesorderView'),_c('SalesorderFlete')],1)],1)],1),_c('SetLogout'),_c('Historicobcv')],1)}
var staticRenderFns = []

export { render, staticRenderFns }