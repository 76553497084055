<template>
  <v-app>
    <Toolbar/>
    <v-main>
      <v-container fluid>
        <v-card>
          <v-card-title>
             <v-icon class="mr-2">
               mdi-application
              </v-icon>
              {{profile.organization.name}} - Usuarios
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-btn
            v-show="validar(profile.permissions,'40002')"
            title='Agregar'
            class='mx-4 mb-4'
            tile
            color='grey lighten-2'
            @click='add()'>
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
          <v-card class="mx-auto" width="98%">
          <v-data-table
            :headers="headers"
            :items="item"
            :search="search"
            no-data-text="no hay datos"
            sort-by="user_name"
            class="elevation-24"
            :header-props="{
              sortByText: 'Ordenar',
            }"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-text': 'usuarios por página',
            }"
            multiSort
          >
          <template v-slot:[`item.estado`]="{ item }">
            <v-chip icon :color="getColor(item.estado)" dark x-small>
              <span v-if="item.estado == '1'"> Activo </span>
              <span v-else> Inactivo </span>
            </v-chip>
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-icon
              class="ma-2"
              title="Activo"
              color="primary"
              @click="actualizarEstado(item)"
              reloadPage
              v-if="item.estado == 1"
            >
              mdi-thumb-up
            </v-icon>
            <v-icon
              class="ma-2"
              title="Inactivo"
              color="error"
              @click="actualizarEstado(item)"
              reloadPage
              v-else
            >
            mdi-thumb-down
            </v-icon>
              <v-icon
                v-show="validar(profile.permissions,'40004')"
                title="Editar Usuario"
                class="mr-2"
                color='#0091EA'
                reloadPage
                @click="update(item)"
              >
                mdi-pencil-outline
              </v-icon>
              <v-icon
                v-show="validar(profile.permissions,'40003')"
                title="Eliminar Usuario"
                class="mr-2"
                color='#D50000'
                @click="eliminarUser(item)"
              >
                mdi-delete-outline
              </v-icon>
              <v-icon
                  v-show="validar(profile.permissions,'33001')"
                  title="Listas de Precios"
                  class="mr-2"
                  color='grey'
                  @click="listUser(item)"
                >
                mdi-format-list-checkbox
              </v-icon>
              <v-icon
                  v-show="validar(profile.permissions,'33001')"
                  title="Accesos"
                  class="mr-2"
                  color='blue'
                  @click="editAccess(item)"
                >
                  mdi mdi-key-chain
              </v-icon>
            </template>
          </v-data-table>
          </v-card>
          <UserForm/>
          <UserList/>
          <UserAcccess/>
          <Historicobcv/>
        </v-card>
      </v-container>
    </v-main>
   <SetLogout/>
  </v-app>

</template>

<script>
// eslint-disable-next-line import/extensions
import { mapState, mapActions, mapMutations } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import UserForm from './UsersForm';
// eslint-disable-next-line import/extensions
import UserList from './UserList';
// eslint-disable-next-line import/extensions
import UserAcccess from './UserAccess';
// eslint-disable-next-line import/extensions
import Toolbar from '../../components/Toolbar';
// eslint-disable-next-line import/extensions
import SetLogout from '../../components/SetLogout';
// eslint-disable-next-line import/extensions
import Historicobcv from '../../components/Historicobcv';

export default {
  dialog: true,
  name: 'usuarios',
  components: {
    UserForm, Toolbar, UserList, SetLogout, UserAcccess, Historicobcv,
  },
  data: () => ({
    active: false,
    search: '',
    headers: [
      { text: 'Usuario', value: 'user_name', align: 'center' },
      { text: 'Nombres', value: 'first_name', align: 'center' },
      { text: 'Apellidos', value: 'last_name', align: 'center' },
      { text: 'Teléfono Nª1', value: 'phone_mobile', align: 'center' },
      { text: 'Email', value: 'email1', align: 'center' },
      { text: 'Rol de Usuario', value: 'nombre', align: 'center' },
      { text: 'Tipo de Usuario', value: 'tipo', align: 'center' },
      { text: 'Estado', value: 'estado', align: 'center' },
      { text: 'Acciones', value: 'acciones', sortable: false },
    ],
  }),
  computed: {
    ...mapState('usuarios', ['item', 'userlista']),
    profile() {
      return Profile.get();
    },
  },
  methods: {
    ...mapMutations('message', ['MESSAGE', 'TYPE', 'LOADING']),
    ...mapActions('message', ['run']),
    ...mapActions('usuarios', ['add', 'update', 'listUser', 'editAccess', 'toAssignId', 'actualizarEstado', 'eliminarUser']),
    getColor(estado) {
      // eslint-disable-next-line eqeqeq
      return estado == 1 ? 'green' : 'red';
    },
    validar(permiso, accion) {
      let valor = false;
      permiso.forEach((permisos) => {
        // eslint-disable-next-line eqeqeq
        if (permisos.accion == accion) {
          valor = true;
        }
      });
      return valor;
    },
    data() {
      return {
        condicion: {
          id: this.formId,
        },
      };
    },
  },
  mounted() {
    try {
      const requests = {
        company_id: this.profile.organization.id,
        user_id: this.profile.user.id,
      };
      this.$store.dispatch('usuarios/data', requests);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(error);
    }
  },
};
</script>
