<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{title}} Zona</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    label="Zona" required
                    v-model="formZone"
                    v-show="isAdd"
                    :rules="zoneRules"
                    counter="6">
                  </v-text-field>
                  <v-text-field
                    label="Zona"
                    required
                    v-model="formZone"
                    v-show="isUpdate || isUserAdd"
                    disabled
                    class="font-weight-bold">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field label="Descripción" v-model="formDescription"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    v-model="formExecutive"
                    :items="dataExecutives"
                    label="Ejecutivo"
                  ></v-autocomplete>
                </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn color="red" text @click="reset()"> Cerrar </v-btn>
            <v-btn color="primary" text @click="add()" v-show="isAdd"> Agregar</v-btn>
            <v-btn color="primary" text @click="update()" v-show="isUpdate"> Editar</v-btn>
            <v-btn color="primary" text @click="addUser()" v-show="isUserAdd"> Agregar Usuario a Zona</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapActions, mapState, mapMutations } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import { store, updated } from '../../config/api/zones';

export default {
  name: 'zones-crud',
  data: () => ({
    zoneRules: [
      (v) => !!v || 'Zona es requerida',
      (v) => v.length <= 6 || 'La zona debe tener entre 5 y 6 caracteres',
      (v) => v.length >= 5 || 'La zona debe tener entre 5 y 6 caracteres',
    ],
  }),
  computed: {
    ...mapState('userProfile', ['profile']),
    ...mapState('zones', ['title', 'show', 'isAdd', 'isUpdate', 'isUserAdd', 'zone', 'lastZone', 'description', 'executive', 'dataExecutives', 'item']),
    formZone: {
      set(value) {
        this.$store.dispatch('zones/toAssignZone', value);
      },
      get() {
        return this.zone;
      },
    },
    formDescription: {
      set(value) {
        this.$store.dispatch('zones/toAssignDescription', value);
      },
      get() {
        return this.description;
      },
    },
    formExecutive: {
      set(value) {
        this.$store.dispatch('zones/toAssignExecutive', value);
      },
      get() {
        return this.executive;
      },
    },
    profile() {
      return Profile.get();
    },
  },
  methods: {
    ...mapMutations('message', ['MESSAGE', 'TYPE', 'LOADING']),
    ...mapActions('message', ['run']),
    ...mapMutations('zones', ['SHOW']),
    ...mapActions('zones', ['reset', 'initializingForm', 'toAssignExecutive']),
    defaultData() {
      if (this.formDescription === '') {
        this.formDescription = 'Sin descripción';
      }
      if (!this.formExecutive) {
        this.formExecutive = 'Disponible';
      }
    },
    data() {
      this.defaultData();
      return {
        zona: {
          zona: this.formZone,
          descripcion: this.formDescription,
          estatus: '1',
          centro: this.profile.organization.org,
        },
        ejecutivo: {
          nombre: this.formExecutive,
          Zona: this.formZone,
          estado: '1',
        },
      };
    },
    async add() {
      if (this.validate()) this.response(await store(this.data()));
    },
    async update() {
      this.response(await updated(this.data()));
    },
    async addUser() {
      console.log(this.data());
      this.response(await updated(this.data()));
    },
    response(api) {
      this.$store.commit('message/LOADING', true);
      try {
        this.$store.dispatch('zones/reset');
        this.$store.commit('message/MESSAGE', api.data.message);
        this.$store.commit('message/TYPE', 'success');
        this.$store.dispatch('message/run');
        this.$store.dispatch('zones/data', this.profile);
      } catch (error) {
        // eslint-disable-next-line no-alert
        alert(error);
      }
      this.$store.commit('message/LOADING', false);
    },
    validate() {
      if (this.formZone === '' || this.formZone.length < 5) {
        this.alert('La zona debe tener entre 5 y 6 caracteres');
        return 0;
      }
      if (this.item.find((element) => element.zone === this.formZone)) {
        this.alert('La zona está repetida');
        return 0;
      }
      if (!/^[0-9A-Za-z]+$/.test(this.formZone)) {
        this.alert('La zona no debe tener caracteres especiales');
        return 0;
      }
      this.data();
      return 1;
    },
    alert(message) {
      this.$store.commit('message/MESSAGE', message);
      this.$store.commit('message/TYPE', 'info');
      this.$store.dispatch('message/run');
    },
  },
  created() {
    try {
      const requests = {
        company_id: this.profile.organization.id,
        user_id: this.profile.user.id,
      };
      this.$store.dispatch('zones/initializingForm', requests);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(error);
    }
  },
};
</script>
