<template>
  <v-app>
    <Toolbar/>
    <v-main>
      <v-container fluid>
        <v-card>

          <v-card-title>
             <v-icon class="mr-2">
               mdi-factory
              </v-icon>
              Organizaciones
            <v-spacer></v-spacer>

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

            <v-btn
              v-show="validar(profile.permissions,'43002')"
              title="Agregar Organización"
              class="mx-4 mb-4"
              tile
              color="grey lighten-2"
              @click="add()"
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>

          <v-card class="mx-auto" width="98%">
            <v-data-table
              :headers="headers"
              :items="item"
              :search="search"
              :loading="item.length === 0"
              sort-by="id"
              class="elevation-24"
              no-data-text="no hay datos"
              :header-props="{
                sortByText: 'Ordenar',
              }"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',
                'items-per-page-text': 'organizaciones por página',
              }"
              multiSort
            >

            <template v-slot:[`item.photo`]="{ item }">
                <v-img
                  v-show="true"
                  :src="item.photo"
                  height="auto"
                  max-width="130px"
                >
                </v-img>
            </template>

            <template v-slot:[`item.status`]='{ item }'>
              <v-chip :color='getColor(item.status)' small text-color='white'>
                {{ item.status == '1' ? 'Habilitada' : 'Inhabilitada' }}
              </v-chip>
            </template>

            <template v-slot:[`item.acciones`]="{ item }">
              <v-btn icon v-show="validar(profile.permissions,'43005')">
                <v-icon
                  class='px-auto'
                  title="Cargar Imagen"
                  @click='imgshow(item)'>
                  mdi-image
               </v-icon>
              </v-btn>
              <v-btn icon v-show="validar(profile.permissions,'43003')">
                <v-icon
                  title="Editar Organización"
                  class="mr-2"
                  @click="update(item)">
                  mdi-pencil-outline
                </v-icon>
              </v-btn>
            </template>
            </v-data-table>
          </v-card>
          <Form/>
          <ImgForm/>
        </v-card>
      </v-container>
    </v-main>
    <SetLogout/>
    <Historicobcv/>
  </v-app>

</template>

<script>
// eslint-disable-next-line import/extensions
import { mapState, mapActions } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import Form from './OrgForm';
// eslint-disable-next-line import/extensions
import ImgForm from './OrgImg';
// eslint-disable-next-line import/extensions
import Toolbar from '../../components/Toolbar';
// eslint-disable-next-line import/extensions
import SetLogout from '../../components/SetLogout';
// eslint-disable-next-line import/extensions
import Historicobcv from '../../components/Historicobcv';

export default {
  name: 'organization',
  components: {
    Form, ImgForm, Toolbar, SetLogout, Historicobcv,
  },
  data: () => ({
    search: '',
    headers: [
      {
        text: 'Logo',
        align: 'start',
        sortable: false,
        value: 'photo',
      },
      { text: 'Nombre', value: 'name', align: 'center' },
      { text: 'Sociedad', value: 'society', align: 'center' },
      { text: 'Organización', value: 'org', align: 'center' },
      { text: 'Dirección', value: 'address', align: 'center' },
      { text: 'Teléfono', value: 'phone', align: 'center' },
      { text: 'Color', value: 'color', align: 'center' },
      { text: 'Estado', value: 'status' },
      { text: 'Acciones', value: 'acciones', sortable: false },
    ],
  }),
  computed: {
    ...mapState('organization', ['item']),
    profile() {
      return Profile.get();
    },
  },
  methods: {
    ...mapActions('organization', ['data', 'imgshow', 'add', 'update']),
    getColor(status) {
      switch (status) {
        case '1':
          return 'green';
        case '0':
          return 'orange';
        default:
          return 'red';
      }
    },
    validar(permiso, accion) {
      let valor = false;
      permiso.forEach((permisos) => {
        if (permisos.accion === accion) {
          valor = true;
        }
      });
      return valor;
    },
  },
  created() {
    try {
      this.$store.dispatch('organization/data', {});
    } catch (error) {
      // eslint-disable-next-line
      alert(error);
    }
  },
};
</script>
