import httpClient from '../axios';

const URL = 'customers';

const customers = async (data) => httpClient.post(URL, data);

const URL_CLIENTE = 'getCliente';

const getCliente = async (data) => httpClient.post(URL_CLIENTE, data);

export {
  getCliente,
  customers,
};
