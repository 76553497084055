<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="600px">
      <v-card>
        <v-toolbar :color="profile.organization.color" dark>
          <v-icon class='mr-2'> mdi-application </v-icon>
            {{title}} Organización
          <v-spacer></v-spacer>
          <v-btn icon dark @click="reset()">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    label="*Empresa" required v-show="isAdd" v-model="formCompany">
                  </v-text-field>
                  <v-text-field
                    label="*Empresa" required disabled v-show="isUpdate"  v-model="formCompany" class="font-weight-bold">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field
                    label="*Rif" required v-show="isAdd" v-model="formRif">
                  </v-text-field>
                  <v-text-field
                    label="*Rif" required disabled v-show="isUpdate"  v-model="formRif" class="font-weight-bold">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field required label="Nombre Completo" v-model="formNameFull" ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field required v-show="isAdd"  label="*Sociedad" type="number" :rules="orgRules" :min="1000" :max="9999" v-model="formSociety" ></v-text-field>
                  <v-text-field required disabled v-show="isUpdate"  label="*Sociedad" type="number" :rules="orgRules" :min="1000" :max="9999" v-model="formSociety" class="font-weight-bold"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field required v-show="isAdd"  label="*Organización de ventas" type="number" :rules="orgRules" :min="1000" :max="9999" v-model="formOrg" ></v-text-field>
                  <v-text-field required disabled v-show="isUpdate"  label="*Organización de ventas" type="number" :rules="orgRules" :min="1000" :max="9999" v-model="formOrg" class="font-weight-bold"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field required label="Dirección" v-model="formAddress" ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field required label="Teléfono" v-model="formPhone" ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field required label="Correo" v-model="formMail" ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field label="*Color" v-model="formColor" @click="modalColor = true"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-combobox
                    required
                    v-model="formStatus"
                    :items="itemsCombobox"
                    label="*Estado"
                    dense
                  ></v-combobox>
                </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn color="red" text @click="reset()"> Cerrar </v-btn>
            <v-btn color="primary" text @click="add()" v-show="isAdd"> Agregar</v-btn>
            <v-btn color="primary" text @click="update()" v-show="isUpdate"> Editar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalColor" open-delay="" max-width="290">
      <v-card>
        <v-toolbar :color="profile.organization.color" dark height="50px">
          <v-icon class="mr-2">
            mdi-application
          </v-icon>
            <h1 class="title mt-2 white--text">
              Seleccione el color
            </h1>
          <v-spacer></v-spacer>
            <v-btn icon dark @click="modalColor = false">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
        </v-toolbar>
        <v-color-picker
          v-model="formColor"
          class="pt-4"
          hide-inputs
          dot-size="25"
        ></v-color-picker>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" outlined @click="modalColor = false">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapActions, mapState, mapMutations } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import { store, updated } from '../../config/api/organization';

function validateMultipleEmails(string) {
  // eslint-disable-next-line no-useless-escape
  const regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
  const result = string.replace(/\s/g, '').split(/,|;/);
  for (let i = 0; i < result.length; i += 1) {
    if (!regex.test(result[i])) {
      return false;
    }
  }
  return true;
}

export default {
  name: 'organization-crud',
  data: () => ({
    orgRules: [
      (v) => !!v || 'Código requerido',
      (v) => v <= 9999 || 'El código requiere 4 digitos',
      (v) => v >= 999 || 'El código requiere 4 digitos',
    ],
    modalColor: false,
    itemsCombobox: ['Habilitada', 'Inhabilitada'],
  }),
  computed: {
    ...mapState('userProfile', ['profile']),
    ...mapState('organization', ['title', 'show', 'item', 'company', 'rif', 'nameFull', 'society', 'org', 'address', 'phone', 'mail', 'color', 'status', 'isAdd', 'isUpdate']),
    formCompany: {
      set(value) {
        this.$store.dispatch('organization/toAssignCompany', value);
      },
      get() {
        return this.company;
      },
    },
    formRif: {
      set(value) {
        this.$store.dispatch('organization/toAssignRif', value);
      },
      get() {
        return this.rif;
      },
    },
    formNameFull: {
      set(value) {
        this.$store.dispatch('organization/toAssignNameFull', value);
      },
      get() {
        return this.nameFull;
      },
    },
    formSociety: {
      set(value) {
        this.$store.dispatch('organization/toAssignSociety', value);
      },
      get() {
        return this.society;
      },
    },
    formOrg: {
      set(value) {
        this.$store.dispatch('organization/toAssignOrg', value);
      },
      get() {
        return this.org;
      },
    },
    formAddress: {
      set(value) {
        this.$store.dispatch('organization/toAssignAddress', value);
      },
      get() {
        return this.address;
      },
    },
    formPhone: {
      set(value) {
        this.$store.dispatch('organization/toAssignPhone', value);
      },
      get() {
        return this.phone;
      },
    },
    formMail: {
      set(value) {
        this.$store.dispatch('organization/toAssignMail', value);
      },
      get() {
        return this.mail;
      },
    },
    formColor: {
      set(value) {
        this.$store.dispatch('organization/toAssignColor', value);
      },
      get() {
        return this.color;
      },
    },
    formStatus: {
      set(value) {
        this.$store.dispatch('organization/toAssignStatus', value === 'Habilitada' ? '1' : '0');
      },
      get() {
        return this.status === '1' ? 'Habilitada' : 'Inhabilitada';
      },
    },
    profile() {
      return Profile.get();
    },
  },
  methods: {
    ...mapMutations('message', ['MESSAGE', 'TYPE', 'LOADING']),
    ...mapActions('message', ['run']),
    ...mapMutations('organization', ['SHOW']),
    ...mapActions('organization', ['reset', 'update', 'initializingForm']),
    data() {
      return {
        society: this.formSociety,
        name: this.formNameFull,
        org: this.formOrg,
        company: this.formCompany,
        rif: this.formRif,
        address: this.formAddress,
        phone: this.formPhone,
        mail: this.formMail,
        color: this.formColor,
        status: this.formStatus === 'Habilitada' ? '1' : '0',
      };
    },
    async add() {
      if (this.validate()) {
        this.response(await store(this.data()));
        this.reset();
      }
    },
    async update() {
      if (this.validate()) {
        this.response(await updated(this.data()));
        this.reset();
      }
    },
    response(api) {
      this.$store.commit('message/LOADING', true);
      try {
        this.alert(api.data.message, 'success');
        this.$store.dispatch('organization/data', this.profile);
      } catch (error) {
        // eslint-disable-next-line no-alert
        this.alert(error, 'error');
      }
      this.$store.commit('message/LOADING', false);
    },
    validate() {
      if (/^[\w\s ,.]+$/.test(this.formCompany) === false) {
        this.alert('El campo empresa solo admite alfanumérico', 'warning');
        return false;
      }

      if (/^\d+$/.test(this.formSociety) === false) {
        this.alert('El campo sociedad solo admite números', 'warning');
        return false;
      }

      if (/^\d+$/.test(this.formOrg) === false) {
        this.alert('El campo organización solo admite números', 'warning');
        return false;
      }

      if (validateMultipleEmails(this.formMail) === false) {
        this.alert('El campo email no mantiene un formato correcto para una dirección de correo', 'warning');
        return false;
      }

      if (/^#[0-9a-f]{3,6}$/i.test(this.formColor) === true) {
        this.alert('El campo color no mantiene el formato hexadecimal', 'warning');
        return false;
      }

      return true;
    },
    alert(message, type = 'info') {
      this.$store.commit('message/MESSAGE', message);
      this.$store.commit('message/TYPE', type);
      this.$store.commit('message/RELOAD', false);
      this.$store.dispatch('message/run');
    },
  },
};
</script>
