import { zones, getSelectZoneExecutive, getLastZone } from '../../config/api/zones';

export default {

  name: 'zones',

  namespaced: true,

  state: {
    // PRINCIPAL
    item: [],
    // FORMULARIO
    show: false,
    isAdd: false,
    isUpdate: false,
    isUserAdd: false,
    title: '',
    lastZone: '',
    //  CAMPOS DE FORMULARIO
    zone: '',
    description: '',
    executive: null,
    dataExecutives: [],
    //  DATOS SELECCIONADOS
    formData: [],
  },
  mutations: {
    // PRINCIPAL
    SET_DATA(state, parameter) {
      state.item = parameter.data;
    },
    // FORMULARIO
    SHOW(state) {
      state.show = !state.show;
    },
    SET_IS_ADD(state, parameter) {
      state.isAdd = parameter;
    },
    SET_IS_UPDATE(state, parameter) {
      state.isUpdate = parameter;
    },
    SET_IS_USER_ADD(state, parameter) {
      state.isUserAdd = parameter;
    },
    SET_TITLE(state, parameter) {
      state.title = parameter;
    },
    SET_LAST_ZONE(state, parameter) {
      console.log(parameter);
      state.lastZone = parameter.data;
    },
    SET_DATA_EXECUTIVES(state, parameter) {
      console.log(parameter);
      state.dataExecutives = parameter.data;
    },
    // CAMPOS DE FORMULARIO
    SET_ZONE(state, parameter) {
      console.log(parameter);
      state.zone = parameter;
    },
    SET_DESCRIPTION(state, parameter) {
      console.log(parameter);
      state.description = parameter;
    },
    SET_EXECUTIVE(state, parameter) {
      console.log(parameter);
      state.executive = parameter;
    },
    // SELECCIONADO
    SET_FORM_DATA(state, parameter) {
      console.log(parameter);
      state.formData = parameter;
    },
  },
  actions: {
    // PRINCIPAL
    async data({ commit }, parameter) {
      commit('SET_DATA', await zones(parameter));
    },
    // FORMULARIO
    add({ commit, state }) {
      commit('SHOW');
      commit('SET_TITLE', 'Agregar');
      commit('SET_IS_ADD', true);
      commit('SET_ZONE', state.lastZone);
    },
    update({ commit }, parameter) {
      commit('SHOW');
      commit('SET_TITLE', 'Editar');
      commit('SET_IS_UPDATE', true);
      commit('SET_ZONE', parameter.zone);
      commit('SET_DATA_EXECUTIVE', parameter.dataExecutive);
      commit('SET_DESCRIPTION', parameter.description);
    },
    addUser({ commit }, parameter) {
      commit('SHOW');
      commit('SET_TITLE', 'Agregar Clien a ');
      commit('SET_IS_USER_ADD', true);
      commit('SET_ZONE', parameter.zone);
      commit('SET_DATA_EXECUTIVE', parameter.dataExecutive);
      commit('SET_DESCRIPTION', parameter.description);
    },
    reset({ commit, state }) {
      commit('SHOW', state.show);
      commit('SET_IS_ADD', false);
      commit('SET_IS_UPDATE', false);
      commit('SET_IS_USER_ADD', false);
      commit('SET_ZONE', state.lastZone);
      commit('SET_EXECUTIVE', '');
      commit('SET_DESCRIPTION', '');
    },
    async initializingForm({ commit }, parameter) {
      console.log('zonas get executives');
      console.log(parameter);
      commit('SET_DATA_EXECUTIVES', await getSelectZoneExecutive(parameter));
      commit('SET_LAST_ZONE', await getLastZone(parameter));
    },
    toAssignExecutive({ commit }, parameter) {
      commit('SET_EXECUTIVE', parameter);
    },
    toAssignZone({ commit }, parameter) {
      commit('SET_ZONE', parameter);
    },
    toAssignDescription({ commit }, parameter) {
      commit('SET_DESCRIPTION', parameter);
    },
    async ZonaCliente({ commit }, parameter) {
      commit('SET_DATA_EXECUTIVES', await getSelectZoneExecutive(parameter));
    },
  },
};
