import httpClient from '../axios';

const URL = 'modules';

const modules = async (data) => httpClient.get(URL, data);

const getModules = async (data) => httpClient.get(URL, data);

export {
  modules,
  getModules,
};
