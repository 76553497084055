<template>
  <v-app>
    <Toolbar/>
  <v-main>
    <v-container fluid>
    <v-card>
    <v-card-title>
        <v-icon class="mr-2">
          mdi-application
        </v-icon>
        {{profile.organization.name}} - Tareas
      <v-spacer></v-spacer>

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <!--<v-col cols="12" sm="4">
    <v-autocomplete
        v-model="id_vendedor"
        label="Ejecutivo"
        :items="ejecutivo"
        class="mx-4 mb-4"
      >
    </v-autocomplete>
    </v-col>
    <v-btn
      v-show="validar(profile.permissions,'21002')"
      title="Agregar Ejecutivo"
      class="mx-4 mb-4"
      tile
      color="grey lighten-2"
      @click='add()'>
      <v-icon dark>
        mdi-plus
      </v-icon>
    </v-btn>-->
   <Calendario/>
  </v-card>
  <!--<TareasForm/>-->
  </v-container>
  </v-main>
  <SetLogout/>
  <Historicobcv/>
 </v-app>
</template>

<script>
// eslint-disable-next-line import/extensions
import { /* mapState, */ mapActions, mapMutations } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import Toolbar from '../../components/Toolbar';
// eslint-disable-next-line import/extensions
import Calendario from '../../components/Calendario';
// eslint-disable-next-line import/extensions
import SetLogout from '../../components/SetLogout';
// eslint-disable-next-line import/extensions
import Historicobcv from '../../components/Historicobcv';
// eslint-disable-next-line import/extensions
// import { tareas } from '../../config/api/tareas';
// import TareasForm from './TareasForm';
// eslint-disable-next-line import/extensions
// import { ejecutivos } from '../../config/api/usuarios';

export default {
  name: 'Tareas',
  components: {
    /* TareasForm, */ Toolbar, SetLogout, Calendario, Historicobcv,
  },
  data: () => ({
    search: '',
    // ejecutivo: [],
    // id_vendedor: null,
  }),
  computed: {
    profile() {
      return Profile.get();
    },
    /* Ejecutivo: {
      set(value) {
        this.$store.dispatch('reportes/toAssignEjecutivo', value);
      },
      get() {
        return this.id_vendedor;
      },
    }, */
  },
  methods: {
    ...mapMutations('message', ['MESSAGE', 'TYPE', 'LOADING']),
    ...mapActions('message', ['run']),
    // ...mapActions('presupuesto', ['data', 'add']),
    validar(permiso, accion) {
      let valor = false;
      permiso.forEach((permisos) => {
        if (permisos.accion === accion) {
          valor = true;
        }
      });
      return valor;
    },
  },
  /* async mounted() {
    try {
      const requests = {
        company_id: this.profile.organization.id,
        user_idku: this.id_vendedor,
        // account_no: this.account_no,
      };
      this.ejecutivo = (await ejecutivos(requests)).data;
      // this.tareas = (await tareas(requests)).data;
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(error);
    }
  }, */
};
</script>
