import httpClient from '../axios';

const URL = 'meta/tareas';

const tareas = async (data) => httpClient.post(URL, data);

const URL_ADD = 'meta/addtareas';

const addtareas = async (data) => httpClient.post(URL_ADD, data);

const URL_UPDATE = 'meta/updatetareas';

const updatetareas = async (data) => httpClient.post(URL_UPDATE, data);

const URL_DELETE = 'meta/deletetareas';

const deletetareas = async (data) => httpClient.post(URL_DELETE, data);

const URL_GESTION = 'meta/gestion';

const gestion = async (data) => httpClient.post(URL_GESTION, data);

export {
  tareas,
  // cnr,
  addtareas,
  updatetareas,
  deletetareas,
  gestion,
};
