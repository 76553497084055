import httpClient from '../axios';

const URL = 'mails/all';

const mails = async (data) => httpClient.post(URL, data);

const URL_SEND = 'mails/send';

const send = async (data) => httpClient.post(URL_SEND, data);

const URL_RESEND = 'mails/resend';

const resend = async (data) => httpClient.post(URL_RESEND, data);

export { mails, send, resend };
