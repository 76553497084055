<template>
  <v-row class="fill-height">
    <v-col>
      <v-sheet height="180">
        <v-col cols="12" sm="4">
          <v-autocomplete
              v-model="id_vendedor"
              label="Ejecutivo"
              @change="getEvents()"
              :items="ejecutivo"
              class="mx-4 mb-4"
            >
          </v-autocomplete>
        </v-col>
        <v-toolbar flat color="white">
          <v-btn color="primary" dark class="mr-4" @click="dialog = true">
            Agregar
          </v-btn>
          <v-btn outlined class="mr-4" @click="setToday">
            Hoy
          </v-btn>
          <v-btn fab text small @click="prev">
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab text small @click="next">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on }">
              <v-btn
                outlined
                v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Día</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Semana</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Mes</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 días</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="events"
          :event-color="getEventColor(events)"
          :event-margin-bottom="3"
          :now="today"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
          :weekdays="[1, 2, 3, 4, 5, 6, 0]"
          locale="es"
          :short-weekdays="false"
        ></v-calendar>

        <!-- Modal Agregar Evento -->
        <v-dialog v-model="dialog">
          <v-card>
            <v-container>
              <v-form @submit.prevent="addEvent">
                <v-autocomplete
                  v-model="id_vendedor"
                  label="Ejecutivo"
                  :items="ejecutivo"
                >
                </v-autocomplete>
                <v-autocomplete
                  label="Cliente"
                  :items="clients"
                  item-text="name"
                  item-value="account_no"
                  v-model="rif_cliente"
                >
                </v-autocomplete>
                <v-autocomplete
                  label="Gestion"
                  :items="gestion"
                  item-text="descripcion"
                  item-value="id"
                  v-model="id_gestion"
                >
                </v-autocomplete>
                <v-text-field
                  type="text" label="Observación" v-model="observacion">
                </v-text-field>
                <v-text-field
                  type="date" label="Inicio del evento" v-model="fecha_visita">
                </v-text-field>
                <!--<v-text-field
                  type="date" label="Fin del evento" v-model="end">
                </v-text-field>
                <v-text-field
                  type="color" label="Color del evento" v-model="color">
                </v-text-field>-->
                <v-btn type="submit" color="primary" class="mr-4"
                @click.stop="dialog = false"> Agregar </v-btn>
              </v-form>
            </v-container>
          </v-card>
        </v-dialog>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card
            color="grey lighten-4"
            min-width="350px"
            flat
          >
            <v-toolbar
              :color="getEventColor(events)"
              dark
            >

              <v-btn icon @click="deleteEvent(selectedEvent)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-toolbar-title></v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>

              <v-form v-if="currentlyEditing !== selectedEvent.id">
                {{selectedEvent.name}} - {{selectedEvent.details}}
                <v-select
                  :items="gestion"
                  item-text="descripcion"
                  item-value="id"
                  v-model="selectedEvent.id_gestion"
                  readonly>
                </v-select>
              </v-form>

              <v-form v-else>
                <v-col cols="12" sm="12">
                <v-text-field
                  type="text" v-model="selectedEvent.name"
                  label="Editar Nombre"
                  readonly>
                </v-text-field>
                <v-autocomplete
                  label="Gestion"
                  :items="gestion"
                  item-text="descripcion"
                  item-value="id"
                  v-model="selectedEvent.id_gestion">
                </v-autocomplete>
                <textarea-autosize
                  v-model="selectedEvent.details"
                  type="text"
                  style="width: 100%"
                  :min-height="100"
                ></textarea-autosize>
              </v-col>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                color="secondary"
                @click="selectedOpen = false"
              >
                Cancel
              </v-btn>
              <v-btn text v-if="currentlyEditing !== selectedEvent.id"
              @click.prevent="editEvent(selectedEvent.id)">Editar</v-btn>

              <v-btn text v-else  @click.prevent="updateEvent(selectedEvent)">Guardar Cambios</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapState } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../plugins/profile';
// eslint-disable-next-line import/extensions
import {
  tareas,
  addtareas,
  updatetareas,
  deletetareas,
  gestion,
} from '../config/api/tareas';
import {
  ejecutivos,
} from '../config/api/usuarios';

export default {
  data: () => ({
    search: '#$#$&%/&&(/(=',
    today: new Date().toISOString().substr(0, 10),
    focus: new Date().toISOString().substr(0, 10),
    type: 'month',
    typeToLabel: {
      month: 'Mes',
      week: 'Semana',
      day: 'Día',
      '4day': '4 Días',
    },
    start: null,
    end: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    ejecutivo: [],
    gestion: [],
    id_ruta: null,
    id_vendedor: null,
    rif_cliente: null,
    // customer: [],
    fecha_visita: null,
    status: null,
    observacion: null,
    color: null,
    id_ubicacion: null,
    id_gestion: null,
    fecha_creacion: null,
    usuario_creacion: null,
    dialog: false,
    currentlyEditing: null,
  }),
  computed: {
    profile() {
      return Profile.get();
    },
    ...mapState('cobranza', ['item', 'clients']),
    ...mapState('tareas', ['item']),
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return '';
      }

      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? '' : endMonth;

      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = startYear === endYear ? '' : endYear;

      const startDay = start.day + this.nth(start.day);
      const endDay = end.day + this.nth(end.day);

      switch (this.type) {
        case 'month':
          return `${startMonth} ${startYear}`;
        case 'week':
        case '4day':
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
        case 'day':
          return `${startMonth} ${startDay} ${startYear}`;
      }
      return '';
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: 'UTC', month: 'long',
      });
    },
  },
  async mounted() {
    this.$refs.calendar.checkChange();
    try {
      const requests = {
        company_id: this.profile.organization.id,
        id_vendedor: this.id_vendedor,
      };
      this.ejecutivo = (await ejecutivos(requests)).data;
      this.$store.dispatch('cobranza/clients', {});
      this.gestion = (await gestion()).data;
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(error);
    }
  },
  created() {
    this.getEvents();
  },
  methods: {
    getColor(status) {
      return status === 'CREADO' ? 'green' : 'red';
    },
    async updateEvent(ev) {
      try {
        console.log(ev);
        (await updatetareas({
          name: ev.name,
          details: ev.details,
          id: ev.id,
          id_gestion: ev.id_gestion,
        }));
        console.log(ev);
        this.selectedOpen = false;
        this.currentlyEditing = null;
      } catch (error) {
        console.log(error);
      }
    },
    /* eslint-disable camelcase */
    editEvent(id) {
      this.currentlyEditing = id;
    },
    async deleteEvent(ev) {
      try {
        (await deletetareas({
          id: ev.id,
        }));
        this.selectedOpen = false;
        this.getEvents();
      } catch (error) {
        console.log(error);
      }
    },
    async addEvent() {
      try {
      // if (this.rif_cliente && this.start && this.end) {
        console.log(this.rif_cliente);
        (await addtareas({
          // id_ruta: this.id_ruta,
          id_vendedor: this.id_vendedor,
          rif_cliente: this.rif_cliente,
          fecha_visita: this.fecha_visita,
          // status: this.status,
          observacion: this.observacion,
          color: '#1976D2',
          // id_ubicacion: this.id_ubicacion,
          id_gestion: this.id_gestion,
          // fecha_creacion: this.fecha_creacion,
          usuario_creacion: this.profile.user.id,
          organization: this.organization,
        }));

        this.getEvents();
        // this.id_ruta = null;
        this.id_vendedor = null;
        this.rif_cliente = null;
        this.fecha_visita = null;
        // this.status = null;
        this.observacion = null;
        this.color = null;
        // this.id_ubicacion = null;
        this.id_gestion = null;
        // this.fecha_creacion = null;
        this.usuario_creacion = null;
        this.organization = null;
        // } else {
        // }
      } catch (error) {
        console.log(error);
      }
    },
    async getEvents() {
      try {
        const requests = {
          company_id: this.profile.organization.id,
          id_vendedor: this.id_vendedor,
          organization: this.organization,
        };
        const snapshot = (await tareas(requests)).data;
        const events = [];
        // const evento = [];
        console.log(snapshot);
        snapshot.forEach((doc) => {
          console.log(doc);
          // const eventoData = doc.data;
          events.push(
            {
              // id_vendedor: doc.id_vendedor,
              color: doc.color,
              details: doc.observacion,
              name: doc.rif_cliente,
              start: doc.fecha_visita,
              end: doc.fecha_visita,
              status: doc.status,
              id: doc.id_ruta,
              gestion: doc.gestion,
              id_gestion: doc.id_gestion,
            },
          ); // events.push(eventoData);
          console.log(events);
        });
        this.events = events;
        this.gestion = events[0].gestion;
        console.log(this.gestion);
      } catch (error) {
        console.log(error);
      }
    },
    viewDay({ date }) {
      // console.log(date);
      this.focus = date;
      this.type = 'day';
    },
    getEventColor(ev) {
      const events = [];
      let estatus = '';
      // const evento = [];
      console.log(ev);
      ev.forEach((doc) => {
        console.log(doc);
        // const eventoData = doc.data;
        events.push(doc.status); // events.push(eventoData);
        // console.log(events);
        estatus = events;
      });
      // this.events = events;
      // this.estatus = events[0].estatus;
      let element = '';
      for (let index = 0; index < estatus.length; index += 1) {
        element = estatus[index];
        console.log(element);
      }
      console.log(element);
      return element === 'CREADO' ? 'green' : 'red';
    },
    setToday() {
      this.focus = this.today;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => { this.selectedOpen = true; }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      // You could load events from an outside source (like database) now that we have the start and end dates on the calendar
      this.start = start;
      this.end = end;
    },
    nth(d) {
      return d > 3 && d < 21
        ? 'th'
        : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10];
    },
  },
};
</script>
