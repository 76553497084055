import httpClient from '../axios';

const URL = 'zones';

const zones = async (data) => httpClient.post(URL, data);

const URL_SELECT_EXECUTIVE = 'getSelectZoneExecutive';

const getSelectZoneExecutive = async (data) => httpClient.post(URL_SELECT_EXECUTIVE, data);

const URL_INPUT_LAST_ZONE = 'getLastZone';

const getLastZone = async () => httpClient.get(URL_INPUT_LAST_ZONE);

const URL_STORE = 'zoneStore';

const store = async (data) => httpClient.post(URL_STORE, data);

const URL_UPDATE = 'zoneUpdate';

const updated = async (data) => httpClient.post(URL_UPDATE, data);

export {
  zones,
  getSelectZoneExecutive,
  getLastZone,
  store,
  updated,
};
