// eslint-disable-next-line import/extensions
import CryptoJS from 'crypto-js';

class Profile {
  static getPassphrase() {
    return '&5bF#$!(/oY?dXz4%4.';
  }

  static getAck() {
    return CryptoJS.AES.encrypt(this.getPassphrase(), this.getPassphrase()).toString().substring(0, 8);
  }

  static getRaw() {
    if (localStorage.getItem('profile') !== null) {
      return localStorage.getItem('profile').toString();
    }

    return null;
  }

  static get() {
    if (!this.check()) return null;
    const decrypt = CryptoJS.AES.decrypt(this.getRaw(), this.getPassphrase()).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decrypt);
  }

  static version() {
    return this.get().version;
  }

  static set(param) {
    this.clear();
    const profileCrypt = CryptoJS.AES.encrypt(JSON.stringify(param), this.getPassphrase()).toString();
    localStorage.setItem('profile', profileCrypt);
  }

  static check() {
    if (localStorage.getItem('profile') === null) {
      return false;
    }
    const ack = localStorage.getItem('profile').substring(0, 8);
    return ack === this.getAck();
  }

  static isExist() {
    return localStorage.getItem('profile') !== null;
  }

  static access(action) {
    return this.get().permissions.find((permisos) => permisos.accion === action) !== undefined;
  }

  static clear() {
    localStorage.removeItem('profile');
  }

  static clearAll() {
    localStorage.clear();
    sessionStorage.clear();
  }
}

export default Profile;
