import { organization } from '../../config/api/organization';

export default {

  name: 'organization',

  namespaced: true,

  state: {
    // PRINCIPAL
    item: [],
    // FORMULARIO
    show: false,
    show_img: false,
    isAdd: false,
    isUpdate: false,
    title: '',
    //  CAMPOS DE FORMULARIO
    company: '',
    rif: '',
    nameFull: '',
    society: '',
    org: '',
    address: '',
    color: '',
    phone: '',
    mail: '',
    photo: '',
    status: true,
    //  DATOS SELECCIONADOS
    formData: [],
  },
  mutations: {
    // PRINCIPAL
    SET_DATA(state, parameter) {
      state.item = parameter.data;
    },
    // FORMULARIO
    SHOW(state) {
      state.show = !state.show;
    },
    SHOW_IMG(state) {
      state.show_img = !state.show_img;
    },
    SET_IS_ADD(state, parameter) {
      state.isAdd = parameter;
    },
    SET_IS_UPDATE(state, parameter) {
      state.isUpdate = parameter;
    },
    SET_TITLE(state, parameter) {
      state.title = parameter;
    },

    // CAMPOS DE FORMULARIO
    SET_COMPANY(state, parameter) {
      state.company = parameter;
    },
    SET_RIF(state, parameter) {
      state.rif = parameter;
    },
    SET_NAMEFULL(state, parameter) {
      state.nameFull = parameter;
    },
    SET_SOCIETY(state, parameter) {
      state.society = parameter;
    },
    SET_ORG(state, parameter) {
      state.org = parameter;
    },
    SET_ADDRESS(state, parameter) {
      state.address = parameter;
    },
    SET_PHONE(state, parameter) {
      state.phone = parameter;
    },
    SET_MAIL(state, parameter) {
      state.mail = parameter;
    },
    SET_COLOR(state, parameter) {
      state.color = parameter;
    },
    SET_PHOTO(state, parameter) {
      state.photo = parameter;
    },
    SET_STATUS(state, parameter) {
      state.status = parameter;
    },

    // SELECCIONADO
    SET_FORM_DATA(state, parameter) {
      state.formData = parameter;
    },
  },
  actions: {
    // PRINCIPAL
    async data({ commit }, parameter) {
      commit('SET_DATA', await organization(parameter));
    },
    // FORMULARIO
    imgshow({ commit }, parameter) {
      commit('SHOW_IMG');
      commit('SET_COMPANY', parameter.name);
      commit('SET_ORG', parameter.org);
      commit('SET_PHOTO', parameter.photo);
    },
    imgclose({ commit }) {
      commit('SHOW_IMG');
      commit('SET_PHOTO', '');
    },
    add({ commit }) {
      commit('SHOW');
      commit('SET_TITLE', 'Agregar');
      commit('SET_IS_ADD', true);
      commit('SET_COMPANY', '');
      commit('SET_RIF', '');
      commit('SET_NAMEFULL', '');
      commit('SET_SOCIETY', '');
      commit('SET_ORG', '');
      commit('SET_STATUS', 'Habilitada');
      commit('SET_ADDRESS', '');
      commit('SET_COLOR', '');
      commit('SET_PHONE', '');
      commit('SET_MAIL', '');
    },
    update({ commit }, parameter) {
      commit('SHOW');
      commit('SET_TITLE', 'Editar');
      commit('SET_IS_UPDATE', true);
      commit('SET_COMPANY', parameter.name);
      commit('SET_RIF', parameter.rif);
      commit('SET_NAMEFULL', parameter.nameFull);
      commit('SET_SOCIETY', parameter.society);
      commit('SET_ORG', parameter.org);
      commit('SET_STATUS', parameter.status);
      commit('SET_ADDRESS', parameter.address);
      commit('SET_COLOR', parameter.color);
      commit('SET_PHONE', parameter.phone);
      commit('SET_MAIL', parameter.email);
    },
    reset({ commit, state }) {
      commit('SHOW', state.show);
      commit('SET_IS_ADD', false);
      commit('SET_IS_UPDATE', false);
      commit('SET_COMPANY', '');
      commit('SET_RIF', '');
      commit('SET_NAMEFULL', '');
      commit('SET_SOCIETY', '');
      commit('SET_ORG', '');
      commit('SET_STATUS', 'Habilitada');
      commit('SET_ADDRESS', '');
      commit('SET_COLOR', '');
      commit('SET_PHONE', '');
      commit('SET_MAIL', '');
    },
    toAssignCompany({ commit }, parameter) {
      commit('SET_COMPANY', parameter);
    },
    toAssignRif({ commit }, parameter) {
      commit('SET_RIF', parameter);
    },
    toAssignNameFull({ commit }, parameter) {
      commit('SET_NAMEFULL', parameter);
    },
    toAssignSociety({ commit }, parameter) {
      commit('SET_SOCIETY', parameter);
    },
    toAssignOrg({ commit }, parameter) {
      commit('SET_ORG', parameter);
    },
    toAssignAddress({ commit }, parameter) {
      commit('SET_ADDRESS', parameter);
    },
    toAssignPhone({ commit }, parameter) {
      commit('SET_PHONE', parameter);
    },
    toAssignMail({ commit }, parameter) {
      commit('SET_MAIL', parameter);
    },
    toAssignColor({ commit }, parameter) {
      commit('SET_COLOR', parameter);
    },
    toAssignImagen({ commit }, parameter) {
      commit('SET_PHOTO', parameter);
    },
    toAssignStatus({ commit }, parameter) {
      commit('SET_STATUS', parameter);
    },
  },
};
