<template>
  <v-app>
    <Toolbar/>
    <v-main>
      <v-container fluid class="mt-5 pt-5">
        <v-card>
          <v-card-title>
            <v-icon class='mr-2'> mdi-application </v-icon>
            {{profile.organization.name}} - Procesar Cobranza
            <v-spacer></v-spacer>
            <v-text-field
              v-model='search'
              append-icon='mdi-magnify'
              label='Buscar'
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-card class='mx-auto' width='98%'>
            <v-data-table
              :headers='headers'
              :items='formData'
              :search='search'
              no-data-text='no hay datos'
              :loading='loading'
              loading-text='cargando'
              sort-by='user_name'
              class='elevation-24 mytable'
              :header-props="{
                sortByText: 'Ordenar',
                class: 'title',
              }"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',
                'items-per-page-text': 'Pedidos Procesados por Página',
              }"
            >
              <template v-slot:[`item.acciones`]='{ item }'>
                <v-btn icon v-show="validar(profile.permissions,'10001')">
                  <v-icon
                    class='px-auto'
                    title='Visualizar'
                    @click='showCobranza(item)'
                    color='blue'
                  >
                    mdi-eye
                  </v-icon>
                </v-btn>
                <v-btn icon v-show="(item.Estatus === 'POR VERIFICAR') & validar(profile.permissions,'10004')">
                  <v-icon
                    class="ma-2"
                    title="Verificar"
                    color="blue"
                    @click="actualizarEstado(item)"
                  >
                    mdi-thumb-up
                  </v-icon>
                </v-btn>
                <v-btn icon v-show="(item.Estatus === 'CREADO' || item.Estatus === 'POR VERIFICAR') & validar(profile.permissions,'10003')">
                  <v-icon
                    class='px-auto'
                    title='Eliminar'
                    color='red'
                    @click="borrarC(item)"
                  >
                    mdi-delete-circle
                  </v-icon>
                </v-btn>
                <v-btn icon v-show="(item.Estatus === 'CREADO' || item.Estatus === 'POR VERIFICAR') & validar(profile.permissions,'10004')">
                  <v-icon
                    class='px-auto'
                    title='Modificar'
                    @click='editCobranza(item,ver)'
                    color='blue'
                  >
                    mdi-circle-edit-outline
                  </v-icon>
                </v-btn>
                <v-btn icon v-show="(item.Estatus === 'VERIFICADO' || item.Estatus === 'PROCESADO') & validar(profile.permissions,'10003')">
                  <v-icon class='px-auto' title='Anular'
                          color='orange' @click="remove(item)"
                  >
                    mdi-delete-variant
                  </v-icon>
                </v-btn>
                <v-btn icon v-show="(item.Estatus === 'CREADO'|| item.Estatus === 'POR VERIFICAR' || item.Estatus === 'VERIFICADO' || item.Estatus === 'PROCESADO')">
                  <v-icon
                    class='px-auto'
                    title='Descargar Archivo'
                    color='blue'
                    @click="download(item)"
                  >
                    mdi-file-alert-outline
                  </v-icon>
                </v-btn>
                <v-btn :disabled="habilitar()"
                  v-show="validar(profile.permissions,'10006')"
                  class="px-3 ml-1" v-if="item.Estatus === 'VERIFICADO'"
                  icon title='Crear Cobranza SAP' @click="Enviar_sap(item)"
                >
                  <v-img src='system/sap.png' height='auto' width='30'></v-img>
                </v-btn>
              </template>
              <template v-slot:[`item.Estatus`]='{ item }'>
                <v-chip :color='getColor(item.Estatus)' small text-color='white'>
                  {{ item.Estatus }}
                </v-chip>
              </template>
            </v-data-table>
          </v-card>
          <ShowCobranza/>
        </v-card>
      </v-container>
      <v-container>
        <v-card class="mx-auto">
          <v-dialog v-model="modalLogout" open-delay="" max-width="290" transition="dialog-top-transition">
              <v-card>
                <v-toolbar :color="profile.organization.color" dark height="50px">
                  <v-icon class="mr-2">
                    mdi-application
                  </v-icon>
                    <h1 class="title mt-2 white--text">
                      Anular Cobranza
                    </h1>
                </v-toolbar>
                <v-card-text class="pt-4 title">
                  ¿Está seguro que desea anular la cobranza?
                </v-card-text>
                  <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="red darken-1" outlined @click="modalLogout = false">
                        No
                      </v-btn>
                      <v-btn color="green darken-1" outlined @click="anularC(formData[id-2])">
                        Sí, Anular!
                      </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
        </v-card>
         <v-card class="mx-auto">
          <v-dialog v-model="modalV" open-delay="" max-width="290" transition="dialog-top-transition">
              <v-card>
                <v-toolbar :color="profile.organization.color" dark height="50px">
                  <v-icon class="mr-2">
                    mdi-application
                  </v-icon>
                    <h1 class="title mt-2 white--text">
                      Verificar Cobranza
                    </h1>
                </v-toolbar>
                <v-card-text class="pt-4 title">
                  ¿Está seguro que desea verificar la cobranza?
                </v-card-text>
                  <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="red darken-1" outlined @click="modalV = false">
                        No
                      </v-btn>
                      <v-btn color="green darken-1" outlined @click="verificarC(formData[id-2])">
                        Sí, Verificar!
                      </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
        </v-card>
        <v-card class="mx-auto">
          <v-dialog v-model="borrarLogout" open-delay="" max-width="290" transition="dialog-top-transition">
              <v-card>
                <v-toolbar :color="profile.organization.color" dark height="50px">
                  <v-icon class="mr-2">
                    mdi-application
                  </v-icon>
                    <h1 class="title mt-2 white--text">
                      Borrar Cobranza
                    </h1>
                </v-toolbar>
                <v-card-text class="pt-4 title">
                  ¿Está seguro que desea Borrar la cobranza?
                </v-card-text>
                  <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="red darken-1" outlined @click="borrarLogout = false">
                        No
                      </v-btn>
                      <v-btn color="green darken-1" outlined @click="borrar(formData[id-2])">
                        Sí, Borrar!
                      </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
        </v-card>
      </v-container>
    </v-main>
   <SetLogout/>
   <Historicobcv/>
  </v-app>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapActions, mapState } from 'vuex';
// eslint-disable-next-line import/extensions
import jspdf from 'jspdf';
// eslint-disable-next-line import/extensions
import autoTable from 'jspdf-autotable';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import ShowCobranza from './ShowCobranza';
// eslint-disable-next-line import/extensions
import {
  emitirCobranzaSap, anularCobranza, verificarCobranza, borrarCobranza, cobranzaReporte,
} from '../../config/api/cobranza';
// eslint-disable-next-line import/extensions
import Toolbar from '../../components/Toolbar';
// eslint-disable-next-line import/extensions
import Logos from '../Salesorder/logos';
// eslint-disable-next-line import/extensions
import SetLogout from '../../components/SetLogout';
// eslint-disable-next-line import/extensions
import Historicobcv from '../../components/Historicobcv';

export default {
  name: 'procesarcobranza',
  components: {
    Toolbar, ShowCobranza, SetLogout, Historicobcv,
  },
  data: () => ({
    modalLogout: false,
    modalV: false,
    borrarLogout: false,
    search: '',
    // tasa: null,
    ver: false,
    dialog: false,
    headers: [
      { text: 'Cobranza', value: 'Cobranza' },
      { text: 'Rif', value: 'Rif' },
      { text: 'Cliente', value: 'Cliente' },
      { text: 'Zona', value: 'Zona' },
      { text: 'Creado', value: 'FechaCreado' },
      { text: 'Modificado', value: 'FechaModificado' },
      { text: 'Monto', value: 'Total' },
      { text: 'Estatus', value: 'Estatus' },
      { text: 'Doc. Compensación', value: 'Docsap' },
      { text: 'Acciones', value: 'acciones' },
    ],
    items: [],
    dataBanco: [],
    dataDocumento: [],
    loading: true,
    id: '0',
    notas: [],
  }),
  computed: {
    ...mapState('cobranza', ['formData', 'Cobranza']),
    profile() {
      return Profile.get();
    },
    id: {
      set(vale) {
        this.$store.dispatch('cobranza/toAssignCobranza', vale);
      },
      get() {
        return this.Cobranza;
      },
    },
  },
  methods: {
    ...mapActions('cobranza', ['cobranzaData', 'showCobranza', 'editCobranza']),
    getColor(estatus) {
      switch (estatus) {
        case 'CREADO':
          return 'blue';
        case 'ANULADO':
          return 'black';
        case 'PROCESADO':
          return 'green';
        case 'VERIFICADO':
          return 'orange';
        default:
          return 'red';
      }
    },
    validar(permiso, accion) {
      let valor = false;
      permiso.forEach((permisos) => {
        if (permisos.accion === accion) {
          valor = true;
        }
      });
      return valor;
    },
    data(item) {
      return {
        data: item,
        org: this.profile.org,
        society_id: this.profile.organization.society,
        user_id: this.profile.user.id,
      };
    },
    alert(message) {
      this.$store.commit('message/MESSAGE', message);
      this.$store.commit('message/TYPE', 'info');
      this.$store.dispatch('message/run');
    },
    remove(item) {
      this.modalLogout = true;
      this.id = item.Cobranza;
    },
    actualizarEstado(item) {
      this.modalV = true;
      this.id = item.Cobranza;
    },
    borrarC(item) {
      this.borrarLogout = true;
      this.id = item.Cobranza;
    },
    async anularC() {
      const requests = {
        user_id: this.profile.user.id,
        cobranza: this.id,
      };
      this.response(await anularCobranza(requests));
      this.modalLogout = false;
    },
    async verificarC() {
      const requests = {
        user_id: this.profile.user.id,
        cobranza: this.id,
      };
      this.response(await verificarCobranza(requests));
      this.modalV = false;
    },
    async borrar() {
      const requests = {
        user_id: this.profile.user.id,
        cobranza: this.id,
      };
      this.response(await borrarCobranza(requests));
      this.borrarLogout = false;
    },
    async Enviar_sap(item) {
      this.response(await emitirCobranzaSap(this.data(item)));
    },
    habilitar() {
      return this.bootsap === false;
    },
    response(api) {
      this.$store.commit('message/LOADING', true);
      try {
        this.$store.commit('message/MESSAGE', api.data.message);
        this.$store.commit('message/TYPE', 'info');
        this.$store.commit('message/MODULO', 'Cobranza');
        this.$store.dispatch('message/run');
      } catch (error) {
        // eslint-disable-next-line no-alert
        alert(error);
      }
      this.$store.commit('message/LOADING', false);
    },
    async download(item) {
      const requests = {
        Cobranza: item.Cobranza,
      };
      const data = await cobranzaReporte(requests);

      const nombre = `Cobranza- ${item.Cobranza}`;
      const date = new Date();
      const a = '-';
      const fecha = date.getFullYear() + a + String(date.getMonth() + 1).padStart(2, '0') + a + String(date.getDate()).padStart(2, '0');
      const columns = [
        { title: 'Forma de Pago', dataKey: 'forma_pago', align: 'center' },
        { title: 'Banco Origen', dataKey: 'banco_origen', align: 'center' },
        { title: 'Monto', dataKey: 'monto', align: 'center' },
        { title: 'Banco Destino', dataKey: 'banco_id', align: 'center' },
        { title: 'Referencia', dataKey: 'referencia_ban', align: 'center' },
        { title: 'Fecha Valor', dataKey: 'fecha_valor', align: 'center' },
        { title: 'Nota', dataKey: 'observacion', align: 'center' },
      ];
      const columns1 = [
        { title: 'Nro de Documento', dataKey: 'nro_doc', align: 'center' },
        { title: 'Monto', dataKey: 'monto', align: 'center' },
        { title: 'Ejercicio', dataKey: 'ejercicio', align: 'center' },
        { title: 'Notas', dataKey: 'notas', align: 'center' },
      ];
      const cel = parseFloat(this.profile.organization.id) === parseFloat('3') ? 'Num. Cel. 0414 5104753' : '';
      /* eslint new-cap: ["error", { "newIsCap": false }] */
      const doc = new jspdf();
      const logoEmpresa = this.profile.organization.id > 4 ? Logos[1] : Logos[this.profile.organization.id - 1];
      const nameMaferca = parseFloat(this.profile.organization.id) === parseFloat('1') ? 'Mayor de Ferretería Maferca, C.A.' : '';
      const nameRefrisa = parseFloat(this.profile.organization.id) === parseFloat('2') ? 'Compañia Nacional de Refrigeración S.A.' : '';
      const nameHierro = parseFloat(this.profile.organization.id) === parseFloat('3') ? 'Hierro Barquisimeto C.A.' : '';
      const nameCaproa = parseFloat(this.profile.organization.id) === parseFloat('4') ? 'Procesador de Acero C.A.' : '';
      const namePeru = parseFloat(this.profile.organization.id) === parseFloat('5') ? 'Refrigeración Nacional Perú' : '';
      const direccionEmpresa = data.data.item[0].direction;
      const phoneEmpresa = data.data.item[0].phone;
      console.log(direccionEmpresa);
      // text is placed using x, y coordinates
      doc.addImage(logoEmpresa, 'jpeg', 156, 4, 50, 16);
      doc.setFontSize(18).text(nameRefrisa, 38, 10);
      doc.setFontSize(18).text(nameMaferca, 52, 10);
      doc.setFontSize(18).text(nameHierro, 38, 10);
      doc.setFontSize(18).text(nameCaproa, 52, 10);
      doc.setFontSize(18).text(namePeru, 52, 10);
      if (direccionEmpresa.length > 53) {
        doc.setFontSize(13).text(direccionEmpresa.slice(0, -60), 38, 15);
        doc.setFontSize(13).text(direccionEmpresa.slice(-60), 30, 19);
        doc.setFontSize(10).text(`Teléfono ${phoneEmpresa}`, 85, 23);
        doc.setFontSize(10).text(cel, 85, 26);
      } else {
        doc.setFontSize(13).text(direccionEmpresa, 42, 15);
        doc.setFontSize(10).text(`Teléfono ${phoneEmpresa}`, 85, 20);
        doc.setFontSize(10).text(cel, 85, 24);
      }
      doc.setFontSize(15).text('Cobranza', 90, 32);
      doc.setFontSize(8).text(`Cliente: ${item.Cliente}`, 20, 40);
      doc.setFontSize(8).text(`Rif: ${item.Rif}`, 20, 44);
      doc.setFontSize(8).text(`Nº SAP: ${item.Docsap}`, 20, 48);
      doc.setFontSize(8).text(`Cobranza No: ${item.Cobranza}`, 150, 40);
      doc.setFontSize(8).text(`Creado El: ${item.FechaCreado}`, 150, 44);
      doc.setFontSize(8).text(`Emitido El: ${fecha}`, 150, 48);
      this.dataBanco = data.data.Banco;
      this.dataDocumento = data.data.Doc;
      console.log(data);
      /* this.dataDocumento = { [item.Doc[0].monto, item.Doc[0].monto]

      }; */
      doc.autoTable({
        columns,
        body: this.dataBanco,
        margin: { left: 20, top: 70 },
      });
      autoTable(doc, {
        head: [['Nro de Documento', 'Monto', 'Ejercicio', 'Notas', '         ', '         ', '         ', '         ']],
        margin: { left: 20, top: 1 },
      });
      doc.autoTable({
        columns1,
        body: this.dataDocumento,
        margin: { left: 20, top: 0 },
      });
      doc.setFontSize(12).text(`Total: ${item.Total}`, 146, 210);
      doc.save(`${nombre}.pdf`);
    },
  },
  mounted() {
    /* eslint-disable-next-line eqeqeq */
    const permissions = this.profile.permissions.find((e) => e.accion == '10001');
    if (!permissions) {
      this.$router.push('notAuthorized');
    }
    try {
      const requests = {
        org: this.profile.org,
        society_id: this.profile.organization.society,
        user_id: this.profile.user.id,
      };
      this.$store.dispatch('cobranza/cobranzaData', requests);
      this.loading = false;
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(error);
    }
  },
};
</script>
