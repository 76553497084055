<template>
  <v-app>
    <Toolbar/>
    <v-main>
      <v-container fluid>
        <v-card>

          <v-card-title>
             <v-icon class="mr-2">
               mdi-application
              </v-icon>
              {{profile.organization.name}} - Presupuesto
            <v-spacer></v-spacer>

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

            <v-btn
              v-show="validar(profile.permissions,'21002')"
              title="Agregar Ejecutivo"
              class="mx-4 mb-4"
              tile
              color="grey lighten-2"
              @click='add()'>
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>

          <v-card class="mx-auto" width="98%">
            <v-data-table
              :headers="headers"
              :items="item"
              :search="search"
              sort-by="presupuesto"
              class="elevation-24"
              no-data-text="no hay datos"
              :header-props="{
                sortByText: 'Ordenar',
              }"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',
                'items-per-page-text': 'presupuesto por página',
              }"
              multiSort
            >
              <template v-slot:[`item.nota`]="{ item }">
                          <v-text-field
                            v-model="presupuesto.nota"
                            :hide-details="true"
                            type="text"
                            dense single-line
                            :autofocus="true"
                            v-if="item.ejecutivo === itemPos">
                          </v-text-field>
                          <span v-else>{{item.nota}}</span>
              </template>
              <template v-slot:[`item.enero`]="{ item }">
                          <v-text-field
                            v-model="presupuesto.enero"
                            :hide-details="true"
                            type="number"
                            dense single-line
                            :autofocus="true"
                            v-if="item.ejecutivo === itemPos">
                          </v-text-field>
                          <span v-else>{{item.enero}}</span>
              </template>
              <template v-slot:[`item.febrero`]="{ item }">
                          <v-text-field
                            v-model="presupuesto.febrero"
                            :hide-details="true"
                            type="number"
                            dense single-line
                            :autofocus="true"
                            v-if="item.ejecutivo === itemPos">
                          </v-text-field>
                          <span v-else>{{item.febrero}}</span>
              </template>
              <template v-slot:[`item.marzo`]="{ item }">
                          <v-text-field
                            v-model="presupuesto.marzo"
                            :hide-details="true"
                            type="number"
                            dense single-line
                            :autofocus="true"
                            v-if="item.ejecutivo === itemPos">
                          </v-text-field>
                          <span v-else>{{item.marzo}}</span>
              </template>
              <template v-slot:[`item.abril`]="{ item }">
                          <v-text-field
                            v-model="presupuesto.abril"
                            :hide-details="true"
                            type="number"
                            dense single-line
                            :autofocus="true"
                            v-if="item.ejecutivo === itemPos">
                          </v-text-field>
                          <span v-else>{{item.abril}}</span>
              </template>
              <template v-slot:[`item.mayo`]="{ item }">
                          <v-text-field
                            v-model="presupuesto.mayo"
                            :hide-details="true"
                            type="number"
                            dense single-line
                            :autofocus="true"
                            v-if="item.ejecutivo === itemPos">
                          </v-text-field>
                          <span v-else>{{item.mayo}}</span>
              </template>
              <template v-slot:[`item.junio`]="{ item }">
                          <v-text-field
                            v-model="presupuesto.junio"
                            :hide-details="true"
                            type="number"
                            dense single-line
                            :autofocus="true"
                            v-if="item.ejecutivo === itemPos">
                          </v-text-field>
                          <span v-else>{{item.junio}}</span>
              </template>
              <template v-slot:[`item.julio`]="{ item }">
                          <v-text-field
                            v-model="presupuesto.julio"
                            :hide-details="true"
                            type="number"
                            dense single-line
                            :autofocus="true"
                            v-if="item.ejecutivo === itemPos">
                          </v-text-field>
                          <span v-else>{{item.julio}}</span>
              </template>
              <template v-slot:[`item.agosto`]="{ item }">
                          <v-text-field
                            v-model="presupuesto.agosto"
                            :hide-details="true"
                            type="number"
                            dense single-line
                            :autofocus="true"
                            v-if="item.ejecutivo === itemPos">
                          </v-text-field>
                          <span v-else>{{item.agosto}}</span>
              </template>
              <template v-slot:[`item.septiembre`]="{ item }">
                          <v-text-field
                            v-model="presupuesto.septiembre"
                            :hide-details="true"
                            type="number"
                            dense single-line
                            :autofocus="true"
                            v-if="item.ejecutivo === itemPos">
                          </v-text-field>
                          <span v-else>{{item.septiembre}}</span>
              </template>
              <template v-slot:[`item.octubre`]="{ item }">
                          <v-text-field
                            v-model="presupuesto.octubre"
                            :hide-details="true"
                            type="number"
                            dense single-line
                            :autofocus="true"
                            v-if="item.ejecutivo === itemPos">
                          </v-text-field>
                          <span v-else>{{item.octubre}}</span>
              </template>
              <template v-slot:[`item.noviembre`]="{ item }">
                          <v-text-field
                            v-model="presupuesto.noviembre"
                            :hide-details="true"
                            type="number"
                            dense single-line
                            :autofocus="true"
                            v-if="item.ejecutivo === itemPos">
                          </v-text-field>
                          <span v-else>{{item.noviembre}}</span>
              </template>
              <template v-slot:[`item.diciembre`]="{ item }">
                          <v-text-field
                            v-model="presupuesto.diciembre"
                            :hide-details="true"
                            type="number"
                            dense single-line
                            :autofocus="true"
                            v-if="item.ejecutivo === itemPos">
                          </v-text-field>
                          <span v-else>{{item.diciembre}}</span>
              </template>
              <template v-slot:[`item.acciones`]="{item}">
                <div v-if="itemPos === item.ejecutivo">
                      <v-btn icon>
                        <v-icon
                          title="Cerrar distribución"
                          class="mr-2"
                          color="red"
                          @click="closeItem()">
                          mdi-window-close
                        </v-icon>
                      </v-btn>
                      <v-btn icon>
                        <v-icon
                          title="Guardar presupuesto"
                          class="mr-2"
                          color="green"
                          @click="update(item)">
                          mdi-content-save
                        </v-icon>
                      </v-btn>
                </div>
                <div v-else>
                  <v-btn icon
                    v-show="validar(profile.permissions,'21004')">
                    <v-icon
                      title="Editar distribución"
                      class="mr-2"
                      color="green"
                      @click="editItem(item)">
                      mdi-pencil-outline
                    </v-icon>
                  </v-btn>
                  <v-btn icon
                    v-show="validar(profile.permissions,'21003')">
                    <v-icon
                      title="Borrar Reguistro"
                      class="mr-2"
                      color="red"
                      @click="borrar(item.ejecutivo)">
                      mdi-delete-outline
                    </v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-card>
        <PresupuestoForm/>
      </v-container>
      <v-container>
        <v-card class="mx-auto">
          <v-dialog v-model="borrarLogout" open-delay="" max-width="290" transition="dialog-top-transition">
              <v-card>
                <v-toolbar :color="profile.organization.color" dark height="50px">
                  <v-icon class="mr-2">
                    mdi-application
                  </v-icon>
                    <h1 class="title mt-2 white--text">
                      Borrar Ejecutivo
                    </h1>
                </v-toolbar>
                <v-card-text class="pt-4 title">
                  ¿Está seguro que desea Borrar el ejecutivo?
                </v-card-text>
                  <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="red darken-1" outlined @click="borrarLogout = false">
                        No
                      </v-btn>
                      <v-btn color="green darken-1" outlined @click="Delete()">
                        Sí, Borrar!
                      </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
        </v-card>
      </v-container>
    </v-main>
    <SetLogout/>
    <Historicobcv/>
  </v-app>

</template>

<script>
// eslint-disable-next-line import/extensions
import { mapState, mapActions, mapMutations } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import Toolbar from '../../components/Toolbar';
// eslint-disable-next-line import/extensions
import SetLogout from '../../components/SetLogout';
// eslint-disable-next-line import/extensions
import Historicobcv from '../../components/Historicobcv';
// eslint-disable-next-line import/extensions
import PresupuestoForm from './PresupuestoForm';
// eslint-disable-next-line import/extensions
import { modifica, eliminar } from '../../config/api/presupuestos';

export default {
  name: 'Presupuesto',
  components: {
    PresupuestoForm, Toolbar, SetLogout, Historicobcv,
  },
  data: () => ({
    borrarLogout: false,
    search: '',
    itemPos: '',
    presupuesto: {},
    headers: [
      { text: 'Ejecutivo', value: 'ejecutivo', align: 'center' },
      { text: 'Nota', value: 'nota', align: 'center' },
      { text: 'Zona', value: 'zona', align: 'center' },
      { text: 'Enero', value: 'enero', align: 'center' },
      { text: 'Febrero', value: 'febrero', align: 'center' },
      { text: 'Marzo', value: 'marzo', align: 'center' },
      { text: 'Abril', value: 'abril', align: 'center' },
      { text: 'Mayo', value: 'mayo', align: 'center' },
      { text: 'Junio', value: 'junio', align: 'center' },
      { text: 'Julio', value: 'julio', align: 'center' },
      { text: 'Agosto', value: 'agosto', align: 'center' },
      { text: 'Septiembre', value: 'septiembre', align: 'center' },
      { text: 'Octubre', value: 'octubre', align: 'center' },
      { text: 'Noviembre', value: 'noviembre', align: 'center' },
      { text: 'Diciembre', value: 'diciembre', align: 'center' },
      { text: 'Acciones', value: 'acciones', sortable: false },
    ],
    items: [],
    ejecutivo: '',
  }),
  computed: {
    ...mapState('presupuesto', ['item']),
    profile() {
      return Profile.get();
    },
  },
  methods: {
    ...mapMutations('message', ['MESSAGE', 'TYPE', 'LOADING']),
    ...mapActions('message', ['run']),
    ...mapActions('presupuesto', ['data', 'add']),
    validar(permiso, accion) {
      let valor = false;
      permiso.forEach((permisos) => {
        if (permisos.accion === accion) {
          valor = true;
        }
      });
      return valor;
    },
    async update(item) {
      this.alert(await modifica(item));
      this.closeItem();
    },
    borrar(item) {
      this.borrarLogout = true;
      this.ejecutivo = item;
    },
    async Delete() {
      const requests = {
        company_id: this.profile.organization.id,
        ejecutivo: this.ejecutivo,
      };
      this.alert(await eliminar(requests));
      this.borrarLogout = false;
      this.closeItem();
    },
    editItem(item) {
      this.itemPos = item.ejecutivo;
      this.presupuesto = item;
    },
    closeItem() {
      this.itemPos = '';
      this.presupuesto = null;
    },
    alert(api) {
      this.$store.commit('message/MESSAGE', api.data.message);
      this.$store.commit('message/TYPE', 'info');
      this.$store.commit('message/RELOAD', false);
      this.$store.dispatch('message/run');
      this.$store.dispatch('presupuesto/data', this.profile);
    },
  },
  async created() {
    try {
      const requests = {
        company_id: this.profile.organization.id,
        user_id: this.profile.user.id,
      };
      this.$store.dispatch('presupuesto/data', requests);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(error);
    }
  },
};
</script>
