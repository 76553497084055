var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('Toolbar'),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-archive-check-outline ")]),_vm._v(" Productos Sensibles "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-btn',{staticClass:"mx-4 mb-4",attrs:{"title":"Modificar Limites","tile":"","color":"grey lighten-2"},on:{"click":function($event){return _vm.openForm()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1),_c('v-card',{staticClass:"mx-auto",attrs:{"width":"98%"}},[_c('v-data-table',{staticClass:"elevation-24",attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.item,"loading":_vm.item.length === 0,"sort-by":"id","no-data-text":"no hay datos","header-props":{
              sortByText: 'Ordenar',
            },"footer-props":{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-text': 'organizaciones por página',
            },"multiSort":""},scopedSlots:_vm._u([{key:"item.acciones",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.Session.access('40003')),expression:"Session.access('40003')"}],attrs:{"icon":""}},[_c('v-icon',{staticClass:"px-auto",attrs:{"title":"Eliminar Limite"},on:{"click":function($event){return _vm.deleteModal(item)}}},[_vm._v(" mdi-delete-outline ")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.Session.access('40003')),expression:"Session.access('40003')"}],attrs:{"icon":""}},[_c('v-icon',{staticClass:"px-auto",attrs:{"title":"Modificar Limite"},on:{"click":function($event){return _vm.openForm1(item)}}},[_vm._v(" mdi-circle-edit-outline ")])],1)]}}],null,true)})],1),_c('LimitForm')],1)],1)],1),_c('SetLogout'),_c('Historicobcv'),_c('v-dialog',{attrs:{"open-delay":"","max-width":"290","transition":"dialog-top-transition"},model:{value:(_vm.modalDelete),callback:function ($$v) {_vm.modalDelete=$$v},expression:"modalDelete"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":_vm.profile.organization.color,"dark":"","height":"50px"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-application ")]),_c('h1',{staticClass:"title mt-2 white--text"},[_vm._v(" Borrado de registro ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.modalDelete = false}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-card-text',{staticClass:"pt-4 title"},[_vm._v(" ¿Está seguro que desea borrar el registro seleccionado? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","outlined":""},on:{"click":function($event){_vm.modalDelete = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"green darken-1","outlined":""},on:{"click":function($event){return _vm.deleteItem()}}},[_vm._v(" Sí, borrar! ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }