import { presupuestos, grafica } from '../../config/api/presupuestos';

export default {

  name: 'presupuesto',

  namespaced: true,

  state: {
    // PRINCIPAL
    item: [],
    grafica: {},
    // FORMULARIO
    show: false,
    isAdd: false,
    isUpdate: false,
    title: '',
    i: 0,
    //  CAMPOS DE FORMULARIO
    nota: '',
    //  DATOS SELECCIONADOS
    formData: [],
    data: {},
  },
  mutations: {
    // PRINCIPAL
    SET_DATA(state, parameter) {
      state.item = parameter.data;
    },
    SET_GRAFICA(state, parameter) {
      state.data = parameter.data;
      state.data.forEach((gra) => {
        state.grafica[state.i] = gra.original;
        state.i += 1;
      });
    },
    SET_TITLE(state, parameter) {
      state.title = parameter;
    },
    SET_FORM_DATA(state, parameter) {
      state.formData = parameter;
    },
    // FORMULARIO
    SHOW(state) {
      state.show = !state.show;
    },
    SET_IS_ADD(state, parameter) {
      state.isAdd = parameter;
    },
    SET_NOTA(state, parameter) {
      state.nota = parameter;
    },
    SET_MES(state, payload) {
      state.mes = payload;
    },
  },
  actions: {
    // PRINCIPAL
    async data({ commit }, parameter) {
      commit('SET_DATA', await presupuestos(parameter));
    },
    async grafica({ commit }, parameter) {
      commit('SET_GRAFICA', await grafica(parameter));
    },
    // FORMULARIO
    add({ commit }) {
      commit('SHOW');
      commit('SET_TITLE', 'AGREGAR');
      commit('SET_IS_ADD', true);
    },
    clean({ commit }) {
      commit('SET_NOTA', '');
    },
    async reset({ commit }) {
      commit('SHOW');
      commit('SET_IS_ADD', false);
      commit('SET_NOTA', '');
    },
    toAssignNota({ commit }, parameter) {
      commit('SET_NOTA', parameter);
    },
    toAssignMes({ commit }, parameter) {
      commit('SET_MES', parameter);
    },
  },
};
