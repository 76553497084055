<template>
<v-row justify="center">
    <v-dialog v-model="show" persistent max-width="95%">
      <v-card>
        <v-toolbar :color="profile.organization.color" dark>
          <v-icon class="mr-2">
            mdi-application
          </v-icon>
            <h1 class="title mt-2 white--text">
              {{title}} PEDIDO
              ##### {{salesorder_no}}
            </h1>
          <v-spacer></v-spacer>
            <v-btn icon dark @click="clean()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
          <SalesorderForm v-if="formulario()"></SalesorderForm>
          <SalesorderFormHierro v-else></SalesorderFormHierro>
          <SalesorderList v-if="formulario()"></SalesorderList>
          <SalesorderListHierro v-else></SalesorderListHierro>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
            <!--<v-btn dark :color="profile.organization_color" @click="clean()" v-show="open">
              Cerrar
            </v-btn>
            <v-btn dark :color="profile.organization_color" @click="liberarp(salesorderid), clean()" v-show="close">
              Cerrar.
            </v-btn>-->
            <v-btn dark :color="profile.organization_color" @click="clean()" v-show="close">
              Cerrar
            </v-btn>
            <v-btn dark :color="profile.organization_color" @click="add()" v-show="isAdd">
              Agregar
            </v-btn>
            <v-btn dark :color="profile.organization_color" @click="update()" v-show="isUpdate">
              Editar
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapActions, mapMutations, mapState } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import SalesorderForm from './SalesorderForm';
// eslint-disable-next-line import/extensions
import SalesorderFormHierro from './SalesorderFormHierro';
// eslint-disable-next-line import/extensions
import SalesorderList from './SalesorderList';
// eslint-disable-next-line import/extensions
import SalesorderListHierro from './SalesorderListHierro';
import { store, update, liberarpedido } from '../../config/api/salesorder';

export default {
  name: 'salesorder-dialog',
  data: () => ({
  }),
  computed: {
    ...mapState('userProfile', ['profile']),
    ...mapState('salesorder', ['close', 'open']),
    ...mapState('salesorder', ['cart']),
    ...mapState('salesorder', ['form']),
    ...mapState('salesorder', ['ivachek', 'region', 'sucursal', 'salesorder_no', 'ejecutivo', 'executive', 'flete', 'descuento', 'nrosap', 'poblacion', 'codigo_postal', 'notas', 'description', 'vp', 'cond_compra', 'transporte', 'type', 'title', 'show', 'salesorderid', 'isAdd', 'isUpdate', 'item', 'accountType', 'toAssignId']),
    salesordertype: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignVIA', value);
      },
      get() {
        return this.type;
      },
    },
    ivachec: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignIVACHE', value);
      },
      get() {
        return this.ivachek;
      },
    },
    regiones: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignRegion', value);
      },
      get() {
        return this.region;
      },
    },
    transpor: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignTransporte', value);
      },
      get() {
        return this.transporte;
      },
    },
    paymen: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignCond_Compra', value);
      },
      get() {
        return this.cond_compra;
      },
    },
    formId: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignId', value);
      },
      get() {
        return this.salesorderid;
      },
    },
    formAccount: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignACCOUNT', value);
      },
      get() {
        return this.accountType;
      },
    },
    viapayment: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignVP', value);
      },
      get() {
        return this.vp;
      },
    },
    obeservation: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignNotas', value);
      },
      get() {
        return this.notas;
      },
    },
    Poblacion: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignPoblacion', value);
      },
      get() {
        return this.poblacion;
      },
    },
    Codigo: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignCodigo', value);
      },
      get() {
        return this.codigo_postal;
      },
    },
    NrSap: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignNoSap', value);
      },
      get() {
        return this.nrosap;
      },
    },
    percent: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignFlete', value);
      },
      get() {
        return this.flete;
      },
    },
    rebaja: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignDescuento', value);
      },
      get() {
        return this.descuento;
      },
    },
    Getcar: {
      set(shoppingCart) {
        this.$store.dispatch('salesorder/SET_CART', shoppingCart);
      },
      get() {
        return this.cart;
      },
    },
    Getcalcular: {
      set(form) {
        this.$store.dispatch('salesorder/SET_FORM', form);
      },
      get() {
        return this.form;
      },
    },
    formEjecutivo: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignEJECUTIVO', value);
      },
      get() {
        return this.executive;
      },
    },
    sucursales: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignSUCURSAL', value);
      },
      get() {
        return this.sucursal;
      },
    },
    nota: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignDescription', value);
      },
      get() {
        return this.description;
      },
    },
    profile() {
      return Profile.get();
    },
  },
  methods: {
    ...mapMutations('message', ['MESSAGE', 'TYPE', 'LOADING']),
    ...mapActions('message', ['run']),
    ...mapMutations('salesorder', ['SHOW', '']),
    ...mapMutations('salesorder', ['SET_CART']),
    ...mapMutations('salesorder', ['SET_FORM']),
    ...mapActions('salesorder', ['clean']),
    data() {
      if (this.form.correo == null) {
        this.$correo = '';
      } else {
        this.$correo = this.form.correo;
      }
      if (this.form.telefono == null) {
        this.$telefono = '';
      } else {
        this.$telefono = this.form.telefono;
      }
      if (this.form.poblac == null) {
        this.$poblacion = this.form.poblacion;
        console.log(this.$poblacion);
      } else {
        this.$poblacion = this.form.poblac;
        console.log(this.$poblacion);
      }
      if (this.form.regi == null) {
        this.$region = this.form.region;
        console.log(this.$region);
      } else {
        this.$region = this.form.regi;
        console.log(this.$region);
      }
      return {
        Pedido: {
          salesorderid: this.formId,
          subject: this.obeservation,
          potentialid: '', // ?
          customerno: this.form.customerno,
          sporadicclient: this.form.accountname,
          quoteid: '', // ?
          type: this.salesordertype,
          adjustment: '', // ?
          total: this.form.total,
          subtotal: this.form.subtotal,
          descuento: this.rebaja,
          flete: this.percent,
          iva: this.form.iva,
          taxtype: '', // ?
          terms_conditions: '', // ?
          purchaseorder: '', // ?
          sostatus: this.form.sostatus,
          conversion_rate: '1.000',
          vp: this.viapayment,
          preventa: 0,
          estatus: 1,
          correo: this.$correo,
          telefono: this.$telefono,
          email: this.form.email1,
          phone: this.form.phone,
          organization: this.profile.organization.id,
          bill: this.form.bill_street,
          ship: this.form.ship_street,
          esporadico: ''.concat('', this.form.esporadico || 0),
          checkiva: this.ivachec,
        },
        PedidoDetalle: {
          salesorderid: this.formId,
          Factsap: '',
          credito: '0',
          transporte: this.transpor,
          zona: this.form.zone,
          cond_venta: this.salesordertype,
          cond_comp: (parseFloat(this.profile.organization.id) === parseFloat('3') || parseFloat(this.profile.organization.id) === parseFloat('4')) ? this.paymen : this.form.cond_compra,
          notas: this.obeservation,
          accountType: this.formAccount,
          tasaBancoCental: 0.000,
          tasaIndicada: 0.000,
          pedidoFinanciero: '',
          status_mail: '1',
          idWeb: ' ',
          clase_documento: ' ',
          nro_sap_entregado: ' ',
          organization: this.profile.organization.id,
          vendor: this.formEjecutivo,
          sucursales: this.sucursales,
          poblacion: this.$poblacion,
          codigo: this.Codigo,
          region: this.$region,
        },
        Productos: {
          id: this.formId,
          cart: this.cart,
        },
        Crmentity: {
          crmid: this.formId,
          Smcreatorid: this.profile.user.id,
          Smownerid: this.profile.user.id,
          modifiedby: this.profile.user.id,
          Setype: 'SalesOrder',
          description: this.nota,
        },
      };
    },
    async add() {
      if (this.formAccount === null) {
        this.alert('Tipo de cliente está vacio');
        // return 0;
      } else if (this.salesordertype === null) {
        this.alert('Condicion de venta esta vacio');
        // return 0;
      } else if (this.vp === null) {
        this.alert('Via de pago esta vacio');
        // return 0;
      } else if (this.transporte === null) {
        this.alert('Transporte esta vacio');
        // return 0;
      } else if (this.poblacion === null) {
        this.alert('Poblacion esta vacio');
      } else if (this.formEjecutivo === null && this.profile.organization.id === 2) {
        this.alert('Ejecutivo esta vacio');
        // return 0;
      } else if (this.sucursales === null && this.profile.organization.id === 2) {
        this.alert('Sucursal esta vacio');
        // return 0;
      } else if (this.cart === []) {
        this.alert('Carrito está vacío');
        // return 0;
      } else {
        const response = await store(this.data());
        if (response.status === 203) {
          this.$store.commit('message/MESSAGE', response.data.message);
          this.$store.commit('message/TYPE', 'info');
          this.$store.dispatch('message/run');
          this.$store.dispatch('salesorder/data', this.profile);
        } else {
          this.$store.commit('message/LOADING', true);
          try {
            this.$store.dispatch('salesorder/clean');
            this.$store.commit('message/MESSAGE', response.data.message);
            this.$store.commit('message/TYPE', 'success');
            this.$store.dispatch('message/run');
            this.$store.dispatch('salesorder/data', this.profile);
          } catch (error) {
            // eslint-disable-next-line no-alert
            alert(error);
          }
          this.$store.commit('message/LOADING', false);
        }
      }
    },
    async update() {
      // this.response(await update(this.data()));
      if (this.formAccount === null) {
        this.alert('Tipo de cliente está vacio');
        // return 0;
      } else if (this.salesordertype === null) {
        this.alert('Condicion de venta esta vacio');
        // return 0;
      } else if (this.vp === null) {
        this.alert('Via de pago esta vacio');
        // return 0;
      } else if (this.transporte === null) {
        this.alert('Transporte esta vacio');
        // return 0;
      } else if (this.formEjecutivo === null && this.profile.organization.id === 2) {
        this.alert('Ejecutivo esta vacio');
        // return 0;
      } else if (this.sucursales === null && this.profile.organization.id === 2) {
        this.alert('Sucursal esta vacio');
        // return 0;
      } else {
        const response = await update(this.data());
        // console.log(response.status);
        if (response.status === 203) {
          this.$store.commit('message/MESSAGE', response.data.message);
          this.$store.commit('message/TYPE', 'info');
          this.$store.dispatch('message/run');
          this.$store.dispatch('salesorder/data', this.profile);
        } else {
          this.$store.commit('message/LOADING', true);
          try {
            this.$store.dispatch('salesorder/clean');
            this.$store.commit('message/MESSAGE', response.data.message);
            this.$store.commit('message/TYPE', 'success');
            this.$store.dispatch('message/run');
            this.$store.dispatch('salesorder/data', this.profile);
          } catch (error) {
            // eslint-disable-next-line no-alert
            alert(error);
            console.log(error);
          }
          this.$store.commit('message/LOADING', false);
        }
      }
    },
    response(api) {
      console.log(this.response.status);
      if (this.response.status === 203) {
        this.$store.commit('message/MESSAGE', api.data.message);
        this.$store.commit('message/TYPE', 'info');
        this.$store.dispatch('message/run');
        this.$store.dispatch('salesorder/data', this.profile);
      } else {
        console.log('entro');
        this.$store.commit('message/LOADING', true);
        try {
          this.$store.dispatch('salesorder/clean');
          this.$store.commit('message/MESSAGE', api.data.message);
          this.$store.commit('message/TYPE', 'success');
          this.$store.dispatch('message/run');
          this.$store.dispatch('salesorder/data', this.profile);
        } catch (error) {
          // eslint-disable-next-line no-alert
          alert(error);
        }
        this.$store.commit('message/LOADING', false);
      }
    },
    validate() {
      if (this.vp === '') {
        this.alert('Via de pago esta vacio');
        // return 0;
      } else {
        console.log('paso el negro');
        this.data();
        // return 1;
      }
    },
    alert(message) {
      this.$store.commit('message/MESSAGE', message);
      this.$store.commit('message/TYPE', 'info');
      this.$store.dispatch('message/run');
    },
    liberarp(salesorderid) {
      const request = {
        pedido: salesorderid,
      };
      liberarpedido(request);
    },
    formulario() {
      return this.profile.organization.name === 'Hierro Barquisimeto' || this.profile.organization.name === 'Caproa' ? 0 : 1;
    },
    Carrito() {
      return this.profile.organization.name === 'Hierro Barquisimeto' || this.profile.organization.name === 'Caproa' ? 0 : 1;
    },
  },
  components: {
    SalesorderForm, SalesorderFormHierro, SalesorderList, SalesorderListHierro,
  },
};
</script>

<style scoped>
body {
  font-size: xx-small;
}
</style>
