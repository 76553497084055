<template>
    <v-bottom-sheet v-model="show" persistent>
        <v-sheet class="text-center" height="150">
            <v-alert :type="type">
              <strong class="overline font-weight-medium">
                {{ message }}
              </strong>
            </v-alert>
            <v-btn @click="Refresh()" outlined>Aceptar</v-btn>
        </v-sheet>
    </v-bottom-sheet>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapActions, mapState } from 'vuex';

export default {
  data: () => ({
  }),
  computed: {
    ...mapState('message', ['show', 'message', 'type', 'reload', 'url', 'modulo']),
  },
  methods: {
    ...mapActions('message', ['close', 'reset']),
    ...mapActions('cobranza', ['cobranzaData']),
    Refresh() {
      if (this.reload && this.type !== 'info') {
        if (this.url === '') {
          window.location.reload();
        } else {
          window.location.href = this.url;
          this.reset();
        }
      } else {
        if (this.modulo === 'Cobranza') {
          this.cobranzaData();
        }
        this.close();
      }
    },
  },
};
</script>
