<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="600px">
      <v-card>
        <v-toolbar :color="profile.organization.color" dark>
          <v-icon class='mr-2'> mdi-send </v-icon>
            {{title}}
          <v-spacer></v-spacer>
          <v-btn icon dark @click="reset()">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field label="*Titulo" required v-show="isTest" v-model="formSubject"></v-text-field>
                  <v-text-field label="*Titulo" disabled v-show="isView" v-model="formSubject" class="font-weight-bold"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-textarea required v-show="isTest" label="*Cuerpo" v-model="formBody"></v-textarea>
                  <v-textarea disabled v-show="isView" label="*Cuerpo" v-model="formBody" class="font-weight-bold"></v-textarea>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field required v-show="isTest"  label="*Recipiente" v-model="formRecipient"></v-text-field>
                  <v-text-field disabled v-show="isView"  label="*Recipiente" v-model="formRecipient" class="font-weight-bold"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" v-if="isView">
                  <v-text-field disabled label="Estado" v-model="formStatus" class="font-weight-bold"></v-text-field>
                </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn color="red" @click="reset()" text v-show="isTest"> Cerrar </v-btn>
            <v-btn color="primary" @click="send()" text v-show="isTest"> Enviar</v-btn>
            <v-btn color="primary" @click="reset()" text v-show="isView"> Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapActions, mapState, mapMutations } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import { send } from '../../config/api/mails';

export default {
  name: 'mails-crud',
  data: () => ({
    modalColor: false,
    itemsCombobox: ['Habilitada', 'Inhabilitada'],
  }),
  computed: {
    ...mapState('userProfile', ['profile']),
    ...mapState('mails', ['show', 'item', 'title', 'subject', 'body', 'recipient', 'status', 'isTest', 'isView']),
    profile() {
      return Profile.get();
    },
    formSubject: {
      set(value) {
        this.$store.dispatch('mails/toAssignSubject', value);
      },
      get() {
        return this.subject;
      },
    },
    formBody: {
      set(value) {
        this.$store.dispatch('mails/toAssignBody', value);
      },
      get() {
        return this.body;
      },
    },
    formRecipient: {
      set(value) {
        this.$store.dispatch('mails/toAssignRecipient', value);
      },
      get() {
        return this.recipient;
      },
    },
    formStatus: {
      set(value) {
        this.$store.dispatch('mails/toAssignStatus', value);
      },
      get() {
        return this.status;
      },
    },
  },
  methods: {
    ...mapMutations('message', ['MESSAGE', 'TYPE', 'LOADING']),
    ...mapActions('message', ['run']),
    ...mapMutations('mails', ['SHOW']),
    ...mapActions('mails', ['reset']),
    data() {
      return {
        recipient: this.formRecipient,
        module: 'TEST',
        subject: this.formSubject,
        body: this.formBody,
      };
    },
    async send() {
      if (this.validate()) {
        this.response(await send(this.data()));
        this.reset();
      }
    },
    response(api) {
      this.$store.commit('message/LOADING', true);
      try {
        this.alert(api.data.message);
        this.$store.dispatch('mails/all', { });
      } catch (error) {
        // eslint-disable-next-line no-alert
        this.alert(error);
      }
      this.$store.commit('message/LOADING', false);
    },
    validate() {
      if (/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.formRecipient) === false) {
        this.alert('El campo recipiente no concuerda con formato de correo', 'warning');
        return false;
      }

      return true;
    },
    alert(message, type = 'info') {
      this.$store.commit('message/MESSAGE', message);
      this.$store.commit('message/TYPE', type);
      this.$store.commit('message/RELOAD', false);
      this.$store.dispatch('message/run');
    },
  },
};
</script>
