var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('Toolbar'),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-email-send-outline ")]),_vm._v(" Cola de correos "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.Session.access('43002')),expression:"Session.access('43002')"}],staticClass:"mx-4 mb-4",attrs:{"title":"Agregar Organización","tile":"","color":"grey lighten-2"},on:{"click":function($event){return _vm.test()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.Session.access('43002')),expression:"Session.access('43002')"}],staticClass:"mx-4 mb-4",attrs:{"title":"Agregar Organización","tile":"","color":"grey lighten-2"},on:{"click":function($event){return _vm.refresh()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-refresh ")])],1),_c('v-card',{staticClass:"mx-auto",attrs:{"width":"98%"}},[_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.Session.access('40003')),expression:"Session.access('40003')"}],staticClass:"elevation-24",attrs:{"headers":_vm.headers,"items":_vm.item,"search":_vm.search,"loading":_vm.item.length === 0,"sort-by":"id","no-data-text":"no hay datos","header-props":{
              sortByText: 'Ordenar',
            },"footer-props":{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-text': 'organizaciones por página',
            },"multiSort":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.status),"small":"","text-color":"white"}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.acciones",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.Session.access('40003')),expression:"Session.access('40003')"}],attrs:{"icon":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"title":"Ver"},on:{"click":function($event){return _vm.view(item)}}},[_vm._v(" mdi-eye-outline ")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.Session.access('40003')),expression:"Session.access('40003')"}],attrs:{"icon":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"title":"Enviar"},on:{"click":function($event){return _vm.send(item)}}},[_vm._v(" mdi-send ")])],1)]}}],null,true)})],1),_c('Form')],1)],1)],1),_c('SetLogout')],1)}
var staticRenderFns = []

export { render, staticRenderFns }