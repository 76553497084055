<template>
  <v-row justify="center">
    <v-dialog v-model="show1" persistent max-width="95%">
      <v-card>

        <v-toolbar :color="profile.organization.color" dark height="50px">
          <v-icon class="mr-2">
            mdi-application
          </v-icon>
            <h1 class="title mt-2 white--text">
              {{title}} Anticipo - {{formClient}}
            </h1>
          <v-spacer></v-spacer>
            <v-btn icon dark @click="close()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
        </v-toolbar>

        <div class="text-right" style="margin:4px 4rem -4px 0">
          <v-chip
            class="ma-2"
            color="pink"
            label
            outlined
            @click="openModalDelete()">
            <v-icon left>
              mdi-delete-forever
            </v-icon>
            Borrar
          </v-chip>

          <v-chip
            class="ma-2"
            color="primary"
            outlined
            @click="editItem()"
            label>
            <v-icon left>
              mdi-pencil
            </v-icon>
            Editar
          </v-chip>

          <v-chip
            class="ma-2"
            color="cyan"
            label
            @click="openPayment()"
            text-color="white">
            <v-icon left>
              mdi-open-in-new
            </v-icon>
            Nuevo pago
          </v-chip>
        </div>

        <v-card-text>
          <v-container>
            <v-container class="mt-0 pt-0" fluid>
              <v-card>
                <v-toolbar color="blue-grey lighten-2" class="elevation-2" height="30">
                  <h1 class="subtitle-1 mt-2 white--text">
                    Pagos
                  </h1>
                </v-toolbar>
                <v-card-text>
                <v-data-table
                  v-model="itemSelected"
                  :headers="headers"
                  :items="payments"
                  :items-per-page="10"
                  :single-select="true"
                  item-key="ID"
                  show-select
                  class="elevation-0"
                  hide-default-footer
                  no-data-text='Añade un pago a la lista'
                  :header-props="{
                    sortByText: 'Ordenar',
                    class: 'title',
                  }"
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus',
                    'items-per-page-text': 'Pagos por Página',
                  }">

                  <template v-slot:[`item.type`]="{ item }">
                    <span >{{typePays[item.type]}}</span>
                  </template>

                </v-data-table>
              </v-card-text>
              </v-card>
            </v-container>
            <v-card width="50%" style="margin-left:49%;padding-bottom:4px;">
              <v-layout row wrap>
                <v-flex xs5 class="mt-2 font-weight-black grey--text text--darken-2" d-flex justify-end>
                    MONTO TOTAL:
                </v-flex>
                <v-flex xs5 class="mt-2 font-weight-black grey--text text--darken-2" d-flex justify-end>
                    {{formPago}}
                </v-flex>
              </v-layout>
            </v-card>
          </v-container>
        </v-card-text>

        <v-card-actions>

        <v-spacer></v-spacer>
            <v-btn color="red" outlined @click="close()"> {{captionBack}}</v-btn>
            <v-btn color="primary" outlined v-if="payments.length > 0" @click="next()"> {{captionNext}}</v-btn>
        </v-card-actions>
        <Payment/>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalSave" open-delay="" max-width="290" transition="dialog-top-transition">
      <v-card>
        <v-toolbar :color="profile.organization.color" dark height="50px">
          <v-icon class="mr-2">
            mdi-application
          </v-icon>
            <h1 class="title mt-2 white--text">
              Guardar Anticipo
            </h1>
          <v-spacer></v-spacer>
            <v-btn icon dark @click="modalDelete = false">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text class="pt-4 title">
          Está por guardar éste registro
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" outlined @click="modalSave = false">
            Cancelar
          </v-btn>
          <v-btn :loading="Send" color="green darken-1" outlined @click="saveRegister()">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalDelete" open-delay="" max-width="290" transition="dialog-top-transition">
      <v-card>
        <v-toolbar :color="profile.organization.color" dark height="50px">
          <v-icon class="mr-2">
            mdi-application
          </v-icon>
            <h1 class="title mt-2 white--text">
              Borrado de registro
            </h1>
          <v-spacer></v-spacer>
            <v-btn icon dark @click="modalDelete = false">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text class="pt-4 title">
          ¿Está seguro que desea borrar el registro seleccionado?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" outlined @click="modalDelete = false">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" outlined @click="deleteItem()">
            Sí, borrar!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapActions, mapState, mapMutations } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import Payment from './TypePayForm';
import { add } from '../../config/api/cobranza';
import { versionCurrent } from '../../middleware/version';

export default {
  name: 'anticipo-crud',
  components: {
    Payment,
  },
  data: () => ({
    // INDICADOR DE ENVIO
    Send: false,
    // container pago seleccionado
    itemSelected: [],
    // triger Modal Delete pago
    modalDelete: false,
    modalSave: false,
    // Captions's de botones de formulario principal
    captionBack: 'Cerrar',
    captionNext: 'Guardar',
    // cabecera de pagos
    typePays: {
      transferencia: 'Transferencia Bancaria',
      pagomovil: 'Pagomovil',
      deposito: 'Deposito Bancario',
      efectivo: 'Efectivo',
      cuentas: 'Cuentas Internacionales',
      retencion: 'Retención',
    },
    headers: [
      { text: 'Pos.', value: 'ID', align: 'center' },
      { text: 'Tipo de Pago', value: 'type', align: 'center' },
      { text: 'Monto', value: 'value', align: 'center' },
      { text: 'Banco', value: 'bankTo', align: 'center' },
    ],
    // cabecera de distribución
    headers2: [
      { text: 'Factura', value: 'fact_sap', align: 'center' },
      {
        text: 'Importe',
        value: 'mount',
        align: 'center',
        width: '150',
      },
      {
        text: 'Monto',
        value: 'load',
        align: 'center',
        width: '150',
      },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
  }),
  computed: {
    ...mapState('userProfile', ['profile']),
    ...mapState('cobranza', ['title', 'show1', 'item', 'payments', 'client', 'clients']),
    formClient: {
      set(value) {
        this.$store.dispatch('cobranza/toAssignClient', value);
      },
      get() {
        return this.client;
      },
    },
    formPago: {
      get() {
        return (this.payments.length > 0) ? this.payments.reduce((acc, item) => acc + parseFloat(item.value), 0).toFixed(2) : 0;
      },
    },
    profile() {
      return Profile.get();
    },
  },
  methods: {
    ...mapMutations('message', ['MESSAGE', 'TYPE', 'LOADING']),
    ...mapActions('message', ['run']),
    ...mapMutations('cobranza', ['SHOW1', 'SHOWPAY']),
    ...mapActions('cobranza', ['closeAnticipo', 'addPayment']),
    dataPut() {
      let clave = '';
      let datacme = true;
      let esporadico = false;
      const DataClient = this.clients.filter((item) => item.name === this.client);
      if (this.client === 'Facturador 01' || this.client === 'Ventas Empleados' || this.client === 'FACT_01' || this.client === 'FACT_02' || this.client === 'FACT_03' || this.client === 'FACT_04' || this.client === 'VENTASEMP') {
        clave = 17;
        datacme = false;
        esporadico = true;
      } else {
        clave = 19;
        datacme = true;
        esporadico = false;
      }
      const DistItems = [{
        document: 'Anticipo',
        value: parseFloat(this.formPago).toFixed(2),
        year_fiscal: '',
        key: clave,
        text: '',
        reference: '',
        pos_ref: 0,
        discount: true, // posicion con descuento?
      }];

      return {
        org: this.profile.org,
        society: this.profile.organization.society,
        user_id: this.profile.user.id,
        origin: 'webapp',
        version: versionCurrent(),
        client: {
          id: DataClient[0].account_id,
          name: DataClient[0].name,
          dni: DataClient[0].customer_dni,
          rif: DataClient[0].account_no,
          sp: DataClient[0].is_esporadico,
        },
        documents: DistItems,
        payments: this.payments,
        cme: datacme,
        otherExpenses: true,
        cliente: esporadico,
      };
    },
    next() {
      if (!this.modalSave && this.payments.length > 0) {
        if (parseFloat(this.formPago).toFixed(2) > 0) {
          this.modalSave = !this.modalSave;
        } else {
          this.alert('La suma de los pagos no puede ser cero', 'warning');
        }
      }
    },
    async saveRegister() {
      if (this.modalSave && this.payments.length > 0) {
        this.Send = true;
        this.response(await add(this.dataPut()));
      }
    },
    close() {
      if (this.payments.length > 0) {
        this.alert('Antes de salir de éste formulario debe borrar los registros realizados', 'warning');
      } else if (this.payments.length === 0) {
        this.closeAnticipo();
      }
    },
    openPayment() {
      this.$store.dispatch('cobranza/setItemPay', []);
      this.addPayment();
    },
    editItem() {
      if (this.itemSelected.length > 0) {
        this.$store.dispatch('cobranza/setItemPay', this.itemSelected);
        this.addPayment();
      } else {
        this.alert('Debe seleccionar un registro');
      }
    },
    openModalDelete() {
      if (this.itemSelected.length > 0) {
        this.modalDelete = true;
      } else {
        this.alert('Debe seleccionar un registro');
      }
    },
    deleteItem() {
      if (this.itemSelected.length > 0) {
        for (let i = 0; i < this.payments.length; i += 1) {
          if (this.payments[i].ID === this.itemSelected[0].ID) {
            this.payments.splice(i, 1);
          }
        }
        this.itemSelected.splice(0, this.itemSelected.length);
      }
      this.modalDelete = false;
    },
    response(api) {
      this.$store.commit('message/LOADING', true);
      try {
        this.Send = false;
        this.alert(api.data.message, 'success', true);
      } catch (error) {
        // eslint-disable-next-line no-alert
        this.alert(error, 'error');
      }
      this.$store.commit('message/LOADING', false);
    },
    alert(message, type = 'info', reload = false) {
      this.$store.commit('message/MESSAGE', message);
      this.$store.commit('message/TYPE', type);
      this.$store.commit('message/RELOAD', reload);
      this.$store.dispatch('message/run');
    },
  },
};
</script>
<style>
/* width */
::-webkit-scrollbar {
    width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0f0f0;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(190, 190, 190);
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9e9e9e;
}
</style>
