<template>
  <v-container class="mt-0 pt-0" fluid>
    <v-card>
      <v-toolbar color="grey" height="40">
        <v-spacer></v-spacer>
        <input v-show="estado" title="solo aplica para cotizaciones" type="checkbox" id="ivachec" v-model="ivachec">
        <label v-show="estado" title="solo aplica para cotizaciones" for="ivachec">{{ 'IVA' }}</label>
        <v-btn icon dark @click="addItem()" v-if="isAdd || isUpdate" title="add carrito">
          <v-icon>mdi-cart-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :headers="headersShow"
          v-if="isShow"
          :items="cart"
          :items-per-page="99"
          class="elevation-1"
          hide-default-footer
          no-data-text='Añade un producto al carrito'
          :header-props="{
            sortByText: 'Ordenar',
            class: 'title',
          }"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            'items-per-page-text': 'Productos por Página',
          }">
          <template v-slot:[`item.imagenurl`]="{ item }">
              <v-row justify="center">
                <v-img
                  :src="item.imagenurl"
                  height="auto"
                  max-width="80px"
                >
                </v-img>
              </v-row>
          </template>
        </v-data-table>
        <v-data-table
          :headers="headers"
          v-else-if="isAdd ||isUpdate"
          readonly
          :items="cart"
          :items-per-page="99"
          class="elevation-1"
          hide-default-footer
          no-data-text='Añade un producto al carrito'
          :header-props="{
            sortByText: 'Ordenar',
            class: 'title',
          }"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            'items-per-page-text': 'Productos por Página',
          }">
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon class="px-3" title="Remover Producto" @click="removeItem(item)">
              mdi-delete
            </v-icon>
          </template>
           <template v-slot:[`item.productname`]="{ item }">
            <v-text-field v-if="item.tipo === 'GENE'"
              color="grey darken-4"
              v-model="item.productname"
              reverse>
            </v-text-field>
            <v-text-field  v-else
              v-model="item.productname"
              reverse
              readonly
              >
            </v-text-field>
          </template>
            <template v-slot:[`item.quantity`]="{ item }">
            <v-text-field
              color="grey darken-4"
              v-model="item.quantity"
              @change="calculate()"
              @click="calculate()"
              min="1"
              max="1000"
              type="number"
              :rules="numberRule"
              reverse>
            </v-text-field>
          </template>
          <template v-slot:[`item.listprice`]="{ item }">
            <v-text-field v-if="item.tipo === 'GENE'"
              color="grey darken-4"
              v-model="item.listprice"
              @change="calculate()"
              @click="calculate()"
              min="1"
              :rules="numberRule"
              reverse>
            </v-text-field>
            <v-text-field v-else
              color="grey darken-4"
              v-model="item.listprice"
              @change="calculate()"
              @click="calculate()"
              min="1"
              type="number"
              :rules="numberRule"
              reverse
              readonly
              >
            </v-text-field>
          </template>
          <template v-slot:[`item.imagenurl`]="{ item }">
              <v-row justify="center">
                <v-img
                  :src="item.imagenurl"
                  height="auto"
                  max-width="80px"
                  title="Imagen Car"
                >
                </v-img>
              </v-row>
          </template>
          <!--<template v-slot:[`item.listprice`]="{ item }">
              <v-text-field
              color="grey darken-4"
              v-model="item.listprice"
              :items="item.listprice"
              @change="update()"
              @click="update()"
              readonly>
              type="number"
              >
            </v-text-field>
          </template>-->
        </v-data-table>
        <v-card>
          <v-card-text>
            <v-container>
              <v-layout row wrap>
                <v-flex
                  xs7
                  class="mt-2 font-weight-black"
                  d-flex justify-end>
                    SUB TOTAL:
                </v-flex>
                <v-flex
                  xs5
                  class="mt-2"
                  d-flex justify-end>
                    {{form.subtotal}}
                </v-flex>
                <v-flex v-if="fle(profile.organization.id)"
                  xs7
                  class="mt-2 font-weight-black"
                  d-flex justify-end>
                    FLETE:
                </v-flex>
                <v-flex v-if="fle(profile.organization.id)"
                  xs5
                  class="mt-2"
                  d-flex justify-end>
                    {{form.freight}}
                </v-flex>
                <v-flex v-if="profile.organization.id == 6"
                  xs7
                  class="mt-2 font-weight-black"
                  d-flex justify-end>
                    DESCUENTO:
                </v-flex>
                 <v-flex v-if="profile.organization.id == 6"
                  xs5
                  class="mt-2"
                  d-flex justify-end>
                    {{form.discount}}
                </v-flex>
                <v-flex v-if="profile.organization.id != 5"
                  xs7
                  class="mt-2 font-weight-black"
                  d-flex justify-end>
                    IVA:
                </v-flex>
                <v-flex v-if="profile.organization.id != 5"
                  xs5
                  class="mt-2"
                  d-flex justify-end>
                    {{0}}
                </v-flex>
                <v-flex v-if="profile.organization.id == 5"
                  xs7
                  class="mt-2 font-weight-black"
                  d-flex justify-end>
                    IGV:
                </v-flex>
                <v-flex v-if="profile.organization.id == 5"
                  xs5
                  class="mt-2"
                  d-flex justify-end>
                    {{0}}
                </v-flex>
                <v-flex
                  xs7
                  class="mt-2 font-weight-black"
                  d-flex justify-end>
                    TOTAL:
                </v-flex>
                <v-flex
                  xs5
                  class="mt-2"
                  d-flex justify-end>
                    {{form.total}}
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
    <!-- Seccion para seleccionar los material del pedido ↓↓ -->
    <v-dialog v-model="dialog" max-width="75%" persistent>
      <v-card>
      <v-toolbar :color="profile.organization.color">
        <h1 class="title mt-2 white--text">
          CATALOGO DE PRODUCTOS
        </h1>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialog = !dialog">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-text-field
            class="mt-5 pt-5"
            v-model="searchDialog"
            append-icon="mdi-magnify"
            label="Buscar"
            color="grey darken-4"
          ></v-text-field>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="tablaCar()"
            :items="itemsDialog"
            :items-per-page="5"
            class="elevation-12 mt-0 pt-0"
            :search="searchDialog"
            no-data-text='Seleccione un producto al carrito'
            :header-props="{
              sortByText: 'Ordenar',
            }"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-text': 'Productos por Página',
            }">
            <template v-slot:[`item.select`]="{ item }">
              <v-icon
                title="Seleccionar"
                @click="selectItem(item)"
                x-large>
                mdi-check-circle-outline
              </v-icon>
            </template>
            <template v-slot:[`item.imagenurl`]="{ item }">
              <v-row justify="center">
                <v-img
                  :src="item.imagenurl"
                  height="auto"
                  max-width="100px"
                  title="Imagen"
                >
                </v-img>
              </v-row>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapState, mapMutations, mapActions } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
import { salesorderItemCar } from '../../config/api/products';

export default {
  name: 'salesorder-list',
  data: () => ({
    dialog: false,
    headers: [
      { text: 'Producto', value: 'imagenurl', align: 'center' },
      { text: 'Código', value: 'productcode', align: 'center' },
      { text: 'Descripción', value: 'productname', align: 'center' },
      {
        text: 'Cantidad', value: 'quantity', width: '10%', align: 'center',
      },
      { text: 'Centro', value: 'center', align: 'center' },
      { text: 'Almacen', value: 'almacen', align: 'center' },
      {
        text: 'Precio', value: 'listprice', width: '7%', align: 'center',
      },
      // { text: 'Total', value: 'total', align: 'center' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    headersShow: [
      { text: 'Producto', value: 'imagenurl', align: 'center' },
      { text: 'Código', value: 'productcode', align: 'center' },
      { text: 'Descripción', value: 'productname', align: 'center' },
      {
        text: 'Cantidad', value: 'quantity', align: 'center',
      },
      { text: 'Centro', value: 'center', align: 'center' },
      { text: 'Almacen', value: 'almacen', align: 'center' },
      {
        text: 'Precio', value: 'listprice', width: '7%', align: 'center',
      },
    ],
    search: '',
    headersDialog: [
      {
        text: 'Seleccionar', align: 'center', sortable: false, value: 'select',
      },
      { text: 'Producto', value: 'imagenurl', align: 'center' },
      { text: 'Descripción', value: 'productname', align: 'center' },
      { text: 'Centro', value: 'center', align: 'center' },
      { text: 'Almacen', value: 'almacen', align: 'center' },
      { text: 'Precio', value: 'listprice', align: 'center' },
      { text: 'Existencia', value: 'existencia', align: 'center' },
      { text: 'Código', value: 'productcode', align: 'center' },
    ],
    headersDialogPeru: [
      {
        text: 'Seleccionar', align: 'center', sortable: false, value: 'select',
      },
      { text: 'Producto', value: 'imagenurl', align: 'center' },
      { text: 'Descripción', value: 'productname', align: 'center' },
      { text: 'Centro', value: 'center', align: 'center' },
      { text: 'Almacen', value: 'almacen', align: 'center' },
      { text: 'Precio', value: 'listprice', align: 'center' },
      { text: 'Existencia', value: 'existencia', align: 'center' },
      { text: 'Código', value: 'productcode', align: 'center' },
      { text: 'Código Ant.', value: 'products_no_ant', align: 'center' },
    ],
    numberRule: [
      (v) => v.length > 0 || 'campo requerido',
      (v) => v > 0 || 'El valor debe ser mayor a cero',
    ],
    itemsDialog: [],
    id: null,
    searchDialog: '',
    request: {
    },
  }),
  computed: {
    ...mapState('userprofile', ['profile']),
    ...mapState('salesorder', ['itemCar', 'isShow', 'isAdd', 'isUpdate']),
    ...mapState('salesorder', ['ivachek', 'type', 'flete', 'descuento', 'items', 'cart', 'form']),
    ...mapState('SalesorderFrom', ['vv']),
    ...mapState('SalesorderForm', ['Dialog1']),
    estado: {
      get() {
        return this.form.sostatus !== 'Cotizacion' ? 0 : 1;
      },
    },
    salesordertype: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignVIA', value);
      },
      get() {
        console.log(this.type);
        return this.type;
      },
    },
    rebaja: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignDescuento', value);
      },
      get() {
        return this.descuento;
      },
    },
    percent: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignFlete', value);
      },
      get() {
        return this.flete;
      },
    },
    ivachec: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignIVACHE', value);
      },
      get() {
        return this.ivachek;
      },
    },
    lista: {
      set(value) {
        this.$store.dispatch('SalesorderFrom/update', value);
      },
      get() {
        console.log(this.vv);
        return this.vv;
      },
    },
    profile() {
      return Profile.get();
    },
  },
  methods: {
    ...mapMutations('message', ['MESSAGE', 'TYPE', 'LOADING']),
    ...mapActions('message', ['run']),
    ...mapMutations('salesorder', ['SET_CART']),
    ...mapActions('salesorder', ['add']),

    async addItem() {
      this.dialog = true;
      console.log(this.type);
      console.log(this.salesordertype);
      this.request.VP = this.salesordertype;
      this.request.company_id = this.profile.organization.id;
      this.request.user_id = this.profile.user.id;
      this.itemsDialog = (await salesorderItemCar(this.request, this.type)).data;
    },
    removeItem(item) {
      const shoppingCart = this.cart;
      shoppingCart.splice(shoppingCart.indexOf(item), 1);
      this.$store.commit('salesorder/SET_CART', shoppingCart);
      this.calculate();
    },
    selectItem(item) {
      this.dialog = false;
      const exists = this.exists(item);
      /* console.log(exists); */
      switch (exists) {
        case -1:
          this.cart.push(
            {
              productcode: item.productcode,
              productname: item.productname,
              quantity: 1,
              center: item.center,
              listprice: item.listprice,
              weight: item.weight,
              total: this.total,
              imagenurl: item.imagenurl,
              almacen: item.almacen,
              tipo: item.tipo,
              // coin: this.porcheck === false ? 'USD' : 'VES',
            },
          );
          this.calculate();
          this.cart.sort((a, b) => a.productcode - b.productcode);
          break;
        default:
          this.$store.commit('message/MESSAGE', 'El producto ya se encuentra en el carrito');
          this.$store.commit('message/TYPE', 'info');
          this.$store.dispatch('message/run');
      }
    },
    exists(item) {
      return this.cart.findIndex(
        (e) => e.productcode === item.productcode && e.center === item.center,
      );
    },
    calculate() {
      let freight = 0;
      const subtotal = this.cart.reduce((a, e) => a + (e.listprice * e.quantity), 0);
      this.$store.commit('salesorder/SET_SUBTOTAL', subtotal.toFixed(2));
      if (parseInt(this.profile.organization.id, 0) === parseInt('5', 10) || parseInt(this.profile.organization.id, 0) === parseInt('6', 10)) {
        const flete = 0;
        freight = (flete * subtotal);
        this.$store.commit('salesorder/SET_FREIGHT', freight.toFixed(2));
      } else {
        freight = (this.percent * subtotal);
        this.$store.commit('salesorder/SET_FREIGHT', freight.toFixed(2));
      }
      console.log(freight);
      const iva = subtotal * 0.16;
      this.$store.commit('salesorder/SET_IVA', iva.toFixed(2));

      let discount = 0;
      // eslint-disable-next-line eqeqeq
      if (this.profile.organization.id == '6') {
        console.log('entro 6');
        discount = (this.rebaja * subtotal);
        this.$store.commit('salesorder/SET_DISCOUNT', discount.toFixed(2));
        console.log(discount);
      } else {
        const desc = 0;
        discount = (desc * subtotal);
        this.$store.commit('salesorder/SET_DISCOUNT', discount.toFixed(2));
      }

      const total = subtotal + freight - discount;
      this.$store.commit('salesorder/SET_TOTAL', total.toFixed(2));
    },
    fle(id) {
      switch (id) {
        case '1':
          return true;
        case '2':
          return true;
        case 1:
          return true;
        case 2:
          return true;
        case '3':
          return false;
        case '4':
          return false;
        case '5':
          return false;
        case 5:
          return false;
        case 6:
          return false;
        case '6':
          return false;
        default:
          return true;
      }
    },
    tablaCar() {
      return this.profile.organization.name === 'CNR Peru' ? this.headersDialogPeru : this.headersDialog;
    },
  },
  /* async created() {
    this.itemsDialog = (await salesorderItemCar(this.request)).data;
  }, */
};
</script>
