import { ajustes, diasvisible, ajusteDias } from '../../config/api/ajustes';

export default {

  name: 'ajustes',
  namespaced: true,
  state: {
    // ***Ajustes***
    item: [],
    formData: [],
    id: '',
    almacen: [],
    almacenEjecutivoFree: null,
    dias: [],
    diasvisible: null,
    // dias: [15, 30, 45, 60, 120, 180, 360],
    vence: [],
    diasvence: null,
    iva: '',
    show: false,
  },
  mutations: {
    SHOW(state) {
      console.log(state.show);
      state.show = !state.show;
    },
    SET_DATA(state, parameter) {
      console.log(parameter.data);
      state.item = parameter.data;
    },
    SET_FORM_DATA(state, parameter) {
      state.formData = parameter;
    },
    SET_ID(state, parameter) {
      console.log(parameter.data);
      state.id = parameter.data;
    },
    SET_DIAS(state, parameter) {
      console.log(parameter);
      state.dias = parameter;
    },
    SET_VENCE(state, parameter) {
      console.log(parameter);
      state.vence = parameter;
    },
    SET_ALMACEN(state, parameter) {
      console.log(parameter.data);
      state.almacen = parameter;
    },
    SET_DATA_DIAS(state, parameter) {
      console.log(parameter);
      state.diasvisible = parameter.data;
    },
    SET_DATA_VENCE(state, parameter) {
      console.log(parameter);
      state.diasvence = parameter.data;
    },
    SET_DATA_ALMACEN(state, parameter) {
      console.log(parameter.data);
      state.almacenEjecutivoFree = parameter.data;
    },
  },
  actions: {
    async data({ commit }, parameter) {
      commit('SET_DATA', await ajustes(parameter));
      // commit('SET_PRODUCTID', await getProducto(parameter));
    },
    show({ commit }) {
      commit('SHOW');
    },
    async add({ commit }, parameter) {
      commit('SET_DATA', await ajusteDias(parameter));
      console.log(parameter);
      commit('SHOW', false);
    },
    cerrar({ commit }) {
      commit('SHOW', false);
      // commit('SET_IS_SHOW', false);
    },
    toAssignDias({ commit }, parameter) {
      commit('SET_DIAS', parameter);
    },
    toAssignVence({ commit }, parameter) {
      commit('SET_VENCE', parameter);
    },
    toAssignAlmacen({ commit }, parameter) {
      commit('SET_ALMACEN', parameter);
    },
    async initializingForm({ commit }, parameter) {
      commit('SET_DATA_DIAS', await diasvisible(parameter));
    },
  },
};
