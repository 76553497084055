<template>
  <v-app>
    <Toolbar/>
    <v-main>
      <v-container fluid>
        <v-card>
          <v-card-title>
            <v-icon class="mr-2"> mdi-application </v-icon>
            {{profile.organization.name}} - Productos
            <v-btn icon v-if="profile.organization.id == 1" v-show="validar(profile.permissions,'2007')">
                  <v-icon
                    class='px-auto'
                    title='Catalogo con Lista Institucional Estandar'
                    color='red'
                    @click="download()"
                  >
                    mdi-file-pdf-box
                  </v-icon>
                </v-btn>
                <v-btn icon v-if="profile.organization.id == 1" v-show="validar(profile.permissions,'2007')">
                  <v-icon
                    class='px-auto'
                    title='Catalogo con Lista Negociación Estandar'
                    color='orange'
                    @click="download2()"
                  >
                    mdi-file-pdf-box
                  </v-icon>
                </v-btn>
                <v-btn icon v-if="profile.organization.id == 1" v-show="validar(profile.permissions,'2007')">
                  <v-icon
                    class='px-auto'
                    title='Catalogo con Lista Gran Mayor 2'
                    color='blue'
                    @click="download7()"
                  >
                    mdi-file-pdf-box
                  </v-icon>
                </v-btn>
                <v-btn icon v-if="profile.organization.id == 2" v-show="validar(profile.permissions,'2007')">
                  <v-icon
                    class='px-auto'
                    title='Catalogo con Lista Gran Mayor'
                    color='blue'
                    @click="download3()"
                  >
                    mdi-file-pdf-box
                  </v-icon>
                </v-btn>
                <v-btn icon v-if="profile.organization.id == 2" v-show="validar(profile.permissions,'2007')">
                  <v-icon
                    class='px-auto'
                    title='Catalogo con Lista Detal'
                    color='green'
                    @click="download4()"
                  >
                    mdi-file-pdf-box
                  </v-icon>
                </v-btn>
                <v-btn icon v-if="profile.organization.id == 2" v-show="validar(profile.permissions,'2007')">
                  <v-icon
                    class='px-auto'
                    title='Catalogo con Lista OnLine'
                    color='orange'
                    @click="download5()"
                  >
                    mdi-file-pdf-box
                  </v-icon>
                </v-btn>
                <v-btn icon v-if="profile.organization.id == 2" v-show="validar(profile.permissions,'2007')">
                  <v-icon
                    class='px-auto'
                    title='Catalogo con Lista Maracaibo'
                    color='red'
                    @click="download6()"
                  >
                    mdi-file-pdf-box
                  </v-icon>
                </v-btn>
                <!--<template>
                  <div class="text-center">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="red"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          Catalogo PDF
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(item, index) in items1"
                          :key="index"
                        >
                          <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </template>-->
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-card class="mx-auto" width="98%">
            <v-data-table
              :headers="tabla()"
              :items="items"
              :search="search"
              loading="true"
              loading-text="Cargando Datos"
              sort-by="productname"
              class="elevation-24"
              :header-props="{
                sortByText: 'Ordenar',
              }"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',
                'items-per-page-text': 'productos por página',
              }"
              multiSort
            >
              <template v-slot:[`item.imagenurl`]="{ item }">
                <v-img
                  v-show="!isMobile"
                  :src="item.imagenurl"
                  height="auto"
                  max-width="130px"
                >
                </v-img>
                <!--<v-avatar>
                  <v-img v-show="isMobile" :src="item.imagenurl"> </v-img>
                </v-avatar>-->
              </template>
              <template v-slot:[`item.acciones`]="{ item }">
              <v-btn icon v-show="validar(profile.permissions,'2005')">
                <v-icon
                  class='px-auto'
                  title="Cargar Imagen"
                  @click='imgshow(item)'>
                  mdi-image
               </v-icon>
               </v-btn>
              <v-btn icon v-show="validar(profile.permissions,'2001')">
                <v-icon
                  class='px-auto'
                  title='Visualizar Ficha del Producto'
                  @click='show(item)'
                  color='#0091EA'
                >
                  mdi-eye
                </v-icon>
              </v-btn>
            </template>
            </v-data-table>
          </v-card>
          <Form/>
          <Imgform/>
        </v-card>
      </v-container>
    </v-main>
    <SetLogout/>
    <Historicobcv/>
  </v-app>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapActions, mapState } from 'vuex';
// eslint-disable-next-line import/extensions
// import jspdf from 'jspdf';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import { products } from '../../config/api/products';
// eslint-disable-next-line import/extensions
import Form from './Verproductos';
// eslint-disable-next-line import/extensions
import Imgform from './Imgproduct';
// eslint-disable-next-line import/extensions
import Toolbar from '../../components/Toolbar';
// eslint-disable-next-line import/extensions
import SetLogout from '../../components/SetLogout';
// eslint-disable-next-line import/extensions
// import Logos from '../Salesorder/logos';
// eslint-disable-next-line import/extensions
import Historicobcv from '../../components/Historicobcv';

export default {
  name: 'products',
  components: {
    Toolbar, Form, Imgform, SetLogout, Historicobcv,
  },
  data: () => ({
    search: '',
    dialog: false,
    imagen: null,
    items1: [
      { title: 'Catalogo con Lista Institucional Estandar' },
      { title: 'Catalogo con Lista Negociacion Estandar' },
    ],
    headers: [
      {
        text: 'Producto',
        align: 'start',
        sortable: false,
        value: 'imagenurl',
      },
      { text: 'Descripcion', value: 'productname', align: 'center' },
      { text: 'Codigo', value: 'productcode', align: 'center' },
      { text: 'Marca', value: 'marca', align: 'center' },
      { text: 'Familia', value: 'productfamily', align: 'center' },
      { text: 'Centro', value: 'centro', align: 'center' },
      { text: 'Existencia', value: 'existencia', align: 'center' },
      { text: 'Consignación', value: 'consignacion', align: 'center' },
      { text: 'Acciones', value: 'acciones', sortable: false },
    ],
    headersPeru: [
      {
        text: 'Producto',
        align: 'start',
        sortable: false,
        value: 'imagenurl',
      },
      { text: 'Descripcion', value: 'productname', align: 'center' },
      { text: 'Codigo', value: 'productcode', align: 'center' },
      { text: 'Codigo Ant', value: 'products_no_ant', align: 'center' },
      { text: 'Marca', value: 'marca', align: 'center' },
      { text: 'Familia', value: 'productfamily', align: 'center' },
      { text: 'Centro', value: 'centro', align: 'center' },
      { text: 'Existencia', value: 'existencia', align: 'center' },
      { text: 'Consignación', value: 'consignacion', align: 'center' },
      { text: 'Acciones', value: 'acciones', sortable: false },
    ],
    headersHieCap: [
      {
        text: 'Producto',
        align: 'start',
        sortable: false,
        value: 'imagenurl',
      },
      { text: 'Descripcion', value: 'productname', align: 'center' },
      { text: 'Codigo', value: 'productcode', align: 'center' },
      { text: 'Marca', value: 'marca', align: 'center' },
      { text: 'Familia', value: 'productfamily', align: 'center' },
      { text: 'Centro', value: 'centro', align: 'center' },
      { text: 'Stock', value: 'stok', align: 'center' },
      { text: 'Tipo Material', value: 'tipo_material', align: 'center' },
      { text: 'Acciones', value: 'acciones', sortable: false },
    ],
    items: [],
    dataProductos: [],
  }),
  computed: {
    ...mapState('products', ['item', 'imagenurl']),
    profile() {
      return Profile.get();
    },
    isMobile() {
      if (window.innerWidth < 500) return true;
      return false;
    },
    formProductId: {
      set(value) {
        this.$store.dispatch('products/toAssignProductId', value);
      },
      get() {
        return this.productId;
      },
    },
    formImagen: {
      set(value) {
        this.$store.dispatch('products/toAssignImagen', value);
      },
      get() {
        return this.imagenurl;
      },
    },
  },
  methods: {
    ...mapActions('products', ['show', 'imgshow']),
    image() {
      this.dialog = true;
      return this.productid;
    },
    validar(permiso, accion) {
      let valor = false;
      permiso.forEach((permisos) => {
        if (permisos.accion === accion) {
          valor = true;
        }
      });
      return valor;
    },
    tabla() {
      if (this.profile.organization.name === 'CNR Peru') {
        return this.headersPeru;
      }
      return this.profile.organization.name === 'Hierro Barquisimeto' || this.profile.organization.name === 'Caproa' ? this.headersHieCap : this.headers;
    },
    download() {
      const formData = [
        { name: 'user_id', value: this.profile.user.id },
        { name: 'session_key', value: this.profile.sesionkey },
        { name: 'society_id', value: this.profile.organization.society },
        { name: 'organization_id', value: this.profile.organization.org },
        { name: 'company_id', value: this.profile.organization.id },
        { name: 'route_current', value: window.location.pathname.toUpperCase() },
        { name: 'timewake', value: this.profile.update },
      ];

      const form = document.createElement('form');
      form.setAttribute('method', 'post');
      form.setAttribute('target', '_blank');
      form.setAttribute('action', '//www.sistemacrm.com.ve/backend/public/api/CatalogoProductos');

      formData.forEach((item) => {
        const hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', item.name);
        hiddenField.setAttribute('value', item.value);
        form.appendChild(hiddenField);
      });

      document.body.appendChild(form);
      form.submit();
    },
    download2() {
      const formData = [
        { name: 'user_id', value: this.profile.user.id },
        { name: 'session_key', value: this.profile.sesionkey },
        { name: 'society_id', value: this.profile.organization.society },
        { name: 'organization_id', value: this.profile.organization.org },
        { name: 'company_id', value: this.profile.organization.id },
        { name: 'route_current', value: window.location.pathname.toUpperCase() },
        { name: 'timewake', value: this.profile.update },
      ];

      const form = document.createElement('form');
      form.setAttribute('method', 'post');
      form.setAttribute('target', '_blank');
      form.setAttribute('action', '//www.sistemacrm.com.ve/backend/public/api/CatalogoProductos2');

      formData.forEach((item) => {
        const hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', item.name);
        hiddenField.setAttribute('value', item.value);
        form.appendChild(hiddenField);
      });

      document.body.appendChild(form);
      form.submit();
    },
    download3() {
      const formData = [
        { name: 'user_id', value: this.profile.user.id },
        { name: 'session_key', value: this.profile.sesionkey },
        { name: 'society_id', value: this.profile.organization.society },
        { name: 'organization_id', value: this.profile.organization.org },
        { name: 'company_id', value: this.profile.organization.id },
        { name: 'route_current', value: window.location.pathname.toUpperCase() },
        { name: 'timewake', value: this.profile.update },
      ];

      const form = document.createElement('form');
      form.setAttribute('method', 'post');
      form.setAttribute('target', '_blank');
      form.setAttribute('action', '//www.sistemacrm.com.ve/backend/public/api/CatalogoProductos3');

      formData.forEach((item) => {
        const hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', item.name);
        hiddenField.setAttribute('value', item.value);
        form.appendChild(hiddenField);
      });

      document.body.appendChild(form);
      form.submit();
    },
    download4() {
      const formData = [
        { name: 'user_id', value: this.profile.user.id },
        { name: 'session_key', value: this.profile.sesionkey },
        { name: 'society_id', value: this.profile.organization.society },
        { name: 'organization_id', value: this.profile.organization.org },
        { name: 'company_id', value: this.profile.organization.id },
        { name: 'route_current', value: window.location.pathname.toUpperCase() },
        { name: 'timewake', value: this.profile.update },
      ];

      const form = document.createElement('form');
      form.setAttribute('method', 'post');
      form.setAttribute('target', '_blank');
      form.setAttribute('action', '//www.sistemacrm.com.ve/backend/public/api/CatalogoProductos4');

      formData.forEach((item) => {
        const hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', item.name);
        hiddenField.setAttribute('value', item.value);
        form.appendChild(hiddenField);
      });

      document.body.appendChild(form);
      form.submit();
    },
    download5() {
      const formData = [
        { name: 'user_id', value: this.profile.user.id },
        { name: 'session_key', value: this.profile.sesionkey },
        { name: 'society_id', value: this.profile.organization.society },
        { name: 'organization_id', value: this.profile.organization.org },
        { name: 'company_id', value: this.profile.organization.id },
        { name: 'route_current', value: window.location.pathname.toUpperCase() },
        { name: 'timewake', value: this.profile.update },
      ];

      const form = document.createElement('form');
      form.setAttribute('method', 'post');
      form.setAttribute('target', '_blank');
      form.setAttribute('action', '//www.sistemacrm.com.ve/backend/public/api/CatalogoProductos5');

      formData.forEach((item) => {
        const hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', item.name);
        hiddenField.setAttribute('value', item.value);
        form.appendChild(hiddenField);
      });

      document.body.appendChild(form);
      form.submit();
    },
    download6() {
      const formData = [
        { name: 'user_id', value: this.profile.user.id },
        { name: 'session_key', value: this.profile.sesionkey },
        { name: 'society_id', value: this.profile.organization.society },
        { name: 'organization_id', value: this.profile.organization.org },
        { name: 'company_id', value: this.profile.organization.id },
        { name: 'route_current', value: window.location.pathname.toUpperCase() },
        { name: 'timewake', value: this.profile.update },
      ];

      const form = document.createElement('form');
      form.setAttribute('method', 'post');
      form.setAttribute('target', '_blank');
      form.setAttribute('action', '//www.sistemacrm.com.ve/backend/public/api/CatalogoProductos6');

      formData.forEach((item) => {
        const hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', item.name);
        hiddenField.setAttribute('value', item.value);
        form.appendChild(hiddenField);
      });

      document.body.appendChild(form);
      form.submit();
    },
    download7() {
      const formData = [
        { name: 'user_id', value: this.profile.user.id },
        { name: 'session_key', value: this.profile.sesionkey },
        { name: 'society_id', value: this.profile.organization.society },
        { name: 'organization_id', value: this.profile.organization.org },
        { name: 'company_id', value: this.profile.organization.id },
        { name: 'route_current', value: window.location.pathname.toUpperCase() },
        { name: 'timewake', value: this.profile.update },
      ];

      const form = document.createElement('form');
      form.setAttribute('method', 'post');
      form.setAttribute('target', '_blank');
      form.setAttribute('action', '//www.sistemacrm.com.ve/backend/public/api/CatalogoProductos7');

      formData.forEach((item) => {
        const hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', item.name);
        hiddenField.setAttribute('value', item.value);
        form.appendChild(hiddenField);
      });

      document.body.appendChild(form);
      form.submit();
    },
  },
  async created() {
    const requests = {
      user_id: this.profile.user.id,
      company_id: this.profile.organization.id,
      // rol: this.profile.roluser.roles_id,// se puede usar para filtar los materiales por sucursal, no se esta implemetando
      // method: 'products',
    };
    const data = await products(requests);
    this.items = data.data;
    this.loading = false;
  },
};
</script>
