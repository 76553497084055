<template>
  <v-app>
    <Toolbar/>
    <v-main>
      <v-container fluid>
        <v-card>
          <v-container class="text-center pt-5 mt-5">
            <div class="content">
              <h1>Sistema CRM</h1>
              <h4>Bienvenido</h4>
            </div>
              <img src="images/log.svg" class="image" height="350"/>
          </v-container>
        </v-card>
      </v-container>
    </v-main>
    <SetLogout/>
   <Historicobcv/>
  </v-app>
</template>

<script>
// eslint-disable-next-line import/extensions
import SetLogout from '../components/SetLogout';
// eslint-disable-next-line import/extensions
import Toolbar from '../components/Toolbar';
// eslint-disable-next-line import/extensions
import Historicobcv from '../components/Historicobcv';

export default {
  name: 'home',
  components: {
    Toolbar, SetLogout, Historicobcv,
  },
};

</script>
