import httpClient from '../axios';

const URL = 'meta/presupuestos';

const presupuestos = async (data) => httpClient.post(URL, data);

const URL_ADD = 'meta/add';

const incluir = async (data) => httpClient.post(URL_ADD, data);

const URL_UPDATE = 'meta/update';

const modifica = async (data) => httpClient.post(URL_UPDATE, data);

const URL_DELETE = 'meta/delete';

const eliminar = async (data) => httpClient.post(URL_DELETE, data);

const URL_GRACIFA = 'meta/grafica';

const grafica = async (data) => httpClient.post(URL_GRACIFA, data);

const URL_GRACIFACOBRANZA = 'meta/graficaFinanza';

const graficacobranza = async (data) => httpClient.post(URL_GRACIFACOBRANZA, data);

const URL_VISITAS = 'meta/visitas';

const visitas = async (data) => httpClient.post(URL_VISITAS, data);

export {
  presupuestos,
  incluir,
  modifica,
  eliminar,
  grafica,
  graficacobranza,
  visitas,
};
