var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('Toolbar'),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-application ")]),_vm._v(" "+_vm._s(_vm.profile.organization.name)+" - Clientes "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card',{staticClass:"mx-auto",attrs:{"width":"98%"}},[_c('v-data-table',{staticClass:"elevation-24",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"no-data-text":"no hay datos","loading":_vm.loading,"loading-text":"Cargando","sort-by":"account_no","header-props":{
                sortByText: 'Ordenar',
                class: 'title',
              },"footer-props":{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',
                'items-per-page-text': 'Clientes por página',
              },"multiSort":""},scopedSlots:_vm._u([{key:"item.imagenurl",fn:function(ref){
              var item = ref.item;
return [_c('v-img',{attrs:{"src":item.imagenurl,"height":"auto","width":"60%"}})]}},{key:"item.agente_retencion",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.agente_retencion),"dark":"","x-small":""}},[(item.agente_retencion)?_c('span',[_vm._v(" Si ")]):_c('span',[_vm._v(" No ")])])]}},{key:"item.acciones",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.validar(_vm.profile.permissions, '30002')),expression:"validar(profile.permissions, '30002')"}],staticClass:"px-auto",attrs:{"title":"Visualizar","color":"#0091EA"},on:{"click":function($event){return _vm.show(item)}}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)})],1),_c('clienteForm')],1)],1)],1),_c('v-footer',{attrs:{"app":""}}),_c('SetLogout'),_c('Historicobcv')],1)}
var staticRenderFns = []

export { render, staticRenderFns }