import httpClient from '../axios';

const URL = 'users';

const users = async (data) => httpClient.post(URL, data);

const URL_ROLES = 'nombreRoles';

const usersroles = async (data) => httpClient.post(URL_ROLES, data);

const URL_ESTADO = 'estado';

const estado = async (data) => httpClient.post(URL_ESTADO, data);

const URL_TIPO = 'tipoUsuario';

const tipoUsuario = async (data) => httpClient.post(URL_TIPO, data);

const URL_ACTUALIZAR = 'actualizarestado';

const actualizarestado = async (data) => httpClient.post(URL_ACTUALIZAR, data);

const URL_STORE = 'userStore';

const store = async (data) => httpClient.post(URL_STORE, data);

const URL_UPDATE = 'userUpdate';

const updated = async (data) => httpClient.post(URL_UPDATE, data);

const URL_DELETE = 'eliminar';

const eliminar = async (data) => httpClient.post(URL_DELETE, data);

const URL_USERCNR = 'usercnr';

const usercnr = async (data) => httpClient.get(URL_USERCNR, data);

const URL_USERLIST = 'userlista';

const userlista = async (data) => httpClient.post(URL_USERLIST, data);

const URL_UPDATELIST = 'updatelist';

const updatelist = async (data) => httpClient.post(URL_UPDATELIST, data);

const URL_ESTATUSLIST = 'estatusList';

const estatusList = async (data) => httpClient.post(URL_ESTATUSLIST, data);

const URL_GETCONDICIONUSER = 'getCondicionUser';

const getCondicionUser = async (data) => httpClient.post(URL_GETCONDICIONUSER, data);

const URL_ACCESS = 'usersAccess';
const usersAccess = async (data) => httpClient.post(URL_ACCESS, data);

const URL_UPDATESOCIETY = 'updateSocietyCentro';

const updateSocietyCentro = async (data) => httpClient.post(URL_UPDATESOCIETY, data);

const URL_SAVEACCESS = 'saveAccess';

const saveAccess = async (data) => httpClient.post(URL_SAVEACCESS, data);
const URL_EJECUTIVO = 'ejecutivos';

const ejecutivos = async (data) => httpClient.post(URL_EJECUTIVO, data);

export {
  users,
  usersroles,
  tipoUsuario,
  store,
  updated,
  estado,
  actualizarestado,
  eliminar,
  usercnr,
  userlista,
  updatelist,
  estatusList,
  getCondicionUser,
  usersAccess,
  saveAccess,
  updateSocietyCentro,
  ejecutivos,
};
