<template>
  <v-row justify="center">
    <v-dialog v-model="showdisc" persistent max-width="600px">
      <v-card>
        <v-toolbar :color="profile.organization.color" dark height="50px">
          <v-icon class="mr-2">
            mdi-application
          </v-icon>
            <h1 class="title mt-2 white--text">
              Agregar - Descuento
            </h1>
            <v-spacer></v-spacer>
            <h1 class="title mt-2 white--text">
              Monto: {{forTotal}}
            </h1>
          <v-spacer></v-spacer>
            <v-btn icon dark @click="close()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                required
                label="porcentaje"
                type="number"
                v-model="formMount"
                :rules="MountRules">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-textarea
                  name="input-7-1"
                  label="Nota"
                  rows="1"
                  v-model="formNote"
                  hint="Observación"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12">
                <h4 class="title mt-2 black--text">
                  Monto a Descontar: {{formDescuento}}
                </h4>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn color="red" text @click="close()"> Cerrar </v-btn>
            <v-btn color="primary" text @click="add()"> Agregar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapActions, mapState, mapMutations } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';

export default {
  name: 'payment-crud',
  data: () => ({
    MountRules: [
      (n) => !!n || 'Debe colocar un monto',
      (n) => n > 0 || 'El monto debe ser positivo',
      (n) => n <= 99 || 'El monto excede la cantidad de digitos permitidos',
    ],
    reset: false,
    vMount: 0,
    vNote: '',
    dmonto: 0,
    Aticipo: 0,
    posicion: 0,
  }),
  computed: {
    ...mapState('userProfile', ['profile']),
    ...mapState('cobranza', ['title', 'showPd', 'showdisc', 'payments', 'pase', 'DescDoc', 'Banco', 'Doc', 'posselected', 'selected', 'Total', 'show']),
    profile() {
      return Profile.get();
    },
    forTotal: {
      get() {
        return this.Total;
      },
    },
    formMount: {
      set(param) {
        this.vMount = param;
        this.reset = false;
      },
      get() {
        return this.vMount;
      },
    },
    formNote: {
      set(param) {
        this.vNote = param;
        this.reset = false;
      },
      get() {
        return this.vNote;
      },
    },
    formDescuento: {
      get() {
        return this.CalcularD().toFixed(2);
      },
    },
    DescPosic: {
      get() {
        let posiciones = [];

        posiciones = this.selected;
        console.log(posiciones);
        return (this.selected.length > 0) ? posiciones : 0;
      },
    },
  },
  methods: {
    ...mapMutations('message', ['MESSAGE', 'TYPE', 'LOADING']),
    ...mapActions('message', ['run']),
    ...mapMutations('cobranza', ['SHOWPD']),
    ...mapActions('cobranza', ['closeDescuento']),
    getID() {
      return this.Banco.length > 0 ? this.Banco[this.Banco.length - 1].ID + 10 : 10;
    },
    resetAll() {
      this.vMount = 0;
      this.vNote = '';
      this.reset = true;
    },
    add() {
      const i = this.payments.length;

      if (this.validateForm()) {
        if (this.pase === 0) {
          this.payments.push({
            ID: this.payments[i - 1].ID + 10,
            bankFrom: '',
            bankTo: '',
            bankToAccount: null,
            bankToId: null,
            datevalue: '',
            note: this.formNote,
            reference: '',
            type: 'mixto',
            value: this.formDescuento,
          });
          this.payments.splice(0, 1, {
            ID: 10,
            bankFrom: this.payments[0].bankFrom,
            bankTo: this.payments[0].bankTo,
            bankToAccount: this.payments[0].bankToAccount,
            bankToId: this.payments[0].bankToId,
            datevalue: this.payments[0].datevalue,
            note: this.payments[0].note,
            reference: this.payments[0].reference,
            type: this.payments[0].type,
            value: parseFloat(this.payments[0].value) - parseFloat(this.formDescuento),
          });
          this.DescDoc.push({
            document: `Descuento ${this.formMount}%`,
            value: this.formDescuento * -1,
            reference: '',
            text: this.formNote,
            year_fiscal: '',
            key: '40',
            pos_ref: '001',
            factSd: 0,
            discount: true,
          });
        } else {
          console.log('this');
        }
        /* this.Banco.push({
          banco_id: null,
          banco_origen: null,
          collectionid: this.Banco[0].collectionid,
          forma_pago: `Descuento ${this.formMount}%`,
          fecha_valor: null,
          monto: parseFloat(this.formDescuento).toFixed(2),
          referencia_ban: null,
          posicion_id: null,
          observacion: this.formNote,
        });
        const posicion = this.Doc.length;
        if (this.Doc[posicion - 1].nro_doc === 'Anticipo') {
          const Ma = parseFloat(parseFloat(this.Doc[posicion - 1].monto) + parseFloat(this.formDescuento)).toFixed(2);
          this.Doc.splice((posicion - 1), 1, {
            nro_doc: 'Anticipo',
            monto: Ma,
            ejercicio: this.Doc[0].ejercicio,
            key: '19',
            discount: 0,
          });
        } else {
          this.Doc.push({
            nro_doc: 'Anticipo',
            monto: parseFloat(this.formDescuento).toFixed(2),
            ejercicio: this.Doc[0].ejercicio,
            key: '19',
            discount: 0,
          });
        }

        for (let i = 0; i < this.selected.length; i += 1) {
          for (let j = 0; j < this.Doc.length; j += 1) {
            if (this.Doc[j].nro_doc === this.selected[i].nro_doc) {
              this.Doc.splice(j, 1, {
                nro_doc: this.Doc[j].nro_doc,
                monto: (this.Doc[j].monto - parseFloat((this.Doc[j].monto * this.formMount) / 100)).toFixed(2),
                ejercicio: this.Doc[j].ejercicio,
                observacion: this.Doc[j].observacion,
                referencia_doc: this.Doc[j].referencia_doc,
              });
            }
          }
        } */
        console.log(this.DescDoc);
        this.closeDescuento();
        // this.resetAll(); // Se reinicia todos los valores
        this.alert('Se Creo Descuento');
      }
    },
    close() {
      this.resetAll();
      this.closeDescuento();
    },
    validateForm() {
      if (this.formMount <= 0 || this.vMount <= 0) {
        this.alert('El monto debe ser mayor de cero', 'warning');
        return false;
      }

      return true;
    },
    alert(message, type = 'info', reload = true) {
      this.$store.commit('message/MESSAGE', message);
      this.$store.commit('message/TYPE', type);
      this.$store.commit('message/RELOAD', reload);
      this.$store.dispatch('message/run');
    },
    CalcularD() {
      /* let Cs = 0;
      this.selected.forEach((DescPosic) => {
        // (DescPosic.monto);
        Cs += parseFloat(DescPosic.doc_total);
      }); */
      return (this.formMount * parseFloat(this.selected[this.posselected].doc_total)) / 100;
    },
  },
  created() {
    try {
      const requests = {
        company_id: this.profile.organization.id,
        user_id: this.profile.user.id,
        user_rol: this.profile.user.rol,
      };

      this.$store.dispatch('cobranza/banks', requests);
    } catch (error) {
      // eslint-disable-next-line no-alert
      this.alert(error);
    }
  },
};
</script>
<style>
  .Documentos-checkbox-container label{
    font-size: 9px;
    font-weight: 600;
    margin-left: 5px;
  }
</style>
