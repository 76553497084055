import httpClient from '../axios';

const URL = 'organization/all';

const organization = async (data) => httpClient.post(URL, data);

const URL_STORE = 'organization/add';

const store = async (data) => httpClient.post(URL_STORE, data);

const URL_UPDATE = 'organization/update';

const updated = async (data) => httpClient.post(URL_UPDATE, data);

export {
  organization,
  store,
  updated,
};
