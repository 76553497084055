<template>
  <div></div>
</template>

<script>
// eslint-disable-next-line import/extensions
import Profile from '../plugins/profile';

export default {
  data() {
    return {
      events: ['click', 'mousedown', 'scroll', 'keypress', 'touchstart'],
      time: new Date(),
    };
  },
  mounted() {
    if (Profile.check()) {
      if (Profile.get().remember === false) {
        // eslint-disable-next-line
        this.events.forEach(function (event) {
          window.addEventListener(event, () => {
            this.time = new Date();
          });
        }, this);

        this.logoutTimer = setInterval(() => {
          const hoy = new Date();
          const diftime = (hoy - this.time) / 1000;
          if (diftime > 3600) {
            // eslint-disable-next-line
            alert('Su sesión ha expirado por inactividad');
            Profile.clear();
            this.$router.push('login');
            clearInterval(this.logoutTimer);
          }
        }, 1000);
      }
    }
  },
  destroyed() {
    // eslint-disable-next-line
    this.events.forEach(function (event) {
      window.removeEventListener(event, this.resetTimer());
    }, this);
    this.resetTimer();
  },
  methods: {
    resetTimer() {
      this.time = new Date();
      clearTimeout(this.logoutTimer);
    },
  },
};
</script>
