<template>
  <v-app>
    <Toolbar/>
    <v-main>
      <v-container fluid>
        <v-card>
          <v-card-title>
             <v-icon class="mr-2">
               mdi-application
              </v-icon>
              {{profile.organization.name}} - Roles
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-btn
          title='Agregar'
          class='mx-4 mb-4'
          tile
          color='grey lighten-2'
          @click='add()'
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
          <v-card class="mx-auto" width="98%">
          <v-data-table
            :headers="headers"
            :items="item"
            :search="search"
            no-data-text="no hay datos"
            sort-by="user_name"
            class="elevation-24"
            :header-props="{
              sortByText: 'Ordenar',
            }"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-text': 'roles por página',
            }"
            multiSort
          >
          <template v-slot:[`item.acciones`]='{ item }'>
              <v-btn icon>
                <v-icon class='px-auto' title='Desactivar Rol' color='#0091EA' @click="actualizarRol(item)"
                >
                  mdi-account-minus-outline
                </v-icon>
              </v-btn>
              <v-btn icon >
                <v-icon class='px-auto' title='Editar Rol'
                @click="update(item)">
                  mdi-circle-edit-outline
                </v-icon>
              </v-btn>
              <v-btn icon v-show="validar(profile.permissions,'41003')">
                <v-icon class='px-auto' title='Eliminar'
                  @click="eliminarRol(item)"
                  color='#D50000'>
                  mdi-delete-alert-outline
                </v-icon>
              </v-btn>
            </template>
          <template v-slot:[`item.estado`]="{ item }">
            <v-chip :color="getColor(item.estado)" dark x-small>
              <span v-if="item.estado === '1'"> Activo </span>
              <span v-else> Inactivo </span>
            </v-chip>
          </template>
          </v-data-table>
          </v-card>
        </v-card>
      </v-container>
      <roles-form></roles-form>
    </v-main>
    <SetLogout/>
   <Historicobcv/>
  </v-app>

</template>

<script>
// eslint-disable-next-line import/extensions
import { mapState, mapActions } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import RolesForm from './RolesForm';
// eslint-disable-next-line import/extensions
import Toolbar from '../../components/Toolbar';
// eslint-disable-next-line import/extensions
import SetLogout from '../../components/SetLogout';
// eslint-disable-next-line import/extensions
import Historicobcv from '../../components/Historicobcv';

export default {
  dialog: true,
  name: 'roles',
  components: {
    Toolbar, RolesForm, SetLogout, Historicobcv,
  },
  data: () => ({
    search: '',
    headers: [
      { text: 'Nombre', value: 'nombre', align: 'center' },
      { text: 'Notas', value: 'notas', align: 'center' },
      { text: 'Estado', value: 'estado', align: 'center' },
      { text: 'Acciones', value: 'acciones', sortable: false },
    ],
  }),
  computed: {
    ...mapState('roles', ['item']),
    profile() {
      return Profile.get();
    },
  },
  methods: {
    ...mapActions('roles', ['data', 'add', 'update', 'show', 'showRolesAdd', 'getVenta', 'eliminarRol', 'actualizarRol']),
    data() {
      return {
        id: this.id,
      };
    },
    getColor(estado) {
      return estado === '1' ? 'green' : 'red';
    },
    validar(permiso, accion) {
      let valor = false;
      permiso.forEach((permisos) => {
        if (permisos.accion === accion) {
          valor = true;
        }
      });
      return valor;
    },
    response(api) {
      this.$store.commit('message/LOADING', true);
      try {
        this.$store.commit('message/MESSAGE', api.data.message);
        this.$store.commit('message/TYPE', 'success');
        this.$store.dispatch('message/run');
      } catch (error) {
        // eslint-disable-next-line no-alert
        alert(error);
      }
      this.$store.commit('message/LOADING', false);
    },
  },
  mounted() {
    try {
      const requests = {
        organization_id: this.profile.organization.id,
        user_id: this.profile.user.id,
      };
      this.$store.dispatch('roles/data', requests);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(error);
    }
  },
};
</script>
