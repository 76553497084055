<template>
  <v-container class="mt-0 pt-0" fluid>
    <v-card>
      <v-toolbar color="grey" height="40">
        <span class="headline">{{title}}</span>
        <v-spacer></v-spacer>
        <span class="headline">{{Docsap}}</span>
      </v-toolbar>
        <v-card>
          <v-card-text>
            <v-container>
              <v-data-table
                :headers="hearders"
                :items="Banco"
                :items-per-page="99"
                class="elevation-1"
                hide-default-footer
                no-data-text='Sin posicion'
                :header-props="{
                  sortByText: 'Ordenar',
                  class: 'title',
                }"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-arrow-collapse-left',
                  lastIcon: 'mdi-arrow-collapse-right',
                  prevIcon: 'mdi-minus',
                  nextIcon: 'mdi-plus',
                  'items-per-page-text': 'Productos por Página',
                }">
                <template v-slot:[`item.monto`]="{ item }">
                          <v-text-field
                            v-model="distribucion.monto"
                            :hide-details="true"
                            type="number"
                            dense single-line
                            :autofocus="true"
                            v-if="item.posicion_id === itemPos">
                          </v-text-field>
                          <span v-else>{{item.monto}}</span>
                        </template>

                <template v-slot:[`item.referencia_ban`]="{ item }">
                          <v-text-field
                            v-model="Refer"
                            :hide-details="true"
                            type="text"
                            dense single-line
                            :autofocus="true"
                            @change="ReferenciaTodosCampos()"
                            v-if="item.posicion_id === itemPos">
                          </v-text-field>
                          <span v-else>{{Refer}}</span>
                        </template>

                <template v-slot:[`item.observacion`]="{ item }">
                          <v-text-field v-show="profile.organization.id == 1"
                            v-model="item.observacion"
                            :hide-details="true"
                            type="text"
                            dense single-line
                            :autofocus="true"
                            @change="ObservacionTodosCampos(item.observacion)"
                            v-if="item.posicion_id === itemPos">
                          </v-text-field>
                          <v-text-field v-show="profile.organization.id == 2"
                            v-model="item.observacion"
                            :hide-details="true"
                            type="text"
                            dense single-line
                            :autofocus="true"
                            v-if="item.posicion_id === itemPos">
                          </v-text-field>
                          <span v-else>{{item.observacion}}</span>
                        </template>

                 <template v-slot:[`item.asignacion`]="{ item }">
                          <v-text-field
                            v-model="item.asignacion"
                            :hide-details="true"
                            type="text"
                            dense single-line
                            :autofocus="true"
                            @change="AsignacionTodosCampos(item.asignacion)"
                            v-if="item.posicion_id === itemPos">
                          </v-text-field>
                          <span v-else>{{item.asignacion}}</span>
                        </template>

                <template v-slot:[`item.actions`]="{item}">
                          <div v-if="itemPos === item.posicion_id">
                            <v-btn icon>
                              <v-icon
                                title="Cerrar distribución"
                                class="mr-2"
                                color="red"
                                @click="closeItemDistribute()">
                                mdi-window-close
                              </v-icon>
                            </v-btn>
                            <v-btn icon>
                              <v-icon
                                title="Guardar distribución"
                                class="mr-2"
                                color="green"
                                @click="guardarItemDistribute(item)">
                                mdi-content-save
                              </v-icon>
                            </v-btn>
                          </div>
                          <div v-else>
                            <v-btn icon>
                              <v-icon
                                title="Editar distribución"
                                class="mr-2"
                                color="green"
                                @click="editItemDistribute(item)">
                                mdi-pencil-outline
                              </v-icon>
                            </v-btn>
                          </div>
                        </template>
              </v-data-table>
            </v-container>
            <v-container>
              <v-data-table
                v-model="selected"
                :headers="heardersDoc"
                :items="Doc"
                :single-select="singleSelect"
                @input="enterSelect()"
                :items-per-page="99"
                show-select
                item-key="nro_doc"
                class="elevation-1"
                hide-default-footer
                no-data-text='Sin Documento'
                :header-props="{
                  sortByText: 'Ordenar',
                  class: 'title',
                }"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-arrow-collapse-left',
                  lastIcon: 'mdi-arrow-collapse-right',
                  prevIcon: 'mdi-minus',
                  nextIcon: 'mdi-plus',
                  'items-per-page-text': 'Productos por Página',
                }">
                <template v-slot:[`item.monto`]="{ item }">
                          <v-text-field
                            v-model="documentos.monto"
                            :hide-details="true"
                            type="text"
                            dense single-line
                            :autofocus="true"
                            v-if="item.nro_doc === itemDoc && item.discount == 0">
                          </v-text-field>
                          <span v-else>{{item.monto}}</span>
                        </template>

                <template v-slot:[`item.referencia_doc`]="{ item }">
                          <v-text-field
                            v-model="Refer"
                            :hide-details="true"
                            type="text"
                            dense single-line
                            :autofocus="true"
                            @change="ReferenciaTodosCampos()"
                            v-if="item.nro_doc === itemDoc">
                          </v-text-field>
                          <span v-else>{{Refer}}</span>
                        </template>

                <template v-slot:[`item.observacion`]="{ item }">
                          <v-text-field v-show="profile.organization.id == 1"
                            v-model="item.observacion"
                            :hide-details="true"
                            type="text"
                            dense single-line
                            :autofocus="true"
                            @change="ObservacionTodosCampos(item.observacion)"
                            v-if="item.nro_doc === itemDoc">
                          </v-text-field>
                          <v-text-field v-show="profile.organization.id == 2"
                            v-model="item.observacion"
                            :hide-details="true"
                            type="text"
                            dense single-line
                            :autofocus="true"
                            v-if="item.nro_doc === itemDoc">
                          </v-text-field>
                          <span v-else>{{item.observacion}}</span>
                        </template>
                <template v-slot:[`item.asignacion`]="{ item }">
                          <v-text-field
                            v-model="documentos.asignacion"
                            :hide-details="true"
                            type="text"
                            dense single-line
                            :autofocus="true"
                            @change="AsignacionTodosCampos(documentos.asignacion)"
                            v-if="item.nro_doc === itemDoc">
                          </v-text-field>
                          <span v-else>{{item.asignacion}}</span>
                        </template>

                <template v-slot:[`item.actions`]="{item}">
                          <div v-if="itemDoc === item.nro_doc">
                            <v-btn icon>
                              <v-icon
                                title="Cerrar distribución"
                                class="mr-2"
                                color="red"
                                @click="closeItemDocumentos()">
                                mdi-window-close
                              </v-icon>
                            </v-btn>
                            <v-btn icon>
                              <v-icon
                                title="Guardar distribución"
                                class="mr-2"
                                color="green"
                                @click="guardarItemDocumento()">
                                mdi-content-save
                              </v-icon>
                            </v-btn>
                          </div>
                          <div v-else>
                            <v-btn icon>
                              <v-icon
                                title="Editar distribución"
                                class="mr-2"
                                color="green"
                                @click="editItemDocumentos(item)">
                                mdi-pencil-outline
                              </v-icon>
                            </v-btn>
                          </div>
                        </template>
              </v-data-table>
            </v-container>
            <v-container>
              <v-row class="mt-2 pt-2">
                <v-col cols="5" class="mt-0 pt-0">
                 <v-autocomplete
                    v-model="Refer"
                    :items="ForPago"
                    readonly
                    v-if="!showEdit"
                    label="Referencia Cobranza"
                    color="grey darken-4">
                  </v-autocomplete>
                  <v-autocomplete
                    v-model="Refer"
                    :items="ForPago"
                    @change="ReferenciaTodosCampos()"
                    required
                    v-else-if="showEdit"
                    label="Referencia Cobranza"
                    color="grey darken-4">
                  </v-autocomplete>
                </v-col>
                <v-col cols="12"  md="6" >
                  <v-text-field
                    class="mt-0 pt-0"
                    label="Texto Compens."
                    v-model="this.Observacion"
                    readonly
                    v-if="!showEdit"
                    counter
                    maxlength="40"
                    color="grey darken-4"
                  >
                  </v-text-field>
                  <v-text-field
                    class="mt-0 pt-0"
                    label="Texto Compens."
                    v-model="this.Observacion"
                    v-else-if="showEdit"
                    counter
                    maxlength="40"
                    color="grey darken-4"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12"  md="6" class="mt-0 pt-0">
                  <v-menu
                    :close-on-content-click="true"
                    transition="scale-transition"
                    offset-y
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-col cols="12" sm="12" >
                        <v-text-field
                          label="Fecha"
                          v-model="FechaM"
                          readonly
                          v-if="!showEdit"
                          v-bind="attrs"
                          v-on="on"
                          type="date">
                        </v-text-field>
                        <v-text-field
                          label="Fecha"
                          v-model="FechaM"
                          v-else-if="showEdit"
                          v-bind="attrs"
                          v-on="on"
                          type="date">
                        </v-text-field>
                      </v-col>
                    </template>
                  </v-menu>
                </v-col>
              </v-row>
              <v-layout>
                <v-flex class="font-weight-black">
                    TOTAL: {{forTotal}}
                </v-flex>
              </v-layout>
              <v-flex class="font-weight-black">
                    Ver Relación de Ventas
              </v-flex>
              <v-btn icon>
              <v-icon title="Ver Relacion" @click="RelacinVentas(),ver()">mdi-eye</v-icon>
              </v-btn>
                <v-icon v-show="verTabla" title="Ocultar" @click="close()">mdi-close</v-icon>
                <v-data-table v-show="verTabla"
                :headers="SalesOder"
                :items="RelacionSD"
                :items-per-page="99"
                class="elevation-1"
                hide-default-footer
                no-data-text='Sin posicion'
                :header-props="{
                  sortByText: 'Ordenar',
                  class: 'title',
                }">
              </v-data-table>

            </v-container>
          </v-card-text>
        </v-card>
    </v-card>
  </v-container>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapState, mapMutations, mapActions } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import { tipopago, sd } from '../../config/api/cobranza';

export default {
  name: 'cobranza-list',
  data: () => ({
    singleSelect: false,
    selected: [],
    dialog: false,
    distribucion: {},
    documentos: {},
    show1: true,
    show2: false,
    itemPos: -1,
    itemDoc: -1,
    headers: [
      { text: 'Forma de Pago', value: 'forma_pago', align: 'center' },
      { text: 'Monto', value: 'monto', align: 'center' },
      { text: 'Banco Origen', value: 'banco_origen', align: 'center' },
      { text: 'Banco Destino', value: 'banco_id', align: 'center' },
      { text: 'Referencia', value: 'referencia_ban', align: 'center' },
      { text: 'Fecha Valor', value: 'fecha_valor', align: 'center' },
      { text: 'Observación', value: 'observacion', align: 'center' },
      { text: 'Asignación', value: 'asignacion', align: 'center' },
    ],
    headers2: [
      { text: 'Forma de Pago', value: 'forma_pago', align: 'center' },
      { text: 'Monto', value: 'monto', align: 'center' },
      { text: 'Banco Origen', value: 'banco_origen', align: 'center' },
      { text: 'Banco Destino', value: 'banco_id', align: 'center' },
      { text: 'Referencia', value: 'referencia_ban', align: 'center' },
      { text: 'Fecha Valor', value: 'fecha_valor', align: 'center' },
      { text: 'Observación', value: 'observacion', align: 'center' },
      { text: 'Asignación', value: 'asignacion', align: 'center' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    headersDocumento: [
      { text: 'Documento', value: 'nro_doc', align: 'center' },
      { text: 'Monto', value: 'monto', align: 'center' },
      { text: 'Ejercicio', value: 'ejercicio', align: 'center' },
      { text: 'Referencia', value: 'referencia_doc', align: 'center' },
      { text: 'Observación', value: 'observacion', align: 'center' },
      { text: 'Asignación', value: 'asignacion', align: 'center' },
    ],
    headersDocumento2: [
      { text: 'Documento', value: 'nro_doc', align: 'center' },
      { text: 'Monto', value: 'monto', align: 'center' },
      { text: 'Ejercicio', value: 'ejercicio', align: 'center' },
      { text: 'Referencia', value: 'referencia_doc', align: 'center' },
      { text: 'Observación', value: 'observacion', align: 'center' },
      { text: 'Asignación', value: 'asignacion', align: 'center' },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
    SalesOder: [
      { text: 'Nro de Pedido ', value: 'salesorder_no', align: 'center' },
      { text: 'Factura SAP', value: 'factsap', align: 'center' },
    ],
    search: '',
    searchDialog: '',
    ForPago: [],
    RelacionSD: [],
  }),
  computed: {
    verTabla() {
      return this.showSD === true ? this.show1 : this.show2;
    },
    hearders() {
      return this.showEdit === true ? this.headers2 : this.headers;
    },
    heardersDoc() {
      return this.showEdit === true ? this.headersDocumento2 : this.headersDocumento;
    },
    title() {
      return this.Anticipo === '0' ? 'Detalle de la Cobranza' : 'Detalle de Anticipo';
    },
    ...mapState('cobranza', ['Cobranza', 'Estatus', 'Total', 'Anticipo', 'Doc', 'Banco', 'Docsap', 'SD', 'showEdit', 'showSD', 'Referencia', 'Observacion', 'FechaCotabilizacion']),
    profile() {
      return Profile.get();
    },
    forTotal: {
      set(value) {
        this.$store.dispatch('cobranza/Id', value);
      },
      get() {
        return this.Total;
      },
    },
    Refer: {
      set(value) {
        this.$store.dispatch('cobranza/toReferencia', value);
      },
      get() {
        return this.Referencia;
      },
    },
    Nota: {
      set(value) {
        this.$store.dispatch('cobranza/toObservacion', value);
      },
      get() {
        if (this.Estatus === 'PROCESADO') {
          return this.Observacion;
        }
        return this.TC;
      },
    },
    FechaM: {
      set(value) {
        this.$store.dispatch('cobranza/toFecha', value);
      },
      get() {
        return this.FechaCotabilizacion;
      },
    },
  },
  methods: {
    ...mapMutations('message', ['MESSAGE', 'TYPE', 'LOADING']),
    ...mapActions('message', ['run']),
    ...mapActions('cobranza', ['ver', 'close']),
    enterSelect() {
      for (let i = 0; i < this.selected.length; i += 1) {
        document.monto = this.selected[i].monto;
        document.referencia_doc = this.selected[i].referencia_doc;
        document.observacion = this.selected[i].observacion;
        document.ejercicio = this.selected[i].ejercicio;
        document.nro_doc = this.selected[i].nro_doc;
      }
      this.$store.dispatch('cobranza/setItemSelected', this.selected);
    },
    editItemDistribute(item) {
      this.itemPos = item.posicion_id;
      this.distribucion = item;
    },
    editItemDocumentos(item) {
      console.log(item);
      this.itemDoc = item.nro_doc;
      this.documentos = item;
    },
    closeItemDistribute() {
      this.itemPos = '';
      this.distribucion = null;
    },
    closeItemDocumentos() {
      this.itemDoc = '';
      this.documentos = null;
    },
    guardarItemDistribute(item) {
      for (let i = 0; i < this.Banco.length; i += 1) {
        if (this.Banco[i].posicion_id === item.posicion_id) {
          console.log(this.Banco);
          console.log(this.distribucion.monto);
          if (parseFloat(this.Total).toFixed(2) >= parseFloat(this.distribucion.monto).toFixed(2)) {
            this.Banco[i].monto = this.distribucion.monto;
            this.itemPos = '';
            this.distribucion = null;
            this.Banco[i].referencia_ban = this.Refer;
            this.calcular(this.Banco);
          } else {
            this.alert('El monto no puede ser mayor al importe', 'warning');
          }
        }
      }
    },
    guardarItemDocumento() {
      this.itemDoc = '';
      this.documentos = null;
    },
    calcular(total) {
      let montoTotal = 0;
      total.forEach((suma) => {
        montoTotal += parseFloat(suma.monto);
      });
      console.log(montoTotal);
      this.$store.commit('cobranza/SET_TOTAL', parseFloat(montoTotal).toFixed(2));
    },
    alert(message) {
      this.$store.commit('message/MESSAGE', message);
      this.$store.commit('message/TYPE', 'info');
      this.$store.dispatch('message/run');
    },
    ReferenciaTodosCampos() {
      for (let i = 0; i < this.Banco.length; i += 1) {
        this.Banco[i].referencia_ban = this.Refer;
      }
      for (let i = 0; i < this.Doc.length; i += 1) {
        this.Doc[i].referencia_doc = this.Refer;
      }
    },
    ObservacionTodosCampos(data) {
      for (let i = 0; i < this.Banco.length; i += 1) {
        this.Banco[i].observacion = data;
      }
      for (let i = 0; i < this.Doc.length; i += 1) {
        this.Doc[i].observacion = data;
        console.log(this.Doc[i].observacion);
      }
      console.log(this.Banco);
      console.log(this.Doc);
    },
    AsignacionTodosCampos(data) {
      for (let i = 0; i < this.Banco.length; i += 1) {
        this.Banco[i].asignacion = data;
      }
      for (let i = 0; i < this.Doc.length; i += 1) {
        this.Doc[i].asignacion = data;
      }
    },
    async RelacinVentas() {
      const requests = {
        Cobranza: this.Cobranza,
      };
      this.RelacionSD = (await sd(requests)).data;
    },
  },
  async created() {
    const requests = {
      org: this.profile.organization.org,
    };
    this.ForPago = (await tipopago(requests)).data;
  },
};
</script>
