<template>
  <v-app>
    <Toolbar/>
    <v-main>
      <v-container fluid>
        <v-card>

          <v-card-title>
             <v-icon class="mr-2">
               mdi-application
              </v-icon>
              {{profile.organization.name}} - Zonas
            <v-spacer></v-spacer>

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

            <v-btn
              v-show="validar(profile.permissions,'32003')"
              title="Agregar Zona"
              class="mx-4 mb-4"
              tile
              color="grey lighten-2"
              @click="add()"
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>

          <v-card class="mx-auto" width="98%">
            <v-data-table
              :headers="headers"
              :items="item"
              :search="search"
              sort-by="zone"
              class="elevation-24"
              no-data-text="no hay datos"
              :header-props="{
                sortByText: 'Ordenar',
              }"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',
                'items-per-page-text': 'zonas por página',
              }"
              multiSort
            >

            <template v-slot:[`item.acciones`]="{ item }">
              <v-icon
                v-show="validar(profile.permissions,'32002')"
                title="Editar Zona"
                class="mr-2"
                @click="update(item)"
              >
                mdi-pencil-outline
              </v-icon>
              <v-icon
                v-show="validar(profile.permissions,'32002')"
                title="Agregar Usuario a Zona Existente"
                class="mr-2"
                @click="addUser(item)"
              >
                mdi-account-plus
              </v-icon>
            </template>
            </v-data-table>
          </v-card>
          <Form/>
        </v-card>
      </v-container>
    </v-main>
    <SetLogout/>
   <Historicobcv/>
  </v-app>

</template>

<script>
// eslint-disable-next-line import/extensions
import { mapState, mapActions } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import Form from './ZoneForm';
// eslint-disable-next-line import/extensions
import Toolbar from '../../components/Toolbar';
// eslint-disable-next-line import/extensions
import SetLogout from '../../components/SetLogout';
// eslint-disable-next-line import/extensions
import Historicobcv from '../../components/Historicobcv';

export default {
  name: 'zones',
  components: {
    Form, Toolbar, SetLogout, Historicobcv,
  },
  data: () => ({
    search: '',
    headers: [
      { text: 'Zona', value: 'zone', align: 'center' },
      { text: 'Descripción', value: 'description', align: 'center' },
      { text: 'Nª Clientes Registrados', value: 'count_customers', align: 'center' },
      { text: 'Nombre de Ejecutivo Asignado', value: 'executive', align: 'center' },
      { text: 'Acciones', value: 'acciones', sortable: false },
    ],
  }),
  computed: {
    ...mapState('zones', ['item']),
    profile() {
      return Profile.get();
    },
  },
  methods: {
    ...mapActions('zones', ['data', 'add', 'update', 'addUser']),
    validar(permiso, accion) {
      let valor = false;
      permiso.forEach((permisos) => {
        if (permisos.accion === accion) {
          valor = true;
        }
      });
      return valor;
    },
  },
  created() {
    try {
      const requests = {
        company_id: this.profile.organization.id,
        user_id: this.profile.user.id,
      };
      this.$store.dispatch('zones/data', requests);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(error);
    }
  },
};
</script>
