<template>
  <div>
    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-btn icon @click="modalLogout = true">
        <v-icon>
          mdi-login
        </v-icon>
      </v-btn>
      <v-btn icon v-if="profile.societies.length > 0" @click="modalMulti = true">
        <v-icon>
          mdi-repeat
        </v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-layout v-show="profile.organization.id != 5" mt-1 mr-2 column align-end>
        <v-flex>
        <v-btn
        class='px-auto'
        title='Historico Tasa BCV'
        @click="Historicobcv()">
          TASA BCV: {{this.tasa}}
        </v-btn>
        </v-flex>
      </v-layout>
      <v-icon
        title="Recargar Usuario"
        class="mr-2"
        color='grey'
        @click="reloadPage()">
        mdi-sync
      </v-icon>
      <v-toolbar-title>
        <img :src="profile.organization.photo" height="auto" width="180" id="logoVkorg">
      </v-toolbar-title>
    </v-app-bar>
    <v-navigation-drawer app v-model="drawer" width="260px">
    <v-system-bar :color="profile.organization.color"></v-system-bar>
    <v-layout mt-4 column align-center>
      <v-flex>
        <p class="mt-3 headline overline">
          {{profile.user.first_name}} {{profile.user.last_name}}
        </p>
      </v-flex>
    </v-layout>
    <v-card
      class="mx-auto"
      width="260"
    >
      <v-list class="caption">
        <v-list-group
          :prepend-icon="item.ico"
          color="gray"
          :value="true"
          v-model="item.active"
          no-action
          v-for="item in links"
          :key="item.name"
          link
        >
          <template v-slot:activator>
            <v-list-item-title v-text="item.name"></v-list-item-title>
          </template>
          <v-list-item
              link
              v-for="routes in item.routes"
              :key="routes.name"
              router :to="routes.routes"
              color="green">
              <v-list-item-icon>
                <v-icon v-text="routes.ico" size="30"></v-icon>
              </v-list-item-icon>
              <v-list-item-title v-text="routes.name" class="text"></v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-card>
    </v-navigation-drawer>

    <v-dialog v-model="modalLogout" open-delay="" max-width="290" transition="dialog-top-transition">
      <v-card>
        <v-toolbar :color="profile.organization.color" dark height="50px">
          <v-icon class="mr-2">
            mdi-application
          </v-icon>
            <h1 class="title mt-2 white--text">
              Cierre de sessión
            </h1>
          <v-spacer></v-spacer>
            <v-btn icon dark @click="modalDelete = false">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text class="pt-4 title">
          ¿Está seguro que desea cerrar sesión?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" outlined @click="modalLogout = false">
            No
          </v-btn>
          <v-btn color="green darken-1" outlined @click="logout()">
            Sí, cerrar!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-bottom-sheet persistent v-model="modalMulti">
      <div class="white">
        <v-toolbar :color="profile.organization.color" dark>
        <v-toolbar-title>Seleccionar Sociedad</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon @click="modalMulti = false">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container fluid>
          <v-row dense>
            <v-col
              v-for="item in profile.societies"
              :key="item.name"
              :cols="3">
              <v-card>
                <v-img
                  :src="item.photo"
                  class="white--text align-end"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  height="70px"
                  width="330px">
                  <v-card-title v-text="item.name"></v-card-title>
                </v-img>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="chooseCompany(item)">
                    <v-icon>mdi-open-in-new</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
              <Historicobcv/>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-bottom-sheet>

  </div>
</template>
<script>
// eslint-disable-next-line import/extensions
import { mapActions } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../plugins/profile';
// eslint-disable-next-line import/extensions
import menu from '../config/api/menu';
// eslint-disable-next-line import/extensions
import { tasaBcv } from '../config/api/salesorder';
// eslint-disable-next-line import/extensions
import Historicobcv from './Historicobcv';

export default {
  name: 'toolbar',
  components: {
    Historicobcv,
  },
  data: () => ({
    modalLogout: false,
    modalMulti: false,
    drawer: false,
    links: [],
    tasa: null,
    dialog: false,
  }),
  computed: {
    profile() {
      return Profile.get();
    },
  },
  methods: {
    ...mapActions('products', ['Historicobcv']),
    logout() {
      Profile.clear();
      this.$router.push('login');
    },
    reloadPage() {
      window.location.reload();
    },
    chooseCompany(item) {
      const profile = Profile.get();
      profile.organization = item;
      Profile.set(profile);
      window.location.reload();
    },
  },
  async created() {
    const requests = {
      user_rol: this.profile.roluser.roles_id,
    };
    this.tasa = (await tasaBcv()).data;
    const link = await menu(requests);
    this.links = link.data;
  },
};
</script>

<style scoped>
.text{
  font-size: small;
  text-transform: capitalize;
}
</style>
