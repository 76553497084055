import {
  users,
  usersroles,
  tipoUsuario,
  estado,
  actualizarestado,
  eliminar,
  userlista,
  usersAccess,
} from '../../config/api/usuarios';

export default {

  name: 'usuarios',
  namespaced: true,
  state: {
    // PRINCIPAL
    item: [],
    // FORMULARIO
    show: false,
    isAdd: false,
    isUpdate: false,
    isShow: false,
    showSociety: false,
    title: '',
    selected: [], // Socieda seleccionada para el usuario
    //  CAMPOS DE FORMULARIO
    id: '',
    user_name: '',
    first_name: '',
    last_name: '',
    email1: '',
    phone_mobile: '',
    user_password: '',
    user_hash: '',
    usersap: '',
    nombre: null,
    codigo_vendedor: 0,
    roles: [],
    societys: [],
    tipo: null,
    tipoUser: [],
    estado: [],
    usuarioEstado: [
      { value: '1', text: 'Activo' },
      { value: '0', text: 'Inactivo' },
    ],
    userEstado: [],
    //  DATOS SELECCIONADOS
    formData: [],
    // LISTA DE PRECIOS
    userlista: [],
    show1: false,
    idUser: '',
    condicion: null,
    userlist: null,
    descripcion: null,
    listaUsuario: [],
    estatus: [],
    getCondicionUser: null,
    usuarioCondicion: [],
    // COMPANY
    showUserAccess: false,
    isCompany: false,
    usersAccess: [],
  },
  mutations: {
    // PRINCIPAL
    SET_DATA(state, parameter) {
      state.item = parameter.data;
    },
    // FORMULARIO
    SHOW(state) {
      state.show = !state.show;
    },
    SHOW1(state) {
      state.show1 = !state.show1;
    },
    SHOWCOMPANY(state) {
      state.showCompany = !state.showCompany;
    },
    SHOW_ACCESS(state) {
      state.showUserAccess = !state.showUserAccess;
    },
    SET_IS_SHOW(state, payload) {
      state.isShow = payload;
    },
    SET_IS_SHOW_COMPANY(state, payload) {
      state.isCompany = payload;
    },
    SET_IS_ADD(state, parameter) {
      state.isAdd = parameter;
    },
    SHOWSOCIETY(state, parameter) {
      state.showSociety = parameter;
    },
    SET_SOCIETY(state, parameter) {
      state.societys = parameter.data;
    },
    SET_IS_UPDATE(state, parameter) {
      state.isUpdate = parameter;
    },
    SET_TITLE(state, parameter) {
      state.title = parameter;
    },
    SET_DATA_ROLES(state, parameter) {
      state.roles = parameter.data;
    },
    SET_DATA_TIPO(state, parameter) {
      state.tipoUser = parameter.data;
    },
    SET_DATA_ESTADO(state, parameter) {
      state.userEstado = parameter.data;
    },
    SET_DATA_USERLIST(state, parameter) {
      state.userlist = parameter.data;
    },
    SET_DATA_ESTATUS(state, parameter) {
      state.estatus = parameter.data;
    },
    SET_DATA_PRICEBOOK(state, parameter) {
      state.usuarioCondicion = parameter.data;
    },
    // prueba
    SET_DATA_ID(state, parameter) {
      state.id = parameter.data;
    },
    // CAMPOS DE FORMULARIO
    SET_ID(state, parameter) {
      state.id = parameter;
    },
    SET_USUARIO(state, parameter) {
      state.user_name = parameter;
    },
    SET_GETUSUARIO(state, parameter) {
      state.getusuario = parameter;
    },
    SET_NOMBRE(state, parameter) {
      state.first_name = parameter;
    },
    SET_APELLIDO(state, parameter) {
      state.last_name = parameter;
    },
    SET_EMAIL(state, parameter) {
      state.email1 = parameter;
    },
    SET_TELEFONO(state, parameter) {
      state.phone_mobile = parameter;
    },
    SET_CONTRASEÑA(state, parameter) {
      state.user_password = parameter;
    },
    SET_ROLES(state, parameter) {
      state.nombre = parameter;
    },
    SET_TIPO(state, parameter) {
      state.tipo = parameter;
    },
    SET_ESTADO(state, parameter) {
      state.estado = parameter;
    },
    SET_ESTATUS(state, parameter) {
      state.estatus = parameter;
    },
    SET_PRICEBOOK(state, parameter) {
      state.userlist = parameter;
    },
    SET_USERSAP(state, parameter) {
      state.usersap = parameter;
    },
    SET_CODIGO(state, parameter) {
      state.codigo_vendedor = parameter;
    },
    // SELECCIONADO
    SET_FORM_DATA(state, parameter) {
      state.formData = parameter;
    },
    SET_DATA_DESCRIOCION(state, parameter) {
      state.descripcion = parameter.data;
    },
    SET_DESCRIOCION(state, parameter) {
      state.descripcion = parameter;
    },
    change(state, payload) {
      state.name = payload;
    },
    SET_LISTA(state, parameter) {
      state.userlista = parameter.data;
    },
    SET_ACCESS(state, parameter) {
      state.usersAccess = parameter.data;
    },
  },
  // PRINCIPAL
  actions: {
    async data({ commit }, parameter) {
      commit('SET_DATA', await users(parameter));
    },
    // FORMULARIO
    setItemSelected({ commit }, parameter) {
      commit('SET_SELECTED', parameter);
    },
    add({ commit }) {
      commit('SHOW');
      commit('SET_TITLE', 'AGREGAR');
      commit('SET_IS_ADD', true);
      commit('SHOWSOCIETY', true);
    },
    async listUser({ commit }, parameter) {
      commit('SHOW1');
      commit('SET_IS_SHOW', true);
      commit('SET_ID', parameter.id);
      commit('SET_NOMBRE', `${parameter.first_name} ${parameter.last_name}`);
      commit('SET_LISTA', await userlista(parameter));
    },
    async editAccess({ commit }, parameter) {
      commit('SHOW_ACCESS');
      commit('SET_IS_SHOW_COMPANY', true);
      commit('SET_ID', parameter.id);
      commit('SET_NOMBRE', `${parameter.first_name} ${parameter.last_name}`);
      commit('SET_ACCESS', await usersAccess(parameter));
    },
    cerrar({ commit }) {
      commit('SHOW1', false);
    },
    cerrarCompany({ commit }) {
      commit('SHOWCOMPANY', false);
    },
    closeUserAccess({ commit }) {
      commit('SHOW_ACCESS', false);
    },
    async update({ commit }, parameter) {
      commit('SHOW');
      commit('SET_TITLE', 'EDITAR');
      commit('SET_IS_UPDATE', true);
      commit('SHOWSOCIETY', true);
      commit('SET_ID', parameter.id);
      commit('SET_USUARIO', parameter.user_name);
      commit('SET_NOMBRE', parameter.first_name);
      commit('SET_APELLIDO', parameter.last_name);
      commit('SET_EMAIL', parameter.email1);
      commit('SET_TELEFONO', parameter.phone_mobile);
      // commit('SET_CONTRASEÑA', parameter.user_password);
      commit('SET_USERSAP', parameter.usersap);
      commit('SET_ROLES', parameter.nombre);
      commit('SET_TIPO', parameter.tipo);
      commit('SET_ESTADO', parameter.estado);
      commit('SET_CODIGO', parameter.codigo_vendedor);
    },
    clean({ commit }) {
      commit('SET_USUARIO', '');
      commit('SET_NOMBRE', '');
      commit('SET_APELLIDO', '');
      commit('SET_EMAIL', '');
      commit('SET_TELEFONO', '');
      commit('SET_CONTRASEÑA', '');
      commit('SET_ROLES', '');
      commit('SET_TIPO', '');
      commit('SET_ESTADO', '');
      commit('SET_SOCIETY', '');
    },
    async reset({ commit }) {
      commit('SHOW');
      commit('SET_IS_ADD', false);
      commit('SET_IS_UPDATE', false);
      commit('SET_USUARIO', '');
      commit('SET_NOMBRE', '');
      commit('SET_APELLIDO', '');
      commit('SET_EMAIL', '');
      commit('SET_TELEFONO', '');
      commit('SET_CONTRASEÑA', '');
      commit('SET_ROLES', '');
      commit('SET_TIPO', '');
      commit('SET_ESTADO', '');
      commit('SET_CODIGO', '');
      commit('SET_LISTA', await userlista());
    },
    async initializingForm({ commit }, parameter) {
      commit('SET_DATA_ROLES', await usersroles(parameter));
      commit('SET_DATA_TIPO', await tipoUsuario(parameter));
      commit('SET_DATA_ESTADO', await estado(parameter));
    },
    toAssignId({ commit }, parameter) {
      commit('SET_ID', parameter);
    },
    toAssignUsuario({ commit }, parameter) {
      commit('SET_USUARIO', parameter);
    },
    toAssignNombre({ commit }, parameter) {
      commit('SET_NOMBRE', parameter);
    },
    toAssignApellido({ commit }, parameter) {
      commit('SET_APELLIDO', parameter);
    },
    toAssignEmail({ commit }, parameter) {
      commit('SET_EMAIL', parameter);
    },
    toAssignTelefono({ commit }, parameter) {
      commit('SET_TELEFONO', parameter);
    },
    toAssignContraseña({ commit }, parameter) {
      commit('SET_CONTRASEÑA', parameter);
    },
    toAssignRoles({ commit }, parameter) {
      commit('SET_ROLES', parameter);
    },
    toAssignCodigo({ commit }, parameter) {
      commit('SET_CODIGO', parameter);
    },
    toAssignTipo({ commit }, parameter) {
      commit('SET_TIPO', parameter);
    },
    toAssignEstado({ commit }, parameter) {
      commit('SET_ESTADO', parameter);
    },
    toAssignList({ commit }, parameter) {
      commit('SET_PRICEBOOK', parameter);
    },
    ver({ commit }) {
      commit('SHOWSOCIETY', true);
    },
    async actualizarEstado({ commit }, parameter) {
      commit('SET_DATA_ID', await actualizarestado(parameter));
      commit('SET_DATA', await users(parameter));
      window.location.reload();
    },
    async eliminarUser({ commit }, parameter) {
      commit('SET_ID', parameter.id);
      commit('SET_DATA_ID', await eliminar(parameter));
      commit('SET_DATA', await users(parameter));
      window.location.reload();
    },
    async getListas({ commit }, parameter) {
      commit('SET_ID', parameter.id);
      commit('SET_LISTA', await userlista());
    },
    async getAccess({ commit }, parameter) {
      commit('SET_ID', parameter.id);
      commit('SET_ACCESS', await usersAccess());
    },
  },
};
