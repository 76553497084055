<template>
  <v-row justify="center" class="mt-2 pt-2">
    <v-col cols="12" sm="4" md="2" class="mt-0 pt-0">
      <v-dialog
        v-model="show"
        persistent
        max-width="900px"
      >
        <v-card>
          <v-card>
          <v-toolbar :color="profile.organization.color" dark>
            <span class="headline">{{title}}-USUARIO</span>
          <v-spacer></v-spacer>
            <v-btn icon dark @click="reset()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar>
          </v-card>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Usuario*" required
                    v-model="formUsuario"
                    :rules="userRules"
                    v-show="isAdd"
                  ></v-text-field>
                  <v-text-field
                    label="Usuario*" required
                    v-model="formUsuario"
                    v-show="isUpdate"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Nombre"
                    v-model="formNombre"
                    hint="Ingrese su nombre"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Apellido"
                    v-model="formApellido"
                    hint="Ingrese su apellido"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="formTelefono"
                    label="Telefono"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="8">
                  <v-text-field
                    v-model="formEmail"
                    label="Email*"
                    required
                    type="email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="formContraseña"
                    label="Contraseña*"
                    type="password"
                    required
                    v-show="isAdd"
                    :rules ="claveRules"
                    @change="hassPassword"
                    hint="Maximo 12 caracteres">
                  </v-text-field>
                  <v-text-field
                    v-model="formContraseña"
                    label="Contraseña*"
                    type="password"
                    required
                    v-show="isUpdate"
                    :rules ="claveRules"
                    @change="hassPassword"
                    hint="Maximo 12 caracteres">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                     v-model="formRoles"
                     :items="roles"
                    label="Rol*"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    v-model="formTipo"
                    :items="tipoUser"
                    label="Tipo de Usuario*"
                    required
                  ></v-select>
                </v-col>
                <v-col  v-show="isAdd" cols="12" sm="4">
                  <v-select
                    v-model="formEstado"
                    :items="usuarioEstado"
                    label="Estado"
                    required>
                  </v-select>
                </v-col>
                <v-col v-show="isUpdate" cols="12" sm="4">
                  <v-text-field
                    v-model="formCodigo"
                    label="Codigo Vendedor"
                    required>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <small>*Indicar los campos requeridos</small>
          </v-card-text>
          <v-card-actions>
            <v-btn color="danger" text @click="clean()" v-show="isAdd">Limpiar Campos</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="reset()">Cerrar</v-btn>
            <v-btn color="blue darken-1" text @click="update()" v-show="isUpdate">Editar</v-btn>
            <v-btn color="blue darken-1" text @click="add()" v-show="isAdd"> Agregar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapState, mapActions, mapMutations } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import * as usuarios from '../../config/api/usuarios';

export default {
  name: 'usuarios-form',
  data: () => ({
    userRules: [
      (v) => !!v || 'El usuario es requerido',
    ],
    claveRules: [
      (v) => !!v || 'La contraseña es requerida',
      (v) => v.length <= 12 || 'La contraseña debe tener entre 6 y 12 caracteres',
      (v) => v.length >= 6 || 'La contraseña debe tener entre 6 y 12 caracteres',
    ],
  }),
  computed: {
    ...mapState('userProfile', ['profile']),
    ...mapState('usuarios', ['item', 'show', 'id', 'user_name',
      'first_name', 'last_name', 'phone_mobile', 'email1', 'user_password', 'user_hash', 'usersap', 'codigo_vendedor',
      'title', 'tipo', 'nombre', 'roles', 'estado', 'usuarioEstado', 'tipoUser', 'isAdd', 'isUpdate']),
    formId: {
      set(value) {
        this.$store.dispatch('usuarios/toAssignId', value);
      },
      get() {
        return this.id;
      },
    },
    formUsuario: {
      set(value) {
        this.$store.dispatch('usuarios/toAssignUsuario', value);
      },
      get() {
        return this.user_name;
      },
    },
    formNombre: {
      set(value) {
        this.$store.dispatch('usuarios/toAssignNombre', value);
      },
      get() {
        return this.first_name;
      },
    },
    formApellido: {
      set(value) {
        this.$store.dispatch('usuarios/toAssignApellido', value);
      },
      get() {
        return this.last_name;
      },
    },
    formEmail: {
      set(value) {
        this.$store.dispatch('usuarios/toAssignEmail', value);
      },
      get() {
        return this.email1;
      },
    },
    formTelefono: {
      set(value) {
        this.$store.dispatch('usuarios/toAssignTelefono', value);
      },
      get() {
        return this.phone_mobile;
      },
    },
    formContraseña: {
      set(value) {
        this.$store.dispatch('usuarios/toAssignContraseña', value);
      },
      get() {
        return this.user_password;
      },
    },
    formRoles: {
      set(value) {
        this.$store.dispatch('usuarios/toAssignRoles', value);
      },
      get() {
        return this.nombre;
      },
    },
    formTipo: {
      set(value) {
        this.$store.dispatch('usuarios/toAssignTipo', value);
      },
      get() {
        return this.tipo;
      },
    },
    formEstado: {
      set(value) {
        this.$store.dispatch('usuarios/toAssignEstado', value);
      },
      get() {
        return this.estado;
      },
    },
    formCodigo: {
      set(value) {
        this.$store.dispatch('usuarios/toAssignCodigo', value);
      },
      get() {
        return this.codigo_vendedor;
      },
    },
    profile() {
      return Profile.get();
    },
  },
  methods: {
    ...mapMutations('message', ['MESSAGE', 'TYPE', 'LOADING']),
    ...mapActions('message', ['run']),
    ...mapMutations('usuarios', ['SHOW']),
    ...mapActions('usuarios', ['add', 'update', 'clean', 'reset',
      'initializingForm']),
    hassPassword() {
      this.formContraseña = btoa(this.formContraseña);
    },
    data() {
      return {
        usuario: {
          id: this.formId,
          user_name: this.formUsuario,
          first_name: this.formNombre,
          last_name: this.formApellido,
          email1: this.formEmail,
          phone_mobile: this.formTelefono,
          user_password: this.formContraseña,
          user_hash: this.formContraseña,
          tipo: this.formTipo,
          estado: this.formEstado,
          usuario_especial: 1,
          idRol: this.formRoles,
          rif: 1,
          estatus: this.formEstado,
          usersap: this.usersap,
          remember_token: 1,
          cod_ven: this.formCodigo,
        },
        roles: {
          user_id: this.id,
          organization_id: this.profile.organization.id,
          nombre: this.formRoles,
        },
      };
    },
    async add() {
      if (this.validate()) this.response(await usuarios.store(this.data()));
    },
    async update() {
      if (this.validate1()) this.response(await usuarios.updated(this.data()));
      // console.log(this.data());
    },
    response(api) {
      this.$store.commit('message/LOADING', true);
      try {
        this.$store.dispatch('usuarios/reset');
        this.$store.commit('message/MESSAGE', api.data.message);
        this.$store.commit('message/TYPE', 'success');
        this.$store.dispatch('message/run');
        this.$store.dispatch('usuarios/data', this.profile);
      } catch (error) {
        // eslint-disable-next-line no-alert
        alert(error);
      }
      this.$store.commit('message/LOADING', false);
    },
    validate() {
      if (this.formUsuario === '' || this.formUsuario.length < 1) {
        this.alert('El usuario no puede estar vacio');
        return 0;
      }
      if (this.item.find((element) => element.user_name === this.formUsuario)) {
        this.alert('El usuario ya existe');
        return 0;
      }
      if (this.formNombre === '' || this.formNombre.length < 1) {
        this.alert('El nombre no puede estar vacio');
        return 0;
      }
      if (!/^[0-9A-Za-z]+$/.test(this.formUsuario)) {
        this.alert('El usuario no debe tener caracteres especiales');
        return 0;
      }
      if (this.formContraseña === '' || this.formContraseña.length < 6) {
        this.alert('La contraseña no puede estar vacia y debe tener minimo 6 caracteres');
        return 0;
      }
      this.data();
      return 1;
    },
    validate1() {
      if (this.formUsuario === '' || this.formUsuario.length < 1) {
        this.alert('El usuario no puede estar vacio');
        return 0;
      }
      if (this.formNombre === '' || this.formNombre.length < 1) {
        this.alert('El nombre no puede estar vacio');
        return 0;
      }
      if (!/^[0-9A-Za-z]+$/.test(this.formUsuario)) {
        this.alert('El usuario no debe tener caracteres especiales');
        return 0;
      }
      /* if (this.formContraseña === '') {
        console.log(this.data());
        this.alert('La contraseña no puede estar vacia');
        return 0;
      } */
      this.data();
      return 1;
    },
    alert(message) {
      this.$store.commit('message/MESSAGE', message);
      this.$store.commit('message/TYPE', 'info');
      this.$store.commit('message/RELOAD', false);
      this.$store.dispatch('message/run');
    },
  },
  created() {
    try {
      const requests = {
        company_id: this.profile.organization.id,
        user_id: this.profile.user.id,
      };
      this.$store.dispatch('usuarios/initializingForm', requests);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(error);
    }
  },
};
</script>
