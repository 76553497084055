import {
  products,
  getProducto,
  getProducts,
} from '../../config/api/products';

export default {

  name: 'products',

  namespaced: true,

  state: {
    item: [],
    items: [],
    imagenurl: '',
    productcode: '',
    productname: '',
    productid: '',
    peso: '',
    marca: '',
    cf_560: '',
    productfamily: '',
    perfilserie: '',
    unidad: '',
    show: false,
    show1: false,
    show2: false,
    formData: [],
    organizacion: '',
    id: '',
    tasa: '',
    fecha: '',
  },
  mutations: {
    SHOW(state) {
      console.log(state.show);
      state.show = !state.show;
    },
    SHOW1(state) {
      console.log(state.show1);
      state.show1 = !state.show1;
    },
    SHOW2(state) {
      console.log(state.show2);
      state.show2 = !state.show2;
    },
    SET_DATA(state, parameter) {
      state.item = parameter.data;
    },
    SET_DATA1(state, parameter) {
      state.items = parameter.data;
    },
    SET_FORM_DATA(state, parameter) {
      state.formData = parameter;
    },
    SET_PRODUCTCODE(state, parameter) {
      console.log(parameter);
      state.productcode = parameter;
    },
    SET_PRODUCTID(state, parameter) {
      console.log(parameter);
      state.productid = parameter;
    },
    SET_IMAGEN(state, parameter) {
      console.log(parameter);
      state.imagenurl = parameter;
    },
    SET_PRODUCTNAME(state, parameter) {
      console.log(parameter);
      state.productname = parameter;
    },
    SET_PESO(state, parameter) {
      console.log(parameter);
      state.peso = parameter;
    },
    SET_MARCA(state, parameter) {
      console.log(parameter);
      state.marca = parameter;
    },
    SET_CODIGO(state, parameter) {
      console.log(parameter);
      state.cf_560 = parameter;
    },
    SET_FAMILIA(state, parameter) {
      console.log(parameter);
      state.productfamily = parameter;
    },
    SET_PERFIL(state, parameter) {
      console.log(parameter);
      state.perfilserie = parameter;
    },
    SET_UNIDAD(state, parameter) {
      console.log(parameter);
      state.unidad = parameter;
    },
    SET_DATA_PRODUCTID(state, parameter) {
      console.log(parameter);
      state.productid = parameter.data;
    },
  },
  actions: {
    async data({ commit }, parameter) {
      commit('SET_DATA', await products(parameter));
      // commit('SET_PRODUCTID', await getProducto(parameter));
    },
    async show({ commit }, parameter) {
      commit('SHOW');
      commit('SET_PRODUCTCODE', parameter.productcode);
      commit('SET_PRODUCTNAME', parameter.productname);
      commit('SET_IMAGEN', parameter.imagenurl);
      commit('SET_PESO', parameter.peso);
      commit('SET_MARCA', parameter.marca);
      commit('SET_CODIGO', parameter.cf_560);
      commit('SET_FAMILIA', parameter.productfamily);
      commit('SET_PERFIL', parameter.perfilserie);
      commit('SET_UNIDAD', parameter.unidad);
      // commit('SET_PRODUCTID', parameter.productid);
      console.log(parameter.productid);
      commit('SET_DATA1', await getProducto(parameter));
      // commit('SET_PRODUCTID', parameter.productid);
      commit('SET_DATA', await getProducts(parameter));
    },
    imgshow({ commit }, parameter) {
      commit('SHOW1');
      commit('SET_PRODUCTCODE', parameter.productcode);
      commit('SET_PRODUCTNAME', parameter.productname);
      commit('SET_IMAGEN', parameter.imagenurl);
    },
    async Historicobcv({ commit }) {
      commit('SHOW2');
    },
    cerrar({ commit }) {
      commit('SHOW', false);
      // commit('SET_IS_SHOW', false);
    },
    close({ commit }) {
      commit('SHOW1', false);
      // commit('SET_IS_SHOW', false);
    },
    exit({ commit }) {
      commit('SHOW2', false);
      // commit('SET_IS_SHOW', false);
    },
    toAssignProductId({ commit }, parameter) {
      console.log(parameter);
      commit('SET_DATA_PRODUCTID', parameter);
    },
    /* toAssignProductName({ commit }, parameter) {
      console.log(parameter);
      commit('SET_PRODUCTNAME', parameter);
    }, */
  },
};
