<template>
<v-row justify="center">
    <v-container>
    <v-dialog v-model="showflete" max-width="460px" persistent>
        <v-card>
        <v-toolbar color="grey" dark>
            <v-icon class='mr-2'> mdi-application </v-icon>
            {{profile.organization.name}} - Flete Pedido - {{salesorder_no}}
        </v-toolbar>
        <v-card-text>
            <v-divider></v-divider>
            <v-col cols="8" sm="0" md="0"  class="mt-4 pt-0">
              <h1 v-if="des" class="title mt-2 black--text" @click="valor()">
               {{(form.subtotal*flete).toFixed(2) }}Flete  ↓
              </h1>
              <h1 v-else class="title mt-2 black--text" @click="valor()">
               Total de Pedidos {{(mot).toFixed(2) }} ↓
              </h1>
            </v-col>
            <v-col cols="12" sm="8" md="8" class="mt-5 pt-0">
              <v-text-field class='mx-0 mb-2'
              label="Flete"
              autofocus
              v-model="valorflete"
              type="Number"
              color="grey darken-4"
              >
              </v-text-field>
            </v-col>
        </v-card-text>
        <v-card-actions>
            <v-divider></v-divider>
            <v-btn @click="cerrarFlete()" text color="red">Cerrar</v-btn>
            <v-btn @click="PFlete()" text color="blue">Pedido Flete</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
    </v-container>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapActions, mapMutations, mapState } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import { tasaBcv, updateflete } from '../../config/api/salesorder';

export default {
  name: 'salesorder-tasa',
  data: () => ({
    dialog: false,
    tasa: null,
    monto: null,
    valorflete: 0.00,
  }),
  computed: {
    ...mapState('salesorder', ['item']),
    ...mapState('salesorder', ['showflete', 'form']),
    ...mapState('salesorder', ['salesorderid', 'salesorder_no', 'flete', 'form', 'des', 'mot']),
    ...mapState('cobranza', ['selected']),
    /* eslint no-return-assign: "error" */
    formId: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignId', value);
      },
      get() {
        return this.salesorderid;
      },
    },
    fletes: {
      set(value) {
        this.$store.dispatch('salesorder/toAssignNEWFLETE', value);
      },
      get() {
        console.log(this.form.subtotal);
        console.log(this.flete);
        const fle = this.form.subtotal * this.flete;
        return fle;
      },
    },
    seleccion: {
      set(value) {
        this.$store.dispatch('cobranza/setItemSelected', value);
      },
      get() {
        return this.selected;
      },
    },
    profile() {
      return Profile.get();
    },
  },
  methods: {
    ...mapActions('salesorder', ['cerrarFlete', 'showFlete']),
    ...mapMutations('salesorder', ['SHOWFlete']),
    ...mapActions('salesorder', ['clean']),
    data() {
      console.log(this.formId.split('-'));
      return {
        tasa: this.tasa,
        newflete: this.valorflete,
        /* eslint-disable-next-line eqeqeq */
        Crmid: (this.formId.split('-')).length == 1 ? this.formId.split('-') : (this.formId.split('-')).splice(0, (this.formId.split('-')).length - 1),
        user_id: this.profile.user.id,
        company_id: this.profile.organization.id,
        tipo: 'FL',
      };
    },
    valor() {
      this.valorflete = this.fletes;
      /* eslint-disable-next-line eqeqeq */
      if (this.des == 0) {
        this.valorflete = this.mot.toFixed(2);
        return this.valorflete;
      }
      return this.valorflete;
    },
    async PFlete() {
      this.response(await updateflete(this.data()));
    },
    response(api) {
      this.$store.commit('message/LOADING', true);
      try {
        this.$store.commit('message/URL', api.data.url);
        this.alert(api.data.message, 'success');
      } catch (error) {
        // eslint-disable-next-line no-alert
        this.$store.commit('message/URL', '');
        this.alert(error, 'error');
      }
      this.$store.commit('message/LOADING', false);
    },
    alert(message, type = 'info') {
      this.$store.commit('message/MESSAGE', message);
      this.$store.commit('message/TYPE', type);
      this.$store.commit('message/RELOAD', true);
      this.$store.dispatch('message/run');
    },
  },
  async created() {
    this.tasa = (await tasaBcv()).data;
    this.loading = false;
    this.monto = (this.form.subtotal * this.flete);
    console.log(this.monto);
  },
};
</script>
