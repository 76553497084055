<template>
  <v-app>
    <Toolbar/>
    <v-main>
    <v-container fluid class="mt-5 pt-5">
      <v-card>
        <v-card-title>
          <v-icon class='mr-2'> mdi-application </v-icon>
          {{profile.organization.name}} - Facturas Fletes
          <v-spacer></v-spacer>
          <v-col sm="7" class="pl-4">
            <v-autocomplete
              class="mt-0 pt-0 mx-4 pl-3"
              label="Clientes"
              :items="clients"
              item-text="name"
              item-value="name"
              @change="updateClient"
              v-model="search"
              color="grey darken-4">
            </v-autocomplete>
          </v-col>
        </v-card-title>
        <v-col sm="5">
            <v-btn
              title="Registrar"
              class="mx-4"
              tile
              color="grey lighten-2"
                  @click='showFlete(calcular)'>
              <v-icon dark>
                mdi-credit-card-plus
              </v-icon>
            </v-btn>
        </v-col>
        <v-card class="mx-auto" width="98%">
            <v-data-table
              v-model="selected"
              :headers="headersMaferca"
              :items="items"
              :search="search"
              :single-select="singleSelect"
              @input="enterSelect()"
              :loading="loading"
              sort-by="createdtime"
              :sort-desc = true
              loading-text="Cargando Datos"
              show-select
              item-key="salesorder_no"
              class="elevation-24"
              no-data-text="no hay datos"
              no-results-text = "Cliente sin Facturas"
              :header-props="{
                sortByText: 'Ordenar',
              }"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',
                'items-per-page-text': 'Documentos por página',
              }"
              multiSort
            >
            <template v-slot:[`item.sostatus`]='{ item }'>
              <v-chip :color='getColor(item.sostatus)' small text-color='white'>
                {{ item.sostatus }}
              </v-chip>
            </template>
            <template v-slot:top>
              <v-switch
                v-model="singleSelect"
                label="Selección simple"
                class="pa-3"
              ></v-switch>
            </template>

            </v-data-table>
          </v-card>
        <salesorderView></salesorderView>
        <SalesorderFlete/>
      </v-card>
    </v-container>
    </v-main>
    <SetLogout/>
    <Historicobcv/>
  </v-app>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapActions, mapState, mapMutations } from 'vuex';
// eslint-disable-next-line import/extensions
import axios from 'axios';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import {
  updatetasa,
} from '../../config/api/salesorder';
// eslint-disable-next-line import/extensions
import SalesorderFlete from './SalesorderFlete';
// eslint-disable-next-line import/extensions
import SalesorderView from './SalesorderView';
// eslint-disable-next-line import/extensions
import Toolbar from '../../components/Toolbar';
// eslint-disable-next-line import/extensions
import SetLogout from '../../components/SetLogout';
// eslint-disable-next-line import/extensions
import Historicobcv from '../../components/Historicobcv';

function groupBy(array, key) {
  const result = [];
  array.forEach((items) => {
    if (!result[items[key]]) {
      result.push(items[key]);
    }
  });

  return result.filter((value, index, self) => self.indexOf(value) === index);
}
export default {
  name: 'salesorder',
  components: {
    SalesorderView, Toolbar, SetLogout, SalesorderFlete, Historicobcv,
  },
  data: () => ({
    singleSelect: false,
    selected: [],
    search: '#$#$&%/&&(/(=',
    loading: true,
    itemSelected: null,
    dialog: false,
    headersMaferca: [
      { text: 'Pedido', value: 'salesorder_no' },
      { text: 'Rif', value: 'customerno' },
      { text: 'Cliente', value: 'name' },
      { text: 'Creado', value: 'createdtime' },
      { text: 'Zona', value: 'zone' },
      { text: 'Monto', value: 'total' },
      { text: 'Estatus', value: 'sostatus' },
      { text: 'Pedido SAP', value: 'nrosap' },
      { text: 'Factura SAP', value: 'factsap' },
      { text: 'Pedido Financiero', value: 'pedidoFinanciero' },
      { text: 'Pedido Flete', value: 'pedidoFlete' },
      { text: 'Tipo', value: 'type' },
    ],
    bootsap: true,
    referencia: [],
    monto: [],
  }),
  computed: {
    ...mapState('cobranza', ['clients']),
    ...mapState('salesorder', ['items']),
    profile() {
      return Profile.get();
    },
    listClients() {
      console.log(this.items);
      return groupBy(this.items, 'customer_name');
    },
    calcular() {
      let mot = 0;
      let ids = [];
      let pdd = [];
      const a = '-';
      const des = 0;
      const item = this.selected;
      this.selected.forEach((fac) => {
        mot += fac.total;
      });

      this.selected.forEach((fac) => {
        ids += fac.salesorderid + a;
      });

      this.selected.forEach((fac) => {
        pdd += fac.salesorder_no + a;
      });
      const data = {
        item, mot, des, pdd, ids,
      };
      console.log(mot);
      return data;
    },
  },
  methods: {
    ...mapMutations('message', ['MESSAGE', 'TYPE', 'LOADING']),
    ...mapActions('message', ['run']),
    ...mapActions('salesorder', ['data', 'show', 'showFlete']),
    getColor(sostatus) {
      switch (sostatus) {
        case 'Anulado':
          return 'black';
        case 'Factura Anulada':
          return 'orange';
        case 'FacturadoNE':
          return 'blue';
        default:
          return 'red';
      }
    },
    validar(permiso, accion) {
      let valor = false;
      permiso.forEach((permisos) => {
        if (permisos.accion === accion) {
          valor = true;
        }
      });
      return valor;
    },
    async sapUpdate(items) {
      this.response(await updatetasa(this.data(items)));
    },
    response(api) {
      this.$store.commit('message/LOADING', true);
      try {
        this.$store.commit('message/URL', api.data.url);
        this.alert(api.data.message, 'success');

        if (api.data.url.trim() !== '') {
          this.bootsap = false;
        }
      } catch (error) {
        // eslint-disable-next-line no-alert
        this.$store.commit('message/URL', '');
        this.alert(error, 'error');
      }
      this.$store.commit('message/LOADING', false);
    },
    response1(api) {
      this.$store.commit('message/LOADING', true);
      try {
        this.alert(api.data.message, 'success');
      } catch (error) {
        // eslint-disable-next-line no-alert
        this.$store.commit('message/URL', '');
        this.alert(error, 'error');
      }
      this.$store.commit('message/LOADING', false);
    },
    enterSelect() {
      this.$store.dispatch('cobranza/setItemSelected', this.selected);
    },
    async updateClient() {
      if (this.search !== '' && this.selected.length > 0) this.selected.splice(0, this.selected.length);
      this.$store.dispatch('cobranza/toAssignClient', this.search);
    },
    alert(message, type = 'info') {
      this.$store.commit('message/MESSAGE', message);
      this.$store.commit('message/TYPE', type);
      this.$store.commit('message/RELOAD', true);
      this.$store.dispatch('message/run');
    },
    /* eslint no-return-assign: "error" */
    tasabcv() {
      this.dialog = true;
      axios.get('https://s3.amazonaws.com/dolartoday/data.json')
        .then((response) => (this.tasa = response.data.USD.sicad2));
      // .then(this.tasa)
      console.log(this.tasa);
    },
  },
  async mounted() {
    /* eslint-disable-next-line eqeqeq */
    const permissions = this.profile.permissions.find((e) => e.accion == '1001');
    if (!permissions) {
      this.$router.push('notAuthorized');
    }
    try {
      this.$store.dispatch('cobranza/clients', {});
      this.$store.dispatch('salesorder/data', {}).then(() => {
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    } catch (error) {
      // eslint-disable-next-line no-alert
      this.alert(error);
    }
  },
};
</script>
