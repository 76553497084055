<template>
  <v-app>
    <Toolbar/>
    <v-main>
      <v-container fluid>
        <v-card>

          <v-card-title>
             <v-icon class="mr-2">
               mdi-archive-check-outline
              </v-icon>
              Productos Sensibles
            <v-spacer></v-spacer>

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

            <v-btn
              title="Modificar Limites"
              class="mx-4 mb-4"
              tile
              color="grey lighten-2"
              @click="openForm()"
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>

          <v-card class="mx-auto" width="98%">
            <v-data-table
              :headers="headers"
              :search="search"
              :items="item"
              :loading="item.length === 0"
              sort-by="id"
              class="elevation-24"
              no-data-text="no hay datos"
              :header-props="{
                sortByText: 'Ordenar',
              }"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',
                'items-per-page-text': 'organizaciones por página',
              }"
              multiSort
            >
            <template v-slot:[`item.acciones`]="{ item }">
              <v-btn icon v-show="Session.access('40003')">
                <v-icon
                  class='px-auto'
                  title="Eliminar Limite"
                  @click='deleteModal(item)'>
                  mdi-delete-outline
               </v-icon>
              </v-btn>
              <v-btn icon v-show="Session.access('40003')">
                <v-icon
                  class='px-auto'
                  title="Modificar Limite"
                  @click='openForm1(item)'>
                  mdi-circle-edit-outline
               </v-icon>
              </v-btn>
            </template>
            </v-data-table>
          </v-card>
          <LimitForm/>
        </v-card>
      </v-container>
    </v-main>
    <SetLogout/>
    <Historicobcv/>

    <v-dialog v-model="modalDelete" open-delay="" max-width="290" transition="dialog-top-transition">
      <v-card>
        <v-toolbar :color="profile.organization.color" dark height="50px">
          <v-icon class="mr-2">
            mdi-application
          </v-icon>
            <h1 class="title mt-2 white--text">
              Borrado de registro
            </h1>
          <v-spacer></v-spacer>
            <v-btn icon dark @click="modalDelete = false">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text class="pt-4 title">
          ¿Está seguro que desea borrar el registro seleccionado?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" outlined @click="modalDelete = false">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" outlined @click="deleteItem()">
            Sí, borrar!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapState, mapActions } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../../plugins/profile';
// eslint-disable-next-line import/extensions
import LimitForm from './LimitForm';
// eslint-disable-next-line import/extensions
import Toolbar from '../../../components/Toolbar';
// eslint-disable-next-line import/extensions
import SetLogout from '../../../components/SetLogout';
// eslint-disable-next-line import/extensions
import Historicobcv from '../../../components/Historicobcv';
// eslint-disable-next-line import/extensions
import { DeleteLimit } from '../../../config/api/products';

export default {
  name: 'ProductLimits',
  components: {
    LimitForm, Toolbar, SetLogout, Historicobcv,
  },
  data: () => ({
    search: '',
    itemSelected: null,
    modalDelete: false,
    modalUpdate: false,
    headers: [
      { text: 'Usuario', value: 'user_name', align: 'center' },
      { text: 'Asesor', value: 'user_asesor', align: 'center' },
      { text: 'Cod.', value: 'code', align: 'center' },
      { text: 'Producto', value: 'name', align: 'center' },
      { text: 'Cant.', value: 'limit1', align: 'center' },
      { text: '%', value: 'limit2', align: 'center' },
      { text: 'Validez', value: 'date_deprecated', align: 'center' },
      { text: 'Acciones', value: 'acciones', sortable: false },
    ],
    headers1: [
      { text: 'Usuario', value: 'user_name', align: 'center' },
      { text: 'Asesor', value: 'user_asesor', align: 'center' },
      { text: 'Cod.', value: 'code', align: 'center' },
      { text: 'Producto', value: 'name', align: 'center' },
      { text: 'Cant.', value: 'limit1', align: 'center' },
      { text: '%', value: 'limit2', align: 'center' },
      { text: 'Validez', value: 'date_deprecated', align: 'center' },
      { text: 'Acciones', value: 'acciones', sortable: false },
    ],
  }),
  computed: {
    ...mapState('ProductLimits', ['item', 'ejecutives', 'products', 'id', 'limit1', 'limit2']),
    Session() {
      return Profile;
    },
    profile() {
      return Profile.get();
    },
    formId: {
      set(value) {
        this.$store.dispatch('usuarios/toAssignId', value);
      },
      get() {
        return this.id;
      },
    },
    formLimit1: {
      set(value) {
        this.$store.dispatch('ProductLimits/toAssignLimit1', value);
      },
      get() {
        return this.limit1;
      },
    },
    formLimit2: {
      set(value) {
        this.$store.dispatch('ProductLimits/toAssignLimit2', value);
      },
      get() {
        return this.limit2;
      },
    },
  },
  methods: {
    ...mapActions('ProductLimits', ['data', 'toggleForm', 'updateLimits']),

    openForm() {
      if (this.ejecutives.length > 0 && this.products.length > 0) {
        this.toggleForm();
      }
    },
    openForm1() {
      this.toggleForm();
    },
    deleteModal(item) {
      this.modalDelete = true;
      this.itemSelected = item;
    },
    updateModal(item) {
      this.modalUpdate = true;
      this.itemSelected = item;
    },
    async deleteItem() {
      this.modalDelete = false;
      this.response(await DeleteLimit({ guid: this.itemSelected.id }));
    },
    async updateItem() {
      this.modalUpdate = false;
      // this.response(await updateLimits({ guid: this.itemSelected.id }));
    },
    response(api) {
      this.$store.commit('message/LOADING', true);
      try {
        this.alert(api.data.message, 'success');
        this.$store.dispatch('ProductLimits/data', {});
      } catch (error) {
        this.alert(error, 'error');
      }
      this.$store.commit('message/LOADING', false);
    },
    alert(message, type = 'info') {
      this.$store.commit('message/MESSAGE', message);
      this.$store.commit('message/TYPE', type);
      this.$store.commit('message/RELOAD', false);
      this.$store.dispatch('message/run');
    },
    data() {
      return {
        id: this.id,
        limit1: this.formLimit1,
        limit2: this.formLimit2,
      };
    },
  },
  created() {
    try {
      this.$store.dispatch('ProductLimits/data', {});
      this.$store.dispatch('ProductLimits/getEjecutives', {});
      this.$store.dispatch('ProductLimits/getProducts', {});
    } catch (error) {
      // eslint-disable-next-line
      alert(error);
    }
  },
};
</script>
