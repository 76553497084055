<template>
  <v-app>
    <Toolbar/>
    <v-main>
        <v-container fluid class="mt-5 pt-5">
          <v-card>
            <v-card-title>
              <v-icon class='mr-2'> mdi-application </v-icon>
              {{profile.organization.name}} - Grafica Pedidos
            </v-card-title>
            <v-row class="mt-2 pt-2">
                <v-col cols="12" sm="2" md="2" class="mt-0 pt-0">
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          readonly
                          label="Fecha Desde"
                          v-model="formDateDesde"
                          v-bind="attrs"
                          v-on="on"
                          type="date">
                        </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="formDateDesde"
                      :min="(new Date((new Date()).setMonth((new Date()).getMonth()-12))).toISOString().substr(0, 10)"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="2" md="2" class="mt-0 pt-0">
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        readonly
                        label="Fecha Hasta"
                        v-model="formDateHasta"
                        v-bind="attrs"
                        v-on="on"
                        type="date">
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="formDateHasta"
                      :min="(new Date((new Date()).setMonth((new Date()).getMonth()-12))).toISOString().substr(0, 10)"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-btn icon>
                    <v-icon
                      title='Generar'
                      color='blue'
                      @click="GenerGrafica()"
                    >
                      mdi-file-find
                    </v-icon>
                  </v-btn>
                </v-col>
            </v-row>
            <v-card class='mx-auto' width='100%'>
              <v-chart class="chart" :option="formBara" />
                <!--<div class="col-sm-6 col-md-8 col-lg-12 col-xl-12">
                  <v-chart class="chart" :option="formPae" />
                  <v-chart class="chart" :option="formLine" />
                  <v-chart class="chart" :option="formBaraInverPedidoMonto" />
                </div> -->
              <v-chart class="chart" :option="formBaraInverPedido" />
            </v-card>
          </v-card>
        </v-container>
    </v-main>
    <SetLogout/>
    <Historicobcv/>
  </v-app>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapState } from 'vuex';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { BarChart, PieChart, LineChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  ToolboxComponent,
  LegendComponent,
  GridComponent,
  // Dataset
  DatasetComponent,
  // Built-in transform (filter, sort)
  TransformComponent,
} from 'echarts/components';
import VChart from 'vue-echarts';
import { LabelLayout, UniversalTransition } from 'echarts/features';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import Toolbar from '../../components/Toolbar';
// eslint-disable-next-line import/extensions
import SetLogout from '../../components/SetLogout';
// eslint-disable-next-line import/extensions
import Historicobcv from '../../components/Historicobcv';
import { grafica } from '../../config/api/presupuestos';

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  ToolboxComponent,
  LegendComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  BarChart,
  LineChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
]);

export default {
  name: 'Graficas',
  components: {
    Toolbar, VChart, SetLogout, Historicobcv,
  },
  data: () => ({
    desde: new Date((new Date()).setMonth((new Date()).getMonth() - 1))
      .toLocaleDateString('en-GB')
      .split('/')
      .reverse()
      .join('-'),
    hasta: new Date().toLocaleDateString('en-GB')
      .split('/')
      .reverse()
      .join('-'),
    dataMes: [
      { value: 0, text: 'Enero' },
      { value: 1, text: 'Febrero' },
      { value: 2, text: 'Marzo' },
      { value: 3, text: 'Abril' },
    ],
    option: {},
    option2: {
    },
    pae: {},
    line: {},
    bar: [],
    bara: [],
    barainvertida: [],
    barainvertidapedido: [],
    barainvertidapedidomonto: [],

  }),
  methods: {
    data() {
      return {
        desde: this.formDateDesde,
        hasta: this.formDateHasta,
        company_id: this.profile.organization.id,
      };
    },
    async GenerGrafica() {
      let i = 0;
      const dato = (await grafica(this.data())).data;
      console.log(dato);
      dato.forEach((gra) => {
        this.option[i] = gra.original;
        i += 1;
      });
      this.bar = this.option['2'];
      this.bara = this.option['3'];
      this.barainvertida = this.option['4'];
      this.barainvertidapedido = this.option['5'];
      this.barainvertidapedidomonto = this.option['6'];
    },
  },
  computed: {
    ...mapState('userProfile', ['profile']),
    formDateDesde: {
      set(param) {
        this.desde = param;
      },
      get() {
        return this.desde;
      },
    },
    formDateHasta: {
      set(param) {
        this.hasta = param;
      },
      get() {
        return this.hasta;
      },
    },
    formPae: {
      get() {
        return this.pae;
      },
    },
    formLine: {
      get() {
        return this.line;
      },
    },
    formBar: {
      get() {
        return this.bar;
      },
    },
    formBara: {
      get() {
        return this.bara;
      },
    },
    formBaraInver: {
      get() {
        return this.barainvertida;
      },
    },
    formBaraInverPedido: {
      get() {
        return this.barainvertidapedido;
      },
    },
    formBaraInverPedidoMonto: {
      get() {
        return this.barainvertidapedidomonto;
      },
    },
    profile() {
      return Profile.get();
    },
  },
  async mounted() {
    let i = 0;
    const dato = (await grafica(this.data())).data;
    dato.forEach((gra) => {
      this.option[i] = gra.original;
      i += 1;
    });
    this.pae = this.option['0'];
    this.line = this.option['1'];
    this.bar = this.option['2'];
    this.bara = this.option['3'];
    this.barainvertida = this.option['4'];
    this.barainvertidapedido = this.option['5'];
    this.barainvertidapedidomonto = this.option['6'];
    /* let j = 0;
    dato[2][0].forEach((eje) => {
      this.bara[j] = eje.ejecutivo;
      j += 1;
    });
    let contador = 1;
    for (let p = 0; p < this.bara.length; p += 1) {
      if (this.bara[p + 1] === this.bara[p]) {
        contador += 1;
      } else {
        this.X.push(this.bara[p]);
        this.Y.push(contador);
        contador = 1;
      }
    }
    this.bar = {
      title: {
        text: `dede ${dato[2]['1']} a ${dato[2]['2']}`,
        left: 'center',
      },
      tooltip: {
        show: true,
        trigger: 'axis',
      },
      toolbox: {
        feature: {
          saveAsImage: {},
        },
      },
      xAxis: {
        type: 'category',
        data: this.X,
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: this.Y,
          type: 'bar',
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)',
          },
        },
      ],
    }; */
  },
};
</script>

<style scoped>
.chart {
  width: 100%;
  height: 400px;
  min-height: 400px;
};
</style>
