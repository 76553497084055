<template>
  <v-app>
    <Toolbar/>
    <v-main>
      <v-container fluid>
        <v-card>

          <v-card-title>
             <v-icon class="mr-2">
               mdi-cash
              </v-icon>
              {{profile.organization.name}} - Cobranza
            <v-spacer></v-spacer>
          </v-card-title>
          <v-row>
          <v-col sm="5">
            <v-btn
              v-show="validar(profile.permissions,'10002')"
              title="Registrar Cobranza"
              class="mx-4"
              tile
              color="grey lighten-2"
              @click="openFormCobranza()">
              <v-icon dark>
                mdi-cash-multiple
              </v-icon>
            </v-btn>
            <v-btn
              v-show="validar(profile.permissions,'10002')"
              title="Registrar Anticipo"
              class="mx-4"
              tile
              color="grey lighten-2"
              @click="openAnticipo()">
              <v-icon dark>
                mdi-credit-card-plus
              </v-icon>
            </v-btn>
          </v-col>
          <v-col sm="7" class="pl-4">
            <v-autocomplete
              class="mt-0 pt-0 mx-4 pl-3"
              label="Clientes"
              :items="clients"
              item-text="name"
              item-value="name"
              @change="updateClient"
              v-model="search"
              color="grey darken-4">
            </v-autocomplete>
          </v-col>
          </v-row>
          <v-card class="mx-auto" width="98%">
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="item"
              :search="search"
              :single-select="singleSelect"
              @input="enterSelect()"
              :loading="loading"
              sort-by="date_doc"
              :sort-desc = true
              loading-text="Cargando Datos"
              show-select
              item-key="fact_key"
              class="elevation-24"
              no-data-text="no hay datos"
              no-results-text = "Cliente sin documentos"
              :header-props="{
                sortByText: 'Ordenar',
              }"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',
                'items-per-page-text': 'Documentos por página',
              }"
              multiSort
            >

            <template v-slot:top>
              <v-switch
                v-model="singleSelect"
                label="Selección simple"
                class="pa-3"
              ></v-switch>
            </template>

            </v-data-table>
          </v-card>
          <CobranzaForm/>
          <AnticipoForm/>
        </v-card>
      </v-container>
    </v-main>
    <SetLogout/>
   <Historicobcv/>
  </v-app>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapState, mapActions, mapMutations } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import CobranzaForm from './CobranzaForm';
// eslint-disable-next-line import/extensions
import AnticipoForm from './AnticipoForm';
// eslint-disable-next-line import/extensions
import Toolbar from '../../components/Toolbar';
// eslint-disable-next-line import/extensions
import SetLogout from '../../components/SetLogout';
// eslint-disable-next-line import/extensions
import Historicobcv from '../../components/Historicobcv';

function groupBy(array, key) {
  const result = [];
  array.forEach((item) => {
    if (!result[item[key]]) {
      result.push(item[key]);
    }
  });

  return result.filter((value, index, self) => self.indexOf(value) === index);
}
export default {
  name: 'cobranza',
  components: {
    CobranzaForm, AnticipoForm, Toolbar, SetLogout, Historicobcv,
  },
  data: () => ({
    singleSelect: false,
    selected: [],
    search: '#$#$&%/&&(/(=',
    loading: true,
    headers: [
      { text: 'Documento', value: 'document_fin', align: 'start' },
      { text: 'Clase Doc.', value: 'class_doc_txt', align: 'start' },
      { text: 'Factura', value: 'fact_sap', align: 'start' },
      { text: 'Entrega', value: 'entrega', align: 'start' },
      { text: 'Ejercicio', value: 'year_fiscal', align: 'start' },
      { text: 'Cliente', value: 'customer_name', align: 'start' },
      { text: 'Fecha', value: 'date_doc', align: 'start' },
      { text: 'Importe', value: 'doc_total', align: 'start' },
    ],
  }),
  computed: {
    ...mapState('cobranza', ['item', 'clients']),
    profile() {
      return Profile.get();
    },
    listClients() {
      return groupBy(this.item, 'customer_name');
    },
  },
  methods: {
    ...mapMutations('message', ['MESSAGE', 'TYPE', 'LOADING']),
    ...mapActions('message', ['run']),
    ...mapActions('cobranza', ['data', 'addCobranza', 'addAnticipo']),
    enterSelect() {
      for (let i = 0; i < this.selected.length; i += 1) {
        this.selected[i].load = 0;
        this.selected[i].mount = 0;
        this.selected[i].text = '';
        this.selected[i].reference = '';
        this.item.forEach((document) => {
          if (document.fact_key === this.selected[i].fact_key || document.document_fin === this.selected[i].fact_key.substring(0, 10)) {
            this.selected[i].mount += parseFloat(document.doc_total);
          }
        });
      }
      this.$store.dispatch('cobranza/setItemSelected', this.selected);
    },
    formImporte() {
      let monto = 0;

      this.selected.forEach((selected) => {
        this.item.forEach((document) => {
          if (document.fact_key === selected.fact_key || document.document_fin === selected.fact_key.substring(0, 10)) {
            monto += parseFloat(document.doc_total);
          }
        });
      });

      return (this.selected.length > 0) ? monto.toFixed(2) : 0;
    },
    updateClient() {
      if (this.search !== '' && this.selected.length > 0) this.selected.splice(0, this.selected.length);
      this.$store.dispatch('cobranza/toAssignClient', this.search);
    },
    alert(message) {
      this.$store.commit('message/MESSAGE', message);
      this.$store.commit('message/TYPE', 'info');
      this.$store.dispatch('message/run');
    },
    openFormCobranza() {
      let docs = 0;
      const monto = [];

      if (this.search.trim().length > 0 && this.selected.length > 0) {
        this.selected.forEach((selected) => {
          docs = 0;
          this.item.forEach((document) => {
            if (document.fact_key === selected.fact_key || document.document_fin === selected.fact_key.substring(0, 10)) {
              docs += parseFloat(document.doc_total);
            }
          });
          if (selected.cme !== '1') {
            monto.push(docs.toFixed(2));
          }
        });

        if (parseFloat(this.formImporte()) === 0) {
          this.alert('Los documentos seleccionados contienen una cobranza cero');
        } else if (parseFloat(this.formImporte()) < 0) {
          this.alert('El documento seleccionado no está disponible para la cobranza');
        } else if (monto.find((el) => el === 0) <= 0) {
          this.alert('Alguno de los documentos seleccionados contiene una cobranza cero');
        } else if (monto.find((el) => el < 0) <= 0) {
          this.alert('Alguno de los documentos seleccionados no está disponible para la cobranza');
        } else if (parseFloat(this.formImporte()) > 0) {
          this.addCobranza();
        }
      } else {
        this.alert('Debe seleccionar un cliente y posterior los documentos a generar la cobranza');
      }
    },
    openAnticipo() {
      if (this.search.trim().length > 0 && this.search.trim() !== '#$#$&%/&&(/(=') {
        this.addAnticipo();
      } else {
        this.alert('Debe seleccionar un cliente para generar el anticipo');
      }
    },
    validar(permiso, accion) {
      let valor = false;
      permiso.forEach((permisos) => {
        if (permisos.accion === accion) {
          valor = true;
        }
      });
      return valor;
    },
  },
  created() {
    try {
      this.$store.dispatch('cobranza/clients', {});
      this.$store.dispatch('cobranza/data', {}).then(() => {
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    } catch (error) {
      // eslint-disable-next-line no-alert
      this.alert(error);
    }
  },
};
</script>
