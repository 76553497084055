<template>
  <div class="containers">
    <a href="#">Hola</a>
      <div class="forms-containers">
        <div class="signin-signup">
          <form class="sign-in-form">
            <v-alert
              :value="show"
              color="red"
              dark
              border="top"
              transition="scale-transition"
            >
            {{message}}
            </v-alert>
            <h2>Iniciar Sesión</h2>
            <div class="input-field">
              <i class="fas fa-user"></i>
              <input type="text" placeholder="Usuario" v-model="form.user_name"/>
            </div>
            <div class="input-field">
              <i class="fas fa-lock"></i>
              <input
                autocomplete="on"
                type="password"
                placeholder="Contraseña"
                v-model="form.password"
                @keyup.enter="login(form)"
                @change="hassPassword"/>
            </div>
            <v-switch class="mt-0 mb-4"
              v-model="form.remember"
              label="Mantener mi sesion abierta"
              color="primary"
              value="false"
              hide-details
            ></v-switch>
            <v-btn
              v-if="validForm"
              :loading="loading"
              rounded
              color="#4481eb"
              dark class="btn"
              @click="login(form)"
              large
            >
              Ingresar
            </v-btn>
            <v-btn
              v-else
              rounded
              disabled
            >
              Ingresar
            </v-btn>
          </form>
        </div>
      </div>

      <div class="panels-containers">
        <div class="panel left-panel">
          <div class="content">
            <h1>Sistema CRM</h1>
            <h4>Bienvenido</h4>
          </div>
          <div>
          <img src="images/log.svg" class="image" alt="" />
          <a href="https://www.sistemacrm.com.ve/version/last_app.apk"
          style="color:black;">APP de CRM para dispositivos Android</a>
          <br/>
          <!--<a href="https://www.sistemacrm.com.ve/version/cnr/cnr.apk"
          style="color:blue;">APP de CNR para dispositivos Android</a>-->
          </div>
        </div>
      </div>
    <v-dialog
      v-model="company"
      width="80%"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Seleccionar Sociedad
        </v-card-title>
        <v-container class="text-center">
          <v-row dense>
            <v-col
              v-for="item in societies"
              :key="item.name"
              cols="l2"
              sm="12"
              md="6"
            >
              <v-card flat class="mt-2">
                <v-btn text class="mx-auto my-4">
                  <v-img
                    @click="choose(item)"
                    :src="item.photo"
                    height="auto"
                    width="250"
                  >
                  </v-img>
                </v-btn>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapActions, mapMutations } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../plugins/profile';
// eslint-disable-next-line import/extensions
import { authenticated, saveAuthProfile } from '../config/api/login';
// eslint-disable-next-line import/extensions
import profile from '../config/api/profile';
// eslint-disable-next-line import/extensions
import { versionCurrent } from '../middleware/version';

export default {
  name: 'login',
  components: {
  },
  data: () => ({
    form: {
      user_name: '',
      password: '',
      remember: false,
    },
    message: '',
    show: false,
    loading: false,
    company: false,
    societies: [],
  }),
  computed: {
    validForm() {
      return (this.form.user_name.length > 3 && this.form.password.length > 3);
    },
  },
  methods: {
    ...mapMutations('message', ['MESSAGE', 'TYPE']),
    ...mapActions('message', ['run']),

    async login() {
      try {
        this.loading = true;
        const response = await authenticated(this.form);

        if (response.status !== 203) {
          // Usuario multiempresa
          if (response.status === 202) {
            this.company = true;
            this.societies = response.data.societies;
            this.form.organization = this.societies[0].id;
          }

          // Proceso de login procesado (registrar sesión)
          const responsePerfil = await profile(this.form);

          if (this.societies.length > 0) {
            responsePerfil.data.societies = this.societies;
          } else {
            responsePerfil.data.societies = {};
          }

          // se registra fecha/hora de inicio de sesion (expiración)
          responsePerfil.data.update = new Date();
          // se registra la version actual de compilación
          responsePerfil.data.version = versionCurrent();
          // mantener la sesion abierta hasta la expiracion?
          responsePerfil.data.remember = this.form.remember;

          // se generea una clave de sesión
          responsePerfil.data.sesionkey = (Math.random() * (new Date()).getTime()).toString(24).substring(2);
          if (response.status === 200) {
            Profile.set(responsePerfil.data);
            // llamar al api para que guarde
            await saveAuthProfile().then(() => {
              this.$router.push('/');
            });
          }
        }

        // Hubo un problema
        if (response.status === 203) {
          this.$store.commit('message/MESSAGE', response.data.message);
          this.$store.commit('message/TYPE', 'info');
          this.$store.dispatch('message/run');
        }

        this.loading = false;
      } catch (error) {
        this.$store.commit('message/MESSAGE', error);
        this.$store.commit('message/TYPE', 'error');
        this.$store.dispatch('message/run');
      }
    },
    hassPassword() {
      this.form.password = btoa(this.form.password);
    },
    choose(item) {
      this.form.organization = item.id;
      this.login();
    },
  },
  beforeCreate() {
    if (!Profile.check()) {
      Profile.clear();
    } else {
      this.$router.push('pedidos');
    }
  },
};
</script>

<style>

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
input {
  font-family: sans-serif;
}

.containers {
  position: relative;
  width: 100%;
  background-color: #fff;
  min-height: 100vh;
  overflow: hidden;
}

.forms-containers {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.signin-signup {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 75%;
  width: 50%;
  transition: 1s 0.7s ease-in-out;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
}

form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0rem 5rem;
  transition: all 0.2s 0.7s;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

form.sign-up-form {
  opacity: 0;
  z-index: 1;
}

form.sign-in-form {
  z-index: 2;
}

.title {
  color: #444;
  margin-bottom: 10px;
}

.input-field {
  max-width: 380px;
  width: 100%;
  background-color: #f0f0f0;
  margin: 10px 0;
  height: 55px;
  border-radius: 55px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
  position: relative;
}

.input-field i {
  text-align: center;
  line-height: 55px;
  color: #acacac;
  transition: 0.5s;
  font-size: 1.1rem;
}

.input-field input {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 600;
  font-size: 1.1rem;
  color: #333;
}

.input-field input::placeholder {
  color: #aaa;
  font-weight: 500;
}

.social-text {
  padding: 0.7rem 0;
  font-size: 1rem;
}

.social-media {
  display: flex;
  justify-content: center;
}

.social-icon {
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.45rem;
  color: #333;
  border-radius: 50%;
  border: 1px solid #333;
  text-decoration: none;
  font-size: 1.1rem;
  transition: 0.3s;
}

.social-icon:hover {
  color: #4481eb;
  border-color: #4481eb;
}

.btn {
  width: 150px;
  background-color: #4481eb;
  border: none;
  outline: none;
  height: 49px;
  border-radius: 49px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
}

.btn:hover {
  background-color: #4481eb;
}
.panels-containers {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.containers:before {
  content: "";
  position: absolute;
  height: 2000px;
  width: 2000px;
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  background-image: linear-gradient(-45deg, #4481eb 0%, #04befe 100%);
  transition: 1.8s ease-in-out;
  border-radius: 50%;
  z-index: 6;
}

.image {
  width: 100%;
  transition: transform 1.1s ease-in-out;
  transition-delay: 0.4s;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  text-align: center;
  z-index: 6;
}

.left-panel {
  pointer-events: all;
  padding: 3rem 17% 2rem 12%;
}

.right-panel {
  pointer-events: none;
  padding: 3rem 12% 2rem 17%;
}

.panel .content {
  color: #fff;
  transition: transform 0.9s ease-in-out;
  transition-delay: 0.6s;
}

.panel h3 {
  font-weight: 600;
  line-height: 1;
  font-size: 1.5rem;
}

.panel p {
  font-size: 0.95rem;
  padding: 0.7rem 0;
}

.btn.transparent {
  margin: 0;
  background: none;
  border: 2px solid #fff;
  width: 130px;
  height: 41px;
  font-weight: 600;
  font-size: 0.8rem;
}

.right-panel .image,
.right-panel .content {
  transform: translateX(800px);
}

/* ANIMATION */

.containers.sign-up-mode:before {
  transform: translate(100%, -50%);
  right: 52%;
}

.containers.sign-up-mode .left-panel .image,
.containers.sign-up-mode .left-panel .content {
  transform: translateX(-800px);
}

.containers.sign-up-mode .signin-signup {
  left: 25%;
}

.containers.sign-up-mode form.sign-up-form {
  opacity: 1;
  z-index: 2;
}

.containers.sign-up-mode form.sign-in-form {
  opacity: 0;
  z-index: 1;
}

.containers.sign-up-mode .right-panel .image,
.containers.sign-up-mode .right-panel .content {
  transform: translateX(0%);
}

.containers.sign-up-mode .left-panel {
  pointer-events: none;
}

.containers.sign-up-mode .right-panel {
  pointer-events: all;
}

@media (max-width: 870px) {
  .containers {
    min-height: 800px;
    height: 100vh;
  }
  .signin-signup {
    width: 100%;
    top: 80%;
    transform: translate(-50%, -100%);
    transition: 1s 0.8s ease-in-out;
  }

  .signin-signup,
  .containers.sign-up-mode .signin-signup {
    left: 50%;
  }

  .panels-containers {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }

  .panel {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 8%;
    grid-column: 1 / 2;
  }

  .right-panel {
    grid-row: 3 / 4;
  }

  .left-panel {
    grid-row: 1 / 2;
  }

  .image {
    width: 200px;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.6s;
  }

  .panel .content {
    padding-right: 15%;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.8s;
  }

  .panel h3 {
    font-size: 1.2rem;
  }

  .panel p {
    font-size: 0.7rem;
    padding: 0.5rem 0;
  }

  .btn.transparent {
    width: 110px;
    height: 35px;
    font-size: 0.7rem;
  }

  .containers:before {
    width: 1500px;
    height: 1500px;
    transform: translateX(-50%);
    left: 30%;
    bottom: 68%;
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  }

  .containers.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 32%;
    right: initial;
  }

  .containers.sign-up-mode .left-panel .image,
  .containers.sign-up-mode .left-panel .content {
    transform: translateY(-300px);
  }

  .containers.sign-up-mode .right-panel .image,
  .containers.sign-up-mode .right-panel .content {
    transform: translateY(0px);
  }

  .right-panel .image,
  .right-panel .content {
    transform: translateY(300px);
  }

  .containers.sign-up-mode .signin-signup {
    top: 5%;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 570px) {
  form {
    padding: 0 1.5rem;
  }

  .image {
    display: none;
  }
  .panel .content {
    padding: 0.5rem 1rem;
  }
  .containers {
    padding: 1.5rem;
  }

  .containers:before {
    bottom: 72%;
    left: 50%;
  }

  .containers.sign-up-mode:before {
    bottom: 28%;
    left: 50%;
  }
}
</style>
