import {
  cobranza, banks, clients, add, getcobranzaP, anularCobranza, updateCobranza, textC, view, update,
} from '../../config/api/cobranza';

export default {

  name: 'cobranza',

  namespaced: true,

  state: {
    // PRINCIPAL
    item: [], // Documentos de la BSID
    banks: [], // Datos de bancos
    clients: [], // Datos de clientes
    payments: [], // Registros de pagos tipeados
    payItem: [], // Registro de pago
    selected: [], // Documentos seleccionados para la cobranza
    // FORMULARIO
    show: false, // Mostrar formulario de Cobranza
    show1: false, // Mostrar formulario de Anticipo
    showPay: false, // Mostrar formulario de Pago
    showdisc: false, // Mostrar formulario de Descuento
    showotrospagos: false, // Mostrar formulario de otras formas de pago
    title: '',
    //  CAMPOS DE FORMULARIO
    accountid: '',
    client: '',
    account_no: '',
    rif: null,
    //  DATOS SELECCIONADOS
    formData: [],

    // DATOS PROCESAR COBRANZA

    // FORMULARIO SHOWPC
    showPc: false,
    showPd: false, // Mostra formulario de Descuento
    showEdit: false,
    showSD: false,
    Check_pos: false,
    collectionid: '',
    Cliente: '',
    Cobranza: '',
    Estatus: '',
    Docsap: '',
    Total: 0.0,
    Referencia: '',
    Observacion: '',
    Anticipo: 0,
    FechaCotabilizacion: '',
    Discount: 0.0,
    Doc: [],
    Banco: [],
    id: '',
    DescDoc: [], // Registros de descuentos
    pase: 0,
    posselected: 0, // posicion a la que se aplica el descuento
  },
  mutations: {
    // PRINCIPAL
    SET_DATA(state, parameter) {
      state.item = parameter.data;
    },
    SET_BANKS(state, parameter) {
      state.banks = parameter.data;
    },
    SET_CLIENTS(state, parameter) {
      state.clients = parameter.data;
    },
    SET_SELECTED(state, parameter) {
      state.selected = parameter;
    },
    SET_POSSELECTED(state, parameter) {
      state.posselected = parameter;
    },
    SET_ITEMPAY(state, parameter) {
      state.payItem = parameter;
    },
    SET_IS_SHOWEDIT(state, payload) {
      state.showEdit = payload;
    },
    SET_IS_SHOWSD(state, payload) {
      state.showSD = payload;
    },
    // FORMULARIO
    SHOW(state) {
      state.show = !state.show;
    },
    SHOW1(state) {
      state.show1 = !state.show1;
    },
    SHOWPAY(state) {
      state.showPay = !state.showPay;
    },
    SHOWDISC(state) {
      state.showdisc = !state.showdisc;
    },
    SHOWOTROPAGO(state) {
      state.showotrospagos = !state.showotrospagos;
    },
    SET_TITLE(state, parameter) {
      state.title = parameter;
    },
    SHOWPC(state) {
      state.showPc = !state.showPc;
    },
    SHOWPD(state) {
      state.showPd = !state.showPd;
    },

    // CAMPOS DE FORMULARIO
    SET_CLIENT(state, parameter) {
      state.client = parameter;
    },
    SET_RIF(state, parameter) {
      state.account_no = parameter;
    },
    SET_DATA_CLIENT(state, parameter) {
      state.Cliente = parameter;
    },
    SET_DATA_RIF(state, parameter) {
      state.account_no = parameter.data;
    },
    SET_DATA_COBRANZA(state, parameter) {
      state.Cobranza = parameter;
    },
    SET_ID_COBRANZA(state, parameter) {
      state.id = parameter;
    },
    SET_TOTAL(state, parameter) {
      state.Total = parameter;
    },
    SET_CME(state, parameter) {
      state.Anticipo = parameter;
    },
    SET_CHECK(state, parameter) {
      state.Check_pos = parameter;
      console.log(parameter);
    },
    SET_DATA_DOC(state, parameter) {
      state.Doc = parameter;
    },
    SET_BANCO(state, payload) {
      state.Banco = payload;
    },
    SET_REFERENCIA(state, parameter) {
      state.Referencia = parameter;
    },
    SET_OBSERVACION(state, parameter) {
      state.Observacion = parameter;
    },
    SET_FECHA(state, parameter) {
      state.FechaCotabilizacion = parameter;
    },
    SET_ESTATUS(state, parameter) {
      state.Estatus = parameter;
    },
    SET_DOCSAP(state, parameter) {
      state.Docsap = parameter;
    },
    // SELECCIONADO
    SET_FORM_DATA(state, parameter) {
      state.formData = parameter.data;
    },
    SET_COBRANZA_DATA(state, parameter) {
      state.Cobranza = parameter.data;
    },
  },
  actions: {
    // PRINCIPAL
    async data({ commit }, parameter) {
      commit('SET_DATA', await cobranza(parameter));
    },
    async banks({ commit }, parameter) {
      commit('SET_BANKS', await banks(parameter));
    },
    async clients({ commit }, parameter) {
      commit('SET_CLIENTS', await clients(parameter));
    },
    async add(parameter) {
      await add(parameter);
    },
    // FORMULARIO
    setItemSelected({ commit }, parameter) {
      commit('SET_SELECTED', parameter);
    },
    setItemPay({ commit }, parameter) {
      commit('SET_ITEMPAY', parameter);
    },
    setItemPaySelect({ commit }, parameter) {
      commit('SET_ITEMPAYSELECT', parameter);
    },
    addCobranza({ commit }) {
      commit('SHOW');
      commit('SET_TITLE', 'Registrar');
    },
    addAnticipo({ commit }) {
      commit('SHOW1');
      commit('SET_TITLE', 'Registrar');
    },
    addPayment({ commit }) {
      commit('SHOWPAY');
      commit('SET_TITLE', 'Agregar');
    },
    /* addDiscount({ commit }) {
      commit('SHOWDISC');
      commit('SET_TITLE', 'Descuento');
    }, */
    showCobranzaDescuento({ commit }) {
      commit('SHOWDISC');
      commit('SET_TITLE', 'Descuento');
    },
    setPosSelected({ commit }, parameter) {
      commit('SET_POSSELECTED', parameter);
    },
    showCobranzaOtrosPagos({ commit }) {
      commit('SHOWOTROPAGO');
      commit('SET_TITLE', 'Otros Pagos');
    },
    async showCobranza({ commit }, parameter) {
      commit('SHOWPC');
      commit('SET_IS_SHOWEDIT', false);
      commit('SET_IS_SHOWSD', false);
      commit('SET_TITLE', 'Visualizar Cobranza');
      commit('SET_DATA_CLIENT', parameter.Cliente);
      commit('SET_DATA_COBRANZA', parameter.Cobranza);
      commit('SET_TOTAL', parameter.Total);
      commit('SET_ESTATUS', parameter.Estatus);
      commit('SET_DOCSAP', parameter.Docsap);
      commit('SET_REFERENCIA', parameter.Referencia);
      // commit('SET_OBSERVACION', parameter.Observacion);
      commit('SET_FECHA', parameter.FechaCotabilizacion);
      commit('SET_CME', parameter.Anticipo);
      const requests = {
        Cobranza: parameter.Cobranza,
      };
      commit('SET_BANCO', (await view(requests)).data);
      commit('SET_DATA_DOC', (await update(requests)).data);
      commit('SET_OBSERVACION', (await textC(requests)).data);
    },
    async editCobranza({ commit }, parameter) {
      commit('SHOWPC');
      commit('SET_IS_SHOWEDIT', true);
      commit('SET_IS_SHOWSD', false);
      commit('SET_CHECK', parameter.Check_pos);
      commit('SET_TITLE', 'Editar Cobranza');
      commit('SET_DATA_CLIENT', parameter.Cliente);
      commit('SET_DATA_COBRANZA', parameter.Cobranza);
      commit('SET_TOTAL', parameter.Total);
      commit('SET_ESTATUS', parameter.Estatus);
      commit('SET_DOCSAP', parameter.Docsap);
      commit('SET_REFERENCIA', parameter.Referencia);
      // commit('SET_OBSERVACION', parameter.Observacion);
      commit('SET_FECHA', parameter.FechaCotabilizacion);
      commit('SET_CME', parameter.Anticipo);
      const requests = {
        Cobranza: parameter.Cobranza,
      };
      commit('SET_BANCO', (await view(requests)).data);
      commit('SET_DATA_DOC', (await update(requests)).data);
      commit('SET_OBSERVACION', (await textC(requests)).data);
    },
    async cobranzaData({ commit }, parameter) {
      commit('SET_FORM_DATA', await getcobranzaP(parameter));
    },
    async anular({ commit }, parameter) {
      commit('SET_DATA_COBRANZA', await anularCobranza(parameter));
      commit('SET_FORM_DATA', await getcobranzaP(parameter));
    },
    async update({ commit }, parameter) {
      commit('SET_ID_COBRANZA', await updateCobranza(parameter));
    },
    closeCobranza({ commit, state }) {
      commit('SHOW', state.show);
    },
    closeAnticipo({ commit, state }) {
      commit('SHOW1', state.show1);
    },
    closePayment({ commit, state }) {
      commit('SHOWPAY', state.showPay);
    },
    closeOtrosPagos({ commit, state }) {
      commit('SHOWOTROPAGO', state.showotrospagos);
    },
    closeDescuento({ commit, state }) {
      commit('SHOWDISC', state.showdisc);
      // commit('SET_SELECTED', []);
    },
    toAssignClient({ commit }, parameter) {
      commit('SET_CLIENT', parameter);
    },
    toAssignRif({ commit }, parameter) {
      commit('SET_RIF', parameter);
    },
    async closeShow({ commit, state }) {
      commit('SHOWPC', state.showPc);
      commit('SET_FORM_DATA', await getcobranzaP(state));
      commit('SET_IS_SHOWSD', false);
      commit('SET_SELECTED', []);
    },
    ver({ commit }) {
      commit('SET_IS_SHOWSD', true);
    },
    close({ commit }) {
      commit('SET_IS_SHOWSD', false);
    },
    toAssignCobranza({ commit }, parameter) {
      commit('SET_COBRANZA_DATA', parameter);
    },
    toReferencia({ commit }, parameter) {
      commit('SET_REFERENCIA', parameter);
    },
    toObservacion({ commit }, parameter) {
      commit('SET_OBSERVACION', parameter);
    },
    toFecha({ commit }, parameter) {
      commit('SET_FECHA', parameter);
    },
  },
};
