<template>
  <v-app>
    <Toolbar/>
    <v-main>
      <v-container fluid>
        <v-card>
          <v-card-title>
             <v-icon class="mr-2">
               mdi-application
              </v-icon>
              {{profile.organization.name}} - Reportes Detallados
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <template>
            <v-container fluid>
              <v-row>
                <v-col sm="2" class="mt-4 pt-0">
                  <v-autocomplete
                    class="mt-0 pt-0"
                    v-model="Ejecutivo"
                    :items="ejecutivos"
                    label="Ejecutivo"
                    color="grey darken-4"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col sm="2" class="mt-4 pt-0">
                  <v-autocomplete
                    class="mt-0 pt-0"
                    v-model="estatu"
                    :items="estatus"
                    label="Estatus del Pedido"
                    color="grey darken-4"
                  >
                  </v-autocomplete>
                </v-col>
                <!--<v-col sm="2,4" class="mt-0 pt-0">
                  <v-text-field class="mt-0 pt-0"
                    label="Fecha Desde Año-Mes-Día"
                    v-model="desde"
                    color="grey darken-4"
                    counter="10">
                  </v-text-field>
                </v-col>
                <v-col sm="2,4" class="mt-0 pt-0">
                  <v-text-field class="mt-0 pt-0"
                    label="Fecha Hasta Año-Mes-Día"
                    v-model="hasta"
                    color="grey darken-4"
                    counter="10">
                  </v-text-field>
                </v-col>-->
                  <v-col sm="2" class="mt-0 pt-0">
                  <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        readonly
                        label="Fecha Desde"
                        v-model="formDateDesde"
                        v-bind="attrs"
                        v-on="on"
                        type="date">
                      </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formDateDesde"
                    :min="(new Date((new Date()).setMonth((new Date()).getMonth()-36))).toISOString().substr(0, 10)"
                  ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col sm="2" class="mt-0 pt-0">
                  <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      readonly
                      label="Fecha Hasta"
                      v-model="formDateHasta"
                      v-bind="attrs"
                      v-on="on"
                      type="date">
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formDateHasta"
                    :min="(new Date((new Date()).setMonth((new Date()).getMonth()-36))).toISOString().substr(0, 10)"
                  ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col sm="2,4" class="mt-4 pt-0">
                  <v-autocomplete
                    class="mt-0 pt-0"
                    v-model="Material"
                    :items="codigos"
                    label="Codigo SAP Items"
                    color="grey darken-4"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-container fluid>
                <v-row>
                  <v-col sm="8" class="mt-0 pt-0">
                    <v-btn icon>
                      <v-icon
                        title='Generar lista'
                        color='blue'
                        @click="generarReporte()"
                      >
                        mdi-file-find
                      </v-icon>
                    </v-btn>
                </v-col>
                </v-row>
              </v-container>
              <v-row>
                <v-btn icon>
                  <v-icon
                    title='Exportar PDF'
                    color='red'
                    @click="generatePDF()"
                  >
                  mdi-file-pdf-box
                  </v-icon>
                </v-btn>
                <v-btn icon>
                  <v-icon
                    title='Exportar Excel'
                    color='green'
                    @click="exportar()"
                  >
                  mdi-file-excel
                  </v-icon>
                </v-btn>
              </v-row>
            </v-container>
          </template>
          <v-card class="mx-auto" width="98%">
            <v-data-table
              :headers="headers"
              :items="items_Reporte"
              :search="search"
              loading-text="Cargando Datos"
              class="elevation-1"
              :header-props="{
                  sortByText: 'Ordenar',
                }"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',
                'items-per-page-text': 'reportes por página',
              }"
            >
            </v-data-table>
          </v-card>
        </v-card>
      </v-container>
    </v-main>
    <SetLogout/>
    <Historicobcv/>
  </v-app>

</template>

<script>
// eslint-disable-next-line import/extensions
import { mapState /* , mapActions */ } from 'vuex';
// eslint-disable-next-line import/extensions
import jspdf from 'jspdf';
// eslint-disable-next-line import/extensions
import 'jspdf-autotable';
// eslint-disable-next-line import/extensions
import XLSX from 'xlsx';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import {
  ejecutivo, condicion, codigo, reportes,
} from '../../config/api/reportes';
// eslint-disable-next-line import/extensions
import Toolbar from '../../components/Toolbar';
// eslint-disable-next-line import/extensions
import SetLogout from '../../components/SetLogout';
// eslint-disable-next-line import/extensions
import Historicobcv from '../../components/Historicobcv';

export default {
  name: 'detallado',
  components: {
    Toolbar, SetLogout, Historicobcv,
  },
  data: () => ({
    dialog: false,
    search: '',
    selected: [],
    heading: 'Reporte Pedidos Detallados',
    headers: [
      { text: 'Ejecutivo', value: 'Ejecutivo', align: 'center' },
      { text: 'Estatus', value: 'Estatus', align: 'center' },
      { text: 'Cliente', value: 'Cliente', align: 'center' },
      { text: 'Pedido', value: 'Numero_Pedido', align: 'center' },
      { text: 'Cod SAP', value: 'Codigo', align: 'center' },
      { text: 'descripcion', value: 'Nombre', align: 'center' },
      { text: 'Marca', value: 'Marca', align: 'center' },
      { text: 'Fecha Creacion', value: 'Fecha_creacion', align: 'center' },
      { text: 'Cantidad', value: 'Cantidad', align: 'center' },
      { text: 'Precio por producto', value: 'Precio', align: 'center' },
      { text: 'Precio Total por producto', value: 'Total', align: 'center' },
      { text: 'Nro de entrega SAP', value: 'nro_sap_entregado', align: 'center' },
    ],
    desde: new Date((new Date()).setMonth((new Date()).getMonth() - 1))
      .toLocaleDateString('en-GB')
      .split('/')
      .reverse()
      .join('-'),
    hasta: new Date().toLocaleDateString('en-GB')
      .split('/')
      .reverse()
      .join('-'),
    items_Reporte: [],
    ejecutivos: [],
    estatus: [],
    codigos: [],
    request: {
    },
  }),
  computed: {
    ...mapState('reportes', ['item', 'status', 'vendedor', 'codigo']),
    profile() {
      return Profile.get();
    },
    estatu: {
      set(value) {
        this.$store.dispatch('reportes/toAssignEstatus', value);
      },
      get() {
        return this.status;
      },
    },
    Ejecutivo: {
      set(value) {
        this.$store.dispatch('reportes/toAssignEjecutivo', value);
      },
      get() {
        return this.vendedor;
      },
    },
    Material: {
      set(value) {
        this.$store.dispatch('reportes/toAssignCodigo', value);
      },
      get() {
        return this.codigo;
      },
    },
    formDateDesde: {
      set(param) {
        this.desde = param;
      },
      get() {
        return this.desde;
      },
    },
    formDateHasta: {
      set(param) {
        this.hasta = param;
      },
      get() {
        return this.hasta;
      },
    },
  },
  methods: {
    // ...mapActions('detallado', ['toAssignCondicion', 'toAssignEjecutivo']),
    data() {
      return {
        desde: this.desde,
        hasta: this.hasta,
        estatus_pedido: this.estatu,
        vendedor: this.Ejecutivo,
        material: this.codigo.substring(0, 10),
        company_id: this.profile.organization.id,
      };
    },
    async generarReporte() {
      this.items_Reporte = (await reportes(this.data())).data;
    },
    generatePDF() {
      const date = new Date();
      const a = '/';
      const fecha = String(date.getDate()).padStart(2, '0') + a + String(date.getMonth() + 1).padStart(2, '0') + a + date.getFullYear();
      const columns = [
        { title: 'Ejecutivo', dataKey: 'Ejecutivo', align: 'center' },
        { title: 'Estatus', dataKey: 'Estatus', align: 'center' },
        { title: 'Cliente', dataKey: 'Cliente', align: 'center' },
        { title: 'Pedido', dataKey: 'Numero_Pedido', align: 'center' },
        { title: 'Cod SAP', dataKey: 'Codigo', align: 'center' },
        { title: 'descripcion', dataKey: 'Nombre', align: 'center' },
        { title: 'Marca', dataKey: 'Marca', align: 'center' },
        { title: 'Fecha Creado', dataKey: 'Fecha_creacion', align: 'center' },
        { title: 'Cantidad', dataKey: 'Cantidad', align: 'center' },
        { title: 'Precio por producto', dataKey: 'Precio', align: 'center' },
        { title: 'Precio Total por producto', dataKey: 'Total', align: 'center' },
        { title: 'Nro de entrega SAP', dataKey: 'nro_sap_entregado', align: 'center' },
      ];
      /* eslint new-cap: ["error", { "newIsCap": false }] */
      const doc = new jspdf({
        orientation: 'Landscape',
        unit: 'in',
        format: 'letter',
      });
      // text is placed using x, y coordinates
      doc.setFontSize(16).text(this.heading, 0.5, 1.0);
      // create a line under heading */
      doc.setLineWidth(0.01).line(0.5, 1.1, 8.0, 1.1);
      // Using autoTable plugin
      doc.autoTable({
        columns,
        body: this.items_Reporte,
        margin: { left: 0.5, top: 1.25 },
      });
      // Using array of sentences
      doc.save(`${this.heading + fecha}.pdf`);
    },
    exportar() {
      const data = XLSX.utils.json_to_sheet(this.items_Reporte);
      const workbook = XLSX.utils.book_new();
      const filename = 'Reporte Detallado XLS';
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
  },
  async mounted() {
    try {
      const requests = {
        user: this.formEjecutivo,
        company_id: this.profile.organization.id,
      };
      this.ejecutivos = (await ejecutivo(requests)).data;// ejecutivo
      this.estatus = (await condicion()).data;// estatus de pedido
      this.codigos = (await codigo()).data;// codigos materiales
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(error);
    }
  },
};
</script>
