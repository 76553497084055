// eslint-disable-next-line import/extensions
import Profile from '../plugins/profile';

export default function auth({ next, router }) {
  let authorized = false;

  if (Profile.check()) {
    const profile = Profile.get();
    const diffTime = Math.abs((new Date()) - (new Date(profile.update)));
    const diffHour = Math.ceil(diffTime / (1000 * 60 * 60));

    // Expiracion de sesión local en Horas
    if (diffHour < 10) {
      authorized = true;
    }
  }

  if (!authorized) {
    if (Profile.isExist()) {
      // eslint-disable-next-line
      window.alert('Su sesión ha expirado');
    }
    Profile.clear();
    return router.push({ name: 'login' });
  }

  return next();
}
