import { mails } from '../../config/api/mails';

export default {

  name: 'mails',

  namespaced: true,

  state: {
    // PRINCIPAL
    item: [],
    // FORMULARIO
    show: false,
    isTest: false,
    isView: false,
    title: '',
    subject: '',
    body: '',
    recipient: '',
    status: '',
  },
  mutations: {
    // PRINCIPAL
    SET_DATA(state, parameter) {
      state.item = parameter.data;
    },
    // FORMULARIO
    SHOW(state) {
      state.show = !state.show;
    },
    SET_IS_TEST(state, parameter) {
      state.isTest = parameter;
    },
    SET_IS_VIEW(state, parameter) {
      state.isView = parameter;
    },
    SET_TITLE(state, parameter) {
      state.title = parameter;
    },
    SET_SUBJECT(state, parameter) {
      state.subject = parameter;
    },
    SET_BODY(state, parameter) {
      state.body = parameter;
    },
    SET_RECIPIENT(state, parameter) {
      state.recipient = parameter;
    },
    SET_STATUS(state, parameter) {
      state.status = parameter;
    },
  },
  actions: {
    // PRINCIPAL
    async all({ commit }, parameter) {
      commit('SET_DATA', await mails(parameter));
    },
    test({ commit }) {
      commit('SHOW');
      commit('SET_TITLE', 'Enviar una prueba');
      commit('SET_IS_TEST', true);
      commit('SET_IS_VIEW', false);
      commit('SET_SUBJECT', '');
      commit('SET_BODY', '');
      commit('SET_RECIPIENT', '');
      commit('SET_STATUS', '');
    },
    view({ commit }, parameter) {
      commit('SHOW');
      commit('SET_TITLE', 'Visualizar correo');
      commit('SET_IS_VIEW', true);
      commit('SET_IS_TEST', false);
      commit('SET_SUBJECT', parameter.title);
      commit('SET_BODY', parameter.body);
      commit('SET_RECIPIENT', parameter.recipient);
      commit('SET_STATUS', parameter.status);
    },
    reset({ commit }) {
      commit('SHOW');
      commit('SET_TITLE', '');
      commit('SET_IS_TEST', false);
      commit('SET_IS_VIEW', false);
      commit('SET_SUBJECT', '');
      commit('SET_BODY', '');
      commit('SET_RECIPIENT', '');
      commit('SET_STATUS', '');
    },
    toAssignSubject({ commit }, parameter) {
      commit('SET_SUBJECT', parameter);
    },
    toAssignBody({ commit }, parameter) {
      commit('SET_BODY', parameter);
    },
    toAssignRecipient({ commit }, parameter) {
      commit('SET_RECIPIENT', parameter);
    },
    toAssignStatus({ commit }, parameter) {
      commit('SET_STATUS', parameter);
    },
  },
};
