<template>
  <v-app>
   <Toolbar/>
   <v-container fluid class="mt-15 pt-5">
    <h1>Visitas</h1>
    <v-spacer></v-spacer>
    <v-row>
      <v-col sm="3" class="mt-4 pt-0">
        <v-autocomplete
          v-model="user"
          label="ejecutivo"
          :items="ejecutivo"
        >
        </v-autocomplete>
      </v-col>
      <v-col sm="2" class="mt-4 pt-0">
      <v-flex >
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="date"
            persistent
            lazy
            full-width
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="date"
                label="fecha"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="$refs.dialog.save(date)">OK</v-btn>
            </v-date-picker>
          </v-dialog>
      </v-flex>
      </v-col>
      <v-col sm="1" class="mt-8 pt-0">
        <v-btn @click="data()">Buscar</v-btn>
      </v-col>
    </v-row>
          <gmap-map :center="center" :zoom="15" style="width: 100%; height: 500px">
            <gmap-marker
                :key="index"
                v-for="(gmp, index) in formdata"
                :position="gmp"
                :title="formdata[index].label"
                @click="center=gmp"
            ></gmap-marker>
            <gmap-polyline v-bind:path.sync="formdata" v-bind:options="{ strokeColor:'#008000'}">
            </gmap-polyline>
          </gmap-map>
   </v-container>
    <SetLogout/>
    <Historicobcv/>
  </v-app>
</template>

<script>
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
import {
  ejecutivos,
} from '../../config/api/usuarios';
import {
  visitas,
} from '../../config/api/presupuestos';
// eslint-disable-next-line import/extensions
import Toolbar from '../../components/Toolbar';
// eslint-disable-next-line import/extensions
import SetLogout from '../../components/SetLogout';
// eslint-disable-next-line import/extensions
import Historicobcv from '../../components/Historicobcv';

export default {
  name: 'Rutas',
  components: {
    Toolbar, SetLogout, Historicobcv,
  },
  data() {
    return {
      date: '',
      user: '',
      ejecutivo: [],
      modal: false,
      center: {
        lat: 10.094910,
        lng: -69.369783,
      },
      locations: [],
      locations2: [
        {
          lat: 10.070215,
          lng: -69.357176,
          label: 'Prosider',
        },
        {
          lat: 10.068823,
          lng: -69.357176,
          label: 'Preca',
        },
        {
          lat: 10.067452,
          lng: -69.357198,
          label: 'Obelico',
        },
        {
          lat: 10.066709,
          lng: -69.356369,
          label: 'Juan estuvo aqui',
        },
        {
          lat: 10.073818,
          lng: -69.092248,
          label: 'Juan estuvo aqui',
        },
      ],
      loct: [],
    };
  },
  computed: {
    profile() {
      return Profile.get();
    },
    formdata: {
      get() {
        return this.locations;
      },
    },
  },
  methods: {
    async data() {
      const requests = {
        fecha: this.date,
        user: this.user,
      };
      let i = 0;
      this.locations = [];
      this.loct = (await visitas(requests)).data;
      this.loct.forEach((element) => {
        this.locations[i] = {
          lat: parseFloat(element.lat),
          lng: parseFloat(element.lng),
          label: element.label,
        };
        // eslint-disable-next-line eqeqeq
        i += 1;
      });
      this.reset();
      console.log(this.locations);
    },
    reset() {
      this.loct = [];
      this.date = '';
      this.user = '';
    },
    alert(message) {
      this.$store.commit('message/MESSAGE', message);
      this.$store.commit('message/TYPE', 'warning');
      this.$store.dispatch('message/run');
    },
  },
  async mounted() {
    try {
      const requests = {
        company_id: this.profile.organization.id,
      };
      this.ejecutivo = (await ejecutivos(requests)).data;
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(error);
    }
  },
};
</script>

<style>
button {
    margin-bottom: 10px;
}
</style>
