<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="95%">
      <v-card>

        <v-toolbar :color="profile.organization.color" dark height="50px">
          <v-icon class="mr-2">
            mdi-application
          </v-icon>
            <h1 class="title mt-2 white--text">
              {{title}} Cobranza - {{formClient}}
            </h1>
          <v-spacer></v-spacer>
            <v-btn icon dark @click="close()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
        </v-toolbar>

        <div class="text-right" style="margin:4px 4rem -4px 0" v-if="currentLayout == 1">
          <v-chip
            class="ma-2"
            color="pink"
            label
            outlined
            @click="openModalDelete()">
            <v-icon left>
              mdi-delete-forever
            </v-icon>
            Borrar
          </v-chip>

          <v-chip
            class="ma-2"
            color="primary"
            outlined
            @click="editItem()"
            label>
            <v-icon left>
              mdi-pencil
            </v-icon>
            Editar
          </v-chip>

          <v-chip
            class="ma-2"
            color="cyan"
            label
            @click="openPayment()"
            text-color="white">
            <v-icon left>
              mdi-open-in-new
            </v-icon>
            Nuevo pago
          </v-chip>
        </div>

        <v-card-text v-if="currentLayout == 1">
          <v-container>
            <v-container class="mt-0 pt-0" fluid>
              <v-card>
                <v-toolbar color="blue-grey lighten-2" class="elevation-2" height="30">
                  <h1 class="subtitle-1 mt-2 white--text">
                    Pagos
                  </h1>
                </v-toolbar>
                <v-card-text>
                <v-data-table
                  v-model="itemSelected"
                  :headers="headers"
                  :items="payments"
                  :items-per-page="10"
                  :single-select="true"
                  item-key="ID"
                  show-select
                  class="elevation-0"
                  hide-default-footer
                  no-data-text='Añade un pago a la lista'
                  :header-props="{
                    sortByText: 'Ordenar',
                    class: 'title',
                  }"
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus',
                    'items-per-page-text': 'Pagos por Página',
                  }">

                  <template v-slot:[`item.type`]="{ item }">
                    <span >{{typePays[item.type]}}</span>
                  </template>

                </v-data-table>
              </v-card-text>
              </v-card>
            </v-container>
            <v-card width="50%" style="margin-left:49%;padding-bottom:4px;">
              <v-layout row wrap>
                <v-flex xs5 class="mt-2 font-weight-black grey--text text--darken-2" d-flex justify-end>
                    COBRANZA:
                </v-flex>
                <v-flex xs5 class="mt-2 font-weight-black grey--text text--darken-2" d-flex justify-end>
                    {{formImporte}}
                </v-flex>
                <v-flex xs5 class="mt-2 font-weight-black grey--text text--darken-2" d-flex justify-end>
                    PAGO REGISTRADO:
                </v-flex>
                <v-flex xs5 class="mt-2 font-weight-black grey--text text--darken-2" d-flex justify-end>
                    {{formPago}}
                </v-flex>
                <v-flex xs5 class="mt-2 font-weight-black grey--text text--darken-2" d-flex justify-end>
                    TOTAL:
                </v-flex>
                <v-flex xs5 class="mt-2 font-weight-black grey--text text--darken-2" d-flex justify-end>
                    {{formTotal}}
                </v-flex>
              </v-layout>
            </v-card>
          </v-container>
        </v-card-text>

        <v-card-text v-if="currentLayout == 2">
          <v-card width="30%" height="45px" class="mt-2 elevation-3" style="margin-left:65.5%;">
            <v-layout row wrap>
              <v-flex xs6 class="mt-2 title font-weight-black grey--text text--darken-1" d-flex justify-end>
                  PAGO TOTAL:
              </v-flex>
              <v-flex xs5 class="mt-2 title font-weight-black grey--text text--darken-1" d-flex justify-end>
                  {{formPago}}
              </v-flex>
            </v-layout>
          </v-card>
          <v-container>
            <v-container class="mt-0 pt-0" fluid>
              <v-card>
                <v-toolbar color="blue-grey lighten-2" class="elevation-2" height="30">
                  <h1 class="subtitle-1 mt-2 white--text">
                    Distribución de pagos por factura
                  </h1>
                </v-toolbar>
                <v-card-text>
                <v-data-table
                  :headers="headers2"
                  :items="selected"
                  class="elevation-0"
                  :header-props="{
                    sortByText: 'Ordenar',
                    class: 'title',
                  }">

                  <template v-slot:[`item.mount`]="{ item }">
                    <span >{{item.mount.toFixed(2)}}</span>
                  </template>

                  <template v-slot:[`item.load`]="{ item }">
                    <v-text-field
                      v-model="itemDistValue.load"
                      :hide-details="true"
                      type="number"
                      dense single-line
                      :autofocus="true"
                      v-if="item.document_fin === docDistSelected">
                    </v-text-field>
                    <span v-else>{{item.load}}</span>
                  </template>

                  <template v-slot:[`item.reference`]="{ item }">
                    <v-text-field
                      v-model="itemDistValue.reference"
                      :hide-details="true"
                      type="text"
                      dense single-line
                      :autofocus="true"
                      v-if="item.document_fin === docDistSelected">
                    </v-text-field>
                    <span v-else>{{item.reference}}</span>
                  </template>

                  <template v-slot:[`item.text`]="{ item }">
                    <v-text-field
                      v-model="itemDistValue.text"
                      :hide-details="true"
                      type="text"
                      dense single-line
                      :autofocus="true"
                      v-if="item.document_fin === docDistSelected">
                    </v-text-field>
                    <span v-else>{{item.text}}</span>
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <div v-if="docDistSelected === item.document_fin">
                      <v-btn icon>
                        <v-icon
                          title="Descartar distribución"
                          class="mr-2"
                          color="red"
                          @click="discardItemDistribute()">
                          mdi-window-close
                        </v-icon>
                      </v-btn>
                      <v-btn icon>
                        <v-icon
                          title="Guardar distribución"
                          class="mr-2"
                          color="green"
                          @click="saveItemDistribute(item)">
                          mdi-content-save
                        </v-icon>
                      </v-btn>
                    </div>
                    <div v-else>
                      <v-btn icon>
                        <v-icon
                          title="Editar distribución"
                          class="mr-2"
                          color="green"
                          @click="editItemDistribute(item)">
                          mdi-pencil-outline
                        </v-icon>
                      </v-btn>
                      <v-btn v-show="profile.organization.id != '5'"
                          icon>
                        <v-icon
                          title="Descuento"
                          class="mr-2"
                          color="green"
                          @click="openDiscount(item)">
                          mdi-open-in-new
                        </v-icon>
                      </v-btn>
                    </div>
                  </template>
                </v-data-table>
              </v-card-text>
              </v-card>
            </v-container>
          </v-container>
          <v-card width="50%" height="45px" class="mt-0 elevation-3" style="margin-left:46%;">
            <v-layout row wrap>
              <v-flex xs6 class="mt-2 title font-weight-black grey--text text--darken-1" d-flex justify-end>
                  DISTRIBUCIÓN TOTAL:
              </v-flex>
              <v-flex xs5 class="mt-2 title font-weight-black grey--text text--darken-1" d-flex justify-end>
                {{DistTotal}}
              </v-flex>
            </v-layout>
          </v-card>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="close()"> {{captionBack}}</v-btn>
          <v-btn :loading="Send" color="primary" outlined v-if="payments.length > 0" @click="next()"> {{captionNext}}</v-btn>
        </v-card-actions>
        <Payment/>
        <DescuentoFrom/>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalDelete" open-delay="" max-width="290" transition="dialog-top-transition">
      <v-card>
        <v-toolbar :color="profile.organization.color" dark height="50px">
          <v-icon class="mr-2">
            mdi-application
          </v-icon>
            <h1 class="title mt-2 white--text">
              Borrado de registro
            </h1>
          <v-spacer></v-spacer>
            <v-btn icon dark @click="modalDelete = false">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text class="pt-4 title">
          ¿Está seguro que desea borrar el registro seleccionado?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" outlined @click="modalDelete = false">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" outlined @click="deleteItem()">
            Sí, borrar!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalAnticipo" open-delay="" max-width="290" transition="dialog-top-transition">
      <v-card>
        <v-toolbar :color="profile.organization.color" dark height="50px">
          <v-icon class="mr-2">
            mdi-application
          </v-icon>
            <h1 class="title mt-2 white--text">
              Elegir documento
            </h1>
          <v-spacer></v-spacer>
            <v-btn icon dark>
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text class="pt-4 title">
          Se ha creado una diferencia de ${{ Math.abs(parseFloat(this.formTotal)).toString() }} , ¿Como desea conservarla?
        </v-card-text>
        <v-card-text class="pt-4 title">
          <input type="checkbox" id="checkbox" v-model="checked">
          La diferencia registrada esta destinada a otros pagos.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" outlined @click="actionPause(false)">
            Diferencia
          </v-btn>
          <v-btn color="green darken-1" outlined @click="actionPause(true)">
            Anticipo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapActions, mapState, mapMutations } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import DescuentoFrom from './DescuentoFrom';
// eslint-disable-next-line import/extensions
import Payment from './TypePayForm';
import { add } from '../../config/api/cobranza';
import { versionCurrent } from '../../middleware/version';

export default {
  name: 'cobranza-crud',
  components: {
    Payment, DescuentoFrom,
  },
  data: () => ({
    // INDICADOR DE ENVIO
    Anticipo: false,
    Pause: false,
    modalAnticipo: false,
    Send: false,
    checked: false,
    check: false,
    z: '',
    posselected: 0,
    // valor de tolerancia
    tolerance: 1.00,
    // Variables de segunda layout (Distribucion de pago)
    docDistSelected: -1,
    itemDistValue: {},
    DistTotal: 0,
    // Documentos adicionales
    docAdditional: [],
    // container pago seleccionado
    itemSelected: [],
    // triger Modal Delete pago
    modalDelete: false,
    // Captions's de botones de formulario principal
    captionBack: 'Cerrar',
    captionNext: 'Siguiente',
    // control de layout pago/distribución
    currentLayout: 1,
    // cabecera de pagos
    typePays: {
      transferencia: 'Transferencia Bancaria',
      pagomovil: 'Pagomovil',
      deposito: 'Deposito Bancario',
      efectivo: 'Efectivo',
      cuentas: 'Cuentas Internacionales',
      // retencion: 'Retención',
    },
    headers: [
      { text: 'Pos.', value: 'ID', align: 'center' },
      { text: 'Tipo de Pago', value: 'type', align: 'center' },
      { text: 'Monto', value: 'value', align: 'center' },
      { text: 'Banco', value: 'bankTo', align: 'center' },
    ],
    // cabecera de distribución
    headers2: [
      { text: 'Factura', value: 'fact_sap', align: 'center' },
      {
        text: 'Importe',
        value: 'mount',
        align: 'center',
        width: '150',
      },
      {
        text: 'Monto',
        value: 'load',
        align: 'center',
        width: '150',
      },
      {
        text: 'Referencia',
        value: 'reference',
        align: 'center',
        width: '150',
      },
      {
        text: 'Observación',
        value: 'text',
        align: 'center',
        width: '150',
      },
      { text: 'Acciones', value: 'actions', sortable: false },
    ],
  }),
  computed: {
    ...mapState('userProfile', ['profile']),
    ...mapState('cobranza', ['title', 'show', 'item', 'Discount', 'payments', 'DescDoc', 'selected', 'client', 'clients']),
    formClient: {
      set(value) {
        this.$store.dispatch('cobranza/toAssignClient', value);
      },
      get() {
        return this.client;
      },
    },
    formImporte: {
      get() {
        let monto = 0;

        this.selected.forEach((selected) => {
          this.item.forEach((document) => {
            if (document.fact_key === selected.fact_key || document.document_fin === selected.fact_key.substring(0, 10)) {
              monto += parseFloat(document.doc_total);
            }
          });
        });

        return (this.selected.length > 0) ? monto.toFixed(2) : 0;
      },
    },
    formPago: {
      get() {
        return (this.payments.length > 0) ? this.payments.reduce((acc, item) => acc + parseFloat(item.value), 0).toFixed(2) : 0;
      },
    },
    formTotal: {
      get() {
        return (this.formImporte - this.formPago).toFixed(2);
      },
    },
    profile() {
      return Profile.get();
    },
  },
  methods: {
    ...mapMutations('message', ['MESSAGE', 'TYPE', 'LOADING']),
    ...mapActions('message', ['run']),
    ...mapMutations('cobranza', ['SHOW', 'SHOWPAY', 'SHOWDISC']),
    ...mapActions('cobranza', ['closeCobranza', 'addPayment', 'addDiscount', 'showCobranzaDescuento']),
    dataPut(condition) {
      let esporadico = false;
      const DataClient = this.clients.filter((item) => item.name === this.client);
      if (this.client === 'Ventas Empleados' || this.client === 'Facturador 01' || this.client === 'FACT_01' || this.client === 'FACT_02' || this.client === 'FACT_03' || this.client === 'FACT_04' || this.client === 'VENTASEMP') {
        esporadico = true;
      } else {
        esporadico = false;
      }
      let DistItems = [];
      let DiscountP = 0.0;

      this.selected.forEach((selected) => {
        DistItems.push({
          document: selected.fact_key.substring(0, 10),
          value: (condition !== 1 ? selected.load : selected.mount),
          reference: selected.reference,
          text: selected.text,
          year_fiscal: selected.year_fiscal,
          key: '15',
          pos_ref: '001', // selected.posicion,
          factSd: selected.fact_sap,
          discount: false, // posicion con descuento?
        });
      });
      if (this.DescDoc.length > 0) {
        DistItems = DistItems.concat(this.DescDoc);
        DiscountP = parseFloat(DistItems[this.z].value)/* - parseFloat(this.DescDoc[0].value) */;
        DistItems.splice(this.z, 1, {
          document: DistItems[this.z].document,
          reference: DistItems[this.z].reference,
          text: DistItems[this.z].text,
          year_fiscal: DistItems[this.z].year_fiscal,
          key: DistItems[this.z].key,
          pos_ref: '001', // DistItems[this.z].pos_ref,
          factSd: DistItems[this.z].factSd,
          value: DiscountP.toFixed(2),
          discount: true, // posicion con descuento?
        });
      }

      if (this.docAdditional.length > 0) {
        DistItems = DistItems.concat(this.docAdditional);
      }
      console.log(DistItems);
      console.log(this.payments);
      return {
        org: this.profile.org,
        society: this.profile.organization.society,
        user_id: this.profile.user.id,
        origin: 'webapp',
        version: versionCurrent(),
        client: {
          id: DataClient[0].account_id,
          name: DataClient[0].name,
          dni: DataClient[0].customer_dni,
          rif: DataClient[0].account_no,
          sp: DataClient[0].is_esporadico,
        },
        documents: DistItems,
        payments: this.payments,
        cme: false,
        otherExpenses: this.checked, // check de otros pagos
        cliente: esporadico,
      };
    },
    async next() {
      if (this.currentLayout === 1 && this.payments.length > 0) {
        if (parseFloat(this.formPago) > 0) {
          this.currentLayout = 2;
          this.captionBack = 'Atras';
          this.captionNext = 'Guardar';

          for (let i = 0; i < this.selected.length; i += 1) {
            this.selected[i].load = parseFloat(this.selected[i].mount.toFixed(2));
            /* if (this.formTotal >= 0) {
              this.selected[i].load = ((this.selected[i].mount / this.formImporte) * this.formPago).toFixed(2);
            } else {
              this.selected[i].load = ((this.selected[i].mount / this.formImporte) * this.formImporte).toFixed(2);
            } */
          }

          this.calcDistriTotal();
        } else {
          this.alert('La suma de los pagos debe ser mayor a cero', 'warning');
        }
      } else if (this.currentLayout === 2 && this.payments.length > 0) {
        let cond = -1;
        // La tolerancia sera de 3% de la covranza total
        this.tolerance = 1; // parseFloat(this.formImporte) * 0.03;

        if (Math.abs(parseFloat(this.formTotal)) <= parseFloat(this.tolerance) && parseFloat(this.formTotal) > 0) {
          // Faltante dentro del rango, Prestamo
          cond = 1;
        } else if (parseFloat(this.formImporte) >= parseFloat(this.formPago)) {
          // Se cancela la deuda o abona una parte
          cond = 2;
        } else if (Math.abs(parseFloat(this.formTotal)) <= parseFloat(this.tolerance) && parseFloat(this.formTotal) < 0) {
          // Excedente dentro del rango, Ganancia
          cond = 3;
          this.Pause = !this.modalAnticipo;
        } else if (Math.abs(parseFloat(this.formTotal)) > parseFloat(this.tolerance) && parseFloat(this.formTotal) < 0) {
          // Excedente fuera del rango, se gerera Anticipo
          cond = 4;
          this.Pause = !this.modalAnticipo;
        } else if (parseFloat(this.DistTotal) > parseFloat(this.formPago)) {
          // La distribución no cuadra con los pagos registrados
          cond = 0;
        }

        if (!this.Pause) {
          switch (cond) {
            case -1:
            case 0: {
              this.alert('La suma de la distribución es distinta al pago total registrado');
              return;
            }
            case 1: {
              this.docAdditional.push({
                document: 'Ajustes-',
                value: Math.abs(parseFloat(this.formTotal)) * -1,
                year_fiscal: '',
                key: '40',
                text: '',
                reference: '',
                pos_ref: 0,
                discount: true, // posicion con descuento?
              });
              break;
            }
            case 2: {
              break;
            }
            case 3: {
              let clave = '';
              if (this.client === 'Ventas Empleados' || this.client === 'Facturador 01' || this.client === 'FACT_01' || this.client === 'FACT_02' || this.client === 'FACT_03' || this.client === 'FACT_04' || this.client === 'VENTASEMP') {
                clave = 15;
              } else {
                clave = 19;
              }
              this.docAdditional.push({
                document: this.Anticipo ? 'Anticipo' : 'Diferencia',
                value: Math.abs(parseFloat(this.formTotal)),
                year_fiscal: '',
                key: this.Anticipo ? clave : '50',
                text: '',
                reference: '',
                pos_ref: 0,
                discount: false, // posicion con descuento?
              });
              break;
            }
            case 4: {
              let clave = '';
              if (this.client === 'Ventas Empleados' || this.client === 'Facturador 01' || this.client === 'FACT_01' || this.client === 'FACT_02' || this.client === 'FACT_03' || this.client === 'FACT_04' || this.client === 'VENTASEMP') {
                clave = 15;
              } else {
                clave = 19;
              }
              this.docAdditional.push({
                document: this.Anticipo ? 'Anticipo' : 'Diferencia',
                value: Math.abs(parseFloat(this.formTotal)),
                year_fiscal: '',
                key: this.Anticipo ? clave : '50',
                text: '',
                reference: '',
                pos_ref: 0,
                discount: false, // posicion con descuento?
              });
              break;
            }
            default:
          }

          this.$store.commit('message/LOADING', true);
          try {
            this.Send = true;
            await add(this.dataPut(cond)).then(() => {
              this.Send = false;
              this.Pause = false;
              this.Anticipo = false;
              this.modalAnticipo = false;
              switch (cond) {
                case 1: {
                  this.alert('Se ha realizado el registro satisfactoriamente y se ha registrado una diferencia por -$'.concat(Math.abs(parseFloat(this.formTotal)).toString()), 'success', true);
                  break;
                }
                case 3: {
                  this.alert('Se ha realizado el registro satisfactoriamente y se ha registrado una diferencia por +$'.concat(Math.abs(parseFloat(this.formTotal)).toString()), 'success', true);
                  break;
                }
                case 4: {
                  this.alert('Se ha realizado el registro satisfactoriamente y se generó una diferencia por $'.concat(Math.abs(parseFloat(this.formTotal)).toString()), 'success', true);
                  break;
                }
                default:
                  this.alert('Se ha realizado el registro satisfactoriamente', 'success', true);
              }
            });
          } catch (error) {
            // eslint-disable-next-line no-alert
            this.alert(error, 'error');
          }
        } else {
          this.modalAnticipo = true;
        }
        this.$store.commit('message/LOADING', false);
      }
    },
    async actionPause(value) {
      this.Anticipo = value;
      this.Pause = false;
      this.next();
    },
    close() {
      if (this.currentLayout === 2) {
        this.currentLayout = 1;
        this.captionBack = 'Cerrar';
        this.captionNext = 'Siguiente';
      } else if (this.currentLayout === 1 && this.payments.length > 0) {
        this.alert('Antes de salir de este formulario debe borrar los registros realizados', 'warning');
      } else if (this.currentLayout === 1 && this.payments.length === 0) {
        this.closeCobranza();
        this.selected.splice(0, this.selected.length);
      }
    },
    openDiscount(item) {
      // this.docDistSelected = item.document_fin;
      const Day = new Date().toLocaleDateString();
      const fecha = Day.split('/');
      const separado = '/';
      const Expiracion = item.date_expiration.split('/');
      function dia(fechas, dias) {
        return new Date(fechas.setDate(fechas.getDate() + dias));
      }
      const dateDay = new Date(String(fecha[2]) + separado + String(fecha[1]) + separado + String(fecha[0])); // fecha del dia
      const dateFull = new Date(String(Expiracion[2]) + separado + String(Expiracion[1]) + separado + String(Expiracion[0]));
      // const dateFull = new Date('2022/12/14');
      const dateExpiracion = dia(dateFull, 1); // fecha valida para aplicar descuento
      let c = '';
      for (let i = 0; i < this.selected.length; i += 1) {
        if (this.selected[i].document_fin === item.document_fin) {
          c = i;
          this.z = i;
        }
      }

      if (this.formPago >= this.selected[c].doc_total) {
        if (dateDay <= dateExpiracion) {
          if ((parseFloat(this.selected[c].doc_total).toFixed(2) === parseFloat(item.load).toFixed(2))) {
            this.posselected = c;
            this.$store.dispatch('cobranza/setPosSelected', this.posselected);
            this.showCobranzaDescuento();
          } else {
            this.alert('No se puede generar descuento al Documento, Monto inferior al del Documento', 'warning');
          }
        } else {
          this.alert('No se puede generar descuento, fecha de Vencimiento inferior', 'warning');
        }
      } else {
        this.alert('No se puede generar descuento, Pago total menor al Monto', 'warning');
      }
    },
    openPayment() {
      this.$store.dispatch('cobranza/setPayment', []);
      this.addPayment();
    },
    editItem() {
      if (this.itemSelected.length > 0) {
        this.$store.dispatch('cobranza/setItemPay', this.itemSelected);
        this.addPayment();
      } else {
        this.alert('Debe seleccionar un registro');
      }
    },
    openModalDelete() {
      if (this.itemSelected.length > 0) {
        this.modalDelete = true;
      } else {
        this.alert('Debe seleccionar un registro');
      }
    },
    deleteItem() {
      if (this.itemSelected.length > 0) {
        for (let i = 0; i < this.payments.length; i += 1) {
          if (this.payments[i].ID === this.itemSelected[0].ID) {
            this.payments.splice(i, 1);
          }
        }
        this.itemSelected.splice(0, this.itemSelected.length);
      }
      this.modalDelete = false;
    },
    editItemDistribute(item) {
      this.docDistSelected = item.document_fin;
      this.itemDistValue = item;
    },
    saveItemDistribute(item) {
      for (let i = 0; i < this.selected.length; i += 1) {
        if (this.selected[i].document_fin === item.document_fin) {
          if (parseFloat(parseFloat(this.selected[i].mount).toFixed(2)) >= parseFloat(parseFloat(this.itemDistValue.load).toFixed(2))) {
            this.selected[i].load = this.itemDistValue.load;
            this.selected[i].text = this.itemDistValue.text;
            this.selected[i].reference = this.itemDistValue.reference;
            this.docDistSelected = '';
            this.itemDistValue = null;
          } else if (parseFloat(parseFloat(this.selected[i].mount).toFixed(2)) <= 0) {
            this.alert('El monto no puede ser menor o igual a cero', 'warning');
          } else {
            this.alert('El monto no puede ser mayor al importe del documento', 'warning');
          }
        }
      }
      this.calcDistriTotal();
    },
    discardItemDistribute() {
      this.docDistSelected = '';
      this.itemDistValue = null;
    },
    calcDistriTotal() {
      this.DistTotal = 0;
      this.selected.forEach((selected) => {
        this.DistTotal += parseFloat(selected.load);
      });
      this.DistTotal = parseFloat(this.DistTotal).toFixed(2);
      return (this.selected.length > 0) ? this.DistTotal : 0;
    },
    response(api) {
      this.$store.commit('message/LOADING', true);
      try {
        this.alert(api.data.message, 'success', true);
      } catch (error) {
        // eslint-disable-next-line no-alert
        this.alert(error, 'error');
      }
      this.$store.commit('message/LOADING', false);
    },
    alert(message, type = 'info', reload = false) {
      this.$store.commit('message/MESSAGE', message);
      this.$store.commit('message/TYPE', type);
      this.$store.commit('message/RELOAD', reload);
      this.$store.dispatch('message/run');
    },
  },
};
</script>
<style>
/* width */
::-webkit-scrollbar {
    width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0f0f0;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(190, 190, 190);
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9e9e9e;
}
</style>
