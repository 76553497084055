<template>
  <v-app>
    <Toolbar/>
    <v-main>
      <v-container fluid>
        <v-card-title>
          <v-icon class="mr-2">
               mdi-application
          </v-icon>
          {{profile.organization.name}} - Ventas Perdidas
          <v-spacer></v-spacer>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details>
          </v-text-field>
        </v-card-title>
        <template>
          <v-container fluid>
            <v-row>
              <!--<v-col sm="3" class="mt-4 pt-0">
                <v-autocomplete
                  class="mt-0 pt-0"
                  v-model="Ejecutivo"
                  :items="ejecutivos"
                  label="Ejecutivo"
                  color="grey darken-4"
                >
                </v-autocomplete>
              </v-col>
              <v-col sm="3" class="mt-4 pt-0">
                <v-autocomplete
                  class="mt-0 pt-0"
                  v-model="estatu"
                  :items="estatus"
                  label="Estatus"
                  color="grey darken-4"
                >
                </v-autocomplete>
              </v-col>-->
              <v-col sm="3" class="mt-0 pt-0">
                <v-menu>
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      readonly
                      label="Fecha Desde"
                      v-model="formDateDesde"
                      v-bind="attrs"
                      v-on="on"
                      type="date">
                    </v-text-field>
                </template>
                <v-date-picker
                  v-model="formDateDesde"
                  :min="(new Date((new Date()).setMonth((new Date()).getMonth()-24))).toISOString().substr(0, 10)"
                ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col sm="3" class="mt-0 pt-0">
                <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    readonly
                    label="Fecha Hasta"
                    v-model="formDateHasta"
                    v-bind="attrs"
                    v-on="on"
                    type="date">
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="formDateHasta"
                  :min="(new Date((new Date()).setMonth((new Date()).getMonth()-24))).toISOString().substr(0, 10)"
                ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-container fluid>
              <v-row>
                <v-col sm="8" class="mt-0 pt-0">
                  <v-btn icon>
                    <v-icon
                      title='Generar'
                      color='blue'
                      @click="reporte()"
                    >
                      mdi-file-find
                    </v-icon>
                  </v-btn>
              </v-col>
              </v-row>
            </v-container>
            <v-row>
              <v-btn icon>
                <v-icon
                  title='Exportar PDF'
                  color='red'
                  @click="generatePDF()"
                >
                mdi-file-pdf-box
                </v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon
                  title='Exportar Excel'
                  color='green'
                  @click="exportar()"
                >
                mdi-file-excel
                </v-icon>
              </v-btn>
            </v-row>
          </v-container>
        </template>
        <v-card>
          <v-row justify="center">
              <v-dialog
                v-model="dialog"
                scrollable
                persistent
                max-width="450px"
              >
              </v-dialog>
            </v-row>
            <v-card class="mx-auto" width="98%">
              <v-data-table
                :headers="headers"
                :items="items_reporte"
                :search="search"
                loading-text="Cargando Datos"
                class="elevation-1"
                :header-props="{
                  sortByText: 'Ordenar',
                }"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-arrow-collapse-left',
                  lastIcon: 'mdi-arrow-collapse-right',
                  prevIcon: 'mdi-minus',
                  nextIcon: 'mdi-plus',
                  'items-per-page-text': 'reportes por página',
                }"
                multiSort
              >
                <template v-slot:[`item.acciones`]='{ item }'>
                    <v-icon v-show="(item.perdida == 0)" class='px-auto' title='Eliminado del Pedido' color='red'>
                      mdi-delete-circle
                    </v-icon>
                    <v-icon v-show="(item.perdida < 0)" class='px-auto' title='Se Adiciono al Pedido' color='green'>
                      mdi-plus-circle
                    </v-icon>
                    <v-icon v-show="(item.perdida > 0)" class='px-auto' title='Se Resto del Pedido' color='red'>
                      mdi-minus-circle
                    </v-icon>
               </template>
              </v-data-table>
            </v-card>
        </v-card>
      </v-container>
    </v-main>
    <SetLogout/>
    <Historicobcv/>
  </v-app>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapState, mapActions } from 'vuex';
// eslint-disable-next-line import/extensions
import jspdf from 'jspdf';
// eslint-disable-next-line import/extensions
import 'jspdf-autotable';
// eslint-disable-next-line import/extensions
import XLSX from 'xlsx';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import {
  ejecutivo, ventasPerdidas,
} from '../../config/api/reportes';
// eslint-disable-next-line import/extensions
import Toolbar from '../../components/Toolbar';
// eslint-disable-next-line import/extensions
import SetLogout from '../../components/SetLogout';
// eslint-disable-next-line import/extensions
import Historicobcv from '../../components/Historicobcv';

export default {
  name: 'reportes',
  components: {
    Toolbar, SetLogout, Historicobcv,
  },
  data: () => ({
    dialog: false,
    search: '',
    selected: [],
    heading: 'Reporte Ventas Perdidas',
    headers: [
      { text: 'Pedido', value: 'pedido', align: 'center' },
      { text: 'Ejecutivo', value: 'ejecutivo', align: 'center' },
      { text: 'Material', value: 'material', align: 'center' },
      { text: 'Nombre', value: 'nombre', align: 'center' },
      { text: 'No Facturado', value: 'perdida', align: 'center' },
      { text: 'Fecha', value: 'fecha', align: 'center' },
      { text: 'Observacion', value: 'acciones' },
    ],
    desde: new Date((new Date()).setMonth((new Date()).getMonth() - 1))
      .toLocaleDateString('en-GB')
      .split('/')
      .reverse()
      .join('-'),
    hasta: new Date().toLocaleDateString('en-GB')
      .split('/')
      .reverse()
      .join('-'),
    items_reporte: [],
    ejecutivos: [],
    estatu: null,
    estatus: [
      { value: 'ANULADO', text: 'ANULADO' },
      { value: 'CREADO', text: 'CREADO' },
      { value: 'POR VERIFICAR', text: 'POR VERIFICAR' },
      { value: 'PROCESADO', text: 'PROCESADO' },
      { value: 'TODOS', text: 'TODOS' },
      { value: 'VERIFICADO', text: 'VERIFICADO' },
    ],
    estatusP: [],
    request: {
    },
  }),
  computed: {
    ...mapState('reportes', ['item', 'vendedor']),
    profile() {
      return Profile.get();
    },
    Ejecutivo: {
      set(value) {
        this.$store.dispatch('reportes/toAssignEjecutivo', value);
      },
      get() {
        return this.vendedor;
      },
    },
    formDateDesde: {
      set(param) {
        this.desde = param;
      },
      get() {
        return this.desde;
      },
    },
    formDateHasta: {
      set(param) {
        this.hasta = param;
      },
      get() {
        return this.hasta;
      },
    },
  },
  methods: {
    ...mapActions('reportes', ['toAssignId', 'toAssignEjecutivo', 'initializingForm', 'add', 'reset']),
    data() {
      return {
        desde: this.desde,
        hasta: this.hasta,
        company_id: this.profile.organization.id,
      };
    },
    async reporte() {
      this.items_reporte = (await ventasPerdidas(this.data())).data;
    },
    generatePDF() {
      const columns = [
        { title: 'Pedido', dataKey: 'pedido', align: 'center' },
        { title: 'Ejecutivo', dataKey: 'ejecutivo', align: 'center' },
        { title: 'Material', dataKey: 'material', align: 'center' },
        { title: 'Nombre', dataKey: 'nombre', align: 'center' },
        { title: 'No Facturado', dataKey: 'perdida', align: 'center' },
        { title: 'Fecha', dataKey: 'fecha', align: 'center' },
        { title: 'Observacion', dataKey: 'acciones', align: 'center' },
      ];
      /* eslint new-cap: ["error", { "newIsCap": false }] */
      const doc = new jspdf({
        orientation: 'Landscape',
        unit: 'in',
        format: 'letter',
      });
      // text is placed using x, y coordinates
      doc.setFontSize(16).text(this.heading, 0.5, 1.0);
      // create a line under heading */
      // doc.setLineWidth(0.01).line(0.5, 1.1, 8.0, 1.1);
      // Using autoTable plugin
      doc.autoTable({
        columns,
        body: this.items_reporte,
        margin: { left: 0.5, top: 1.25 },
      });
      // Using array of sentences
      doc.save(`${this.heading}.pdf`);
    },
    exportar() {
      const data = XLSX.utils.json_to_sheet(this.items_reporte);
      const workbook = XLSX.utils.book_new();
      const filename = 'Reporte Ventas Perdidas';
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
  },
  async mounted() {
    try {
      const requests = {
        company_id: this.profile.organization.id,
      };
      this.ejecutivos = (await ejecutivo(requests)).data;// ejecutivo
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(error);
    }
  },
};
</script>
