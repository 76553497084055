<template>
  <v-row justify="center" class="mt-2 pt-2">
    <v-col cols="12" sm="4" md="2" class="mt-0 pt-0">
      <v-dialog
        v-model="showUserAccess"
        persistent
        max-width="500px"
      >
        <v-card>
          <v-card>
          <v-toolbar :color="profile.organization.color" dark>
            <span class="headline">Accesos</span>
          <v-spacer></v-spacer>
            <v-btn icon dark @click="closeUserAccess()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar>
          </v-card>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="formUsuario" class="headline" sm="4" md="2" label="Usuario" readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-card>
              <v-treeview
                dense
                selectable
                v-model="selection"
                item-disabled="locked"
                :items="modelAccess"
              ></v-treeview>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="closeUserAccess()">Cerrar</v-btn>
            <v-btn color="blue darken-1" outlined @click="saveAccess()">Guardar</v-btn>
          </v-card-actions>
         </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapState, mapActions, mapMutations } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import { saveAccess } from '../../config/api/usuarios';

export default {
  name: 'user-access',
  data() {
    return {
      status: true,
      selection: [],
    };
  },
  computed: {
    ...mapState('userProfile', ['profile']),
    ...mapState('usuarios', ['item', 'showUserAccess', 'first_name', 'id', 'usersAccess', 'isShow']),
    profile() {
      return Profile.get();
    },
    modelAccess: {
      get() {
        return this.usersAccess.model;
      },
    },
    formId: {
      set(value) {
        this.$store.dispatch('usuarios/toAssignId', value);
      },
      get() {
        return this.id;
      },
    },
    formUsuario: {
      set(value) {
        this.$store.dispatch('usuarios/toAssignNombre', value);
      },
      get() {
        return this.first_name;
      },
    },
  },
  methods: {
    ...mapMutations('message', ['MESSAGE', 'TYPE', 'LOADING']),
    ...mapActions('message', ['run']),
    ...mapActions('usuarios', ['add', 'clean', 'updatelista', 'reset', 'closeUserAccess', 'toAssignId']),
    data() {
      return {
        id: this.formId,
        access: this.selection,
      };
    },
    async saveAccess() {
      this.response(await saveAccess(this.data()));
    },
    response(api) {
      this.$store.commit('message/LOADING', true);
      try {
        this.closeUserAccess();
        this.$store.commit('message/MESSAGE', api.data.message);
        this.$store.commit('message/RELOAD', false);
        this.$store.commit('message/TYPE', api.data.error ? 'error' : 'success');
        this.$store.dispatch('message/run');
      } catch (error) {
        // eslint-disable-next-line no-alert
        alert(error);
      }
      this.$store.commit('message/LOADING', false);
    },
  },
  created() {
    try {
      const id = 0;
      this.$store.dispatch('usuarios/usersAccess', id);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(error);
    }
  },
  watch: {
    showUserAccess: {
      handler() {
        this.selection.splice(0, this.selection.length);
      },
    },
    usersAccess: {
      handler() {
        this.usersAccess.access.forEach((item) => {
          this.selection.push(item.society.concat(':', item.center, ':', item.warehouse));
        });
      },
    },
  },
};
</script>

<style>
  .roles-checkbox-container label{
    font-size: 9px;
    font-weight: 600;
    margin-left: 5px;
  }
</style>
