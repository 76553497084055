export default {

  name: 'userProfile',

  namespaced: true,

  state: {
    profile: {},

  },
  mutations: {
    SET_PERFIL(state, payload) {
      state.profile = payload;
    },
  },
  actions: {
    data({ commit }, payload) {
      commit('SET_PERFIL', payload);
    },
  },
};
