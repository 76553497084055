<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="725px">
      <v-card>
        <v-toolbar :color="profile.organization.color" dark>
            <span class="headline">Ficha Tecnica del Cliente</span>
          <v-spacer></v-spacer>
            <v-btn icon dark @click="cerrar()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar>
        <v-card-text>
          <v-container fluid>
              <v-row>
              <!--<v-col cols="12" sm="12" offset="5">
              <span class="headline" style="color: black">Producto</span>
              </v-col>-->
                <v-col cols="3">
                <v-subheader class="headline" style="color: black">Cliente:</v-subheader>
                </v-col>
                <v-col cols="9">
                <v-text-field v-model="accountname" filled readonly></v-text-field>
                </v-col>
                <v-col cols="3">
                <v-subheader class="headline" style="color: black">Rif:</v-subheader>
                </v-col>
                <v-col cols="9">
                <v-text-field v-model="account_no" filled readonly></v-text-field>
                </v-col>
                <v-col cols="3">
                <v-subheader class="headline" style="color: black">Telefono:</v-subheader>
                </v-col>
                <v-col cols="9">
                <v-text-field v-model="phone" filled readonly></v-text-field>
                </v-col>
                <v-col cols="3">
                <v-subheader class="headline" style="color: black">Email:</v-subheader>
                </v-col>
                <v-col cols="9">
                <v-text-field v-model="correo" filled readonly></v-text-field>
                </v-col>
                <v-col cols="3">
                <v-subheader class="headline" style="color: black">Direccion:</v-subheader>
                </v-col>
                <v-col cols="9">
                <v-text-field v-model="direction" filled readonly></v-text-field>
                </v-col>
                <v-col cols="3">
                <v-subheader class="headline" style="color: black">Es agente de retención?</v-subheader>
                </v-col>
                <v-col cols="9">
                <v-text-field v-model="agente_retencion" filled readonly></v-text-field>
                </v-col>
                <v-col cols="3">
                <v-subheader class="headline" style="color: black">Limite de Credito:</v-subheader>
                </v-col>
                <v-col cols="9">
                <v-text-field v-model="limite_credito" filled readonly></v-text-field>
                </v-col>
                <v-col cols="3">
                <v-subheader class="headline" style="color: black">Zona:</v-subheader>
                </v-col>
                <v-col cols="9">
                <v-text-field v-model="zona" filled readonly></v-text-field>
                </v-col>
                <v-col cols="3">
                <v-subheader class="headline" style="color: black">Ruta:</v-subheader>
                </v-col>
                <v-col cols="9">
                <v-text-field v-model="ruta" filled readonly></v-text-field>
                </v-col>
                <v-col cols="3">
                <v-subheader class="headline" style="color: black">Poblacion:</v-subheader>
                </v-col>
                <v-col cols="9">
                <v-text-field v-model="poblacion" filled readonly></v-text-field>
                </v-col>
                <v-col cols="3" v-show="formulario()">
                <v-subheader class="headline" style="color: black">Condicion de Cobro:</v-subheader>
                </v-col>
                <v-col cols="9" v-show="formulario()">
                <v-text-field v-model="cond_cobro" filled readonly></v-text-field>
                </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-container>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn color="red" text @click="cerrar()"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapState, mapActions, mapMutations } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// import { customers } from '../../config/api/customers';
// eslint-disable-next-line import/extensions

export default {
  name: 'customer-list',
  data: () => ({
    search: '',
  }),
  computed: {
    ...mapState('customers', ['item', 'show', 'accountname', 'account_no', 'phone', 'correo', 'direction', 'agente_retencion',
      'limite_credito', 'zona', 'ruta', 'poblacion', 'cond_cobro']),
    profile() {
      return Profile.get();
    },
    formAccountno: {
      set(value) {
        this.$store.dispatch('customers/toAssignAccountno', value);
      },
      get() {
        return this.account_no;
      },
    },
  },
  methods: {
    ...mapMutations('customers', ['SHOW']),
    ...mapActions('customers', ['cerrar']),
    data() {
      return {
        account_no: this.account_no,
      };
    },
    formulario() {
      return this.profile.organization.name === 'Hierro Barquisimeto' ? 1 : 0;
    },
  },
  /* async mounted() {
    const requests = {
      user_id: this.profile.user.id,
      company_id: this.profile.organization.id,
      method: 'customers',
    };
    const data = await customers(requests);
    this.items = data.data;
    this.loading = false;
  }, */
};
</script>
