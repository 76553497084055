// eslint-disable-next-line import/extensions
import Profile from '../plugins/profile';

function versionCurrent() {
  return '1.0.33';
}

function version({ next, router }) {
  if (Profile.check()) {
    const profile = Profile.get();
    if (profile.version === null || profile.version === undefined || profile.version === '' || profile.version !== versionCurrent()) {
      Profile.clear();
      // eslint-disable-next-line
      window.alert('Su sesión ha sido cerrada debido a que existen cambios de version en el sistema. por favor, vuelva a ingresar.');
      return router.push({ name: 'login' });
    }
  }
  return next();
}

export { versionCurrent, version };
