<template>
  <v-row justify="center">
    <v-dialog v-model="showotrospagos" persistent max-width="600px">
      <v-card>
        <v-toolbar :color="profile.organization.color" dark height="50px">
          <v-icon class="mr-2">
            mdi-application
          </v-icon>
            <h1 class="title mt-2 white--text">
              Agregar - Otros Pagos
            </h1>
            <v-spacer></v-spacer>
            <h1 class="title mt-2 white--text">
              Monto: {{forTotal}}
            </h1>
          <v-spacer></v-spacer>
            <v-btn icon dark @click="close()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                required
                label="monto"
                type="number"
                v-model="formMount"
                :rules="MountRules">
                </v-text-field>
              </v-col>
               <v-radio-group row v-model="formType">
                <v-radio
                  label="Ajustes +"
                  color="primary"
                  value="Ajustes+"
                ></v-radio>
                <v-radio
                  label="Ajustes -"
                  color="primary"
                  value="Ajustes-"
                ></v-radio>
                <v-radio
                  v-show="profile.organization.org == '5022' || profile.organization.org == '4002' || profile.organization.org == '2002' || profile.organization.org == '1002'"
                  label="IGTF"
                  color="primary"
                  value="IGTF"
                ></v-radio>
                <v-radio
                  v-show="profile.organization.org == '5022' || profile.organization.org == '4002' || profile.organization.org == '2002' || profile.organization.org == '1002'"
                  label="IVA"
                  color="primary"
                  value="IVA"
                ></v-radio>
                <v-radio
                  v-show="profile.organization.org == '6000'"
                  label="IGV"
                  color="primary"
                  value="IGV"
                ></v-radio>
                <v-radio
                  label="Sobrantes"
                  color="deep-purple lighten-2"
                  value="Sobrantes"
                ></v-radio>
              </v-radio-group>
              <v-col cols="12" sm="12">
                <v-textarea
                  name="input-7-1"
                  label="Nota"
                  rows="1"
                  v-model="formNote"
                  hint="Observación"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12">
                <h4 class="title mt-2 black--text">
                  Monto a Descontar: {{formDescuento}}
                </h4>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn color="red" text @click="close()"> Cerrar </v-btn>
            <v-btn color="primary" text @click="add()"> Agregar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapActions, mapState, mapMutations } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';

export default {
  name: 'payment-crud',
  data: () => ({
    MountRules: [
      (n) => !!n || 'Debe colocar un monto',
      (n) => n > 0 || 'El monto debe ser positivo',
      (n) => n <= 99 || 'El monto excede la cantidad de digitos permitidos',
    ],
    reset: false,
    vType: '',
    vMount: 0,
    vNote: '',
    dmonto: 0,
    Aticipo: 0,
    posicion: 0,
  }),
  computed: {
    ...mapState('userProfile', ['profile']),
    ...mapState('cobranza', ['title', 'showPd', 'showotrospagos', 'payments', 'pase', 'DescDoc', 'Banco', 'Doc', 'client', 'selected', 'Total', 'show']),
    profile() {
      return Profile.get();
    },
    formType: {
      set(param) {
        this.vType = param;
        this.reset = false;
      },
      get() {
        return this.vType;
      },
    },
    forTotal: {
      get() {
        let Cs = 0;
        this.selected.forEach((DescPosic) => {
          Cs += parseFloat(DescPosic.monto);
        });
        return Cs.toFixed(2);
      },
    },
    formMount: {
      set(param) {
        this.vMount = param;
        this.reset = false;
      },
      get() {
        return this.vMount;
      },
    },
    formNote: {
      set(param) {
        this.vNote = param;
        this.reset = false;
      },
      get() {
        return this.vNote;
      },
    },
    formDescuento: {
      get() {
        return this.CalcularD();
      },
    },
    DescPosic: {
      get() {
        let posiciones = [];

        posiciones = this.selected;
        return (this.selected.length > 0) ? posiciones : 0;
      },
    },
  },
  methods: {
    ...mapMutations('message', ['MESSAGE', 'TYPE', 'LOADING']),
    ...mapActions('message', ['run']),
    ...mapMutations('cobranza', ['SHOWPD']),
    ...mapActions('cobranza', ['closeOtrosPagos']),
    getID() {
      return this.Banco.length > 0 ? this.Banco[this.Banco.length - 1].ID + 10 : 10;
    },
    resetAll() {
      this.vMount = 0;
      this.vNote = '';
      this.reset = true;
    },
    add() {
      if (this.validateForm()) {
        let verificar = 0;
        /* this.Banco.push({
          banco_id: null,
          banco_origen: null,
          collectionid: this.Banco[0].collectionid,
          forma_pago: 'Otro Pago',
          fecha_valor: null,
          monto: parseFloat(this.formDescuento).toFixed(2),
          referencia_ban: null,
          posicion_id: null,
          observacion: this.formNote,
        }); */

        for (let i = 0; i < this.selected.length; i += 1) {
          for (let j = 0; j < this.Doc.length; j += 1) {
            if (this.Doc[j].nro_doc === this.selected[i].nro_doc) {
              if ((this.Doc[j].monto - this.formMount) < 0) {
                verificar = 1;
              } else {
                this.Doc.splice(j, 1, {
                  nro_doc: this.Doc[j].nro_doc,
                  monto: (this.Doc[j].monto - this.formMount).toFixed(2),
                  ejercicio: this.Doc[j].ejercicio,
                  observacion: this.Doc[j].observacion,
                  referencia_doc: this.Doc[j].referencia_doc,
                  discount: this.Doc[j].discount,
                  asignacion: this.Doc[j].asignacion,
                });
                this.alert('Se Creo Posicion');
              }
            }
          }
        }

        const posicion = this.Doc.length;
        if (verificar === 1) {
          this.alert('No se puede generar, Diferecia Negativa', 'warning', false);
        } else if (this.Doc[posicion - 1].nro_doc === this.formType) {
          const Ma = parseFloat(parseFloat(this.Doc[posicion - 1].monto) + parseFloat(this.formDescuento)).toFixed(2);
          let clave = 0;
          if (this.formType === 'Ajustes-' && Ma < 0) {
            clave = 40;
          } else if (this.formType === 'Ajustes+' && Ma > 0) {
            clave = 50;
          } else if (this.formType === 'IVA' || this.formType === 'IGV' || this.formType === 'IGTF') {
            clave = 50;
          } else if (this.formType === 'Sobrantes') {
            if (this.client === 'Ventas Empleados' || this.client === 'Facturador 01' || this.client === 'FACT_01' || this.client === 'FACT_02' || this.client === 'FACT_03' || this.client === 'FACT_04' || this.client === 'VENTASEMP') {
              clave = 15;
            } else {
              clave = 19;
            }
          }
          this.Doc.splice((posicion - 1), 1, {
            nro_doc: this.formType,
            monto: Ma,
            ejercicio: this.Doc[0].ejercicio,
            key: clave,
            pos_ref: 0,
            discount: false,
            asignacion: '',
          });
        } else {
          let clave = 0;
          let mon = 0;
          if (this.formType === 'Ajustes-') {
            clave = 40;
            mon = parseFloat(this.formDescuento).toFixed(2) * -1;
          } else if (this.formType === 'Ajustes+') {
            clave = 50;
            mon = parseFloat(this.formDescuento).toFixed(2);
          } else if (this.formType === 'IVA' || this.formType === 'IGV' || this.formType === 'IGTF') {
            clave = 50;
            mon = parseFloat(this.formDescuento).toFixed(2);
          } else if (this.formType === 'Sobrantes') {
            if (this.client === 'Facturador 01' || this.client === 'FACT_01' || this.client === 'FACT_02' || this.client === 'FACT_03' || this.client === 'FACT_04' || this.client === 'VENTASEMP') {
              clave = 15;
            } else {
              clave = 19;
            }
            mon = parseFloat(this.formDescuento).toFixed(2);
          }
          this.Doc.push({
            nro_doc: this.formType,
            monto: mon,
            ejercicio: this.Doc[0].ejercicio,
            key: clave,
            pos_ref: 0,
            discount: false,
            asignacion: '',
          });
        }

        console.log(this.DescDoc);
        this.closeOtrosPagos();
        this.resetAll(); // Se reinicia todos los valores
        // this.alert('Se Creo Posicion');
      }
    },
    close() {
      this.resetAll();
      this.closeOtrosPagos();
    },
    validateForm() {
      if (this.formMount <= 0 || this.vMount <= 0) {
        this.alert('El monto debe ser mayor de cero', 'warning', false);
        return false;
      }

      return true;
    },
    alert(message, type = 'info', reload = true) {
      this.$store.commit('message/MESSAGE', message);
      this.$store.commit('message/TYPE', type);
      this.$store.commit('message/RELOAD', reload);
      this.$store.dispatch('message/run');
    },
    CalcularD() {
      /* let Cs = 0;
      console.log(this.selected);
      this.selected.forEach((DescPosic) => {
        // (DescPosic.monto);
        Cs += parseFloat(DescPosic.monto);
      }); */
      return this.formMount; // ( * parseFloat(Cs)) / 100;
    },
  },
  created() {
    try {
      const requests = {
        company_id: this.profile.organization.id,
        user_id: this.profile.user.id,
        user_rol: this.profile.user.rol,
      };

      this.$store.dispatch('cobranza/banks', requests);
    } catch (error) {
      // eslint-disable-next-line no-alert
      this.alert(error);
    }
  },
};
</script>
<style>
  .Documentos-checkbox-container label{
    font-size: 9px;
    font-weight: 600;
    margin-left: 5px;
  }
</style>
