<template>
  <v-row justify="center" class="mt-2 pt-2">
    <v-col cols="12" sm="4" md="2" class="mt-0 pt-0">
      <v-dialog
        v-model="show1"
        persistent
        max-width="900px"
      >
        <v-card>
          <v-card>
          <v-toolbar :color="profile.organization.color" dark>
            <span class="headline">Listas de Precios</span>
          <v-spacer></v-spacer>
            <v-btn icon dark @click="cerrar()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar>
          </v-card>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="formUsuario"
                    class="headline"
                    sm="4"
                    md="2"
                    label="Usuario"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-card>
              <v-card-text>
                <v-container class="roles-checkbox-container">
                 <v-row>
                  <v-col
                    v-for="(item, index) in userlista" :key="index"
                    cols="12"
                    xs="6"
                    sm="6"
                    md="4"
                  >
                    <input class="pt-4" type="checkbox" :id="item.descripcion" v-model="item.estatus">
                    <label :for="item.descripcion">{{ item.descripcion }}</label>
                  </v-col>
                </v-row>
              </v-container>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="cerrar()">Cerrar</v-btn>
            <v-btn color="blue darken-1" text @click="updatelista()">Continuar</v-btn>
          </v-card-actions>
         </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapState, mapActions, mapMutations } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import { updatelist } from '../../config/api/usuarios';

export default {
  name: 'user-list',
  data() {
    return {
      status: true,
      modulo: {
        selected: [],
      },
    };
  },
  computed: {
    ...mapState('userProfile', ['profile']),
    ...mapState('usuarios', ['item', 'show1', 'first_name', 'id', 'userlista', 'userlist', 'listaUsuario', 'isShow']),
    profile() {
      return Profile.get();
    },
    formId: {
      set(value) {
        this.$store.dispatch('usuarios/toAssignId', value);
      },
      get() {
        return this.id;
      },
    },
    formUsuario: {
      set(value) {
        this.$store.dispatch('usuarios/toAssignNombre', value);
      },
      get() {
        return this.first_name;
      },
    },
    formList: {
      set(value) {
        this.$store.dispatch('usuarios/updatelista', value);
      },
      get() {
        return this.userlist;
      },
    },
  },
  methods: {
    ...mapMutations('message', ['MESSAGE', 'TYPE', 'LOADING']),
    ...mapActions('message', ['run']),
    ...mapMutations('usuarios', ['SHOW1']),
    ...mapActions('usuarios', ['add', 'listUser', 'clean', 'updatelista', 'reset', 'cerrar', 'toAssignId']),
    data() {
      console.log(this.formId);
      console.log(this.userlista);
      return {
        id: this.formId,
        listas: this.userlista,
      };
    },
    async updatelista() {
      this.response(await updatelist(this.data()));
      console.log(this.data());
    },
    response(api) {
      this.$store.commit('message/LOADING', true);
      try {
        this.$store.commit('message/MESSAGE', api.data.message);
        this.$store.commit('message/TYPE', 'success');
        this.$store.dispatch('message/run');
      } catch (error) {
        // eslint-disable-next-line no-alert
        alert(error);
      }
      this.$store.commit('message/LOADING', false);
    },
  },
  created() {
    try {
      /* console.log(this.formId);
      const usuario = this.formId;
      console.log('j');
      const requests = {
        company_id: this.profile.organization.id,
        usuario,
      }; */
      const id = 0;
      console.log(id);
      this.$store.dispatch('usuarios/getListas', id);
      // this.$store.dispatch('usuarios/data', );
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(error);
    }
  },
};
</script>

<style>
  .roles-checkbox-container label{
    font-size: 9px;
    font-weight: 600;
    margin-left: 5px;
  }
</style>
