<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <v-card>
          <v-container class="text-center pt-5 mt-5">
            <p class="display-4">COBRANZA ENVIADA A SAP</p>
            <button @click="cerrarse()"> cerrar </button>
          </v-container>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// eslint-disable-next-line import/extensions
// import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions

export default {
  name: 'Mensaje202',
  components: {
  },
  methods: {
    cerrarse() {
      window.close();
    },
  },
};
</script>
