<template>
  <v-app>
    <Toolbar/>
    <v-main>
      <v-container fluid>
        <v-card>
          <v-card-title>
             <v-icon class="mr-2">
               mdi-application
              </v-icon>
              {{profile.organization.name}} - Ejecutivos
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-card class="mx-auto" width="98%">
          <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            loading="true"
            loading-text="Cargando Datos"
            sort-by="user_name"
            class="elevation-24"
            :header-props="{
              sortByText: 'Ordenar',
            }"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-text': 'Ejecutivos por página',
            }"
            multiSort
          >
          <template v-slot:[`item.estado`]="{ item }">
            <v-chip :color="getColor(item.estado)" dark x-small>
              <span v-if="item.estado === '1'"> Activo </span>
              <span v-else> Inactivo </span>
            </v-chip>
          </template>
          </v-data-table>
          </v-card>
        </v-card>
      </v-container>
    </v-main>
    <SetLogout/>
   <Historicobcv/>
  </v-app>

</template>

<script>
// eslint-disable-next-line import/extensions
import Profile from '../plugins/profile';
import executives from '../config/api/executives';
// eslint-disable-next-line import/extensions
import Toolbar from '../components/Toolbar';
// eslint-disable-next-line import/extensions
import SetLogout from '../components/SetLogout';
// eslint-disable-next-line import/extensions
import Historicobcv from '../components/Historicobcv';

export default {
  name: 'executives',
  components: {
    Toolbar, SetLogout, Historicobcv,
  },
  data: () => ({
    logo: 'http://www.sistemacrm.com.ve/backend/public/Company/maferca.png',
    search: '',
    headers: [
      { text: 'Usuario', value: 'user_name', align: 'center' },
      { text: 'Nombres', value: 'first_name', align: 'center' },
      { text: 'Apellidos', value: 'last_name', align: 'center' },
      { text: 'Teléfono Nª1', value: 'phone_mobile', align: 'center' },
      { text: 'Email', value: 'email1', align: 'center' },
      { text: 'Estado', value: 'estado', align: 'center' },
    ],

    items: [],

    /* request: {
      user_id: '14623414',
      company_id: '1',
    }, */
  }),
  computed: {
    profile() {
      return Profile.get();
    },
  },
  methods: {
    getColor(estado) {
      return estado === '1' ? 'green' : 'red';
    },
  },
  /* async created() {
    const data = await executives(this.request);
    this.items = data.data;
  }, */
  async created() {
    const requests = {
      user_id: this.profile.user.id,
      company_id: this.profile.organization.id,
      method: 'executives',
    };
    const data = await executives(requests);
    this.items = data.data;
    this.loading = false;
  },
};
</script>
