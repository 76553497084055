import {
  modules,
} from '../../config/api/modulos';

export default {

  name: 'modules',
  namespaced: true,
  state: {
    item: [],
  },
  mutations: {
    SET_DATA(state, parameter) {
      state.item = parameter.data;
    },
  },
  actions: {
    async data({ commit }, parameter) {
      commit('SET_DATA', await modules(parameter));
    },
  },
};
