var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('Toolbar'),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-application ")]),_vm._v(" "+_vm._s(_vm.profile.organization.name)+" - Usuarios "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.validar(_vm.profile.permissions,'40002')),expression:"validar(profile.permissions,'40002')"}],staticClass:"mx-4 mb-4",attrs:{"title":"Agregar","tile":"","color":"grey lighten-2"},on:{"click":function($event){return _vm.add()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1),_c('v-card',{staticClass:"mx-auto",attrs:{"width":"98%"}},[_c('v-data-table',{staticClass:"elevation-24",attrs:{"headers":_vm.headers,"items":_vm.item,"search":_vm.search,"no-data-text":"no hay datos","sort-by":"user_name","header-props":{
            sortByText: 'Ordenar',
          },"footer-props":{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            'items-per-page-text': 'usuarios por página',
          },"multiSort":""},scopedSlots:_vm._u([{key:"item.estado",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"icon":"","color":_vm.getColor(item.estado),"dark":"","x-small":""}},[(item.estado == '1')?_c('span',[_vm._v(" Activo ")]):_c('span',[_vm._v(" Inactivo ")])])]}},{key:"item.acciones",fn:function(ref){
          var item = ref.item;
return [(item.estado == 1)?_c('v-icon',{staticClass:"ma-2",attrs:{"title":"Activo","color":"primary","reloadPage":""},on:{"click":function($event){return _vm.actualizarEstado(item)}}},[_vm._v(" mdi-thumb-up ")]):_c('v-icon',{staticClass:"ma-2",attrs:{"title":"Inactivo","color":"error","reloadPage":""},on:{"click":function($event){return _vm.actualizarEstado(item)}}},[_vm._v(" mdi-thumb-down ")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.validar(_vm.profile.permissions,'40004')),expression:"validar(profile.permissions,'40004')"}],staticClass:"mr-2",attrs:{"title":"Editar Usuario","color":"#0091EA","reloadPage":""},on:{"click":function($event){return _vm.update(item)}}},[_vm._v(" mdi-pencil-outline ")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.validar(_vm.profile.permissions,'40003')),expression:"validar(profile.permissions,'40003')"}],staticClass:"mr-2",attrs:{"title":"Eliminar Usuario","color":"#D50000"},on:{"click":function($event){return _vm.eliminarUser(item)}}},[_vm._v(" mdi-delete-outline ")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.validar(_vm.profile.permissions,'33001')),expression:"validar(profile.permissions,'33001')"}],staticClass:"mr-2",attrs:{"title":"Listas de Precios","color":"grey"},on:{"click":function($event){return _vm.listUser(item)}}},[_vm._v(" mdi-format-list-checkbox ")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.validar(_vm.profile.permissions,'33001')),expression:"validar(profile.permissions,'33001')"}],staticClass:"mr-2",attrs:{"title":"Accesos","color":"blue"},on:{"click":function($event){return _vm.editAccess(item)}}},[_vm._v(" mdi mdi-key-chain ")])]}}],null,true)})],1),_c('UserForm'),_c('UserList'),_c('UserAcccess'),_c('Historicobcv')],1)],1)],1),_c('SetLogout')],1)}
var staticRenderFns = []

export { render, staticRenderFns }