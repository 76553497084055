import httpClient from '../axios';

const URL = 'reportes';

const reportes = async (data) => httpClient.post(URL, data);

const URL_EJECUTIVO = 'ejecutivo';

const ejecutivo = async (data) => httpClient.post(URL_EJECUTIVO, data);

const URL_CONDICION = 'condicion';

const condicion = async (data) => httpClient.post(URL_CONDICION, data);

const URL_CODIGO = 'codigo';

const codigo = async (data) => httpClient.post(URL_CODIGO, data);

const URL_GUIA = 'guia';

const guia = async (data) => httpClient.post(URL_GUIA, data);

const URL_DETALLE = 'detalle';

const detalle = async (data) => httpClient.post(URL_DETALLE, data);

const URL_COBRANZA = 'cobranzaReporte';

const cobranzaReporte = async (data) => httpClient.post(URL_COBRANZA, data);

const URL_VP = 'ventasPerdidas';

const ventasPerdidas = async (data) => httpClient.post(URL_VP, data);

const URL_LISTA = 'ListaMateriales';

const listaprecio = async (data) => httpClient.post(URL_LISTA, data);

const URL_LISTAS = 'lista_precio';

const listado = async (data) => httpClient.post(URL_LISTAS, data);

export {
  reportes,
  ejecutivo,
  condicion,
  codigo,
  guia,
  detalle,
  cobranzaReporte,
  ventasPerdidas,
  listaprecio,
  listado,
};
