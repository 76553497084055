<template>
  <v-app>
    <Toolbar/>
    <v-main>
      <v-container fluid>
        <v-card-title>
          <v-icon class='mr-2'> mdi-application </v-icon>
          {{profile.organization.name}} - Ajustes Generales
          <v-spacer></v-spacer>
          <v-text-field
            v-model='search'
            append-icon='mdi-magnify'
            label='Buscar'
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-card class="mx-auto" width="98%">
            <v-data-table
                :headers="headers"
                :items="item"
                :search="search"
                no-data-text='no hay datos'
                :loading='loading'
                loading-text="Cargando"
                sort-by="account_no"
                class="elevation-24"
                :header-props="{
                  sortByText: 'Ordenar',
                  class: 'title',
                }"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-arrow-collapse-left',
                  lastIcon: 'mdi-arrow-collapse-right',
                  prevIcon: 'mdi-minus',
                  nextIcon: 'mdi-plus',
                  'items-per-page-text': 'Clientes por página',
                }"
                multiSort
              >
              <template v-slot:[`item.acciones`]='{ item }'>
              <v-btn icon>
                <v-icon
                  class='px-auto'
                  title='Visualizar'
                  @click='show(item)'
                  color='#0091EA'
                >
                  mdi-circle-edit-outline
                </v-icon>
              </v-btn>
            </template>
           </v-data-table>
          </v-card>
        <AjustesForm/>
      </v-container>
    </v-main>
    <SetLogout/>
  </v-app>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapActions, mapState } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import Toolbar from '../../components/Toolbar';
// eslint-disable-next-line import/extensions
import AjustesForm from './AjustesForm';
// eslint-disable-next-line import/extensions
// import ajustes from '../../config/api/ajustes';
// eslint-disable-next-line import/extensions
import SetLogout from '../../components/SetLogout';

export default {
  name: 'ajustes',
  components: {
    Toolbar, AjustesForm, SetLogout,
  },
  data: () => ({
    logo: 'http://www.sistemacrm.com.ve/backend/public/Company/maferca.png',
    search: '',
    headers: [
      { text: 'Rango de los pedidos visibles en días', value: 'diasvisible', align: 'center' },
      { text: 'Rango de las cobranzas visibles en días', value: 'diasvence', align: 'center' },
      // { text: 'Cancelar el pedido', value: 'Si', align: 'center' },
      // { text: 'Almacen', value: 'almacenEjecutivoFree', align: 'center' },
      // { text: 'IVA', value: 'iva', align: 'center' },
      { text: 'Acciones', value: 'acciones', sortable: false },
    ],
    // items: [],
    loading: false,
  }),
  computed: {
    ...mapState('ajustes', ['item']),
    profile() {
      return Profile.get();
    },
  },
  methods: {
    ...mapActions('ajustes', ['show']),
    data() {
      return {
        company_id: this.profile.organization.id,
        user_id: this.profile.user.id,
      };
    },
  },
  created() {
    try {
      const requests = {
        company_id: this.profile.organization.id,
        user_id: this.profile.user.id,
      };
      this.$store.dispatch('ajustes/data', requests);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(error);
    }
  },
};
</script>
