import {
  rol,
  getventa,
  actualizar,
  eliminar,
  modules,
  // modulos,
} from '../../config/api/roles';

export default {

  name: 'roles',
  namespaced: true,
  state: {
    // PRINCIPAL
    item: [],
    // MODULOS
    accion: null,
    accionRol: [],
    // FORMULARIO
    show: false,
    isAdd: false,
    isUpdate: false,
    title: '',
    //  CAMPOS DE FORMULARIO
    id: '',
    idRol: '',
    nombre: '',
    notas: '',
    metodo: '',
    //  DATOS SELECCIONADOS
    formData: [],
    modules: [],
  },
  mutations: {
    // PRINCIPAL
    SET_DATA(state, parameter) {
      state.item = parameter.data;
      console.log(parameter);
    },
    // FORMULARIO
    SHOW(state) {
      console.log(state.show);
      state.show = !state.show;
    },
    SET_IS_ADD(state, parameter) {
      state.isAdd = parameter;
    },
    SET_IS_UPDATE(state, parameter) {
      state.isUpdate = parameter;
    },
    SET_TITLE(state, parameter) {
      state.title = parameter;
    },
    SET_IS_METODO(state, parameter) {
      console.log(parameter);
      state.metodo = parameter;
    },
    // prueba
    SET_ID_DATA(state, parameter) {
      console.log(parameter);
      state.id = parameter.data;
    },
    // CAMPOS DE FORMULARIO MODAL
    SET_ID(state, parameter) {
      console.log(parameter);
      state.id = parameter;
    },
    SET_ROL(state, parameter) {
      console.log(parameter);
      state.nombre = parameter;
    },
    SET_OBSERVACION(state, parameter) {
      console.log(parameter);
      state.notas = parameter;
    },
    SET_ACCION(state, parameter) {
      console.log(parameter);
      state.accion = parameter.data;
    },
    // SELECCIONADO
    SET_FORM_DATA(state, parameter) {
      state.formData = parameter;
    },
    SET_MODULES(state, parameter) {
      console.log(parameter);
      state.modules = parameter.data;
    },
  },
  actions: {
    // PRINCIPAL
    async data({ commit }, parameter) {
      commit('SET_DATA', await rol(parameter));
    },
    // FORMULARIO
    async add({ commit }) {
      commit('SHOW');
      commit('SET_TITLE', 'AGREGAR');
      commit('SET_IS_ADD', true);
      commit('SET_ID', 0);
    },
    async update({ commit }, parameter) {
      commit('SHOW');
      commit('SET_TITLE', 'EDITAR');
      commit('SET_IS_UPDATE', true);
      commit('SET_ID', parameter.id);
      commit('SET_ROL', parameter.nombre);
      commit('SET_OBSERVACION', parameter.notas);
      commit('SET_IS_METODO', parameter.metodo);
      commit('SET_MODULES', await modules(parameter));
    },
    async reset({ commit }) {
      commit('SHOW');
      commit('SET_IS_ADD', false);
      commit('SET_IS_UPDATE', false);
      commit('SET_ID', '');
      commit('SET_ROL', '');
      commit('SET_OBSERVACION', '');
      commit('SET_MODULES', await modules());
    },
    toAssignId({ commit }, parameter) {
      commit('SET_ID', parameter);
    },
    toAssignRol({ commit }, parameter) {
      commit('SET_ROL', parameter);
    },
    toAssignObservacion({ commit }, parameter) {
      commit('SET_OBSERVACION', parameter);
    },
    toAssignAccion({ commit }, parameter) {
      commit('SET_ACCION', parameter);
    },
    async getVenta({ commit }, parameter) {
      commit('SET_ID', await getventa(parameter));
    },
    async actualizarRol({ commit }, parameter) {
      commit('SET_ID', await actualizar(parameter));
      commit('SET_DATA', await rol(parameter));
    },
    async eliminarRol({ commit }, parameter) {
      commit('SET_ID', await eliminar(parameter));
      commit('SET_DATA', await rol(parameter));
      // window.location.reload();
    },
    async getModules({ commit }, parameter) {
      commit('SET_ID', parameter.id);
      commit('SET_MODULES', await modules());
    },

  },
};
