<template>
  <v-app>
    <Toolbar/>
    <v-main>
      <v-container fluid>
        <v-card>
          <v-card-title>
             <v-icon class="mr-2">
               mdi-application
              </v-icon>
              {{profile.organization.name}} - Clientes
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
            <v-card class="mx-auto" width="98%">
              <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                no-data-text='no hay datos'
                :loading='loading'
                loading-text="Cargando"
                sort-by="account_no"
                class="elevation-24"
                :header-props="{
                  sortByText: 'Ordenar',
                  class: 'title',
                }"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-arrow-collapse-left',
                  lastIcon: 'mdi-arrow-collapse-right',
                  prevIcon: 'mdi-minus',
                  nextIcon: 'mdi-plus',
                  'items-per-page-text': 'Clientes por página',
                }"
                multiSort
              >
              <template v-slot:[`item.imagenurl`]="{ item }">
                <v-img
                  :src="item.imagenurl"
                  height="auto"
                  width="60%"
                >
                </v-img>
              </template>
              <template v-slot:[`item.agente_retencion`]="{ item }">
                <v-chip :color="getColor(item.agente_retencion)" dark x-small>
                  <span v-if="item.agente_retencion"> Si </span>
                  <span v-else> No </span>
                </v-chip>
              </template>
              <template v-slot:[`item.acciones`]='{ item }'>
              <v-btn icon>
                <v-icon
                  v-show="validar(profile.permissions, '30002')"
                  class='px-auto'
                  title='Visualizar'
                  @click='show(item)'
                  color='#0091EA'
                >
                  mdi-eye
                </v-icon>
              </v-btn>
              </template>
              </v-data-table>
            </v-card>
          <clienteForm/>
        </v-card>
      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>
    <SetLogout/>
   <Historicobcv/>
  </v-app>

</template>

<script>
// eslint-disable-next-line import/extensions
import { mapActions, mapState } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import { customers } from '../../config/api/customers';
// eslint-disable-next-line import/extensions
import clienteForm from './Vercliente';
// eslint-disable-next-line import/extensions
import Toolbar from '../../components/Toolbar';
// eslint-disable-next-line import/extensions
import SetLogout from '../../components/SetLogout';
// eslint-disable-next-line import/extensions
import Historicobcv from '../../components/Historicobcv';

export default {
  name: 'customers',
  components: {
    Toolbar, clienteForm, SetLogout, Historicobcv,
  },
  data: () => ({
    search: '',
    headers: [
      { text: 'Rif', value: 'account_no', align: 'center' },
      { text: 'Nombre', value: 'accountname', align: 'center' },
      { text: 'Teléfono', value: 'phone', align: 'center' },
      { text: 'Email', value: 'correo', align: 'center' },
      { text: 'Zona', value: 'zona', align: 'center' },
      { text: 'Limite de crédito', value: 'limite_credito', align: 'center' },
      { text: 'Agente de Retención', value: 'agente_retencion', align: 'center' },
      { text: 'Acciones', value: 'acciones', sortable: false },
    ],
    items: [],
    loading: true,
  }),
  computed: {
    ...mapState('customers', ['item']),
    profile() {
      return Profile.get();
    },
  },
  methods: {
    ...mapActions('customers', ['show']),
    getColor(agenteRetencion) {
      return agenteRetencion ? 'green' : 'blue';
    },
    validar(permiso, accion) {
      let valor = false;
      permiso.forEach((permisos) => {
        if (permisos.accion === accion) {
          valor = true;
        }
      });
      return valor;
    },
  },
  async created() {
    const requests = {
      user_id: this.profile.user.id,
      company_id: this.profile.organization.id,
      method: 'customers',
    };
    const data = await customers(requests);
    this.items = data.data;
    this.loading = false;
  },
};
</script>
