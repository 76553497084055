import httpClient from '../axios';

const URL = 'products';

const products = async (data) => httpClient.post(URL, data);

const URL_CAR_ITEM = 'productoCar';

const salesorderItemCar = async (data) => httpClient.post(URL_CAR_ITEM, data);

const URL_GETPRODUCTO = 'getProducto';

const getProducto = async (data) => httpClient.post(URL_GETPRODUCTO, data);

const URL_GETPRODUCTS = 'getProducts';

const getProducts = async (data) => httpClient.post(URL_GETPRODUCTS, data);

const URL_LOANDING = 'LoandingImagen';

const imagen = async (data) => httpClient.post(URL_LOANDING, data);

const URL_LIMITSLIST = 'products/limitslist';

const LimitList = async (data) => httpClient.post(URL_LIMITSLIST, data);

const URL_EJECUTIVES = 'products/salesejecutives';

const SalesEjecutives = async (data) => httpClient.post(URL_EJECUTIVES, data);

const URL_SIMPLELIST = 'products/simplelist';

const SimpleList = async (data) => httpClient.post(URL_SIMPLELIST, data);

const URL_SAVELIMITS = 'products/savelimits';

const SaveLimits = async (data) => httpClient.post(URL_SAVELIMITS, data);

const URL_UPDATELIMITS = 'products/updatelimits';

const updateLimits = async (data) => httpClient.post(URL_UPDATELIMITS, data);

const URL_DELETELIMIT = 'products/deletelimit';

const DeleteLimit = async (data) => httpClient.post(URL_DELETELIMIT, data);

const URL_CATALOGO = 'CatalogoProductos';

const catalogo = async (data) => httpClient.post(URL_CATALOGO, data);

const URL_CATALOGO2 = 'CatalogoProductos';

const catalogo2 = async (data) => httpClient.post(URL_CATALOGO2, data);

const URL_HISTORICO = 'tasabcvHistorico';

const Historicobcv = async (data) => httpClient.post(URL_HISTORICO, data);

export {
  products,
  salesorderItemCar,
  getProducto,
  getProducts,
  imagen,
  SimpleList,
  LimitList,
  SalesEjecutives,
  SaveLimits,
  updateLimits,
  DeleteLimit,
  catalogo,
  catalogo2,
  Historicobcv,
};
