var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('Toolbar'),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-application ")]),_vm._v(" "+_vm._s(_vm.profile.organization.name)+" - Reportes "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"mt-0 pt-0",attrs:{"sm":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.ejecutivos,"label":"Ejecutivo","color":"grey darken-4"},model:{value:(_vm.Ejecutivo),callback:function ($$v) {_vm.Ejecutivo=$$v},expression:"Ejecutivo"}})],1),_c('v-col',{staticClass:"mt-0 pt-0",attrs:{"sm":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.estatus,"label":"Estatus del Pedido","color":"grey darken-4"},model:{value:(_vm.estatu),callback:function ($$v) {_vm.estatu=$$v},expression:"estatu"}})],1),_c('v-col',{staticClass:"mt-0 pt-0",attrs:{"sm":"3"}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","label":"Fecha Desde","type":"date"},model:{value:(_vm.formDateDesde),callback:function ($$v) {_vm.formDateDesde=$$v},expression:"formDateDesde"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"min":(new Date((new Date()).setMonth((new Date()).getMonth()-36))).toISOString().substr(0, 10)},model:{value:(_vm.formDateDesde),callback:function ($$v) {_vm.formDateDesde=$$v},expression:"formDateDesde"}})],1)],1),_c('v-col',{staticClass:"mt-0 pt-0",attrs:{"sm":"3"}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","label":"Fecha Hasta","type":"date"},model:{value:(_vm.formDateHasta),callback:function ($$v) {_vm.formDateHasta=$$v},expression:"formDateHasta"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"min":(new Date((new Date()).setMonth((new Date()).getMonth()-36))).toISOString().substr(0, 10)},model:{value:(_vm.formDateHasta),callback:function ($$v) {_vm.formDateHasta=$$v},expression:"formDateHasta"}})],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"mt-0 pt-0",attrs:{"sm":"8"}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"title":"Generar lista","color":"blue"},on:{"click":function($event){return _vm.reporteDetallado()}}},[_vm._v(" mdi-file-find ")])],1)],1)],1)],1),_c('v-row',[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"title":"Exportar PDF","color":"red"},on:{"click":function($event){return _vm.generatePDF()}}},[_vm._v(" mdi-file-pdf-box ")])],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"title":"Exportar Excel","color":"green"},on:{"click":function($event){return _vm.exportar()}}},[_vm._v(" mdi-file-excel ")])],1)],1)],1)],_c('v-card',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"450px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1),_c('v-card',{staticClass:"mx-auto",attrs:{"width":"98%"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items_reporte,"search":_vm.search,"loading-text":"Cargando Datos","header-props":{
                sortByText: 'Ordenar',
              },"footer-props":{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',
                'items-per-page-text': 'reportes por página',
              },"multiSort":""}})],1)],1)],2)],1),_c('Historicobcv'),_c('SetLogout')],1)}
var staticRenderFns = []

export { render, staticRenderFns }