import httpClient from '../axios';

const URL = 'login';

const authenticated = async (data) => httpClient.post(URL, data);

const URL_SAVE = 'login/save';
const saveAuthProfile = async () => httpClient.post(URL_SAVE);

export {
  authenticated,
  saveAuthProfile,
};
