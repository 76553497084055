import httpClient from '../axios';

const URL = 'role';

const rol = async (data) => httpClient.get(URL, data);

const URL_VENTA = 'getventa';

const getventa = async (data) => httpClient.post(URL_VENTA, data);

const URL_STORE = 'rolesStore';

const store = async (data) => httpClient.post(URL_STORE, data);

const URL_UPDATE = 'updateRoles';

const updated = async (data) => httpClient.post(URL_UPDATE, data);

const URL_ACTUALIZAR = 'actualizarrol';

const actualizar = async (data) => httpClient.post(URL_ACTUALIZAR, data);

const URL_DELETE = 'delete';

const eliminar = async (data) => httpClient.post(URL_DELETE, data);

const URL_MODULES = 'modules';

const modules = async (data) => httpClient.post(URL_MODULES, data);

const MODULOS = 'modulos';

const modulos = async (data) => httpClient.post(MODULOS, data);

export {
  rol,
  store,
  updated,
  getventa,
  actualizar,
  eliminar,
  modules,
  modulos,
};
