<template>
  <v-row justify="center">
    <v-dialog v-model="show2" persistent max-width="700px">
      <v-card>
        <v-toolbar :color="profile.organization.color" dark>
            <span class="headline">Tasa BCV</span>
          <v-spacer></v-spacer>
            <v-btn icon dark @click="exit()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar>
          <v-container>
            <v-row>
            <v-col cols="6" sm="4" offset="1">
                  <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        readonly
                        label="Fecha Desde"
                        v-model="formDateDesde"
                        v-bind="attrs"
                        v-on="on"
                        type="date">
                      </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formDateDesde"
                    :min="(new Date((new Date()).setMonth((new Date()).getMonth()-36))).toISOString().substr(0, 10)"
                  ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" sm="4" offset="2">
                  <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      readonly
                      label="Fecha Hasta"
                      v-model="formDateHasta"
                      v-bind="attrs"
                      v-on="on"
                      type="date">
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formDateHasta"
                    :min="(new Date((new Date()).setMonth((new Date()).getMonth()-36))).toISOString().substr(0, 10)"
                  ></v-date-picker>
                  </v-menu>
                </v-col>
                  <v-col sm="8" class="mt-0 pt-0" offset="1">
                    <v-btn icon>
                      <v-icon
                        title='Generar Tasa'
                        color='blue'
                        @click="generarTasa()"
                      >
                        mdi-file-find
                      </v-icon>
                    </v-btn>
                </v-col>
                </v-row>
              </v-container>
        <v-card class="mx-auto" width="98%">
            <v-data-table
              :headers="header"
              :items="items_tasa"
              :search="search"
              loading="true"
              loading-text="Cargando Datos"
              sort-by="Tasa"
              class="elevation-24"
              :header-props="{
                sortByText: 'Ordenar',
              }"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',
                'items-per-page-text': 'Tasa por página',
              }"
              multiSort
            >
            </v-data-table>
          </v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn color="red" text @click="exit()"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapState, mapMutations, mapActions } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../plugins/profile';
import { Historicobcv } from '../config/api/products';

export default {
  name: 'user-list',
  data: () => ({
    search: '',
    header: [
      { text: 'ID', value: 'id', align: 'center' },
      { text: 'Tasa', value: 'tasa', align: 'center' },
      { text: 'Fecha', value: 'fecha', align: 'center' },
    ],
    desde: new Date((new Date()).setMonth((new Date()).getMonth() - 1))
      .toLocaleDateString('en-GB')
      .split('/')
      .reverse()
      .join('-'),
    hasta: new Date().toLocaleDateString('en-GB')
      .split('/')
      .reverse()
      .join('-'),
    items_tasa: [],
  }),
  computed: {
    ...mapState('products', ['show2']),
    profile() {
      return Profile.get();
    },
    formDateDesde: {
      set(param) {
        this.desde = param;
      },
      get() {
        return this.desde;
      },
    },
    formDateHasta: {
      set(param) {
        this.hasta = param;
      },
      get() {
        return this.hasta;
      },
    },
  },
  methods: {
    ...mapMutations('products', ['SHOW2']),
    ...mapActions('products', ['exit']),
    data() {
      return {
        desde: this.desde,
        hasta: this.hasta,
      };
    },
    async generarTasa() {
      this.items_tasa = (await Historicobcv(this.data())).data;
      console.log(this.items_tasa);
    },
  },
};
</script>
