import httpClient from '../axios';

const URL_DOCUMENTS = 'cobranza/documents';

const cobranza = async (data) => httpClient.post(URL_DOCUMENTS, data);

const URL_CLIENTS = 'cobranza/clients';

const clients = async (data) => httpClient.post(URL_CLIENTS, data);

const URL_BANKS = 'cobranza/banks';

const banks = async (data) => httpClient.post(URL_BANKS, data);

const URL_SEND = 'cobranza/add';

const add = async (data) => httpClient.post(URL_SEND, data);

const URL_GETCOBRANZA = 'getcobranza';

const getcobranzaP = async (data) => httpClient.post(URL_GETCOBRANZA, data);

const URL_EMITIR = 'EnviarCobraza';

const emitirCobranzaSap = async (data) => httpClient.post(URL_EMITIR, data);

const URL_ANULAR = 'anularcobranza';

const anularCobranza = async (data) => httpClient.post(URL_ANULAR, data);

const URL_UPDATE = 'updatecobranza';

const updateCobranza = async (data) => httpClient.post(URL_UPDATE, data);

const URL_DELETE = 'deletecobranza';

const borrarCobranza = async (data) => httpClient.post(URL_DELETE, data);

const URL_VERIFICAR = 'verificarcobranza';

const verificarCobranza = async (data) => httpClient.post(URL_VERIFICAR, data);

const URL_REPORTE = 'pdfcobranza';

const cobranzaReporte = async (data) => httpClient.post(URL_REPORTE, data);

const URL_TIPO_PAGO = 'cobranza/tipoPago';

const tipopago = async (data) => httpClient.post(URL_TIPO_PAGO, data);

const URL_SD = 'SD';

const sd = async (data) => httpClient.post(URL_SD, data);

const URL_TC = 'text';

const textC = async (data) => httpClient.post(URL_TC, data);

const URL_VIEW = 'cobranza/view';

const view = async (data) => httpClient.post(URL_VIEW, data);

const URL_UPD = 'cobranza/view_cf';

const update = async (data) => httpClient.post(URL_UPD, data);

export {
  cobranza,
  clients,
  banks,
  add,
  getcobranzaP,
  emitirCobranzaSap,
  anularCobranza,
  verificarCobranza,
  updateCobranza,
  borrarCobranza,
  cobranzaReporte,
  tipopago,
  sd,
  textC,
  view,
  update,
};
