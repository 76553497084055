import httpClient from '../axios';

const URL = 'salesorder';

const salesorder = async (data) => httpClient.post(URL, data);

const URL_STORE = 'salesorderStore';

const store = async (data) => httpClient.post(URL_STORE, data);

const URL_UPDATE = 'salesorderUpdate';

const update = async (data) => httpClient.post(URL_UPDATE, data);

const URL_FORM = 'salesorderForm';

const salesorderForm = async (data) => httpClient.post(URL_FORM, data);

const URL_DETAIL = 'salesorderDetail';

const salesorderDetail = async (data) => httpClient.post(URL_DETAIL, data);

const URL_ACCOUNT = 'account_type';

const accounttype = async (data) => httpClient.get(URL_ACCOUNT, data);

const URL_SALESORDER = 'salesorder_type';

const salesordertype = async (data) => httpClient.get(URL_SALESORDER, data);

const URL_PAYMENT = 'vtiger_payment';

const vtigerpayment = async (data) => httpClient.get(URL_PAYMENT, data);

const URL_VIASPAGO = 'viaspago';

const viaspago = async (data) => httpClient.get(URL_VIASPAGO, data);

const URL_PRICEBOOKUSER = 'pricebookuser';

const pricebookuser = async (data) => httpClient.get(URL_PRICEBOOKUSER, data);

const URL_FLETE = 'flete';

const flete = async (data) => httpClient.get(URL_FLETE, data);

const URL_TRANSPORTE = 'transporte';

const transporte = async (data) => httpClient.get(URL_TRANSPORTE, data);

const URL_ESTATUS = 'estatus';

const estatus = async (data) => httpClient.post(URL_ESTATUS, data);

const URL_SAP = 'SAP';

const sap = async (data) => httpClient.post(URL_SAP, data);

const URL_SAPCRN = 'SAPCNR';

const sapcnr = async (data) => httpClient.post(URL_SAPCRN, data);

const URL_TASA = 'updatetasa';

const updatetasa = async (data) => httpClient.post(URL_TASA, data);

const URL_BSAP = 'BSAP';

const bsap = async (data) => httpClient.post(URL_BSAP, data);

const URL_REMOVER = 'Remover';

const remover = async (data) => httpClient.post(URL_REMOVER, data);

const URL_ANULAR = 'Anular';

const anular = async (data) => httpClient.post(URL_ANULAR, data);

const URL_CONDICION = 'getProducto';

const getProducto = async (data) => httpClient.post(URL_CONDICION, data);

/* const URL_CNR = 'cnr';

const cnr = async (data) => httpClient.post(URL_CNR, data); */

const URL_LISTA = 'actualizar';

const updateListaPrecio = async (data) => httpClient.post(URL_LISTA, data);

const URL_TRATATPEDIDO = 'tratarPedido';

const tratarpedido = async (data) => httpClient.post(URL_TRATATPEDIDO, data);

const URL_LIBERARPEDIDO = 'liberarPedido';

const liberarpedido = async (data) => httpClient.post(URL_LIBERARPEDIDO, data);

const URL_TASABCV = 'tasa';

const tasaBcv = async (data) => httpClient.post(URL_TASABCV, data);

const URL_FACTURA_FLETE = 'updateflete';

const updateflete = async (data) => httpClient.post(URL_FACTURA_FLETE, data);

const URL_GET_FACTURA_FLETE = 'salesorder/facflete';

const getflete = async (data) => httpClient.post(URL_GET_FACTURA_FLETE, data);

const URL_ACTUALIZAR_MATERIAL = 'actualizarMaterial';

const actualizarMaterial = async (data) => httpClient.post(URL_ACTUALIZAR_MATERIAL, data);

export {
  salesorder,
  store,
  update,
  salesorderForm,
  salesorderDetail,
  accounttype,
  salesordertype,
  vtigerpayment,
  viaspago,
  flete,
  pricebookuser,
  transporte,
  estatus,
  sap,
  sapcnr,
  bsap,
  updatetasa,
  remover,
  anular,
  getProducto,
  updateListaPrecio,
  tratarpedido,
  liberarpedido,
  tasaBcv,
  updateflete,
  getflete,
  actualizarMaterial,
  // cnr,
};
