import Vue from 'vue';
import VueRouter from 'vue-router';
import Usuarios from '../views/Usuarios/Users.vue';
import Products from '../views/Products/Products.vue';
import ProductLimits from '../views/Products/Limits/ProductLimits.vue';
import Login from '../views/Login.vue';
import Customers from '../views/Customers/Customers.vue';
import Executives from '../views/Executives.vue';
import Ajustes from '../views/Ajustes/Ajustes.vue';
import Zones from '../views/Zones/Zones.vue';
import Roles from '../views/Roles/Roles.vue';
import Salesorder from '../views/Salesorder/Salesorder.vue';
import SalesorderProcess from '../views/Salesorder/SalesorderProcess.vue';
import SalesorderFacFlete from '../views/Salesorder/SalesorderFacFlete.vue';
import Reportes from '../views/Reportes/Reportes.vue';
import Detallado from '../views/Reportes/Detallado.vue';
import ReportesCobranza from '../views/Reportes/ReportesCobranza.vue';
import VentasPerdidas from '../views/Reportes/VentasPerdidas.vue';
import ListaMateriales from '../views/Reportes/ListaMateriales.vue';
import Organization from '../views/Organization/Org.vue';
import Cobranza from '../views/Cobranza/Cobranza.vue';
import ProcesarCobranza from '../views/Cobranza/ProcesarCobranza.vue';
import Email from '../views/Email/Email.vue';
import Presupuesto from '../views/Presupuestos/Presupuesto.vue';
import EstadisticasV from '../views/Presupuestos/EstadisticasV.vue';
import EstadisticasF from '../views/Presupuestos/EstadisticasF.vue';
import Tareas from '../views/Tareas/Tareas.vue';
import Rutas from '../views/Rutas/Rutas.vue';
// middleware
import auth from '../middleware/auth';
import { version } from '../middleware/version';
// View's code response
import home from '../views/welcome.vue';
import Error401 from '../views/error/401.vue';
import Error404 from '../views/error/404.vue';
import Mensaje202 from '../views/error/202.vue';

Vue.use(VueRouter);
Vue.prototype.versionCurrent = '1.0.0';

const routes = [
  {
    path: '/',
    name: 'home',
    component: home,
    meta: { middleware: [auth, version] },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: Usuarios,
    meta: { middleware: [auth, version] },
  },
  {
    path: '/productos',
    name: 'productos',
    component: Products,
    meta: { middleware: [auth, version] },
  },
  {
    path: '/limiteproductos',
    name: 'ProductLimits',
    component: ProductLimits,
    meta: { middleware: [auth, version] },
  },
  {
    path: '/clientes',
    name: 'clientes',
    component: Customers,
    meta: { middleware: [auth, version] },
  },
  {
    path: '/ejecutivos',
    name: 'ejecutivos',
    component: Executives,
    meta: { middleware: [auth, version] },
  },
  {
    path: '/ajustes',
    name: 'ajustes',
    component: Ajustes,
    meta: { middleware: [auth, version] },
  },
  {
    path: '/zonas',
    name: 'zonas',
    component: Zones,
    meta: { middleware: [auth, version] },
  },
  {
    path: '/roles',
    name: 'roles',
    component: Roles,
    meta: { middleware: [auth, version] },
  },
  {
    path: '/pedidos',
    name: 'pedidos',
    component: Salesorder,
    meta: { middleware: [auth, version] },
  },
  {
    path: '/procesados',
    name: 'procesados',
    component: SalesorderProcess,
    meta: { middleware: [auth, version] },
  },
  {
    path: '/facflete',
    name: 'facflete',
    component: SalesorderFacFlete,
    meta: { middleware: [auth, version] },
  },
  {
    path: '/reportes',
    name: 'reportes',
    component: Reportes,
    meta: { middleware: [auth, version] },
  },
  {
    path: '/detallados',
    name: 'detallados',
    component: Detallado,
    meta: { middleware: [auth, version] },
  },
  {
    path: '/reportescobranza',
    name: 'reportescobranza',
    component: ReportesCobranza,
    meta: { middleware: [auth, version] },
  },
  {
    path: '/ventasperdidas',
    name: 'ventasperdidas',
    component: VentasPerdidas,
    meta: { middleware: [auth, version] },
  },
  {
    path: '/listamateriales',
    name: 'listamateriales',
    component: ListaMateriales,
    meta: { middleware: [auth, version] },
  },
  {
    path: '/organizacion',
    name: 'organizacion',
    component: Organization,
    meta: { middleware: [auth, version] },
  },
  {
    path: '/cobranza',
    name: 'cobranza',
    component: Cobranza,
    meta: { middleware: [auth, version] },
  },
  {
    path: '/procesarcobranza',
    name: 'procesarcobranza',
    component: ProcesarCobranza,
    meta: { middleware: [auth, version] },
  },
  {
    path: '/presupuesto',
    name: 'presupuesto',
    component: Presupuesto,
    meta: { middleware: [auth, version] },
  },
  {
    path: '/EstadisticasV',
    name: 'EstadisticasV',
    component: EstadisticasV,
    meta: { middleware: [auth, version] },
  },
  {
    path: '/estadisticasF',
    name: 'estadisticasF',
    component: EstadisticasF,
    meta: { middleware: [auth, version] },
  },
  {
    path: '/tareas',
    name: 'tareas',
    component: Tareas,
    meta: { middleware: [auth, version] },
  },
  {
    path: '/rutas',
    name: 'rutas',
    component: Rutas,
    meta: { middleware: [auth, version] },
  },
  {
    path: '/scot',
    name: 'scot',
    component: Email,
    meta: { middleware: [auth, version] },
  },
  {
    path: '*',
    name: '/NotFound',
    component: Error404,
    meta: { middleware: [auth, version] },
  },
  {
    path: '/notAuthorized',
    name: 'notAuthorized',
    component: Error401,
    meta: { middleware: [auth, version] },
  },
  {
    path: '/mensaje202',
    name: 'mensaje202',
    component: Mensaje202,
    meta: { middleware: [auth, version] },
  },
];

const router = new VueRouter({
  mode: !process.env.CORDOVA_PLATFORM ? 'history' : 'hash',
  base: process.env.BASE_URL,
  routes,
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
