<template>
  <v-app>
    <Toolbar/>
    <v-main>
      <v-container fluid>
        <v-card-title>
          <v-icon class="mr-2">
               mdi-application
          </v-icon>
          {{profile.organization.name}} - Listas de Materiales
          <v-spacer></v-spacer>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details>
          </v-text-field>
        </v-card-title>
        <template>
          <v-container fluid>
            <v-row>
              <v-col sm="3" class="mt-0 pt-0">
                <v-autocomplete
                  class="mt-0 pt-0"
                  v-model="seleccion"
                  :items="lista"
                  label="Lista"
                  color="grey darken-4"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-container fluid>
              <v-row>
                <v-col sm="8" class="mt-0 pt-0">
                  <v-btn icon>
                    <v-icon
                      title='Generar'
                      color='blue'
                      @click="reporte()"
                    >
                      mdi-file-find
                    </v-icon>
                  </v-btn>
              </v-col>
              </v-row>
            </v-container>
            <v-row>
              <v-btn icon>
                <v-icon
                  title='Exportar PDF'
                  color='red'
                  @click="generatePDF()"
                >
                mdi-file-pdf-box
                </v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon
                  title='Exportar Excel'
                  color='green'
                  @click="exportar()"
                >
                mdi-file-excel
                </v-icon>
              </v-btn>
            </v-row>
          </v-container>
        </template>
        <v-card>
          <v-row justify="center">
              <v-dialog
                v-model="dialog"
                scrollable
                persistent
                max-width="450px"
              >
              </v-dialog>
            </v-row>
            <v-card class="mx-auto" width="98%">
              <v-data-table
                :headers="headers"
                :items="items_reporte"
                :search="search"
                loading-text="Cargando Datos"
                class="elevation-1"
                :header-props="{
                  sortByText: 'Ordenar',
                }"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-arrow-collapse-left',
                  lastIcon: 'mdi-arrow-collapse-right',
                  prevIcon: 'mdi-minus',
                  nextIcon: 'mdi-plus',
                  'items-per-page-text': 'reportes por página',
                }"
                multiSort
              >

              </v-data-table>
            </v-card>
        </v-card>
      </v-container>
    </v-main>
    <SetLogout/>
    <Historicobcv/>
  </v-app>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapState, mapActions } from 'vuex';
// eslint-disable-next-line import/extensions
import jspdf from 'jspdf';
// eslint-disable-next-line import/extensions
import 'jspdf-autotable';
// eslint-disable-next-line import/extensions
import XLSX from 'xlsx';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import {
  listado, listaprecio,
} from '../../config/api/reportes';
// eslint-disable-next-line import/extensions
import Toolbar from '../../components/Toolbar';
// eslint-disable-next-line import/extensions
import SetLogout from '../../components/SetLogout';
// eslint-disable-next-line import/extensions
import Historicobcv from '../../components/Historicobcv';

export default {
  name: 'reportes',
  components: {
    Toolbar, SetLogout, Historicobcv,
  },
  data: () => ({
    dialog: false,
    search: '',
    selected: [],
    heading: 'Lista de Materiales',
    headers: [
      { text: 'Lista', value: 'lista', align: 'center' },
      { text: 'Codigo', value: 'codigo', align: 'center' },
      { text: 'Material', value: 'nombre', align: 'center' },
      { text: 'Precio', value: 'precio', align: 'center' },
      { text: 'Fecha de Actualizacion', value: 'fecha de actualizacion', align: 'center' },
      { text: 'Fecha de Vencimiento', value: 'fecha de vencimiento', align: 'center' },
    ],
    items_reporte: [],
    lista: [],
    estatu: null,
    request: {
    },
  }),
  computed: {
    ...mapState('reportes', ['item', 'lista_precio']),
    profile() {
      return Profile.get();
    },
    seleccion: {
      set(value) {
        this.$store.dispatch('reportes/toAssignlistasdo', value);
      },
      get() {
        return this.lista_precio;
      },
    },
  },
  methods: {
    ...mapActions('reportes', ['toAssignId', 'toAssignEjecutivo', 'initializingForm', 'add', 'reset']),
    data() {
      return {
        lista: this.seleccion,
      };
    },
    async reporte() {
      this.items_reporte = (await listaprecio(this.data())).data;
    },
    generatePDF() {
      const columns = [
        { title: 'Lista', dataKey: 'lista', align: 'center' },
        { title: 'Codigo', dataKey: 'codigo', align: 'center' },
        { title: 'Material', dataKey: 'nombre', align: 'center' },
        { title: 'Precio', dataKey: 'precio', align: 'center' },
        { title: 'Fecha de Actualizacion', dataKey: 'fecha de actualizacion', align: 'center' },
      ];
      /* eslint new-cap: ["error", { "newIsCap": false }] */
      const doc = new jspdf({
        orientation: 'Landscape',
        unit: 'in',
        format: 'letter',
      });
      // text is placed using x, y coordinates
      doc.setFontSize(16).text(this.heading, 0.5, 1.0);
      // create a line under heading */
      // doc.setLineWidth(0.01).line(0.5, 1.1, 8.0, 1.1);
      // Using autoTable plugin
      doc.autoTable({
        columns,
        body: this.items_reporte,
        margin: { left: 0.5, top: 1.25 },
      });
      // Using array of sentences
      doc.save(`${this.heading}.pdf`);
    },
    exportar() {
      const data = XLSX.utils.json_to_sheet(this.items_reporte);
      const workbook = XLSX.utils.book_new();
      const filename = 'Lista de Materiales';
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
  },
  async mounted() {
    try {
      const requests = {
        company_id: this.profile.organization.id,
      };
      this.lista = (await listado(requests)).data;// ejecutivo
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(error);
    }
  },
};
</script>
