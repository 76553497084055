<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="70%">
      <v-card>
        <v-toolbar :color="profile.organization.color" dark>
          <v-icon class='mr-2'> mdi-order-bool-ascending-variant </v-icon>
            {{title}} Registros
          <v-spacer></v-spacer>
          <v-btn icon dark @click="toggleForm()">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
                <v-col cols="12" sm="12" class="mt-4">
                  <v-combobox
                    v-model="productSelected"
                    :items="products"
                    @change="checkLimits()"
                    item-text="name"
                    item-value="code"
                    required
                    label="Producto"
                    dense
                  ></v-combobox>
                </v-col>
                <v-col cols="12" sm="12">
                <v-menu
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        readonly
                        label="Fecha de validez"
                        v-model="formDateValue"
                        v-bind="attrs"
                        v-on="on"
                        type="date">
                      </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formDateValue"
                  ></v-date-picker>
                </v-menu>
                </v-col>
                <v-data-table
                  :headers="headers"
                  :items="ejecutives"
                  sort-by="id"
                  class="col-12 elevation-0 mt-2"
                  no-data-text="no hay datos"
                  :header-props="{
                    sortByText: 'Ordenar',
                  }"
                  :footer-props="{
                    'items-per-page-text': 'Registros por página',
                  }"
                  multiSort
                >

                <template v-slot:[`item.limit1`]="{ item }">
                    <v-text-field
                      v-model="itemLimit.limit1"
                      style="width:100px;"
                      :hide-details="true"
                      type="text"
                      dense single-line
                      :autofocus="true"
                      v-if="item.user_id === userSelected">
                    </v-text-field>
                    <span v-else>{{item.limit1}}</span>
                  </template>

                  <template v-slot:[`item.limit2`]="{ item }">
                    <v-text-field
                      v-model="itemLimit.limit2"
                      style="width:100px;"
                      :hide-details="true"
                      type="text"
                      dense single-line
                      :autofocus="true"
                      v-if="item.user_id === userSelected">
                    </v-text-field>
                    <span v-else>{{item.limit2}}</span>
                  </template>

                <template v-slot:[`item.acciones`]="{ item }">
                    <div v-if="userSelected === item.user_id">
                      <v-btn icon>
                        <v-icon
                          title="Descartar limite"
                          class="mr-2"
                          color="red"
                          @click="discardItemLimit()">
                          mdi-window-close
                        </v-icon>
                      </v-btn>
                      <v-btn icon>
                        <v-icon
                          title="Guardar limite"
                          class="mr-2"
                          color="green"
                          @click="saveItemLimit(item)">
                          mdi-content-save
                        </v-icon>
                      </v-btn>
                    </div>
                    <div v-else>
                      <v-btn icon>
                        <v-icon
                          title="Editar limite"
                          class="mr-2"
                          color="green"
                          @click="editItemLimit(item)">
                          mdi-pencil-outline
                        </v-icon>
                      </v-btn>
                    </div>
                  </template>
                </v-data-table>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn color="red" text @click="toggleForm()"> Cerrar </v-btn>
            <v-btn color="primary" text @click="saveLimits()"> Agregar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapActions, mapState } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../../plugins/profile';
// eslint-disable-next-line import/extensions
import { SaveLimits } from '../../../config/api/products';

export default {
  name: 'ProductLimits-Form',
  data: () => ({
    userSelected: -1,
    itemLimit: {},
    productSelected: null,
    headers: [
      { text: 'Usuario', value: 'user_name', align: 'left' },
      { text: 'Asesor', value: 'name', align: 'left' },
      { text: 'Cant.', value: 'limit1', align: 'center' },
      { text: '%', value: 'limit2', align: 'center' },
      { text: 'Acciones', value: 'acciones', sortable: false },
    ],
    vDateValue: null,
  }),
  computed: {
    ...mapState('userProfile', ['profile']),
    ...mapState('ProductLimits', ['title', 'item', 'ejecutives', 'products', 'show']),

    formDateValue: {
      set(param) {
        this.vDateValue = param;
      },
      get() {
        return this.vDateValue;
      },
    },
    profile() {
      return Profile.get();
    },
  },
  methods: {
    ...mapActions('ProductLimits', ['toggleForm']),

    reset() {
      this.vDateValue = null;
      this.productSelected = null;
      for (let i = 0; i < this.ejecutives.length; i += 1) {
        this.ejecutives[i].modified = false;
      }
    },
    checkLimits() {
      let i = 0;
      const items = this.item.filter((v) => v.code === this.productSelected.code);

      for (i = 0; i < this.ejecutives.length; i += 1) {
        this.ejecutives[i].modified = false;
        this.ejecutives[i].limit1 = 0;
        this.ejecutives[i].limit2 = 0;
      }

      if (items.length > 0) {
        for (i = 0; i < this.ejecutives.length; i += 1) {
          for (let j = 0; j < items.length; j += 1) {
            if (this.ejecutives[i].user_name === items[j].user_name) {
              this.ejecutives[i].limit1 = items[j].limit1;
              this.ejecutives[i].limit2 = items[j].limit2;
            }
          }
        }
      }
    },
    editItemLimit(item) {
      this.userSelected = item.user_id;
      this.itemLimit = item;
    },
    saveItemLimit(item) {
      for (let i = 0; i < this.ejecutives.length; i += 1) {
        if (this.ejecutives[i].user_id === item.user_id) {
          if (parseFloat(this.itemLimit.limit1) > 0 && parseFloat(this.itemLimit.limit2) > 0) {
            this.alert('Solo puede establecer un formato de limite. puede inhabilitar colocando un cero', 'warning');
          } else if (parseFloat(this.itemLimit.limit2) > 100) {
            this.alert('El limite en porcentaje no puede exceder el 100%', 'warning');
          } else if (parseFloat(this.itemLimit.limit1) >= 0 && parseFloat(this.itemLimit.limit2) >= 0) {
            this.ejecutives[i].limit1 = this.itemLimit.limit1;
            this.ejecutives[i].limit2 = this.itemLimit.limit2;
            this.ejecutives[i].modified = true;
            this.userSelected = -1;
            this.itemLimit = {};
          } else {
            this.alert('El limite no puede ser negativo', 'warning');
          }
        }
      }
    },
    discardItemLimit() {
      this.userSelected = -1;
      this.itemLimit = null;
    },
    async saveLimits() {
      // eslint-disable-next-line prefer-const
      let data = [];
      let counter = 0;

      if (this.productSelected != null && this.formDateValue != null) {
        for (let i = 0; i < this.ejecutives.length; i += 1) {
          if (this.ejecutives[i].limit1 > 0 || this.ejecutives[i].limit2 > 0) {
            data.push({
              user_id: this.ejecutives[i].user_id,
              limit1: parseFloat(this.ejecutives[i].limit1),
              limit2: parseFloat(this.ejecutives[i].limit2),
            });
            counter += this.ejecutives[i].modified === true ? 1 : 0;
          }
        }

        if (data.length === 0 || counter === 0) {
          this.alert('No hay limites modificados', 'warning');
        } else {
          const dataSend = {
            product: this.productSelected.code,
            deprecated: this.formDateValue,
            limits: data,
          };

          this.response(await SaveLimits(dataSend));
          this.reset();
          this.toggleForm();
          this.$store.dispatch('ProductLimits/data', {});
        }
      } else if (this.productSelected === null) {
        this.alert('Debe selecionar un producto', 'warning');
      } else if (this.formDateValue === null) {
        this.alert('Debe selecionar una fecha de vencimiento', 'warning');
      }
    },
    response(api) {
      this.$store.commit('message/LOADING', true);
      try {
        this.alert(api.data.message, 'success');
      } catch (error) {
        this.alert(error, 'error');
      }
      this.$store.commit('message/LOADING', false);
    },
    alert(message, type = 'info') {
      this.$store.commit('message/MESSAGE', message);
      this.$store.commit('message/TYPE', type);
      this.$store.commit('message/RELOAD', false);
      this.$store.dispatch('message/run');
    },
  },
};
</script>
