<template>
  <v-row justify="center" class="mt-2 pt-2">
    <v-col cols="12" sm="4" md="2" class="mt-0 pt-0">
      <v-dialog
        v-model="show"
        persistent
        max-width="900px"
      >
        <v-card>
          <v-card>
          <v-toolbar :color="profile.organization.color" dark>
            <span class="headline">{{title}}</span>
          <v-spacer></v-spacer>
            <v-btn icon dark @click="reset()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar>
          </v-card>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-select
                     v-model="formExecutive"
                     :items="dataExecutives"
                    label="Ejecutivo"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="8">
                  <v-text-field
                    v-model="formnota"
                    label="Nota"
                    type="text"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn color="red" text @click="reset()"> Cerrar </v-btn>
            <v-btn color="primary" text @click="add()"> Agregar</v-btn>
        </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapState, mapActions, mapMutations } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import { incluir } from '../../config/api/presupuestos';

export default {
  name: 'presupuesto',
  data: () => ({
    userRules: [
      (v) => !!v || 'El usuario es requerido',
    ],
    claveRules: [
      (v) => !!v || 'La contraseña es requerida',
      (v) => v.length <= 12 || 'La contraseña debe tener entre 6 y 12 caracteres',
      (v) => v.length >= 6 || 'La contraseña debe tener entre 6 y 12 caracteres',
    ],
  }),
  computed: {
    ...mapState('userProfile', ['profile']),
    ...mapState('presupuesto', ['item', 'show', 'title', 'nota']),
    ...mapState('zones', ['executive', 'dataExecutives']),
    formId: {
      set(value) {
        this.$store.dispatch('usuarios/toAssignId', value);
      },
      get() {
        return this.id;
      },
    },
    formnota: {
      set(value) {
        this.$store.dispatch('presupuesto/toAssignNota', value);
      },
      get() {
        return this.nota;
      },
    },
    formExecutive: {
      set(value) {
        this.$store.dispatch('zones/toAssignExecutive', value);
      },
      get() {
        return this.executive;
      },
    },
    profile() {
      return Profile.get();
    },
  },
  methods: {
    ...mapMutations('message', ['MESSAGE', 'TYPE', 'LOADING']),
    ...mapActions('message', ['run']),
    ...mapMutations('presupuesto', ['SHOW']),
    ...mapActions('presupuesto', ['add', 'update', 'clean', 'reset']),
    data() {
      return {
        org: this.profile.organization.id,
        ejecutivo: this.formExecutive,
        nota: this.formnota,
      };
    },
    async add() {
      console.log(this.data());
      this.alert(await incluir(this.data()));
    },
    response(api) {
      this.$store.commit('message/LOADING', true);
      try {
        this.$store.dispatch('presupuesto/reset');
        this.$store.commit('message/MESSAGE', api.data.message);
        this.$store.commit('message/TYPE', 'success');
        this.$store.dispatch('message/run');
        this.$store.dispatch('presupuesto/data', this.profile);
      } catch (error) {
        // eslint-disable-next-line no-alert
        alert(error);
      }
      this.$store.commit('message/LOADING', false);
    },
    alert(api) {
      this.$store.dispatch('presupuesto/reset');
      this.$store.commit('message/MESSAGE', api.data.message);
      this.$store.commit('message/TYPE', 'info');
      this.$store.commit('message/RELOAD', false);
      this.$store.dispatch('message/run');
      this.$store.dispatch('presupuesto/data', this.profile);
    },
  },
  created() {
    try {
      const requests = {
        company_id: this.profile.organization.id,
        user_id: this.profile.user.id,
      };
      this.$store.dispatch('zones/ZonaCliente', requests);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(error);
    }
  },
};
</script>
