export default {

  name: 'login',

  namespaced: true,

  state: {
    apiToken: null,
    loading: true,
  },
  mutations: {
    session(state, data) {
      state.apiToken = data.token;
    },
    loading(state) {
      state.loading = !state.loading;
    },
  },
  actions: {

    login({ commit }) {
      commit('loading');
    },
  },
};
