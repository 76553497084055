<template>
  <v-row justify="center">
    <v-dialog v-model="showPay" persistent max-width="600px">
      <v-card>
        <v-toolbar :color="profile.organization.color" dark height="50px">
          <v-icon class="mr-2">
            mdi-application
          </v-icon>
            <h1 class="title mt-2 white--text">
              {{titleButtonAdd}} Pago
            </h1>
          <v-spacer></v-spacer>
            <v-btn icon dark @click="closePayment()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-row>
              <v-radio-group row v-model="formType">
                <v-radio
                  label="Transferencia"
                  color="primary"
                  value="transferencia"
                ></v-radio>
                <v-radio
                  v-show="this.profile.organization.id != ('1','5')"
                  label="Pagomovil"
                  color="primary"
                  value="pagomovil"
                ></v-radio>
                <v-radio
                  v-show="this.profile.organization.id == '1'"
                  label="Cuentas Internacionales"
                  color="primary"
                  value="cuentas Internacionales"
                ></v-radio>
                <v-radio
                  label="Depósito"
                  color="deep-purple lighten-2"
                  value="deposito"
                ></v-radio>
                <v-radio
                  v-show="this.profile.organization.id != '5'"
                  label="Efectivo"
                  color="success"
                  value="efectivo"
                ></v-radio>
                <!--<v-radio v-if="show"
                  label="Retención"
                  color="info"
                  value="retencion"
                ></v-radio>-->
              </v-radio-group>
              <v-col v-show="this.profile.organization.id == '3' || this.profile.organization.id == '4'" cols="12" sm="12" v-if="vType=='transferencia' || vType=='pagomovil' || (reset && (formType=='pagomovil' || formType=='transferencia'))">
                <v-autocomplete
                  :items="listBanksOrigin"
                  label="Banco origen"
                  v-model="formBankFrom"
                  color="grey darken-4">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" v-if="(vType=='transferencia' || vType=='pagomovil' || vType=='deposito' || vType=='cuentas Internacionales') || (reset && (formType=='pagomovil' ||formType=='transferencia' || formType=='deposito' || formType=='cuentas Internacionales'))">
                <v-autocomplete
                  :items="listBanksDest"
                  label="Banco destino"
                  v-model="formBankTo"
                  color="grey darken-4">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" v-if="(vType=='transferencia' || vType=='pagomovil' || (reset && (formType=='pagomovil' || formType=='transferencia'))) || (vType=='deposito' || (reset && formType=='deposito'))">
                <v-text-field label="Referencia de transacción" v-model="formReference" type="text"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field required label="Monto" type="number" v-model="formMount" :rules="MountRules"></v-text-field>
              </v-col>
              <v-menu
                v-if="(vType=='transferencia' || vType=='pagomovil' || (reset && (formType=='pagomovil' || formType=='transferencia'))) || (vType=='deposito' || (reset && formType=='deposito'))"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-col cols="12" sm="12">
                    <v-text-field
                      readonly
                      label="Fecha valor"
                      v-model="formDateValue"
                      v-bind="attrs"
                      v-on="on"
                      type="date">
                    </v-text-field>
                  </v-col>
                </template>
                <v-date-picker
                  v-model="formDateValue"
                  :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                  :min="(new Date((new Date()).setMonth((new Date()).getMonth()-3))).toISOString().substr(0, 10)"
                ></v-date-picker>
              </v-menu>
              <v-col cols="12" sm="12">
                <v-textarea
                  name="input-7-1"
                  label="Nota"
                  rows="1"
                  v-model="formNote"
                  hint="Observación"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn color="red" text @click="close()"> Cerrar </v-btn>
            <v-btn color="primary" text @click="add()"> {{titleButtonAdd}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapActions, mapState, mapMutations } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';

function groupBy(array, key, extend = false) {
  const result = [];
  array.forEach((item) => {
    if (!result[item[key]]) {
      const name = item.idbanc !== '' && item.sociedad !== '' ? item[key].concat(' - ', item.idbanc) : item[key];
      result.push(extend ? name : item[key]);
    }
  });
  return result;
}

function filterBanks(array, bank) {
  let ret = {};
  const filter = array.filter((item) => item.entidad.substring(0, 20) === bank.substring(0, 20));
  filter.forEach((item) => {
    ret = item;
  });
  return ret;
}

export default {
  name: 'payment-crud',
  data: () => ({
    MountRules: [
      (n) => !!n || 'Debe colocar un monto',
      (n) => n > 0 || 'El monto debe ser positivo',
      (n) => n <= 9999999999 || 'El monto excede la cantidad de digitos permitidos',
    ],
    reset: false,
    vType: '',
    vBankFrom: '',
    vBankTo: '',
    vReference: '',
    vMount: 0,
    vDateValue: '',
    vNote: '',
  }),
  computed: {
    ...mapState('userProfile', ['profile']),
    ...mapState('cobranza', ['title', 'showPay', 'payments', 'payItem', 'banks', 'show']),
    profile() {
      return Profile.get();
    },
    listBanksDest() {
      return (this.formType == 'transferencia' || this.formType == 'pagomovil') ? this.listBanksDestTran : this.listBanksDestDepo;
    },
    listBanksOrigin: {
      set() {},
      get() {
        // return groupBy(this.banks, 'entidad');
        /* eslint eqeqeq: 0 */
        return groupBy(this.banks.filter((item) => item.cuenta1 == '' && item.cuenta2 == ''), 'entidad');
      },
    },
    listBanksDestTran: {
      set() { },
      get() {
        const nacionales = [];// [{}];
        let i = 0; let j = 0;
        if (this.profile.organization.org === '5022' && this.formType === 'transferencia') {
          this.banks.forEach((element) => {
            if (element.entidad === 'BANCO NACIONAL BOLIVARES') {
              nacionales[j] = this.banks[i];
              j += 1;
            }
            i += 1;
          });
          return groupBy(nacionales.filter((item) => item.cuenta1 !== '' && item.sociedad === this.profile.organization.org), 'entidad', true);
        }
        if (this.formType === 'pagomovil') {
          this.banks.forEach((element) => {
            if (element.entidad === 'BANCO NACIONAL BOLIVARES') {
              nacionales[j] = this.banks[i];
              j += 1;
            }
            i += 1;
          });
          return groupBy(nacionales.filter((item) => item.cuenta1 !== '' && item.sociedad === this.profile.organization.org), 'entidad', true);
        }
        if (this.profile.organization.org === '4002' && this.formType === 'transferencia') {
          this.banks.forEach((element) => {
            if (element.entidad !== 'BNC DOLAR') {
              nacionales[j] = this.banks[i];
              j += 1;
            }
            i += 1;
          });
          return groupBy(nacionales.filter((item) => item.cuenta1 !== '' && item.sociedad === this.profile.organization.org), 'entidad', true);
        }
        return groupBy(this.banks.filter((item) => item.cuenta1 !== '' && item.sociedad === this.profile.organization.org), 'entidad', true);
      },
    },
    listBanksDestDepo: {
      set() { },
      get() {
        const nacionales = [];
        const internacionales = [];
        let bank = [{}];
        let i = 0; let j = 0; let k = 0;
        this.banks.forEach((element) => {
          if (element.tipo === 'N' && this.profile.organization.org !== '4002') {
            nacionales[j] = this.banks[i];
            j += 1;
          } else if (element.tipo === 'N' && element.entidad === 'BNC DOLAR' && this.profile.organization.org === '4002') {
            nacionales[j] = this.banks[i];
            j += 1;
          } else {
            internacionales[k] = this.banks[i];
            k += 1;
          }
          i += 1;
        });
        if (this.formType == 'Deposito' || this.formType == 'cuentas Internacionales' || this.profile.organization.org === '6000') {
          bank = internacionales;
        } else {
          bank = nacionales;
        }
        return groupBy(bank.filter((item) => item.cuenta2 !== '' && item.sociedad === this.profile.organization.org), 'entidad', true);
      },
    },
    titleButtonAdd: {
      get() {
        return (this.payItem.length === 0) ? 'Agregar' : 'Modificar';
      },
    },
    formType: {
      set(param) {
        this.vType = param;
        this.reset = false;
      },
      get() {
        return (this.payItem.length === 0) ? this.vType : this.payItem[0].type;
      },
    },
    formBankFrom: {
      set(param) {
        if (this.vType === '') this.vType = this.payItem[0].type;
        this.vBankFrom = param;
        this.reset = false;
      },
      get() {
        return (this.payItem.length === 0) ? this.vBankFrom : this.payItem[0].bankFrom;
      },
    },
    formBankTo: {
      set(param) {
        if (this.vType === '') this.vType = this.payItem[0].type;
        this.vBankTo = param;
        this.reset = false;
      },
      get() {
        return (this.payItem.length === 0) ? this.vBankTo : this.payItem[0].bankTo;
      },
    },
    formReference: {
      set(param) {
        if (this.vType === '') this.vType = this.payItem[0].type;
        this.vReference = param;
        this.reset = false;
      },
      get() {
        return (this.payItem.length === 0) ? this.vReference : this.payItem[0].reference;
      },
    },
    formMount: {
      set(param) {
        if (this.vType === '') this.vType = this.payItem[0].type;
        this.vMount = param;
        this.reset = false;
      },
      get() {
        return (this.payItem.length === 0) ? this.vMount : this.payItem[0].value;
      },
    },
    formDateValue: {
      set(param) {
        if (this.vType === '') this.vType = this.payItem[0].type;
        this.vDateValue = param;
        this.reset = false;
      },
      get() {
        return (this.payItem.length === 0) ? this.vDateValue : this.payItem[0].datevalue;
      },
    },
    formNote: {
      set(param) {
        if (this.vType === '') this.vType = this.payItem[0].type;
        this.vNote = param;
        this.reset = false;
      },
      get() {
        return (this.payItem.length === 0) ? this.vNote : this.payItem[0].note;
      },
    },
  },
  methods: {
    ...mapMutations('message', ['MESSAGE', 'TYPE', 'LOADING']),
    ...mapActions('message', ['run']),
    ...mapMutations('cobranza', ['SHOWPAY']),
    ...mapActions('cobranza', ['closePayment']),
    getID() {
      return this.payments.length > 0 ? this.payments[this.payments.length - 1].ID + 10 : 10;
    },
    resetAll() {
      this.vType = '';
      this.vMount = 0;
      this.vBankTo = '';
      this.vBankFrom = '';
      this.vReference = '';
      this.vDateValue = '';
      this.vNote = '';
      this.reset = true;
    },
    add() {
      if (this.validateForm()) {
        if (this.payItem.length === 0) {
          const bank = filterBanks(this.banks, this.formBankTo);
          this.payments.push({
            ID: this.getID(),
            type: this.formType,
            value: this.formMount,
            bankFrom: this.formBankFrom,
            bankTo: this.formBankTo,
            bankToId: bank.idbanc,
            // eslint-disable-next-line no-nested-ternary
            bankToAccount: (this.formType == 'transferencia' || this.formType == 'pagomovil' || this.formType == 'Cuentas Internacionales') ? bank.cuenta1 : (bank.cuenta2 || bank.cuenta1),
            reference: this.formReference,
            datevalue: this.formDateValue,
            note: this.formNote,
          });
        } else {
          for (let i = 0; i < this.payments.length; i += 1) {
            if (this.payments[i].ID === this.payItem[0].ID) {
              if (this.vType !== '') this.payments[i].type = this.vType;
              if (this.vMount !== 0) this.payments[i].value = this.vMount;
              if (this.vBankFrom !== '') this.payments[i].bankFrom = this.vBankFrom;
              if (this.vBankTo !== '') {
                const bank = filterBanks(this.banks, this.vBankTo);
                this.payments[i].bankTo = this.vBankTo;
                this.payments[i].bankToId = bank.idbanc;
                this.payments[i].bankToAccount = (this.vType == 'transferencia' || this.vType == 'pagomovil' || this.formType == 'cuentas Internacionales') ? bank.cuenta1 : (bank.cuenta2 || bank.cuenta1);
              }
              if (this.vReference !== '') this.payments[i].reference = this.vReference;
              if (this.vDateValue !== '') this.payments[i].datevalue = this.vDateValue;
              if (this.vNote !== '') this.payments[i].note = this.vNote;
            }
          }
        }
        console.log(this.payments);
        this.close();
        // this.closePayment();
        // this.resetAll(); // Se reinicia todos los valores
      }
    },
    close() {
      this.resetAll();
      this.closePayment();
    },
    validateForm() {
      if (this.formType === '' || (this.payItem.length === 0 && this.vType === '')) {
        this.alert('Debe seleccionar un tipo de pago', 'warning');
        return false;
      }

      if ((this.vType === 'transferencia' || this.vType === 'pagomovil') || (this.reset && (this.formType === 'transferencia' || this.formType === 'pagomovil'))) {
        if (this.profile.organization.org == ('2002', '1002')) {
          if (this.formBankFrom === '' || (this.payItem.length === 0 && this.vBankFrom === '')) {
            this.alert('Debe seleccionar un banco de origen', 'warning');
            return false;
          }
        }
        if (this.formBankTo === '' || (this.payItem.length === 0 && this.vBankTo === '')) {
          this.alert('Debe seleccionar un banco de destino', 'warning');
          return false;
        }
        if (this.formReference === '' || (this.payItem.length === 0 && this.vReference === '')) {
          this.alert('Debe colocar la referencia de transferencia', 'warning');
          return false;
        }
        if (this.formDateValue === '' || (this.payItem.length === 0 && this.vDateValue === '')) {
          this.alert('Debe colocar la fecha valor de la transferencia', 'warning');
          return false;
        }
      }
      if (this.vType === 'deposito' || (this.reset && this.formType === 'deposito')) {
        if (this.formBankTo === '' || (this.payItem.length === 0 && this.formBankTo === '')) {
          this.alert('Debe seleccionar un banco de destino', 'warning');
          return false;
        }
        if (this.formReference === '' || (this.payItem.length === 0 && this.vReference === '')) {
          this.alert('Debe colocar la referencia de deposito', 'warning');
          return false;
        }
        if (this.formDateValue === '' || (this.payItem.length === 0 && this.vDateValue === '')) {
          this.alert('Debe colocar la fecha valor de la transferencia', 'warning');
          return false;
        }
      }

      if (this.formMount <= 0 || (this.payItem.length === 0 && this.vMount <= 0)) {
        this.alert('El monto debe ser mayor de cero', 'warning');
        return false;
      }

      return true;
    },
    alert(message, type = 'info') {
      this.$store.commit('message/MESSAGE', message);
      this.$store.commit('message/TYPE', type);
      this.$store.commit('message/RELOAD', false);
      this.$store.dispatch('message/run');
    },
  },
  created() {
    try {
      const requests = {
        company_id: this.profile.organization.id,
        user_id: this.profile.user.id,
        user_rol: this.profile.user.rol,
      };

      this.$store.dispatch('cobranza/banks', requests);
    } catch (error) {
      // eslint-disable-next-line no-alert
      this.alert(error);
    }
  },
};
</script>
