var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"70%"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":_vm.profile.organization.color,"dark":""}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-order-bool-ascending-variant ")]),_vm._v(" "+_vm._s(_vm.title)+" Registros "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.toggleForm()}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"mt-4",attrs:{"cols":"12","sm":"12"}},[_c('v-combobox',{attrs:{"items":_vm.products,"item-text":"name","item-value":"code","required":"","label":"Producto","dense":""},on:{"change":function($event){return _vm.checkLimits()}},model:{value:(_vm.productSelected),callback:function ($$v) {_vm.productSelected=$$v},expression:"productSelected"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","label":"Fecha de validez","type":"date"},model:{value:(_vm.formDateValue),callback:function ($$v) {_vm.formDateValue=$$v},expression:"formDateValue"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{model:{value:(_vm.formDateValue),callback:function ($$v) {_vm.formDateValue=$$v},expression:"formDateValue"}})],1)],1),_c('v-data-table',{staticClass:"col-12 elevation-0 mt-2",attrs:{"headers":_vm.headers,"items":_vm.ejecutives,"sort-by":"id","no-data-text":"no hay datos","header-props":{
                  sortByText: 'Ordenar',
                },"footer-props":{
                  'items-per-page-text': 'Registros por página',
                },"multiSort":""},scopedSlots:_vm._u([{key:"item.limit1",fn:function(ref){
                var item = ref.item;
return [(item.user_id === _vm.userSelected)?_c('v-text-field',{staticStyle:{"width":"100px"},attrs:{"hide-details":true,"type":"text","dense":"","single-line":"","autofocus":true},model:{value:(_vm.itemLimit.limit1),callback:function ($$v) {_vm.$set(_vm.itemLimit, "limit1", $$v)},expression:"itemLimit.limit1"}}):_c('span',[_vm._v(_vm._s(item.limit1))])]}},{key:"item.limit2",fn:function(ref){
                var item = ref.item;
return [(item.user_id === _vm.userSelected)?_c('v-text-field',{staticStyle:{"width":"100px"},attrs:{"hide-details":true,"type":"text","dense":"","single-line":"","autofocus":true},model:{value:(_vm.itemLimit.limit2),callback:function ($$v) {_vm.$set(_vm.itemLimit, "limit2", $$v)},expression:"itemLimit.limit2"}}):_c('span',[_vm._v(_vm._s(item.limit2))])]}},{key:"item.acciones",fn:function(ref){
                var item = ref.item;
return [(_vm.userSelected === item.user_id)?_c('div',[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"title":"Descartar limite","color":"red"},on:{"click":function($event){return _vm.discardItemLimit()}}},[_vm._v(" mdi-window-close ")])],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"title":"Guardar limite","color":"green"},on:{"click":function($event){return _vm.saveItemLimit(item)}}},[_vm._v(" mdi-content-save ")])],1)],1):_c('div',[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"title":"Editar limite","color":"green"},on:{"click":function($event){return _vm.editItemLimit(item)}}},[_vm._v(" mdi-pencil-outline ")])],1)],1)]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.toggleForm()}}},[_vm._v(" Cerrar ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.saveLimits()}}},[_vm._v(" Agregar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }