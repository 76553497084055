import Vue from 'vue';
import Vuex from 'vuex';

import login from './modules/login';
import message from './modules/message';
import zones from './modules/zones';
import userProfile from './modules/userProfile';
import salesorder from './modules/salesorder';
import usuarios from './modules/usuarios';
import roles from './modules/roles';
import reportes from './modules/reportes';
import ajustes from './modules/ajustes';
import products from './modules/products';
import ProductLimits from './modules/ProductLimits';
import customers from './modules/customers';
import modulos from './modules/modulos';
import organization from './modules/organization';
import cobranza from './modules/cobranza';
import mails from './modules/mails';
import presupuesto from './modules/presupuesto';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    login, message, zones, userProfile, salesorder, usuarios, roles, reportes, presupuesto, ProductLimits, products, customers, modulos, organization, cobranza, mails, ajustes,
  },
});
