<template>
 <v-row justify="center">
  <v-dialog v-model="showPc" persistent max-width="950px">
   <v-card>
    <v-toolbar :color="profile.organization.color" dark>
     <span class="headline">{{title}} - {{Cliente}}</span>
     <v-spacer></v-spacer>
     <v-btn icon dark
      @click="closeShow()">
      <v-icon> mdi-close </v-icon>
     </v-btn>
    </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row class="mt-0 pt-0">
                <v-col cols="6" sm="3">
                 <v-flex class="font-weight-black text--darken-2" >
                    Cobranza No.: {{Cobranza}}
                 </v-flex>
                </v-col>
                <v-col cols="6" sm="3">
                 <v-flex class="font-weight-black text--darken-2" >
                    Estatus: {{Estatus}}
                 </v-flex>
                </v-col>
                <v-chip
                  class="ma-2"
                  color="blue"
                  label
                  v-show="validar(profile.permissions,'10007') && showEdit"
                  @click="BOtrospago()"
                  text-color="white">
                  Otros Pagos
                </v-chip>
            </v-row>
          </v-container>
        <v-card-text>
          <CobranzaDetalle></CobranzaDetalle>
        </v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn color="red" text @click="closeShow()"> Cerrar </v-btn>
            <v-btn color="red" text @click="editar()" v-show="showEdit"> Editar </v-btn>
        </v-card-actions>
        <DescuentoFrom/>
        <CobranzaOtrosPagos/>
   </v-card>
  </v-dialog>
 </v-row>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapActions, mapState, mapMutations } from 'vuex';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';
// eslint-disable-next-line import/extensions
import CobranzaOtrosPagos from './CobranzaOtrosPagos';
// eslint-disable-next-line import/extensions
import DescuentoFrom from './DescuentoFrom';
// eslint-disable-next-line import/extensions
import CobranzaDetalle from './CobranzaDetalle';
// eslint-disable-next-line import/extensions
import { updateCobranza } from '../../config/api/cobranza';

export default {
  name: 'cobranza-show',
  data: () => ({
  }),
  computed: {
    ...mapState('cobranza', ['title', 'showPc', 'showdisc', 'item', 'Cliente', 'Cobranza', 'Estatus', 'selected', 'Check_pos', 'showEdit', 'Doc', 'SD', 'Banco', 'Total', 'Referencia', 'Observacion', 'FechaCotabilizacion']),
    profile() {
      return Profile.get();
    },
    Refer: {
      set(value) {
        this.$store.dispatch('cobranza/toReferencia', value);
      },
      get() {
        return this.Referencia;
      },
    },
    Nota: {
      set(value) {
        this.$store.dispatch('cobranza/toObservacion', value);
      },
      get() {
        if (this.Estatus === 'PROCESADO') {
          return this.Observacion;
        }
        const j = [];
        this.SD.forEach((item) => {
          j.push(item.factsap.replace('000', '*'));
        });
        console.log(j.toString());
        return j.toString().replace(',', ' -').replace(',', ' -').replace(',', ' -')
          .replace(',', ' -')
          .replace(',', ' -')
          .replace(',', ' -');
      },
    },
    FechaM: {
      set(value) {
        this.$store.dispatch('cobranza/toFecha', value);
      },
      get() {
        return this.FechaCotabilizacion;
      },
    },
  },
  methods: {
    ...mapMutations('cobranza', ['SHOWPC', 'SHOWPD', 'SHOWDISC', 'SHOWOTROPAGO']),
    ...mapActions('cobranza', ['closeShow', 'update', 'showCobranzaOtrosPagos']),
    data() {
      return {
        id: this.Cobranza,
        Banco: this.Banco,
        Doc: this.Doc,
        Referencia: this.Refer,
        Observacion: (this.Observacion.length > 40) ? this.Observacion.substring(0, 50) : this.Observacion,
        FechaCot: this.FechaM,
        org: this.profile.org,
        society_id: this.profile.organization.society,
        user_id: this.profile.user.id,
      };
    },
    editar() {
      let Cs = 0;
      this.Doc.forEach((DocPosic) => {
        Cs += parseFloat(DocPosic.monto);
      });
      const si = (parseFloat(Cs).toFixed(2) === parseFloat(this.Total).toFixed(2)) ? 1 : 0;
      console.log(si);
      if (parseFloat(Cs).toFixed(2) === parseFloat(this.Total).toFixed(2)) {
        const limite = (this.Doc.length - 1);
        for (let i = 0; (i <= limite); i += 1) {
          if (this.Doc[i].nro_doc === 'Diferencia') {
            if (this.Doc[i].monto > 0) {
              this.alert('La Diferencia debe ser Distribuida en su totalidad');
            } else if (this.validateForm() !== false) {
              updateCobranza(this.data());
              this.closeShow();
              this.alert('Edito Cobranza Correctamente', 'info');
            } else {
              this.alert('Debe colocar la Fecha Cotabilizacion ', 'warning', false);
            }
            i = this.Doc.length;
          } else if (i === limite) {
            if (this.validateForm() !== false) {
              updateCobranza(this.data());
              this.closeShow();
              this.alert('Edito Cobranza Correctamente', 'info');
            } else {
              this.alert('Debe colocar la Fecha Cotabilizacion ', 'warning', false);
            }
          }
        }
      } else {
        this.alert('La suma de los Documentos debe ser igual al total', 'warning', false);
      }
    },
    BOtrospago() {
      if (this.selected.length === 1) {
        // eslint-disable-next-line eqeqeq
        if (this.selected[0].discount == 1) {
          this.alert('El documento seleccionado no se le puede generar Otro Pago');
        } else {
          this.showCobranzaOtrosPagos();
        }
      } else {
        this.alert('Debe seleccionar un solo documento, para generar Otro Pago');
      }
    },
    response(api) {
      this.$store.commit('message/LOADING', true);
      try {
        this.$store.commit('message/MESSAGE', api.data.message);
        this.$store.commit('message/TYPE', 'success');
        this.$store.dispatch('message/run');
        // this.$store.dispatch('cobranza/cobranzaData', this.profile);
      } catch (error) {
        // eslint-disable-next-line no-alert
        alert(error);
      }
      this.$store.commit('message/LOADING', false);
    },
    alert(message, type = 'info', reload = true) {
      this.$store.commit('message/MESSAGE', message);
      this.$store.commit('message/TYPE', type);
      this.$store.commit('message/RELOAD', reload);
      this.$store.dispatch('message/run');
    },
    validateForm() {
      if (this.FechaM === '' || this.FechaM === null) {
        return false;
      }
      return true;
    },
    validar(permiso, accion) {
      let valor = false;
      permiso.forEach((permisos) => {
        // eslint-disable-next-line eqeqeq
        if (permisos.accion == accion) {
          valor = true;
        }
      });
      return valor;
    },
  },
  components: {
    CobranzaDetalle, DescuentoFrom, CobranzaOtrosPagos,
  },
};
</script>
