var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('Toolbar'),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-cash ")]),_vm._v(" "+_vm._s(_vm.profile.organization.name)+" - Cobranza "),_c('v-spacer')],1),_c('v-row',[_c('v-col',{attrs:{"sm":"5"}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.validar(_vm.profile.permissions,'10002')),expression:"validar(profile.permissions,'10002')"}],staticClass:"mx-4",attrs:{"title":"Registrar Cobranza","tile":"","color":"grey lighten-2"},on:{"click":function($event){return _vm.openFormCobranza()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-cash-multiple ")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.validar(_vm.profile.permissions,'10002')),expression:"validar(profile.permissions,'10002')"}],staticClass:"mx-4",attrs:{"title":"Registrar Anticipo","tile":"","color":"grey lighten-2"},on:{"click":function($event){return _vm.openAnticipo()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-credit-card-plus ")])],1)],1),_c('v-col',{staticClass:"pl-4",attrs:{"sm":"7"}},[_c('v-autocomplete',{staticClass:"mt-0 pt-0 mx-4 pl-3",attrs:{"label":"Clientes","items":_vm.clients,"item-text":"name","item-value":"name","color":"grey darken-4"},on:{"change":_vm.updateClient},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-card',{staticClass:"mx-auto",attrs:{"width":"98%"}},[_c('v-data-table',{staticClass:"elevation-24",attrs:{"headers":_vm.headers,"items":_vm.item,"search":_vm.search,"single-select":_vm.singleSelect,"loading":_vm.loading,"sort-by":"date_doc","sort-desc":true,"loading-text":"Cargando Datos","show-select":"","item-key":"fact_key","no-data-text":"no hay datos","no-results-text":"Cliente sin documentos","header-props":{
              sortByText: 'Ordenar',
            },"footer-props":{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-text': 'Documentos por página',
            },"multiSort":""},on:{"input":function($event){return _vm.enterSelect()}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-switch',{staticClass:"pa-3",attrs:{"label":"Selección simple"},model:{value:(_vm.singleSelect),callback:function ($$v) {_vm.singleSelect=$$v},expression:"singleSelect"}})]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('CobranzaForm'),_c('AnticipoForm')],1)],1)],1),_c('SetLogout'),_c('Historicobcv')],1)}
var staticRenderFns = []

export { render, staticRenderFns }