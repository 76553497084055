import {
  reportes,
  // condicion,
  // detallado,
} from '../../config/api/reportes';

export default {

  name: 'reportes',

  namespaced: true,
  state: {
    item: [],
    id: '',
    descripcion: null,
    status: [],
    lista_precio: [],
    user_name: null,
    vendedor: [],
    codigo: [],
    // SELECCIONADO
    formData: [],
  },
  mutations: {
    // PRINCIPAL
    SET_DATA(state, parameter) {
      console.log('Hola');
      console.log(parameter.data);
      state.item = parameter.data;
    },
    SHOW(state) {
      console.log(state.show);
      state.show = !state.show;
    },
    SET_IS_ADD(state, parameter) {
      state.isAdd = parameter;
    },
    /* SET_DATA_ESTATUS(state, parameter) {
      console.log(parameter.data);
      state.status = parameter.data;
    },
    SET_DATA_EJECUTIVO(state, parameter) {
      console.log(parameter.data);
      state.ejecutivo = parameter.data;
    }, */
    SET_ID(state, parameter) {
      state.id = parameter;
    },
    SET_ESTATUS(state, parameter) {
      console.log(parameter);
      state.status = parameter;
    },
    SET_LISTA(state, parameter) {
      console.log(parameter);
      state.lista_precio = parameter;
    },
    SET_EJECUTIVO(state, parameter) {
      console.log(parameter);
      state.vendedor = parameter;
    },
    SET_CODIGO(state, parameter) {
      console.log(parameter);
      state.codigo = parameter;
    },
    // FORMULARIO
    SET_FORM_DATA(state, parameter) {
      state.formData = parameter;
    },
  },
  actions: {
    async data() {
      // console.log(parameter);
      // commit('SET_DATA_EJECUTIVO', await ejecutivo(parameter));
      // commit('SET_DATA_CONDICION', await condicion(parameter));
      // commit('SET_DATA', await reportes(parameter));
      // commit('SET_DATA', await detallado(parameter));
    },
    /* async initializingForm({ commit }, parameter) {
      console.log(parameter);
      commit('SET_DATA_CONDICION', await condicion(parameter));
      commit('SET_DATA_EJECUTIVO', await ejecutivo(parameter));
      commit('SET_DATA', await reportes(parameter));
    }, */
    async add({ commit }, parameter) {
      console.log(parameter);
      // commit('SET_ID', parameter.id);
      commit('SET_EJECUTIVO', parameter.user_name);
      commit('SET_DATA', await reportes(parameter));
      commit('SHOW');
    },
    reset({ commit }) {
      commit('SET_EJECUTIVO', '');
      commit('SHOW');
    },
    toAssignEstatus({ commit }, parameter) {
      commit('SET_ESTATUS', parameter);
    },
    toAssignlistasdo({ commit }, parameter) {
      commit('SET_LISTA', parameter);
    },
    toAssignEjecutivo({ commit }, parameter) {
      commit('SET_EJECUTIVO', parameter);
    },
    toAssignCodigo({ commit }, parameter) {
      commit('SET_CODIGO', parameter);
    },
  },
};
