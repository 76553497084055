var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('Toolbar'),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-application ")]),_vm._v(" "+_vm._s(_vm.profile.organization.name)+" - Ejecutivos "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card',{staticClass:"mx-auto",attrs:{"width":"98%"}},[_c('v-data-table',{staticClass:"elevation-24",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":"true","loading-text":"Cargando Datos","sort-by":"user_name","header-props":{
            sortByText: 'Ordenar',
          },"footer-props":{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            'items-per-page-text': 'Ejecutivos por página',
          },"multiSort":""},scopedSlots:_vm._u([{key:"item.estado",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.estado),"dark":"","x-small":""}},[(item.estado === '1')?_c('span',[_vm._v(" Activo ")]):_c('span',[_vm._v(" Inactivo ")])])]}}],null,true)})],1)],1)],1)],1),_c('SetLogout'),_c('Historicobcv')],1)}
var staticRenderFns = []

export { render, staticRenderFns }