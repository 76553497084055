<template>
  <v-row justify="center">
    <v-dialog v-model="show_img" max-width="500" enctype="multipart/form-data">
      <v-card enctype="multipart/form-data">
        <v-toolbar :color="profile.organization.color" dark>
            <v-icon class='mr-2'> mdi-application </v-icon>
              Cargar Imagen
        </v-toolbar>
          <v-container >
              <v-col class="text-center" cols="12" sm="12">
                <span class="headline" style="color: black"> {{ formCompany }} - {{ formOrg }}</span>
              </v-col>
              <v-col cols="6" sm="6" offset="3">
              <img
                :src="photo"
                height="auto"
                width="200"
              >
              </v-col>
              <form @submit.prevent="submit" enctype="multipart/form-data">
                <v-col cols="8" sm="8">
                  <v-file-input truncate-length="15"
                  prepend-icon="mdi-camera"
                  label="Seleccionar imagen"
                  accept="image/png, image/jpeg, image/bmp, image/jpg, image/webp"
                  v-model="imga"
                  placeholder="Subir Imagen"
                  enctype="multipart/form-data"
                  name="imga">
                  </v-file-input>
                </v-col>
              <v-card-actions>
                <v-divider></v-divider>
                <v-btn @click="imgclose()" text color="red">Cerrar</v-btn>
                <v-btn @click="submit()" text color="blue">Cargar Imagen</v-btn>
              </v-card-actions>
              </form>
          </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/extensions
import { mapState, mapActions, mapMutations } from 'vuex';
// import Axios from 'axios';
import Axios from 'axios';
// eslint-disable-next-line import/extensions
import Profile from '../../plugins/profile';

export default {
  name: 'img-organization',
  data: () => ({
    imga: null,
  }),
  computed: {
    ...mapState('organization', ['item', 'show_img', 'photo', 'org', 'company']),
    profile() {
      return Profile.get();
    },
    formCompany: {
      get() {
        return this.company;
      },
    },
    formOrg: {
      set(value) {
        this.$store.dispatch('organization/toAssignOrg', value);
      },
      get() {
        return this.org;
      },
    },
    formImagen: {
      set(value) {
        this.$store.dispatch('products/toAssignImagen', value);
      },
      get() {
        return this.photo;
      },
    },
  },
  methods: {
    ...mapMutations('organization', ['SHOW_IMG']),
    ...mapActions('organization', ['imgclose']),
    submit() {
      const fd = new FormData();
      fd.append('img', this.imga);
      fd.append('orgcode', this.org);
      fd.append('orgname', this.employ);
      Axios.post('https://www.sistemacrm.com.ve/backend/public/api/organization/logo', fd).then((res) => {
        this.response(res);
      }).catch((err) => console.log(err));
    },
    response(api) {
      this.$store.commit('message/LOADING', true);
      try {
        if (api.status === 203) {
          this.$store.commit('message/MESSAGE', api.data.message);
          this.$store.commit('message/TYPE', 'info');
          this.$store.dispatch('message/run');
        } else {
          this.$store.commit('message/MESSAGE', api.data.message);
          this.$store.commit('message/TYPE', 'success');
          this.$store.dispatch('message/run');
        }
      } catch (error) {
        // eslint-disable-next-line no-alert
        alert(error);
      }
      this.$store.commit('message/LOADING', false);
    },
  },
};
</script>
